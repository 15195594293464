/* eslint-disable */ 
// source: sip/storeroom/storeroom.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.sip.storeroom.ConfirmExternalStorageRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ConfirmExternalStorageResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ConfirmReservationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ConfirmReservationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ConfirmReturnReservationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ConfirmReturnReservationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateExternalStorageDateRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateExternalStorageDateResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateExternalStorageRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateExternalStorageResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateItemRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateItemResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateOrganisationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateOrganisationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreatePlaceRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreatePlaceResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateReservationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateReservationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateStorageUnitRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateStorageUnitResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateTagRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.CreateTagResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteExternalStorageDateRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteExternalStorageDateResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteExternalStorageRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteExternalStorageResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteItemRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteItemResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteOrganisationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteOrganisationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeletePlaceRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeletePlaceResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteReservationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteReservationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteStorageUnitRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteStorageUnitResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteTagRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.DeleteTagResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ExternalStorageAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.ExternalStorageDateAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.GetExternalStorageDateAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.GetExternalStorageRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.GetExternalStorageResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.GetItemRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.GetItemResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.GetOrganisationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.GetOrganisationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.GetPlaceRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.GetPlaceResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.GetReservationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.GetReservationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.GetStorageUnitRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.GetStorageUnitResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.GetTagResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ItemAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.ItemStorageUnitRelationAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.ListExternalStorageDatesRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListExternalStorageDatesResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListExternalStoragesRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListExternalStoragesResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListItemsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListItemsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListMyConfirmedReservationsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListMyConfirmedReservationsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListMyUserReservationsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListMyUserReservationsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationExternalStoragesRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationExternalStoragesResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationItemsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationItemsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationPlacesRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationPlacesResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationReservationsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationReservationsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationStorageUnitsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationStorageUnitsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOrganisationsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOwnerReservationsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListOwnerReservationsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListPlaceStorageUnitsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListPlaceStorageUnitsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListPlacesRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListPlacesResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListRentableItemsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListRentableItemsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListReservationsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListReservationsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListStorageUnitItemsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListStorageUnitItemsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListStorageUnitsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListStorageUnitsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListTagsRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListTagsResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.ListUsersRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.ListUsersResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.OrganisationAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.PlaceAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.RentableItem', null, global);
goog.exportSymbol('proto.sip.storeroom.ReservationAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.ReservationItemRelationAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.ShortReservationAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.StorageUnitAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.StorageUnitTypes', null, global);
goog.exportSymbol('proto.sip.storeroom.TagAttributes', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateExternalStorageDateRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateExternalStorageDateResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateExternalStorageRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateExternalStorageResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateItemRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateItemResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateOrganisationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateOrganisationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdatePlaceRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdatePlaceResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateReservationRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateReservationResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateStorageUnitRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateStorageUnitResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateTagRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateTagResponse', null, global);
goog.exportSymbol('proto.sip.storeroom.UpdateUserRequest', null, global);
goog.exportSymbol('proto.sip.storeroom.UserAttributes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.OrganisationAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.OrganisationAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.OrganisationAttributes.displayName = 'proto.sip.storeroom.OrganisationAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateOrganisationRequest.displayName = 'proto.sip.storeroom.CreateOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateOrganisationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateOrganisationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateOrganisationResponse.displayName = 'proto.sip.storeroom.CreateOrganisationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteOrganisationRequest.displayName = 'proto.sip.storeroom.DeleteOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteOrganisationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteOrganisationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteOrganisationResponse.displayName = 'proto.sip.storeroom.DeleteOrganisationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateOrganisationRequest.displayName = 'proto.sip.storeroom.UpdateOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateOrganisationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateOrganisationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateOrganisationResponse.displayName = 'proto.sip.storeroom.UpdateOrganisationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetOrganisationRequest.displayName = 'proto.sip.storeroom.GetOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetOrganisationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetOrganisationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetOrganisationResponse.displayName = 'proto.sip.storeroom.GetOrganisationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationsRequest.displayName = 'proto.sip.storeroom.ListOrganisationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListOrganisationsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationsResponse.displayName = 'proto.sip.storeroom.ListOrganisationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ItemStorageUnitRelationAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ItemStorageUnitRelationAttributes.displayName = 'proto.sip.storeroom.ItemStorageUnitRelationAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ItemAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ItemAttributes.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ItemAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ItemAttributes.displayName = 'proto.sip.storeroom.ItemAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateItemRequest.displayName = 'proto.sip.storeroom.CreateItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateItemResponse.displayName = 'proto.sip.storeroom.CreateItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteItemRequest.displayName = 'proto.sip.storeroom.DeleteItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteItemResponse.displayName = 'proto.sip.storeroom.DeleteItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateItemRequest.displayName = 'proto.sip.storeroom.UpdateItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateItemResponse.displayName = 'proto.sip.storeroom.UpdateItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetItemRequest.displayName = 'proto.sip.storeroom.GetItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.GetItemResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.GetItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetItemResponse.displayName = 'proto.sip.storeroom.GetItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListRentableItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListRentableItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListRentableItemsRequest.displayName = 'proto.sip.storeroom.ListRentableItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.RentableItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.RentableItem.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.RentableItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.RentableItem.displayName = 'proto.sip.storeroom.RentableItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListRentableItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListRentableItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListRentableItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListRentableItemsResponse.displayName = 'proto.sip.storeroom.ListRentableItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationItemsRequest.displayName = 'proto.sip.storeroom.ListOrganisationItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListOrganisationItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationItemsResponse.displayName = 'proto.sip.storeroom.ListOrganisationItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListItemsRequest.displayName = 'proto.sip.storeroom.ListItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListItemsResponse.displayName = 'proto.sip.storeroom.ListItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.TagAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.TagAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.TagAttributes.displayName = 'proto.sip.storeroom.TagAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateTagRequest.displayName = 'proto.sip.storeroom.CreateTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateTagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateTagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateTagResponse.displayName = 'proto.sip.storeroom.CreateTagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteTagRequest.displayName = 'proto.sip.storeroom.DeleteTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteTagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteTagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteTagResponse.displayName = 'proto.sip.storeroom.DeleteTagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateTagRequest.displayName = 'proto.sip.storeroom.UpdateTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateTagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateTagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateTagResponse.displayName = 'proto.sip.storeroom.UpdateTagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListTagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListTagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListTagsRequest.displayName = 'proto.sip.storeroom.ListTagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetTagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetTagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetTagResponse.displayName = 'proto.sip.storeroom.GetTagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListTagsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListTagsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListTagsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListTagsResponse.displayName = 'proto.sip.storeroom.ListTagsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.StorageUnitAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.StorageUnitAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.StorageUnitAttributes.displayName = 'proto.sip.storeroom.StorageUnitAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateStorageUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateStorageUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateStorageUnitRequest.displayName = 'proto.sip.storeroom.CreateStorageUnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateStorageUnitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateStorageUnitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateStorageUnitResponse.displayName = 'proto.sip.storeroom.CreateStorageUnitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteStorageUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteStorageUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteStorageUnitRequest.displayName = 'proto.sip.storeroom.DeleteStorageUnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteStorageUnitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteStorageUnitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteStorageUnitResponse.displayName = 'proto.sip.storeroom.DeleteStorageUnitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateStorageUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateStorageUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateStorageUnitRequest.displayName = 'proto.sip.storeroom.UpdateStorageUnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateStorageUnitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateStorageUnitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateStorageUnitResponse.displayName = 'proto.sip.storeroom.UpdateStorageUnitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetStorageUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetStorageUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetStorageUnitRequest.displayName = 'proto.sip.storeroom.GetStorageUnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetStorageUnitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetStorageUnitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetStorageUnitResponse.displayName = 'proto.sip.storeroom.GetStorageUnitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationStorageUnitsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationStorageUnitsRequest.displayName = 'proto.sip.storeroom.ListOrganisationStorageUnitsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListOrganisationStorageUnitsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationStorageUnitsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationStorageUnitsResponse.displayName = 'proto.sip.storeroom.ListOrganisationStorageUnitsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListStorageUnitsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListStorageUnitsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListStorageUnitsRequest.displayName = 'proto.sip.storeroom.ListStorageUnitsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListStorageUnitsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListStorageUnitsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListStorageUnitsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListStorageUnitsResponse.displayName = 'proto.sip.storeroom.ListStorageUnitsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListStorageUnitItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListStorageUnitItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListStorageUnitItemsRequest.displayName = 'proto.sip.storeroom.ListStorageUnitItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListStorageUnitItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListStorageUnitItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListStorageUnitItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListStorageUnitItemsResponse.displayName = 'proto.sip.storeroom.ListStorageUnitItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.PlaceAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.PlaceAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.PlaceAttributes.displayName = 'proto.sip.storeroom.PlaceAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreatePlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreatePlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreatePlaceRequest.displayName = 'proto.sip.storeroom.CreatePlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreatePlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreatePlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreatePlaceResponse.displayName = 'proto.sip.storeroom.CreatePlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeletePlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeletePlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeletePlaceRequest.displayName = 'proto.sip.storeroom.DeletePlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeletePlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeletePlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeletePlaceResponse.displayName = 'proto.sip.storeroom.DeletePlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdatePlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdatePlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdatePlaceRequest.displayName = 'proto.sip.storeroom.UpdatePlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdatePlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdatePlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdatePlaceResponse.displayName = 'proto.sip.storeroom.UpdatePlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetPlaceRequest.displayName = 'proto.sip.storeroom.GetPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetPlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetPlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetPlaceResponse.displayName = 'proto.sip.storeroom.GetPlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListPlaceStorageUnitsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListPlaceStorageUnitsRequest.displayName = 'proto.sip.storeroom.ListPlaceStorageUnitsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListPlaceStorageUnitsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListPlaceStorageUnitsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListPlaceStorageUnitsResponse.displayName = 'proto.sip.storeroom.ListPlaceStorageUnitsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationPlacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationPlacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationPlacesRequest.displayName = 'proto.sip.storeroom.ListOrganisationPlacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationPlacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListOrganisationPlacesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationPlacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationPlacesResponse.displayName = 'proto.sip.storeroom.ListOrganisationPlacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListPlacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListPlacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListPlacesRequest.displayName = 'proto.sip.storeroom.ListPlacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListPlacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListPlacesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListPlacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListPlacesResponse.displayName = 'proto.sip.storeroom.ListPlacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ReservationItemRelationAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ReservationItemRelationAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ReservationItemRelationAttributes.displayName = 'proto.sip.storeroom.ReservationItemRelationAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ReservationAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ReservationAttributes.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ReservationAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ReservationAttributes.displayName = 'proto.sip.storeroom.ReservationAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateReservationRequest.displayName = 'proto.sip.storeroom.CreateReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateReservationResponse.displayName = 'proto.sip.storeroom.CreateReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteReservationRequest.displayName = 'proto.sip.storeroom.DeleteReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteReservationResponse.displayName = 'proto.sip.storeroom.DeleteReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateReservationRequest.displayName = 'proto.sip.storeroom.UpdateReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateReservationResponse.displayName = 'proto.sip.storeroom.UpdateReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ConfirmReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ConfirmReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ConfirmReservationRequest.displayName = 'proto.sip.storeroom.ConfirmReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ConfirmReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ConfirmReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ConfirmReservationResponse.displayName = 'proto.sip.storeroom.ConfirmReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ConfirmReturnReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ConfirmReturnReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ConfirmReturnReservationRequest.displayName = 'proto.sip.storeroom.ConfirmReturnReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ConfirmReturnReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ConfirmReturnReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ConfirmReturnReservationResponse.displayName = 'proto.sip.storeroom.ConfirmReturnReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetReservationRequest.displayName = 'proto.sip.storeroom.GetReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetReservationResponse.displayName = 'proto.sip.storeroom.GetReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ShortReservationAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ShortReservationAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ShortReservationAttributes.displayName = 'proto.sip.storeroom.ShortReservationAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListMyUserReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListMyUserReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListMyUserReservationsRequest.displayName = 'proto.sip.storeroom.ListMyUserReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListMyUserReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListMyUserReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListMyUserReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListMyUserReservationsResponse.displayName = 'proto.sip.storeroom.ListMyUserReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListMyConfirmedReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListMyConfirmedReservationsRequest.displayName = 'proto.sip.storeroom.ListMyConfirmedReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListMyConfirmedReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListMyConfirmedReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListMyConfirmedReservationsResponse.displayName = 'proto.sip.storeroom.ListMyConfirmedReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationReservationsRequest.displayName = 'proto.sip.storeroom.ListOrganisationReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListOrganisationReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationReservationsResponse.displayName = 'proto.sip.storeroom.ListOrganisationReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOwnerReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListOwnerReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOwnerReservationsRequest.displayName = 'proto.sip.storeroom.ListOwnerReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOwnerReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListOwnerReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListOwnerReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOwnerReservationsResponse.displayName = 'proto.sip.storeroom.ListOwnerReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListReservationsRequest.displayName = 'proto.sip.storeroom.ListReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListReservationsResponse.displayName = 'proto.sip.storeroom.ListReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.displayName = 'proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ExternalStorageAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ExternalStorageAttributes.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ExternalStorageAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ExternalStorageAttributes.displayName = 'proto.sip.storeroom.ExternalStorageAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateExternalStorageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateExternalStorageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateExternalStorageRequest.displayName = 'proto.sip.storeroom.CreateExternalStorageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateExternalStorageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateExternalStorageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateExternalStorageResponse.displayName = 'proto.sip.storeroom.CreateExternalStorageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteExternalStorageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteExternalStorageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteExternalStorageRequest.displayName = 'proto.sip.storeroom.DeleteExternalStorageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteExternalStorageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteExternalStorageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteExternalStorageResponse.displayName = 'proto.sip.storeroom.DeleteExternalStorageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateExternalStorageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateExternalStorageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateExternalStorageRequest.displayName = 'proto.sip.storeroom.UpdateExternalStorageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateExternalStorageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateExternalStorageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateExternalStorageResponse.displayName = 'proto.sip.storeroom.UpdateExternalStorageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ConfirmExternalStorageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ConfirmExternalStorageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ConfirmExternalStorageRequest.displayName = 'proto.sip.storeroom.ConfirmExternalStorageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ConfirmExternalStorageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ConfirmExternalStorageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ConfirmExternalStorageResponse.displayName = 'proto.sip.storeroom.ConfirmExternalStorageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetExternalStorageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetExternalStorageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetExternalStorageRequest.displayName = 'proto.sip.storeroom.GetExternalStorageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetExternalStorageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetExternalStorageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetExternalStorageResponse.displayName = 'proto.sip.storeroom.GetExternalStorageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationExternalStoragesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationExternalStoragesRequest.displayName = 'proto.sip.storeroom.ListOrganisationExternalStoragesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListOrganisationExternalStoragesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListOrganisationExternalStoragesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListOrganisationExternalStoragesResponse.displayName = 'proto.sip.storeroom.ListOrganisationExternalStoragesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListExternalStoragesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListExternalStoragesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListExternalStoragesRequest.displayName = 'proto.sip.storeroom.ListExternalStoragesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListExternalStoragesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListExternalStoragesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListExternalStoragesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListExternalStoragesResponse.displayName = 'proto.sip.storeroom.ListExternalStoragesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ExternalStorageDateAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ExternalStorageDateAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ExternalStorageDateAttributes.displayName = 'proto.sip.storeroom.ExternalStorageDateAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateExternalStorageDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateExternalStorageDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateExternalStorageDateRequest.displayName = 'proto.sip.storeroom.CreateExternalStorageDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.CreateExternalStorageDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.CreateExternalStorageDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.CreateExternalStorageDateResponse.displayName = 'proto.sip.storeroom.CreateExternalStorageDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteExternalStorageDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteExternalStorageDateRequest.displayName = 'proto.sip.storeroom.DeleteExternalStorageDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.DeleteExternalStorageDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.DeleteExternalStorageDateResponse.displayName = 'proto.sip.storeroom.DeleteExternalStorageDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateExternalStorageDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateExternalStorageDateRequest.displayName = 'proto.sip.storeroom.UpdateExternalStorageDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateExternalStorageDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateExternalStorageDateResponse.displayName = 'proto.sip.storeroom.UpdateExternalStorageDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.GetExternalStorageDateAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.GetExternalStorageDateAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.GetExternalStorageDateAttributes.displayName = 'proto.sip.storeroom.GetExternalStorageDateAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListExternalStorageDatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListExternalStorageDatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListExternalStorageDatesRequest.displayName = 'proto.sip.storeroom.ListExternalStorageDatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListExternalStorageDatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListExternalStorageDatesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListExternalStorageDatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListExternalStorageDatesResponse.displayName = 'proto.sip.storeroom.ListExternalStorageDatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListUsersRequest.displayName = 'proto.sip.storeroom.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.storeroom.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.storeroom.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.ListUsersResponse.displayName = 'proto.sip.storeroom.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UpdateUserRequest.displayName = 'proto.sip.storeroom.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.storeroom.UserAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.storeroom.UserAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.storeroom.UserAttributes.displayName = 'proto.sip.storeroom.UserAttributes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.OrganisationAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.OrganisationAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.OrganisationAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoPath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactMail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contactAddress: jspb.Message.getFieldWithDefault(msg, 6, ""),
    editRole: jspb.Message.getFieldWithDefault(msg, 7, ""),
    viewRole: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.OrganisationAttributes}
 */
proto.sip.storeroom.OrganisationAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.OrganisationAttributes;
  return proto.sip.storeroom.OrganisationAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.OrganisationAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.OrganisationAttributes}
 */
proto.sip.storeroom.OrganisationAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoPath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactMail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditRole(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setViewRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.OrganisationAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.OrganisationAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.OrganisationAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactMail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContactAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEditRole();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getViewRole();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string short_name = 1;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_path = 3;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getLogoPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setLogoPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contact_mail = 4;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getContactMail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setContactMail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_phone = 5;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contact_address = 6;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getContactAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setContactAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string edit_role = 7;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getEditRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setEditRole = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string view_role = 8;
 * @return {string}
 */
proto.sip.storeroom.OrganisationAttributes.prototype.getViewRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.OrganisationAttributes} returns this
 */
proto.sip.storeroom.OrganisationAttributes.prototype.setViewRole = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationInfo: (f = msg.getOrganisationInfo()) && proto.sip.storeroom.OrganisationAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateOrganisationRequest}
 */
proto.sip.storeroom.CreateOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateOrganisationRequest;
  return proto.sip.storeroom.CreateOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateOrganisationRequest}
 */
proto.sip.storeroom.CreateOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.OrganisationAttributes;
      reader.readMessage(value,proto.sip.storeroom.OrganisationAttributes.deserializeBinaryFromReader);
      msg.setOrganisationInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.OrganisationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrganisationAttributes organisation_info = 1;
 * @return {?proto.sip.storeroom.OrganisationAttributes}
 */
proto.sip.storeroom.CreateOrganisationRequest.prototype.getOrganisationInfo = function() {
  return /** @type{?proto.sip.storeroom.OrganisationAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.OrganisationAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.OrganisationAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateOrganisationRequest} returns this
*/
proto.sip.storeroom.CreateOrganisationRequest.prototype.setOrganisationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateOrganisationRequest} returns this
 */
proto.sip.storeroom.CreateOrganisationRequest.prototype.clearOrganisationInfo = function() {
  return this.setOrganisationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateOrganisationRequest.prototype.hasOrganisationInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateOrganisationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateOrganisationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateOrganisationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateOrganisationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && proto.sip.storeroom.GetOrganisationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateOrganisationResponse}
 */
proto.sip.storeroom.CreateOrganisationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateOrganisationResponse;
  return proto.sip.storeroom.CreateOrganisationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateOrganisationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateOrganisationResponse}
 */
proto.sip.storeroom.CreateOrganisationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetOrganisationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetOrganisationResponse.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateOrganisationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateOrganisationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateOrganisationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateOrganisationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetOrganisationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrganisationResponse organisation = 1;
 * @return {?proto.sip.storeroom.GetOrganisationResponse}
 */
proto.sip.storeroom.CreateOrganisationResponse.prototype.getOrganisation = function() {
  return /** @type{?proto.sip.storeroom.GetOrganisationResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetOrganisationResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetOrganisationResponse|undefined} value
 * @return {!proto.sip.storeroom.CreateOrganisationResponse} returns this
*/
proto.sip.storeroom.CreateOrganisationResponse.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateOrganisationResponse} returns this
 */
proto.sip.storeroom.CreateOrganisationResponse.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateOrganisationResponse.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteOrganisationRequest}
 */
proto.sip.storeroom.DeleteOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteOrganisationRequest;
  return proto.sip.storeroom.DeleteOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteOrganisationRequest}
 */
proto.sip.storeroom.DeleteOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteOrganisationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteOrganisationRequest} returns this
 */
proto.sip.storeroom.DeleteOrganisationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteOrganisationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteOrganisationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteOrganisationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteOrganisationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteOrganisationResponse}
 */
proto.sip.storeroom.DeleteOrganisationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteOrganisationResponse;
  return proto.sip.storeroom.DeleteOrganisationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteOrganisationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteOrganisationResponse}
 */
proto.sip.storeroom.DeleteOrganisationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteOrganisationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteOrganisationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteOrganisationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteOrganisationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteOrganisationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteOrganisationResponse} returns this
 */
proto.sip.storeroom.DeleteOrganisationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organisationInfo: (f = msg.getOrganisationInfo()) && proto.sip.storeroom.OrganisationAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateOrganisationRequest}
 */
proto.sip.storeroom.UpdateOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateOrganisationRequest;
  return proto.sip.storeroom.UpdateOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateOrganisationRequest}
 */
proto.sip.storeroom.UpdateOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.OrganisationAttributes;
      reader.readMessage(value,proto.sip.storeroom.OrganisationAttributes.deserializeBinaryFromReader);
      msg.setOrganisationInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganisationInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.OrganisationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdateOrganisationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateOrganisationRequest} returns this
 */
proto.sip.storeroom.UpdateOrganisationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrganisationAttributes organisation_info = 2;
 * @return {?proto.sip.storeroom.OrganisationAttributes}
 */
proto.sip.storeroom.UpdateOrganisationRequest.prototype.getOrganisationInfo = function() {
  return /** @type{?proto.sip.storeroom.OrganisationAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.OrganisationAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.OrganisationAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateOrganisationRequest} returns this
*/
proto.sip.storeroom.UpdateOrganisationRequest.prototype.setOrganisationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateOrganisationRequest} returns this
 */
proto.sip.storeroom.UpdateOrganisationRequest.prototype.clearOrganisationInfo = function() {
  return this.setOrganisationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateOrganisationRequest.prototype.hasOrganisationInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateOrganisationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateOrganisationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateOrganisationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateOrganisationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && proto.sip.storeroom.GetOrganisationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateOrganisationResponse}
 */
proto.sip.storeroom.UpdateOrganisationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateOrganisationResponse;
  return proto.sip.storeroom.UpdateOrganisationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateOrganisationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateOrganisationResponse}
 */
proto.sip.storeroom.UpdateOrganisationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetOrganisationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetOrganisationResponse.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateOrganisationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateOrganisationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateOrganisationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateOrganisationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetOrganisationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrganisationResponse organisation = 1;
 * @return {?proto.sip.storeroom.GetOrganisationResponse}
 */
proto.sip.storeroom.UpdateOrganisationResponse.prototype.getOrganisation = function() {
  return /** @type{?proto.sip.storeroom.GetOrganisationResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetOrganisationResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetOrganisationResponse|undefined} value
 * @return {!proto.sip.storeroom.UpdateOrganisationResponse} returns this
*/
proto.sip.storeroom.UpdateOrganisationResponse.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateOrganisationResponse} returns this
 */
proto.sip.storeroom.UpdateOrganisationResponse.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateOrganisationResponse.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetOrganisationRequest}
 */
proto.sip.storeroom.GetOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetOrganisationRequest;
  return proto.sip.storeroom.GetOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetOrganisationRequest}
 */
proto.sip.storeroom.GetOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetOrganisationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetOrganisationRequest} returns this
 */
proto.sip.storeroom.GetOrganisationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetOrganisationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetOrganisationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetOrganisationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organisationInfo: (f = msg.getOrganisationInfo()) && proto.sip.storeroom.OrganisationAttributes.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateUser: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetOrganisationResponse}
 */
proto.sip.storeroom.GetOrganisationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetOrganisationResponse;
  return proto.sip.storeroom.GetOrganisationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetOrganisationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetOrganisationResponse}
 */
proto.sip.storeroom.GetOrganisationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.OrganisationAttributes;
      reader.readMessage(value,proto.sip.storeroom.OrganisationAttributes.deserializeBinaryFromReader);
      msg.setOrganisationInfo(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetOrganisationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetOrganisationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetOrganisationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganisationInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.OrganisationAttributes.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetOrganisationResponse} returns this
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OrganisationAttributes organisation_info = 2;
 * @return {?proto.sip.storeroom.OrganisationAttributes}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.getOrganisationInfo = function() {
  return /** @type{?proto.sip.storeroom.OrganisationAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.OrganisationAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.OrganisationAttributes|undefined} value
 * @return {!proto.sip.storeroom.GetOrganisationResponse} returns this
*/
proto.sip.storeroom.GetOrganisationResponse.prototype.setOrganisationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetOrganisationResponse} returns this
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.clearOrganisationInfo = function() {
  return this.setOrganisationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.hasOrganisationInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetOrganisationResponse} returns this
*/
proto.sip.storeroom.GetOrganisationResponse.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetOrganisationResponse} returns this
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string update_user = 4;
 * @return {string}
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.getUpdateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetOrganisationResponse} returns this
 */
proto.sip.storeroom.GetOrganisationResponse.prototype.setUpdateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationsRequest}
 */
proto.sip.storeroom.ListOrganisationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationsRequest;
  return proto.sip.storeroom.ListOrganisationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationsRequest}
 */
proto.sip.storeroom.ListOrganisationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListOrganisationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    proto.sip.storeroom.GetOrganisationResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationsResponse}
 */
proto.sip.storeroom.ListOrganisationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationsResponse;
  return proto.sip.storeroom.ListOrganisationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationsResponse}
 */
proto.sip.storeroom.ListOrganisationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetOrganisationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetOrganisationResponse.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetOrganisationResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetOrganisationResponse organisations = 1;
 * @return {!Array<!proto.sip.storeroom.GetOrganisationResponse>}
 */
proto.sip.storeroom.ListOrganisationsResponse.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetOrganisationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetOrganisationResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetOrganisationResponse>} value
 * @return {!proto.sip.storeroom.ListOrganisationsResponse} returns this
*/
proto.sip.storeroom.ListOrganisationsResponse.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetOrganisationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetOrganisationResponse}
 */
proto.sip.storeroom.ListOrganisationsResponse.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetOrganisationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListOrganisationsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationsResponse.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ItemStorageUnitRelationAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ItemStorageUnitRelationAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    storageUnitId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isInExternalStorage: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ItemStorageUnitRelationAttributes}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ItemStorageUnitRelationAttributes;
  return proto.sip.storeroom.ItemStorageUnitRelationAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ItemStorageUnitRelationAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ItemStorageUnitRelationAttributes}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStorageUnitId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInExternalStorage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ItemStorageUnitRelationAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ItemStorageUnitRelationAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStorageUnitId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsInExternalStorage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 item_id = 1;
 * @return {number}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.getItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ItemStorageUnitRelationAttributes} returns this
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.setItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 storage_unit_id = 2;
 * @return {number}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.getStorageUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ItemStorageUnitRelationAttributes} returns this
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.setStorageUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 quantity = 3;
 * @return {number}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ItemStorageUnitRelationAttributes} returns this
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_in_external_storage = 4;
 * @return {boolean}
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.getIsInExternalStorage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ItemStorageUnitRelationAttributes} returns this
 */
proto.sip.storeroom.ItemStorageUnitRelationAttributes.prototype.setIsInExternalStorage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ItemAttributes.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ItemAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ItemAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ItemAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ItemAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    organisationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    externalRent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    storageUnitRelationsList: jspb.Message.toObjectList(msg.getStorageUnitRelationsList(),
    proto.sip.storeroom.ItemStorageUnitRelationAttributes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ItemAttributes}
 */
proto.sip.storeroom.ItemAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ItemAttributes;
  return proto.sip.storeroom.ItemAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ItemAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ItemAttributes}
 */
proto.sip.storeroom.ItemAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTags(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalRent(value);
      break;
    case 6:
      var value = new proto.sip.storeroom.ItemStorageUnitRelationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ItemStorageUnitRelationAttributes.deserializeBinaryFromReader);
      msg.addStorageUnitRelations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ItemAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ItemAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ItemAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ItemAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getExternalRent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStorageUnitRelationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.sip.storeroom.ItemStorageUnitRelationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.sip.storeroom.ItemAttributes.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sip.storeroom.ItemAttributes.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 tags = 3;
 * @return {!Array<number>}
 */
proto.sip.storeroom.ItemAttributes.prototype.getTagsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int32 organisation_id = 4;
 * @return {number}
 */
proto.sip.storeroom.ItemAttributes.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool external_rent = 5;
 * @return {boolean}
 */
proto.sip.storeroom.ItemAttributes.prototype.getExternalRent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.setExternalRent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated ItemStorageUnitRelationAttributes storage_unit_relations = 6;
 * @return {!Array<!proto.sip.storeroom.ItemStorageUnitRelationAttributes>}
 */
proto.sip.storeroom.ItemAttributes.prototype.getStorageUnitRelationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.ItemStorageUnitRelationAttributes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.ItemStorageUnitRelationAttributes, 6));
};


/**
 * @param {!Array<!proto.sip.storeroom.ItemStorageUnitRelationAttributes>} value
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
*/
proto.sip.storeroom.ItemAttributes.prototype.setStorageUnitRelationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sip.storeroom.ItemStorageUnitRelationAttributes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.ItemStorageUnitRelationAttributes}
 */
proto.sip.storeroom.ItemAttributes.prototype.addStorageUnitRelations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sip.storeroom.ItemStorageUnitRelationAttributes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ItemAttributes} returns this
 */
proto.sip.storeroom.ItemAttributes.prototype.clearStorageUnitRelationsList = function() {
  return this.setStorageUnitRelationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemInfo: (f = msg.getItemInfo()) && proto.sip.storeroom.ItemAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateItemRequest}
 */
proto.sip.storeroom.CreateItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateItemRequest;
  return proto.sip.storeroom.CreateItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateItemRequest}
 */
proto.sip.storeroom.CreateItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.ItemAttributes;
      reader.readMessage(value,proto.sip.storeroom.ItemAttributes.deserializeBinaryFromReader);
      msg.setItemInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.ItemAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional ItemAttributes item_info = 1;
 * @return {?proto.sip.storeroom.ItemAttributes}
 */
proto.sip.storeroom.CreateItemRequest.prototype.getItemInfo = function() {
  return /** @type{?proto.sip.storeroom.ItemAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ItemAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.ItemAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateItemRequest} returns this
*/
proto.sip.storeroom.CreateItemRequest.prototype.setItemInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateItemRequest} returns this
 */
proto.sip.storeroom.CreateItemRequest.prototype.clearItemInfo = function() {
  return this.setItemInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateItemRequest.prototype.hasItemInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.sip.storeroom.GetItemResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateItemResponse}
 */
proto.sip.storeroom.CreateItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateItemResponse;
  return proto.sip.storeroom.CreateItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateItemResponse}
 */
proto.sip.storeroom.CreateItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetItemResponse;
      reader.readMessage(value,proto.sip.storeroom.GetItemResponse.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetItemResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetItemResponse item = 1;
 * @return {?proto.sip.storeroom.GetItemResponse}
 */
proto.sip.storeroom.CreateItemResponse.prototype.getItem = function() {
  return /** @type{?proto.sip.storeroom.GetItemResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetItemResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetItemResponse|undefined} value
 * @return {!proto.sip.storeroom.CreateItemResponse} returns this
*/
proto.sip.storeroom.CreateItemResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateItemResponse} returns this
 */
proto.sip.storeroom.CreateItemResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateItemResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deleting: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteItemRequest}
 */
proto.sip.storeroom.DeleteItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteItemRequest;
  return proto.sip.storeroom.DeleteItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteItemRequest}
 */
proto.sip.storeroom.DeleteItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeleting();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteItemRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteItemRequest} returns this
 */
proto.sip.storeroom.DeleteItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool deleting = 2;
 * @return {boolean}
 */
proto.sip.storeroom.DeleteItemRequest.prototype.getDeleting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.DeleteItemRequest} returns this
 */
proto.sip.storeroom.DeleteItemRequest.prototype.setDeleting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteItemResponse}
 */
proto.sip.storeroom.DeleteItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteItemResponse;
  return proto.sip.storeroom.DeleteItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteItemResponse}
 */
proto.sip.storeroom.DeleteItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteItemResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteItemResponse} returns this
 */
proto.sip.storeroom.DeleteItemResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemInfo: (f = msg.getItemInfo()) && proto.sip.storeroom.ItemAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateItemRequest}
 */
proto.sip.storeroom.UpdateItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateItemRequest;
  return proto.sip.storeroom.UpdateItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateItemRequest}
 */
proto.sip.storeroom.UpdateItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ItemAttributes;
      reader.readMessage(value,proto.sip.storeroom.ItemAttributes.deserializeBinaryFromReader);
      msg.setItemInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ItemAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdateItemRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateItemRequest} returns this
 */
proto.sip.storeroom.UpdateItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ItemAttributes item_info = 2;
 * @return {?proto.sip.storeroom.ItemAttributes}
 */
proto.sip.storeroom.UpdateItemRequest.prototype.getItemInfo = function() {
  return /** @type{?proto.sip.storeroom.ItemAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ItemAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ItemAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateItemRequest} returns this
*/
proto.sip.storeroom.UpdateItemRequest.prototype.setItemInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateItemRequest} returns this
 */
proto.sip.storeroom.UpdateItemRequest.prototype.clearItemInfo = function() {
  return this.setItemInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateItemRequest.prototype.hasItemInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.sip.storeroom.GetItemResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateItemResponse}
 */
proto.sip.storeroom.UpdateItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateItemResponse;
  return proto.sip.storeroom.UpdateItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateItemResponse}
 */
proto.sip.storeroom.UpdateItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetItemResponse;
      reader.readMessage(value,proto.sip.storeroom.GetItemResponse.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetItemResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetItemResponse item = 1;
 * @return {?proto.sip.storeroom.GetItemResponse}
 */
proto.sip.storeroom.UpdateItemResponse.prototype.getItem = function() {
  return /** @type{?proto.sip.storeroom.GetItemResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetItemResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetItemResponse|undefined} value
 * @return {!proto.sip.storeroom.UpdateItemResponse} returns this
*/
proto.sip.storeroom.UpdateItemResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateItemResponse} returns this
 */
proto.sip.storeroom.UpdateItemResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateItemResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetItemRequest}
 */
proto.sip.storeroom.GetItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetItemRequest;
  return proto.sip.storeroom.GetItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetItemRequest}
 */
proto.sip.storeroom.GetItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetItemRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetItemRequest} returns this
 */
proto.sip.storeroom.GetItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.GetItemResponse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemInfo: (f = msg.getItemInfo()) && proto.sip.storeroom.ItemAttributes.toObject(includeInstance, f),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateUser: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.sip.storeroom.ShortReservationAttributes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetItemResponse}
 */
proto.sip.storeroom.GetItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetItemResponse;
  return proto.sip.storeroom.GetItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetItemResponse}
 */
proto.sip.storeroom.GetItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ItemAttributes;
      reader.readMessage(value,proto.sip.storeroom.ItemAttributes.deserializeBinaryFromReader);
      msg.setItemInfo(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateUser(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 7:
      var value = new proto.sip.storeroom.ShortReservationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ShortReservationAttributes.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ItemAttributes.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUser();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.sip.storeroom.ShortReservationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetItemResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
 */
proto.sip.storeroom.GetItemResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ItemAttributes item_info = 2;
 * @return {?proto.sip.storeroom.ItemAttributes}
 */
proto.sip.storeroom.GetItemResponse.prototype.getItemInfo = function() {
  return /** @type{?proto.sip.storeroom.ItemAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ItemAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ItemAttributes|undefined} value
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
*/
proto.sip.storeroom.GetItemResponse.prototype.setItemInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
 */
proto.sip.storeroom.GetItemResponse.prototype.clearItemInfo = function() {
  return this.setItemInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetItemResponse.prototype.hasItemInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp create_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetItemResponse.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
*/
proto.sip.storeroom.GetItemResponse.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
 */
proto.sip.storeroom.GetItemResponse.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetItemResponse.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetItemResponse.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
*/
proto.sip.storeroom.GetItemResponse.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
 */
proto.sip.storeroom.GetItemResponse.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetItemResponse.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string update_user = 5;
 * @return {string}
 */
proto.sip.storeroom.GetItemResponse.prototype.getUpdateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
 */
proto.sip.storeroom.GetItemResponse.prototype.setUpdateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool deleted = 6;
 * @return {boolean}
 */
proto.sip.storeroom.GetItemResponse.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
 */
proto.sip.storeroom.GetItemResponse.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated ShortReservationAttributes reservations = 7;
 * @return {!Array<!proto.sip.storeroom.ShortReservationAttributes>}
 */
proto.sip.storeroom.GetItemResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.ShortReservationAttributes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.ShortReservationAttributes, 7));
};


/**
 * @param {!Array<!proto.sip.storeroom.ShortReservationAttributes>} value
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
*/
proto.sip.storeroom.GetItemResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sip.storeroom.ShortReservationAttributes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.ShortReservationAttributes}
 */
proto.sip.storeroom.GetItemResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sip.storeroom.ShortReservationAttributes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.GetItemResponse} returns this
 */
proto.sip.storeroom.GetItemResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListRentableItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListRentableItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListRentableItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListRentableItemsRequest}
 */
proto.sip.storeroom.ListRentableItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListRentableItemsRequest;
  return proto.sip.storeroom.ListRentableItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListRentableItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListRentableItemsRequest}
 */
proto.sip.storeroom.ListRentableItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListRentableItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListRentableItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListRentableItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 organisation_id = 1;
 * @return {number}
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListRentableItemsRequest} returns this
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListRentableItemsRequest} returns this
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListRentableItemsRequest} returns this
 */
proto.sip.storeroom.ListRentableItemsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.RentableItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.RentableItem.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.RentableItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.RentableItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.RentableItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemInfo: (f = msg.getItemInfo()) && proto.sip.storeroom.ItemAttributes.toObject(includeInstance, f),
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.sip.storeroom.ShortReservationAttributes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.RentableItem}
 */
proto.sip.storeroom.RentableItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.RentableItem;
  return proto.sip.storeroom.RentableItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.RentableItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.RentableItem}
 */
proto.sip.storeroom.RentableItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ItemAttributes;
      reader.readMessage(value,proto.sip.storeroom.ItemAttributes.deserializeBinaryFromReader);
      msg.setItemInfo(value);
      break;
    case 3:
      var value = new proto.sip.storeroom.ShortReservationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ShortReservationAttributes.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.RentableItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.RentableItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.RentableItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.RentableItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ItemAttributes.serializeBinaryToWriter
    );
  }
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sip.storeroom.ShortReservationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.RentableItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.RentableItem} returns this
 */
proto.sip.storeroom.RentableItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ItemAttributes item_info = 2;
 * @return {?proto.sip.storeroom.ItemAttributes}
 */
proto.sip.storeroom.RentableItem.prototype.getItemInfo = function() {
  return /** @type{?proto.sip.storeroom.ItemAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ItemAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ItemAttributes|undefined} value
 * @return {!proto.sip.storeroom.RentableItem} returns this
*/
proto.sip.storeroom.RentableItem.prototype.setItemInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.RentableItem} returns this
 */
proto.sip.storeroom.RentableItem.prototype.clearItemInfo = function() {
  return this.setItemInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.RentableItem.prototype.hasItemInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ShortReservationAttributes reservations = 3;
 * @return {!Array<!proto.sip.storeroom.ShortReservationAttributes>}
 */
proto.sip.storeroom.RentableItem.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.ShortReservationAttributes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.ShortReservationAttributes, 3));
};


/**
 * @param {!Array<!proto.sip.storeroom.ShortReservationAttributes>} value
 * @return {!proto.sip.storeroom.RentableItem} returns this
*/
proto.sip.storeroom.RentableItem.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sip.storeroom.ShortReservationAttributes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.ShortReservationAttributes}
 */
proto.sip.storeroom.RentableItem.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sip.storeroom.ShortReservationAttributes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.RentableItem} returns this
 */
proto.sip.storeroom.RentableItem.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListRentableItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListRentableItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListRentableItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListRentableItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListRentableItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.sip.storeroom.RentableItem.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListRentableItemsResponse}
 */
proto.sip.storeroom.ListRentableItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListRentableItemsResponse;
  return proto.sip.storeroom.ListRentableItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListRentableItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListRentableItemsResponse}
 */
proto.sip.storeroom.ListRentableItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.RentableItem;
      reader.readMessage(value,proto.sip.storeroom.RentableItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListRentableItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListRentableItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListRentableItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListRentableItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.RentableItem.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated RentableItem items = 1;
 * @return {!Array<!proto.sip.storeroom.RentableItem>}
 */
proto.sip.storeroom.ListRentableItemsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.RentableItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.RentableItem, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.RentableItem>} value
 * @return {!proto.sip.storeroom.ListRentableItemsResponse} returns this
*/
proto.sip.storeroom.ListRentableItemsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.RentableItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.RentableItem}
 */
proto.sip.storeroom.ListRentableItemsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.RentableItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListRentableItemsResponse} returns this
 */
proto.sip.storeroom.ListRentableItemsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListRentableItemsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListRentableItemsResponse} returns this
 */
proto.sip.storeroom.ListRentableItemsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationItemsRequest}
 */
proto.sip.storeroom.ListOrganisationItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationItemsRequest;
  return proto.sip.storeroom.ListOrganisationItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationItemsRequest}
 */
proto.sip.storeroom.ListOrganisationItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationItemsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationItemsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 organisation_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationItemsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationItemsRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListOrganisationItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.sip.storeroom.GetItemResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationItemsResponse}
 */
proto.sip.storeroom.ListOrganisationItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationItemsResponse;
  return proto.sip.storeroom.ListOrganisationItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationItemsResponse}
 */
proto.sip.storeroom.ListOrganisationItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetItemResponse;
      reader.readMessage(value,proto.sip.storeroom.GetItemResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetItemResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetItemResponse items = 1;
 * @return {!Array<!proto.sip.storeroom.GetItemResponse>}
 */
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetItemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetItemResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetItemResponse>} value
 * @return {!proto.sip.storeroom.ListOrganisationItemsResponse} returns this
*/
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetItemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetItemResponse}
 */
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetItemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListOrganisationItemsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationItemsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationItemsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListItemsRequest}
 */
proto.sip.storeroom.ListItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListItemsRequest;
  return proto.sip.storeroom.ListItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListItemsRequest}
 */
proto.sip.storeroom.ListItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListItemsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListItemsRequest} returns this
 */
proto.sip.storeroom.ListItemsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListItemsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListItemsRequest} returns this
 */
proto.sip.storeroom.ListItemsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.sip.storeroom.GetItemResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListItemsResponse}
 */
proto.sip.storeroom.ListItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListItemsResponse;
  return proto.sip.storeroom.ListItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListItemsResponse}
 */
proto.sip.storeroom.ListItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetItemResponse;
      reader.readMessage(value,proto.sip.storeroom.GetItemResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetItemResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetItemResponse items = 1;
 * @return {!Array<!proto.sip.storeroom.GetItemResponse>}
 */
proto.sip.storeroom.ListItemsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetItemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetItemResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetItemResponse>} value
 * @return {!proto.sip.storeroom.ListItemsResponse} returns this
*/
proto.sip.storeroom.ListItemsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetItemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetItemResponse}
 */
proto.sip.storeroom.ListItemsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetItemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListItemsResponse} returns this
 */
proto.sip.storeroom.ListItemsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListItemsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListItemsResponse} returns this
 */
proto.sip.storeroom.ListItemsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.TagAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.TagAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.TagAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.TagAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.TagAttributes}
 */
proto.sip.storeroom.TagAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.TagAttributes;
  return proto.sip.storeroom.TagAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.TagAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.TagAttributes}
 */
proto.sip.storeroom.TagAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.TagAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.TagAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.TagAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.TagAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tag_name = 1;
 * @return {string}
 */
proto.sip.storeroom.TagAttributes.prototype.getTagName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.TagAttributes} returns this
 */
proto.sip.storeroom.TagAttributes.prototype.setTagName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagInfo: (f = msg.getTagInfo()) && proto.sip.storeroom.TagAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateTagRequest}
 */
proto.sip.storeroom.CreateTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateTagRequest;
  return proto.sip.storeroom.CreateTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateTagRequest}
 */
proto.sip.storeroom.CreateTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.TagAttributes;
      reader.readMessage(value,proto.sip.storeroom.TagAttributes.deserializeBinaryFromReader);
      msg.setTagInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.TagAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional TagAttributes tag_info = 1;
 * @return {?proto.sip.storeroom.TagAttributes}
 */
proto.sip.storeroom.CreateTagRequest.prototype.getTagInfo = function() {
  return /** @type{?proto.sip.storeroom.TagAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.TagAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.TagAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateTagRequest} returns this
*/
proto.sip.storeroom.CreateTagRequest.prototype.setTagInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateTagRequest} returns this
 */
proto.sip.storeroom.CreateTagRequest.prototype.clearTagInfo = function() {
  return this.setTagInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateTagRequest.prototype.hasTagInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateTagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateTagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateTagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateTagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tag: (f = msg.getTag()) && proto.sip.storeroom.GetTagResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateTagResponse}
 */
proto.sip.storeroom.CreateTagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateTagResponse;
  return proto.sip.storeroom.CreateTagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateTagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateTagResponse}
 */
proto.sip.storeroom.CreateTagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetTagResponse;
      reader.readMessage(value,proto.sip.storeroom.GetTagResponse.deserializeBinaryFromReader);
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateTagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateTagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateTagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateTagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetTagResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTagResponse tag = 1;
 * @return {?proto.sip.storeroom.GetTagResponse}
 */
proto.sip.storeroom.CreateTagResponse.prototype.getTag = function() {
  return /** @type{?proto.sip.storeroom.GetTagResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetTagResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetTagResponse|undefined} value
 * @return {!proto.sip.storeroom.CreateTagResponse} returns this
*/
proto.sip.storeroom.CreateTagResponse.prototype.setTag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateTagResponse} returns this
 */
proto.sip.storeroom.CreateTagResponse.prototype.clearTag = function() {
  return this.setTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateTagResponse.prototype.hasTag = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteTagRequest}
 */
proto.sip.storeroom.DeleteTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteTagRequest;
  return proto.sip.storeroom.DeleteTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteTagRequest}
 */
proto.sip.storeroom.DeleteTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteTagRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteTagRequest} returns this
 */
proto.sip.storeroom.DeleteTagRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteTagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteTagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteTagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteTagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteTagResponse}
 */
proto.sip.storeroom.DeleteTagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteTagResponse;
  return proto.sip.storeroom.DeleteTagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteTagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteTagResponse}
 */
proto.sip.storeroom.DeleteTagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteTagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteTagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteTagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteTagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteTagResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteTagResponse} returns this
 */
proto.sip.storeroom.DeleteTagResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tagInfo: (f = msg.getTagInfo()) && proto.sip.storeroom.TagAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateTagRequest}
 */
proto.sip.storeroom.UpdateTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateTagRequest;
  return proto.sip.storeroom.UpdateTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateTagRequest}
 */
proto.sip.storeroom.UpdateTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.TagAttributes;
      reader.readMessage(value,proto.sip.storeroom.TagAttributes.deserializeBinaryFromReader);
      msg.setTagInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTagInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.TagAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdateTagRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateTagRequest} returns this
 */
proto.sip.storeroom.UpdateTagRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TagAttributes tag_info = 2;
 * @return {?proto.sip.storeroom.TagAttributes}
 */
proto.sip.storeroom.UpdateTagRequest.prototype.getTagInfo = function() {
  return /** @type{?proto.sip.storeroom.TagAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.TagAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.TagAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateTagRequest} returns this
*/
proto.sip.storeroom.UpdateTagRequest.prototype.setTagInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateTagRequest} returns this
 */
proto.sip.storeroom.UpdateTagRequest.prototype.clearTagInfo = function() {
  return this.setTagInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateTagRequest.prototype.hasTagInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateTagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateTagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateTagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateTagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tag: (f = msg.getTag()) && proto.sip.storeroom.GetTagResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateTagResponse}
 */
proto.sip.storeroom.UpdateTagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateTagResponse;
  return proto.sip.storeroom.UpdateTagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateTagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateTagResponse}
 */
proto.sip.storeroom.UpdateTagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetTagResponse;
      reader.readMessage(value,proto.sip.storeroom.GetTagResponse.deserializeBinaryFromReader);
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateTagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateTagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateTagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateTagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetTagResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTagResponse tag = 1;
 * @return {?proto.sip.storeroom.GetTagResponse}
 */
proto.sip.storeroom.UpdateTagResponse.prototype.getTag = function() {
  return /** @type{?proto.sip.storeroom.GetTagResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetTagResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetTagResponse|undefined} value
 * @return {!proto.sip.storeroom.UpdateTagResponse} returns this
*/
proto.sip.storeroom.UpdateTagResponse.prototype.setTag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateTagResponse} returns this
 */
proto.sip.storeroom.UpdateTagResponse.prototype.clearTag = function() {
  return this.setTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateTagResponse.prototype.hasTag = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListTagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListTagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListTagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListTagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListTagsRequest}
 */
proto.sip.storeroom.ListTagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListTagsRequest;
  return proto.sip.storeroom.ListTagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListTagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListTagsRequest}
 */
proto.sip.storeroom.ListTagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListTagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListTagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListTagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListTagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListTagsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListTagsRequest} returns this
 */
proto.sip.storeroom.ListTagsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListTagsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListTagsRequest} returns this
 */
proto.sip.storeroom.ListTagsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetTagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetTagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetTagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetTagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tagName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetTagResponse}
 */
proto.sip.storeroom.GetTagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetTagResponse;
  return proto.sip.storeroom.GetTagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetTagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetTagResponse}
 */
proto.sip.storeroom.GetTagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetTagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetTagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetTagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetTagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTagName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetTagResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetTagResponse} returns this
 */
proto.sip.storeroom.GetTagResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tag_name = 2;
 * @return {string}
 */
proto.sip.storeroom.GetTagResponse.prototype.getTagName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetTagResponse} returns this
 */
proto.sip.storeroom.GetTagResponse.prototype.setTagName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListTagsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListTagsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListTagsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListTagsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListTagsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.sip.storeroom.GetTagResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListTagsResponse}
 */
proto.sip.storeroom.ListTagsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListTagsResponse;
  return proto.sip.storeroom.ListTagsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListTagsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListTagsResponse}
 */
proto.sip.storeroom.ListTagsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetTagResponse;
      reader.readMessage(value,proto.sip.storeroom.GetTagResponse.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListTagsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListTagsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListTagsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListTagsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetTagResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetTagResponse tags = 1;
 * @return {!Array<!proto.sip.storeroom.GetTagResponse>}
 */
proto.sip.storeroom.ListTagsResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetTagResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetTagResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetTagResponse>} value
 * @return {!proto.sip.storeroom.ListTagsResponse} returns this
*/
proto.sip.storeroom.ListTagsResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetTagResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetTagResponse}
 */
proto.sip.storeroom.ListTagsResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetTagResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListTagsResponse} returns this
 */
proto.sip.storeroom.ListTagsResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListTagsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListTagsResponse} returns this
 */
proto.sip.storeroom.ListTagsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.StorageUnitAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.StorageUnitAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.StorageUnitAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    storageUnitType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isInStorageUnitId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    placeId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    organisationId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.StorageUnitAttributes}
 */
proto.sip.storeroom.StorageUnitAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.StorageUnitAttributes;
  return proto.sip.storeroom.StorageUnitAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.StorageUnitAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.StorageUnitAttributes}
 */
proto.sip.storeroom.StorageUnitAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.storeroom.StorageUnitTypes} */ (reader.readEnum());
      msg.setStorageUnitType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsInStorageUnitId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaceId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.StorageUnitAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.StorageUnitAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.StorageUnitAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStorageUnitType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsInStorageUnitId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.StorageUnitAttributes} returns this
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StorageUnitTypes storage_unit_type = 2;
 * @return {!proto.sip.storeroom.StorageUnitTypes}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.getStorageUnitType = function() {
  return /** @type {!proto.sip.storeroom.StorageUnitTypes} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.storeroom.StorageUnitTypes} value
 * @return {!proto.sip.storeroom.StorageUnitAttributes} returns this
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.setStorageUnitType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.StorageUnitAttributes} returns this
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 is_in_storage_unit_id = 4;
 * @return {number}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.getIsInStorageUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.StorageUnitAttributes} returns this
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.setIsInStorageUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 place_id = 5;
 * @return {number}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.getPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.StorageUnitAttributes} returns this
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 organisation_id = 6;
 * @return {number}
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.StorageUnitAttributes} returns this
 */
proto.sip.storeroom.StorageUnitAttributes.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateStorageUnitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateStorageUnitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateStorageUnitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateStorageUnitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageUnitInfo: (f = msg.getStorageUnitInfo()) && proto.sip.storeroom.StorageUnitAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateStorageUnitRequest}
 */
proto.sip.storeroom.CreateStorageUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateStorageUnitRequest;
  return proto.sip.storeroom.CreateStorageUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateStorageUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateStorageUnitRequest}
 */
proto.sip.storeroom.CreateStorageUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.StorageUnitAttributes;
      reader.readMessage(value,proto.sip.storeroom.StorageUnitAttributes.deserializeBinaryFromReader);
      msg.setStorageUnitInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateStorageUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateStorageUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateStorageUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateStorageUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageUnitInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.StorageUnitAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional StorageUnitAttributes storage_unit_info = 1;
 * @return {?proto.sip.storeroom.StorageUnitAttributes}
 */
proto.sip.storeroom.CreateStorageUnitRequest.prototype.getStorageUnitInfo = function() {
  return /** @type{?proto.sip.storeroom.StorageUnitAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.StorageUnitAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.StorageUnitAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateStorageUnitRequest} returns this
*/
proto.sip.storeroom.CreateStorageUnitRequest.prototype.setStorageUnitInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateStorageUnitRequest} returns this
 */
proto.sip.storeroom.CreateStorageUnitRequest.prototype.clearStorageUnitInfo = function() {
  return this.setStorageUnitInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateStorageUnitRequest.prototype.hasStorageUnitInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateStorageUnitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateStorageUnitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateStorageUnitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateStorageUnitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageUnit: (f = msg.getStorageUnit()) && proto.sip.storeroom.GetStorageUnitResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateStorageUnitResponse}
 */
proto.sip.storeroom.CreateStorageUnitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateStorageUnitResponse;
  return proto.sip.storeroom.CreateStorageUnitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateStorageUnitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateStorageUnitResponse}
 */
proto.sip.storeroom.CreateStorageUnitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetStorageUnitResponse;
      reader.readMessage(value,proto.sip.storeroom.GetStorageUnitResponse.deserializeBinaryFromReader);
      msg.setStorageUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateStorageUnitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateStorageUnitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateStorageUnitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateStorageUnitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageUnit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetStorageUnitResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetStorageUnitResponse storage_unit = 1;
 * @return {?proto.sip.storeroom.GetStorageUnitResponse}
 */
proto.sip.storeroom.CreateStorageUnitResponse.prototype.getStorageUnit = function() {
  return /** @type{?proto.sip.storeroom.GetStorageUnitResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetStorageUnitResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetStorageUnitResponse|undefined} value
 * @return {!proto.sip.storeroom.CreateStorageUnitResponse} returns this
*/
proto.sip.storeroom.CreateStorageUnitResponse.prototype.setStorageUnit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateStorageUnitResponse} returns this
 */
proto.sip.storeroom.CreateStorageUnitResponse.prototype.clearStorageUnit = function() {
  return this.setStorageUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateStorageUnitResponse.prototype.hasStorageUnit = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteStorageUnitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteStorageUnitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteStorageUnitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteStorageUnitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteStorageUnitRequest}
 */
proto.sip.storeroom.DeleteStorageUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteStorageUnitRequest;
  return proto.sip.storeroom.DeleteStorageUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteStorageUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteStorageUnitRequest}
 */
proto.sip.storeroom.DeleteStorageUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteStorageUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteStorageUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteStorageUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteStorageUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteStorageUnitRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteStorageUnitRequest} returns this
 */
proto.sip.storeroom.DeleteStorageUnitRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteStorageUnitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteStorageUnitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteStorageUnitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteStorageUnitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteStorageUnitResponse}
 */
proto.sip.storeroom.DeleteStorageUnitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteStorageUnitResponse;
  return proto.sip.storeroom.DeleteStorageUnitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteStorageUnitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteStorageUnitResponse}
 */
proto.sip.storeroom.DeleteStorageUnitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteStorageUnitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteStorageUnitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteStorageUnitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteStorageUnitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteStorageUnitResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteStorageUnitResponse} returns this
 */
proto.sip.storeroom.DeleteStorageUnitResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateStorageUnitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateStorageUnitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateStorageUnitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    storageUnitInfo: (f = msg.getStorageUnitInfo()) && proto.sip.storeroom.StorageUnitAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateStorageUnitRequest}
 */
proto.sip.storeroom.UpdateStorageUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateStorageUnitRequest;
  return proto.sip.storeroom.UpdateStorageUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateStorageUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateStorageUnitRequest}
 */
proto.sip.storeroom.UpdateStorageUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.StorageUnitAttributes;
      reader.readMessage(value,proto.sip.storeroom.StorageUnitAttributes.deserializeBinaryFromReader);
      msg.setStorageUnitInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateStorageUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateStorageUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateStorageUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStorageUnitInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.StorageUnitAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateStorageUnitRequest} returns this
 */
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StorageUnitAttributes storage_unit_info = 2;
 * @return {?proto.sip.storeroom.StorageUnitAttributes}
 */
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.getStorageUnitInfo = function() {
  return /** @type{?proto.sip.storeroom.StorageUnitAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.StorageUnitAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.StorageUnitAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateStorageUnitRequest} returns this
*/
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.setStorageUnitInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateStorageUnitRequest} returns this
 */
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.clearStorageUnitInfo = function() {
  return this.setStorageUnitInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateStorageUnitRequest.prototype.hasStorageUnitInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateStorageUnitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateStorageUnitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateStorageUnitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateStorageUnitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageUnit: (f = msg.getStorageUnit()) && proto.sip.storeroom.GetStorageUnitResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateStorageUnitResponse}
 */
proto.sip.storeroom.UpdateStorageUnitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateStorageUnitResponse;
  return proto.sip.storeroom.UpdateStorageUnitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateStorageUnitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateStorageUnitResponse}
 */
proto.sip.storeroom.UpdateStorageUnitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetStorageUnitResponse;
      reader.readMessage(value,proto.sip.storeroom.GetStorageUnitResponse.deserializeBinaryFromReader);
      msg.setStorageUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateStorageUnitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateStorageUnitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateStorageUnitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateStorageUnitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageUnit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetStorageUnitResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetStorageUnitResponse storage_unit = 1;
 * @return {?proto.sip.storeroom.GetStorageUnitResponse}
 */
proto.sip.storeroom.UpdateStorageUnitResponse.prototype.getStorageUnit = function() {
  return /** @type{?proto.sip.storeroom.GetStorageUnitResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetStorageUnitResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetStorageUnitResponse|undefined} value
 * @return {!proto.sip.storeroom.UpdateStorageUnitResponse} returns this
*/
proto.sip.storeroom.UpdateStorageUnitResponse.prototype.setStorageUnit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateStorageUnitResponse} returns this
 */
proto.sip.storeroom.UpdateStorageUnitResponse.prototype.clearStorageUnit = function() {
  return this.setStorageUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateStorageUnitResponse.prototype.hasStorageUnit = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetStorageUnitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetStorageUnitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetStorageUnitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetStorageUnitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetStorageUnitRequest}
 */
proto.sip.storeroom.GetStorageUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetStorageUnitRequest;
  return proto.sip.storeroom.GetStorageUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetStorageUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetStorageUnitRequest}
 */
proto.sip.storeroom.GetStorageUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetStorageUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetStorageUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetStorageUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetStorageUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetStorageUnitRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetStorageUnitRequest} returns this
 */
proto.sip.storeroom.GetStorageUnitRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetStorageUnitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetStorageUnitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetStorageUnitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    storageUnitInfo: (f = msg.getStorageUnitInfo()) && proto.sip.storeroom.StorageUnitAttributes.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateUser: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetStorageUnitResponse}
 */
proto.sip.storeroom.GetStorageUnitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetStorageUnitResponse;
  return proto.sip.storeroom.GetStorageUnitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetStorageUnitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetStorageUnitResponse}
 */
proto.sip.storeroom.GetStorageUnitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.StorageUnitAttributes;
      reader.readMessage(value,proto.sip.storeroom.StorageUnitAttributes.deserializeBinaryFromReader);
      msg.setStorageUnitInfo(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetStorageUnitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetStorageUnitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetStorageUnitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStorageUnitInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.StorageUnitAttributes.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetStorageUnitResponse} returns this
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StorageUnitAttributes storage_unit_info = 2;
 * @return {?proto.sip.storeroom.StorageUnitAttributes}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.getStorageUnitInfo = function() {
  return /** @type{?proto.sip.storeroom.StorageUnitAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.StorageUnitAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.StorageUnitAttributes|undefined} value
 * @return {!proto.sip.storeroom.GetStorageUnitResponse} returns this
*/
proto.sip.storeroom.GetStorageUnitResponse.prototype.setStorageUnitInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetStorageUnitResponse} returns this
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.clearStorageUnitInfo = function() {
  return this.setStorageUnitInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.hasStorageUnitInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetStorageUnitResponse} returns this
*/
proto.sip.storeroom.GetStorageUnitResponse.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetStorageUnitResponse} returns this
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string update_user = 4;
 * @return {string}
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.getUpdateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetStorageUnitResponse} returns this
 */
proto.sip.storeroom.GetStorageUnitResponse.prototype.setUpdateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationStorageUnitsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationStorageUnitsRequest;
  return proto.sip.storeroom.ListOrganisationStorageUnitsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationStorageUnitsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 organisation_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationStorageUnitsRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationStorageUnitsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageUnitsList: jspb.Message.toObjectList(msg.getStorageUnitsList(),
    proto.sip.storeroom.GetStorageUnitResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationStorageUnitsResponse;
  return proto.sip.storeroom.ListOrganisationStorageUnitsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetStorageUnitResponse;
      reader.readMessage(value,proto.sip.storeroom.GetStorageUnitResponse.deserializeBinaryFromReader);
      msg.addStorageUnits(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationStorageUnitsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetStorageUnitResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetStorageUnitResponse storage_units = 1;
 * @return {!Array<!proto.sip.storeroom.GetStorageUnitResponse>}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.getStorageUnitsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetStorageUnitResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetStorageUnitResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetStorageUnitResponse>} value
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse} returns this
*/
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.setStorageUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetStorageUnitResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetStorageUnitResponse}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.addStorageUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetStorageUnitResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.clearStorageUnitsList = function() {
  return this.setStorageUnitsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationStorageUnitsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationStorageUnitsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListStorageUnitsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListStorageUnitsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListStorageUnitsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListStorageUnitsRequest}
 */
proto.sip.storeroom.ListStorageUnitsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListStorageUnitsRequest;
  return proto.sip.storeroom.ListStorageUnitsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListStorageUnitsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListStorageUnitsRequest}
 */
proto.sip.storeroom.ListStorageUnitsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListStorageUnitsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListStorageUnitsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListStorageUnitsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListStorageUnitsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListStorageUnitsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListStorageUnitsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListStorageUnitsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListStorageUnitsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListStorageUnitsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListStorageUnitsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListStorageUnitsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageUnitsList: jspb.Message.toObjectList(msg.getStorageUnitsList(),
    proto.sip.storeroom.GetStorageUnitResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListStorageUnitsResponse}
 */
proto.sip.storeroom.ListStorageUnitsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListStorageUnitsResponse;
  return proto.sip.storeroom.ListStorageUnitsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListStorageUnitsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListStorageUnitsResponse}
 */
proto.sip.storeroom.ListStorageUnitsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetStorageUnitResponse;
      reader.readMessage(value,proto.sip.storeroom.GetStorageUnitResponse.deserializeBinaryFromReader);
      msg.addStorageUnits(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListStorageUnitsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListStorageUnitsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListStorageUnitsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetStorageUnitResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetStorageUnitResponse storage_units = 1;
 * @return {!Array<!proto.sip.storeroom.GetStorageUnitResponse>}
 */
proto.sip.storeroom.ListStorageUnitsResponse.prototype.getStorageUnitsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetStorageUnitResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetStorageUnitResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetStorageUnitResponse>} value
 * @return {!proto.sip.storeroom.ListStorageUnitsResponse} returns this
*/
proto.sip.storeroom.ListStorageUnitsResponse.prototype.setStorageUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetStorageUnitResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetStorageUnitResponse}
 */
proto.sip.storeroom.ListStorageUnitsResponse.prototype.addStorageUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetStorageUnitResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListStorageUnitsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitsResponse.prototype.clearStorageUnitsList = function() {
  return this.setStorageUnitsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListStorageUnitsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListStorageUnitsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListStorageUnitItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListStorageUnitItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListStorageUnitItemsRequest}
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListStorageUnitItemsRequest;
  return proto.sip.storeroom.ListStorageUnitItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListStorageUnitItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListStorageUnitItemsRequest}
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListStorageUnitItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListStorageUnitItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsRequest} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsRequest} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsRequest} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListStorageUnitItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListStorageUnitItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    storageUnitInfo: (f = msg.getStorageUnitInfo()) && proto.sip.storeroom.StorageUnitAttributes.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateUser: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.sip.storeroom.GetItemResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListStorageUnitItemsResponse;
  return proto.sip.storeroom.ListStorageUnitItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListStorageUnitItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.StorageUnitAttributes;
      reader.readMessage(value,proto.sip.storeroom.StorageUnitAttributes.deserializeBinaryFromReader);
      msg.setStorageUnitInfo(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateUser(value);
      break;
    case 5:
      var value = new proto.sip.storeroom.GetItemResponse;
      reader.readMessage(value,proto.sip.storeroom.GetItemResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListStorageUnitItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListStorageUnitItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStorageUnitInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.StorageUnitAttributes.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sip.storeroom.GetItemResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StorageUnitAttributes storage_unit_info = 2;
 * @return {?proto.sip.storeroom.StorageUnitAttributes}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.getStorageUnitInfo = function() {
  return /** @type{?proto.sip.storeroom.StorageUnitAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.StorageUnitAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.StorageUnitAttributes|undefined} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
*/
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.setStorageUnitInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.clearStorageUnitInfo = function() {
  return this.setStorageUnitInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.hasStorageUnitInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
*/
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string update_user = 4;
 * @return {string}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.getUpdateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.setUpdateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated GetItemResponse items = 5;
 * @return {!Array<!proto.sip.storeroom.GetItemResponse>}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetItemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetItemResponse, 5));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetItemResponse>} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
*/
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sip.storeroom.GetItemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetItemResponse}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sip.storeroom.GetItemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string next_page_token = 6;
 * @return {string}
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListStorageUnitItemsResponse} returns this
 */
proto.sip.storeroom.ListStorageUnitItemsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.PlaceAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.PlaceAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.PlaceAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.PlaceAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    room: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rack: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.PlaceAttributes}
 */
proto.sip.storeroom.PlaceAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.PlaceAttributes;
  return proto.sip.storeroom.PlaceAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.PlaceAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.PlaceAttributes}
 */
proto.sip.storeroom.PlaceAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRack(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.PlaceAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.PlaceAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.PlaceAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.PlaceAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRack();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string room = 1;
 * @return {string}
 */
proto.sip.storeroom.PlaceAttributes.prototype.getRoom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.PlaceAttributes} returns this
 */
proto.sip.storeroom.PlaceAttributes.prototype.setRoom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rack = 2;
 * @return {string}
 */
proto.sip.storeroom.PlaceAttributes.prototype.getRack = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.PlaceAttributes} returns this
 */
proto.sip.storeroom.PlaceAttributes.prototype.setRack = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.sip.storeroom.PlaceAttributes.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.PlaceAttributes} returns this
 */
proto.sip.storeroom.PlaceAttributes.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 organisation_id = 4;
 * @return {number}
 */
proto.sip.storeroom.PlaceAttributes.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.PlaceAttributes} returns this
 */
proto.sip.storeroom.PlaceAttributes.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreatePlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreatePlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreatePlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreatePlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeInfo: (f = msg.getPlaceInfo()) && proto.sip.storeroom.PlaceAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreatePlaceRequest}
 */
proto.sip.storeroom.CreatePlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreatePlaceRequest;
  return proto.sip.storeroom.CreatePlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreatePlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreatePlaceRequest}
 */
proto.sip.storeroom.CreatePlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.PlaceAttributes;
      reader.readMessage(value,proto.sip.storeroom.PlaceAttributes.deserializeBinaryFromReader);
      msg.setPlaceInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreatePlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreatePlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreatePlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreatePlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.PlaceAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaceAttributes place_info = 1;
 * @return {?proto.sip.storeroom.PlaceAttributes}
 */
proto.sip.storeroom.CreatePlaceRequest.prototype.getPlaceInfo = function() {
  return /** @type{?proto.sip.storeroom.PlaceAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.PlaceAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.PlaceAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreatePlaceRequest} returns this
*/
proto.sip.storeroom.CreatePlaceRequest.prototype.setPlaceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreatePlaceRequest} returns this
 */
proto.sip.storeroom.CreatePlaceRequest.prototype.clearPlaceInfo = function() {
  return this.setPlaceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreatePlaceRequest.prototype.hasPlaceInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreatePlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreatePlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreatePlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreatePlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    place: (f = msg.getPlace()) && proto.sip.storeroom.GetPlaceResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreatePlaceResponse}
 */
proto.sip.storeroom.CreatePlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreatePlaceResponse;
  return proto.sip.storeroom.CreatePlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreatePlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreatePlaceResponse}
 */
proto.sip.storeroom.CreatePlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetPlaceResponse;
      reader.readMessage(value,proto.sip.storeroom.GetPlaceResponse.deserializeBinaryFromReader);
      msg.setPlace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreatePlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreatePlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreatePlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreatePlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetPlaceResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetPlaceResponse place = 1;
 * @return {?proto.sip.storeroom.GetPlaceResponse}
 */
proto.sip.storeroom.CreatePlaceResponse.prototype.getPlace = function() {
  return /** @type{?proto.sip.storeroom.GetPlaceResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetPlaceResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetPlaceResponse|undefined} value
 * @return {!proto.sip.storeroom.CreatePlaceResponse} returns this
*/
proto.sip.storeroom.CreatePlaceResponse.prototype.setPlace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreatePlaceResponse} returns this
 */
proto.sip.storeroom.CreatePlaceResponse.prototype.clearPlace = function() {
  return this.setPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreatePlaceResponse.prototype.hasPlace = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeletePlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeletePlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeletePlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeletePlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeletePlaceRequest}
 */
proto.sip.storeroom.DeletePlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeletePlaceRequest;
  return proto.sip.storeroom.DeletePlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeletePlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeletePlaceRequest}
 */
proto.sip.storeroom.DeletePlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeletePlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeletePlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeletePlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeletePlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeletePlaceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeletePlaceRequest} returns this
 */
proto.sip.storeroom.DeletePlaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeletePlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeletePlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeletePlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeletePlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeletePlaceResponse}
 */
proto.sip.storeroom.DeletePlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeletePlaceResponse;
  return proto.sip.storeroom.DeletePlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeletePlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeletePlaceResponse}
 */
proto.sip.storeroom.DeletePlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeletePlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeletePlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeletePlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeletePlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeletePlaceResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeletePlaceResponse} returns this
 */
proto.sip.storeroom.DeletePlaceResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdatePlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdatePlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdatePlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdatePlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    placeInfo: (f = msg.getPlaceInfo()) && proto.sip.storeroom.PlaceAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdatePlaceRequest}
 */
proto.sip.storeroom.UpdatePlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdatePlaceRequest;
  return proto.sip.storeroom.UpdatePlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdatePlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdatePlaceRequest}
 */
proto.sip.storeroom.UpdatePlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.PlaceAttributes;
      reader.readMessage(value,proto.sip.storeroom.PlaceAttributes.deserializeBinaryFromReader);
      msg.setPlaceInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdatePlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdatePlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdatePlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdatePlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlaceInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.PlaceAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdatePlaceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdatePlaceRequest} returns this
 */
proto.sip.storeroom.UpdatePlaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PlaceAttributes place_info = 2;
 * @return {?proto.sip.storeroom.PlaceAttributes}
 */
proto.sip.storeroom.UpdatePlaceRequest.prototype.getPlaceInfo = function() {
  return /** @type{?proto.sip.storeroom.PlaceAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.PlaceAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.PlaceAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdatePlaceRequest} returns this
*/
proto.sip.storeroom.UpdatePlaceRequest.prototype.setPlaceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdatePlaceRequest} returns this
 */
proto.sip.storeroom.UpdatePlaceRequest.prototype.clearPlaceInfo = function() {
  return this.setPlaceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdatePlaceRequest.prototype.hasPlaceInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdatePlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdatePlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdatePlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdatePlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    place: (f = msg.getPlace()) && proto.sip.storeroom.GetPlaceResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdatePlaceResponse}
 */
proto.sip.storeroom.UpdatePlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdatePlaceResponse;
  return proto.sip.storeroom.UpdatePlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdatePlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdatePlaceResponse}
 */
proto.sip.storeroom.UpdatePlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetPlaceResponse;
      reader.readMessage(value,proto.sip.storeroom.GetPlaceResponse.deserializeBinaryFromReader);
      msg.setPlace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdatePlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdatePlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdatePlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdatePlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetPlaceResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetPlaceResponse place = 1;
 * @return {?proto.sip.storeroom.GetPlaceResponse}
 */
proto.sip.storeroom.UpdatePlaceResponse.prototype.getPlace = function() {
  return /** @type{?proto.sip.storeroom.GetPlaceResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetPlaceResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetPlaceResponse|undefined} value
 * @return {!proto.sip.storeroom.UpdatePlaceResponse} returns this
*/
proto.sip.storeroom.UpdatePlaceResponse.prototype.setPlace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdatePlaceResponse} returns this
 */
proto.sip.storeroom.UpdatePlaceResponse.prototype.clearPlace = function() {
  return this.setPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdatePlaceResponse.prototype.hasPlace = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetPlaceRequest}
 */
proto.sip.storeroom.GetPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetPlaceRequest;
  return proto.sip.storeroom.GetPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetPlaceRequest}
 */
proto.sip.storeroom.GetPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetPlaceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetPlaceRequest} returns this
 */
proto.sip.storeroom.GetPlaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetPlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetPlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetPlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    placeInfo: (f = msg.getPlaceInfo()) && proto.sip.storeroom.PlaceAttributes.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateUser: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetPlaceResponse}
 */
proto.sip.storeroom.GetPlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetPlaceResponse;
  return proto.sip.storeroom.GetPlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetPlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetPlaceResponse}
 */
proto.sip.storeroom.GetPlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.PlaceAttributes;
      reader.readMessage(value,proto.sip.storeroom.PlaceAttributes.deserializeBinaryFromReader);
      msg.setPlaceInfo(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetPlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetPlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetPlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlaceInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.PlaceAttributes.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetPlaceResponse} returns this
 */
proto.sip.storeroom.GetPlaceResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PlaceAttributes place_info = 2;
 * @return {?proto.sip.storeroom.PlaceAttributes}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.getPlaceInfo = function() {
  return /** @type{?proto.sip.storeroom.PlaceAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.PlaceAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.PlaceAttributes|undefined} value
 * @return {!proto.sip.storeroom.GetPlaceResponse} returns this
*/
proto.sip.storeroom.GetPlaceResponse.prototype.setPlaceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetPlaceResponse} returns this
 */
proto.sip.storeroom.GetPlaceResponse.prototype.clearPlaceInfo = function() {
  return this.setPlaceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.hasPlaceInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetPlaceResponse} returns this
*/
proto.sip.storeroom.GetPlaceResponse.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetPlaceResponse} returns this
 */
proto.sip.storeroom.GetPlaceResponse.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string update_user = 4;
 * @return {string}
 */
proto.sip.storeroom.GetPlaceResponse.prototype.getUpdateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetPlaceResponse} returns this
 */
proto.sip.storeroom.GetPlaceResponse.prototype.setUpdateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListPlaceStorageUnitsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsRequest}
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListPlaceStorageUnitsRequest;
  return proto.sip.storeroom.ListPlaceStorageUnitsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsRequest}
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListPlaceStorageUnitsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} returns this
 */
proto.sip.storeroom.ListPlaceStorageUnitsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListPlaceStorageUnitsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeInfo: (f = msg.getPlaceInfo()) && proto.sip.storeroom.GetPlaceResponse.toObject(includeInstance, f),
    storageUnitsList: jspb.Message.toObjectList(msg.getStorageUnitsList(),
    proto.sip.storeroom.GetStorageUnitResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsResponse}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListPlaceStorageUnitsResponse;
  return proto.sip.storeroom.ListPlaceStorageUnitsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsResponse}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetPlaceResponse;
      reader.readMessage(value,proto.sip.storeroom.GetPlaceResponse.deserializeBinaryFromReader);
      msg.setPlaceInfo(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.GetStorageUnitResponse;
      reader.readMessage(value,proto.sip.storeroom.GetStorageUnitResponse.deserializeBinaryFromReader);
      msg.addStorageUnits(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListPlaceStorageUnitsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetPlaceResponse.serializeBinaryToWriter
    );
  }
  f = message.getStorageUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sip.storeroom.GetStorageUnitResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional GetPlaceResponse place_info = 1;
 * @return {?proto.sip.storeroom.GetPlaceResponse}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.getPlaceInfo = function() {
  return /** @type{?proto.sip.storeroom.GetPlaceResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetPlaceResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetPlaceResponse|undefined} value
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} returns this
*/
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.setPlaceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} returns this
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.clearPlaceInfo = function() {
  return this.setPlaceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.hasPlaceInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetStorageUnitResponse storage_units = 2;
 * @return {!Array<!proto.sip.storeroom.GetStorageUnitResponse>}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.getStorageUnitsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetStorageUnitResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetStorageUnitResponse, 2));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetStorageUnitResponse>} value
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} returns this
*/
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.setStorageUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sip.storeroom.GetStorageUnitResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetStorageUnitResponse}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.addStorageUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sip.storeroom.GetStorageUnitResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} returns this
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.clearStorageUnitsList = function() {
  return this.setStorageUnitsList([]);
};


/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListPlaceStorageUnitsResponse} returns this
 */
proto.sip.storeroom.ListPlaceStorageUnitsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationPlacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationPlacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationPlacesRequest}
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationPlacesRequest;
  return proto.sip.storeroom.ListOrganisationPlacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationPlacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationPlacesRequest}
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationPlacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationPlacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationPlacesRequest} returns this
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationPlacesRequest} returns this
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 organisation_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationPlacesRequest} returns this
 */
proto.sip.storeroom.ListOrganisationPlacesRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationPlacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationPlacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.sip.storeroom.GetPlaceResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationPlacesResponse}
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationPlacesResponse;
  return proto.sip.storeroom.ListOrganisationPlacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationPlacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationPlacesResponse}
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetPlaceResponse;
      reader.readMessage(value,proto.sip.storeroom.GetPlaceResponse.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationPlacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationPlacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetPlaceResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetPlaceResponse places = 1;
 * @return {!Array<!proto.sip.storeroom.GetPlaceResponse>}
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetPlaceResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetPlaceResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetPlaceResponse>} value
 * @return {!proto.sip.storeroom.ListOrganisationPlacesResponse} returns this
*/
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetPlaceResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetPlaceResponse}
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetPlaceResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListOrganisationPlacesResponse} returns this
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationPlacesResponse} returns this
 */
proto.sip.storeroom.ListOrganisationPlacesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListPlacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListPlacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListPlacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListPlacesRequest}
 */
proto.sip.storeroom.ListPlacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListPlacesRequest;
  return proto.sip.storeroom.ListPlacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListPlacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListPlacesRequest}
 */
proto.sip.storeroom.ListPlacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListPlacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListPlacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListPlacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListPlacesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListPlacesRequest} returns this
 */
proto.sip.storeroom.ListPlacesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListPlacesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListPlacesRequest} returns this
 */
proto.sip.storeroom.ListPlacesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListPlacesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListPlacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListPlacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListPlacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.sip.storeroom.GetPlaceResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListPlacesResponse}
 */
proto.sip.storeroom.ListPlacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListPlacesResponse;
  return proto.sip.storeroom.ListPlacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListPlacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListPlacesResponse}
 */
proto.sip.storeroom.ListPlacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetPlaceResponse;
      reader.readMessage(value,proto.sip.storeroom.GetPlaceResponse.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListPlacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListPlacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListPlacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListPlacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetPlaceResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetPlaceResponse places = 1;
 * @return {!Array<!proto.sip.storeroom.GetPlaceResponse>}
 */
proto.sip.storeroom.ListPlacesResponse.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetPlaceResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetPlaceResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetPlaceResponse>} value
 * @return {!proto.sip.storeroom.ListPlacesResponse} returns this
*/
proto.sip.storeroom.ListPlacesResponse.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetPlaceResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetPlaceResponse}
 */
proto.sip.storeroom.ListPlacesResponse.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetPlaceResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListPlacesResponse} returns this
 */
proto.sip.storeroom.ListPlacesResponse.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListPlacesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListPlacesResponse} returns this
 */
proto.sip.storeroom.ListPlacesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ReservationItemRelationAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ReservationItemRelationAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ReservationItemRelationAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reservationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reservationItemName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    confirmationUser: jspb.Message.getFieldWithDefault(msg, 6, ""),
    confirmationTime: (f = msg.getConfirmationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    returnTime: (f = msg.getReturnTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    returnText: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ReservationItemRelationAttributes;
  return proto.sip.storeroom.ReservationItemRelationAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ReservationItemRelationAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationItemName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationUser(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConfirmationTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReturnTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ReservationItemRelationAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ReservationItemRelationAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ReservationItemRelationAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getReservationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getReservationItemName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConfirmationUser();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConfirmationTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReturnTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReturnText();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 item_id = 2;
 * @return {number}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 reservation_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getReservationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 quantity = 4;
 * @return {number}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string reservation_item_name = 5;
 * @return {string}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getReservationItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setReservationItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string confirmation_user = 6;
 * @return {string}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getConfirmationUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setConfirmationUser = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp confirmation_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getConfirmationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
*/
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setConfirmationTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.clearConfirmationTime = function() {
  return this.setConfirmationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.hasConfirmationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp return_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getReturnTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
*/
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setReturnTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.clearReturnTime = function() {
  return this.setReturnTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.hasReturnTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string return_text = 9;
 * @return {string}
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.getReturnText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes} returns this
 */
proto.sip.storeroom.ReservationItemRelationAttributes.prototype.setReturnText = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ReservationAttributes.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ReservationAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ReservationAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ReservationAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ReservationAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createUser: jspb.Message.getFieldWithDefault(msg, 4, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    requestText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemRelationsList: jspb.Message.toObjectList(msg.getItemRelationsList(),
    proto.sip.storeroom.ReservationItemRelationAttributes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ReservationAttributes}
 */
proto.sip.storeroom.ReservationAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ReservationAttributes;
  return proto.sip.storeroom.ReservationAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ReservationAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ReservationAttributes}
 */
proto.sip.storeroom.ReservationAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateUser(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestText(value);
      break;
    case 7:
      var value = new proto.sip.storeroom.ReservationItemRelationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ReservationItemRelationAttributes.deserializeBinaryFromReader);
      msg.addItemRelations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ReservationAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ReservationAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ReservationAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ReservationAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRequestText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemRelationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.sip.storeroom.ReservationItemRelationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.sip.storeroom.ReservationAttributes.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
 */
proto.sip.storeroom.ReservationAttributes.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ReservationAttributes.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
*/
proto.sip.storeroom.ReservationAttributes.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
 */
proto.sip.storeroom.ReservationAttributes.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ReservationAttributes.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ReservationAttributes.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
*/
proto.sip.storeroom.ReservationAttributes.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
 */
proto.sip.storeroom.ReservationAttributes.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ReservationAttributes.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string create_user = 4;
 * @return {string}
 */
proto.sip.storeroom.ReservationAttributes.prototype.getCreateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
 */
proto.sip.storeroom.ReservationAttributes.prototype.setCreateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 organisation_id = 5;
 * @return {number}
 */
proto.sip.storeroom.ReservationAttributes.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
 */
proto.sip.storeroom.ReservationAttributes.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string request_text = 6;
 * @return {string}
 */
proto.sip.storeroom.ReservationAttributes.prototype.getRequestText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
 */
proto.sip.storeroom.ReservationAttributes.prototype.setRequestText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ReservationItemRelationAttributes item_relations = 7;
 * @return {!Array<!proto.sip.storeroom.ReservationItemRelationAttributes>}
 */
proto.sip.storeroom.ReservationAttributes.prototype.getItemRelationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.ReservationItemRelationAttributes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.ReservationItemRelationAttributes, 7));
};


/**
 * @param {!Array<!proto.sip.storeroom.ReservationItemRelationAttributes>} value
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
*/
proto.sip.storeroom.ReservationAttributes.prototype.setItemRelationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sip.storeroom.ReservationItemRelationAttributes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.ReservationItemRelationAttributes}
 */
proto.sip.storeroom.ReservationAttributes.prototype.addItemRelations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sip.storeroom.ReservationItemRelationAttributes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ReservationAttributes} returns this
 */
proto.sip.storeroom.ReservationAttributes.prototype.clearItemRelationsList = function() {
  return this.setItemRelationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationInfo: (f = msg.getReservationInfo()) && proto.sip.storeroom.ReservationAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateReservationRequest}
 */
proto.sip.storeroom.CreateReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateReservationRequest;
  return proto.sip.storeroom.CreateReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateReservationRequest}
 */
proto.sip.storeroom.CreateReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.ReservationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ReservationAttributes.deserializeBinaryFromReader);
      msg.setReservationInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.ReservationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReservationAttributes reservation_info = 1;
 * @return {?proto.sip.storeroom.ReservationAttributes}
 */
proto.sip.storeroom.CreateReservationRequest.prototype.getReservationInfo = function() {
  return /** @type{?proto.sip.storeroom.ReservationAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ReservationAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.ReservationAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateReservationRequest} returns this
*/
proto.sip.storeroom.CreateReservationRequest.prototype.setReservationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateReservationRequest} returns this
 */
proto.sip.storeroom.CreateReservationRequest.prototype.clearReservationInfo = function() {
  return this.setReservationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateReservationRequest.prototype.hasReservationInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservation: (f = msg.getReservation()) && proto.sip.storeroom.GetReservationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateReservationResponse}
 */
proto.sip.storeroom.CreateReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateReservationResponse;
  return proto.sip.storeroom.CreateReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateReservationResponse}
 */
proto.sip.storeroom.CreateReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetReservationResponse reservation = 1;
 * @return {?proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.CreateReservationResponse.prototype.getReservation = function() {
  return /** @type{?proto.sip.storeroom.GetReservationResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetReservationResponse|undefined} value
 * @return {!proto.sip.storeroom.CreateReservationResponse} returns this
*/
proto.sip.storeroom.CreateReservationResponse.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateReservationResponse} returns this
 */
proto.sip.storeroom.CreateReservationResponse.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateReservationResponse.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteReservationRequest}
 */
proto.sip.storeroom.DeleteReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteReservationRequest;
  return proto.sip.storeroom.DeleteReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteReservationRequest}
 */
proto.sip.storeroom.DeleteReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteReservationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteReservationRequest} returns this
 */
proto.sip.storeroom.DeleteReservationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteReservationResponse}
 */
proto.sip.storeroom.DeleteReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteReservationResponse;
  return proto.sip.storeroom.DeleteReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteReservationResponse}
 */
proto.sip.storeroom.DeleteReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteReservationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteReservationResponse} returns this
 */
proto.sip.storeroom.DeleteReservationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reservationInfo: (f = msg.getReservationInfo()) && proto.sip.storeroom.ReservationAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateReservationRequest}
 */
proto.sip.storeroom.UpdateReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateReservationRequest;
  return proto.sip.storeroom.UpdateReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateReservationRequest}
 */
proto.sip.storeroom.UpdateReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ReservationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ReservationAttributes.deserializeBinaryFromReader);
      msg.setReservationInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getReservationInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ReservationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdateReservationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateReservationRequest} returns this
 */
proto.sip.storeroom.UpdateReservationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ReservationAttributes reservation_info = 2;
 * @return {?proto.sip.storeroom.ReservationAttributes}
 */
proto.sip.storeroom.UpdateReservationRequest.prototype.getReservationInfo = function() {
  return /** @type{?proto.sip.storeroom.ReservationAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ReservationAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ReservationAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateReservationRequest} returns this
*/
proto.sip.storeroom.UpdateReservationRequest.prototype.setReservationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateReservationRequest} returns this
 */
proto.sip.storeroom.UpdateReservationRequest.prototype.clearReservationInfo = function() {
  return this.setReservationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateReservationRequest.prototype.hasReservationInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservation: (f = msg.getReservation()) && proto.sip.storeroom.GetReservationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateReservationResponse}
 */
proto.sip.storeroom.UpdateReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateReservationResponse;
  return proto.sip.storeroom.UpdateReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateReservationResponse}
 */
proto.sip.storeroom.UpdateReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetReservationResponse reservation = 1;
 * @return {?proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.UpdateReservationResponse.prototype.getReservation = function() {
  return /** @type{?proto.sip.storeroom.GetReservationResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetReservationResponse|undefined} value
 * @return {!proto.sip.storeroom.UpdateReservationResponse} returns this
*/
proto.sip.storeroom.UpdateReservationResponse.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateReservationResponse} returns this
 */
proto.sip.storeroom.UpdateReservationResponse.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateReservationResponse.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ConfirmReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ConfirmReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    confirmed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ConfirmReservationRequest}
 */
proto.sip.storeroom.ConfirmReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ConfirmReservationRequest;
  return proto.sip.storeroom.ConfirmReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ConfirmReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ConfirmReservationRequest}
 */
proto.sip.storeroom.ConfirmReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ConfirmReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ConfirmReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getConfirmed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 reservation_id = 1;
 * @return {number}
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.getReservationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ConfirmReservationRequest} returns this
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 item_id = 2;
 * @return {number}
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.getItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ConfirmReservationRequest} returns this
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool confirmed = 3;
 * @return {boolean}
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.getConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ConfirmReservationRequest} returns this
 */
proto.sip.storeroom.ConfirmReservationRequest.prototype.setConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ConfirmReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ConfirmReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ConfirmReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservation: (f = msg.getReservation()) && proto.sip.storeroom.GetReservationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ConfirmReservationResponse}
 */
proto.sip.storeroom.ConfirmReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ConfirmReservationResponse;
  return proto.sip.storeroom.ConfirmReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ConfirmReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ConfirmReservationResponse}
 */
proto.sip.storeroom.ConfirmReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ConfirmReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ConfirmReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ConfirmReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetReservationResponse reservation = 1;
 * @return {?proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.ConfirmReservationResponse.prototype.getReservation = function() {
  return /** @type{?proto.sip.storeroom.GetReservationResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetReservationResponse|undefined} value
 * @return {!proto.sip.storeroom.ConfirmReservationResponse} returns this
*/
proto.sip.storeroom.ConfirmReservationResponse.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ConfirmReservationResponse} returns this
 */
proto.sip.storeroom.ConfirmReservationResponse.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ConfirmReservationResponse.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ConfirmReturnReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ConfirmReturnReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    returned: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    returnText: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ConfirmReturnReservationRequest}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ConfirmReturnReservationRequest;
  return proto.sip.storeroom.ConfirmReturnReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ConfirmReturnReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ConfirmReturnReservationRequest}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturned(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ConfirmReturnReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ConfirmReturnReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getReturned();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getReturnText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 reservation_id = 1;
 * @return {number}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.getReservationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ConfirmReturnReservationRequest} returns this
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 item_id = 2;
 * @return {number}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.getItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ConfirmReturnReservationRequest} returns this
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool returned = 3;
 * @return {boolean}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.getReturned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ConfirmReturnReservationRequest} returns this
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.setReturned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string return_text = 4;
 * @return {string}
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.getReturnText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ConfirmReturnReservationRequest} returns this
 */
proto.sip.storeroom.ConfirmReturnReservationRequest.prototype.setReturnText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ConfirmReturnReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ConfirmReturnReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservation: (f = msg.getReservation()) && proto.sip.storeroom.GetReservationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ConfirmReturnReservationResponse}
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ConfirmReturnReservationResponse;
  return proto.sip.storeroom.ConfirmReturnReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ConfirmReturnReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ConfirmReturnReservationResponse}
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ConfirmReturnReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ConfirmReturnReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetReservationResponse reservation = 1;
 * @return {?proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.prototype.getReservation = function() {
  return /** @type{?proto.sip.storeroom.GetReservationResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetReservationResponse|undefined} value
 * @return {!proto.sip.storeroom.ConfirmReturnReservationResponse} returns this
*/
proto.sip.storeroom.ConfirmReturnReservationResponse.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ConfirmReturnReservationResponse} returns this
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ConfirmReturnReservationResponse.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetReservationRequest}
 */
proto.sip.storeroom.GetReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetReservationRequest;
  return proto.sip.storeroom.GetReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetReservationRequest}
 */
proto.sip.storeroom.GetReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetReservationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetReservationRequest} returns this
 */
proto.sip.storeroom.GetReservationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reservationInfo: (f = msg.getReservationInfo()) && proto.sip.storeroom.ReservationAttributes.toObject(includeInstance, f),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createUser: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateUser: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.GetReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetReservationResponse;
  return proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ReservationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ReservationAttributes.deserializeBinaryFromReader);
      msg.setReservationInfo(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateUser(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getReservationInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ReservationAttributes.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUser();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetReservationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
 */
proto.sip.storeroom.GetReservationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ReservationAttributes reservation_info = 2;
 * @return {?proto.sip.storeroom.ReservationAttributes}
 */
proto.sip.storeroom.GetReservationResponse.prototype.getReservationInfo = function() {
  return /** @type{?proto.sip.storeroom.ReservationAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ReservationAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ReservationAttributes|undefined} value
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
*/
proto.sip.storeroom.GetReservationResponse.prototype.setReservationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
 */
proto.sip.storeroom.GetReservationResponse.prototype.clearReservationInfo = function() {
  return this.setReservationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetReservationResponse.prototype.hasReservationInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp create_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetReservationResponse.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
*/
proto.sip.storeroom.GetReservationResponse.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
 */
proto.sip.storeroom.GetReservationResponse.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetReservationResponse.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string create_user = 4;
 * @return {string}
 */
proto.sip.storeroom.GetReservationResponse.prototype.getCreateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
 */
proto.sip.storeroom.GetReservationResponse.prototype.setCreateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp update_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetReservationResponse.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
*/
proto.sip.storeroom.GetReservationResponse.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
 */
proto.sip.storeroom.GetReservationResponse.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetReservationResponse.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string update_user = 6;
 * @return {string}
 */
proto.sip.storeroom.GetReservationResponse.prototype.getUpdateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetReservationResponse} returns this
 */
proto.sip.storeroom.GetReservationResponse.prototype.setUpdateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ShortReservationAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ShortReservationAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ShortReservationAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ShortReservationAttributes}
 */
proto.sip.storeroom.ShortReservationAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ShortReservationAttributes;
  return proto.sip.storeroom.ShortReservationAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ShortReservationAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ShortReservationAttributes}
 */
proto.sip.storeroom.ShortReservationAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ShortReservationAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ShortReservationAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ShortReservationAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ShortReservationAttributes} returns this
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ShortReservationAttributes} returns this
*/
proto.sip.storeroom.ShortReservationAttributes.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ShortReservationAttributes} returns this
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ShortReservationAttributes} returns this
*/
proto.sip.storeroom.ShortReservationAttributes.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ShortReservationAttributes} returns this
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ShortReservationAttributes.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListMyUserReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListMyUserReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyUserReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    onlyInFuture: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListMyUserReservationsRequest}
 */
proto.sip.storeroom.ListMyUserReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListMyUserReservationsRequest;
  return proto.sip.storeroom.ListMyUserReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListMyUserReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListMyUserReservationsRequest}
 */
proto.sip.storeroom.ListMyUserReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyInFuture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListMyUserReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListMyUserReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyUserReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOnlyInFuture();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListMyUserReservationsRequest} returns this
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListMyUserReservationsRequest} returns this
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool only_in_future = 3;
 * @return {boolean}
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.getOnlyInFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ListMyUserReservationsRequest} returns this
 */
proto.sip.storeroom.ListMyUserReservationsRequest.prototype.setOnlyInFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListMyUserReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListMyUserReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListMyUserReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyUserReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.sip.storeroom.GetReservationResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListMyUserReservationsResponse}
 */
proto.sip.storeroom.ListMyUserReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListMyUserReservationsResponse;
  return proto.sip.storeroom.ListMyUserReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListMyUserReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListMyUserReservationsResponse}
 */
proto.sip.storeroom.ListMyUserReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListMyUserReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListMyUserReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyUserReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetReservationResponse reservations = 1;
 * @return {!Array<!proto.sip.storeroom.GetReservationResponse>}
 */
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetReservationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetReservationResponse>} value
 * @return {!proto.sip.storeroom.ListMyUserReservationsResponse} returns this
*/
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetReservationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetReservationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListMyUserReservationsResponse} returns this
 */
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListMyUserReservationsResponse} returns this
 */
proto.sip.storeroom.ListMyUserReservationsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListMyConfirmedReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    onlyInFuture: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsRequest}
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListMyConfirmedReservationsRequest;
  return proto.sip.storeroom.ListMyConfirmedReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsRequest}
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyInFuture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListMyConfirmedReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOnlyInFuture();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} returns this
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} returns this
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool only_in_future = 3;
 * @return {boolean}
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.getOnlyInFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} returns this
 */
proto.sip.storeroom.ListMyConfirmedReservationsRequest.prototype.setOnlyInFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListMyConfirmedReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.sip.storeroom.GetReservationResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsResponse}
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListMyConfirmedReservationsResponse;
  return proto.sip.storeroom.ListMyConfirmedReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsResponse}
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListMyConfirmedReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetReservationResponse reservations = 1;
 * @return {!Array<!proto.sip.storeroom.GetReservationResponse>}
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetReservationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetReservationResponse>} value
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsResponse} returns this
*/
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetReservationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetReservationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsResponse} returns this
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListMyConfirmedReservationsResponse} returns this
 */
proto.sip.storeroom.ListMyConfirmedReservationsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    onlyInFuture: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationReservationsRequest}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationReservationsRequest;
  return proto.sip.storeroom.ListOrganisationReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationReservationsRequest}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyInFuture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOnlyInFuture();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationReservationsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationReservationsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 organisation_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationReservationsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool only_in_future = 4;
 * @return {boolean}
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.getOnlyInFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ListOrganisationReservationsRequest} returns this
 */
proto.sip.storeroom.ListOrganisationReservationsRequest.prototype.setOnlyInFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.sip.storeroom.GetReservationResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationReservationsResponse}
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationReservationsResponse;
  return proto.sip.storeroom.ListOrganisationReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationReservationsResponse}
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetReservationResponse reservations = 1;
 * @return {!Array<!proto.sip.storeroom.GetReservationResponse>}
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetReservationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetReservationResponse>} value
 * @return {!proto.sip.storeroom.ListOrganisationReservationsResponse} returns this
*/
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetReservationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetReservationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListOrganisationReservationsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationReservationsResponse} returns this
 */
proto.sip.storeroom.ListOrganisationReservationsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOwnerReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOwnerReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOwnerReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    onlyInFuture: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOwnerReservationsRequest}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOwnerReservationsRequest;
  return proto.sip.storeroom.ListOwnerReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOwnerReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOwnerReservationsRequest}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyInFuture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOwnerReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOwnerReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOwnerReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOnlyInFuture();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOwnerReservationsRequest} returns this
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOwnerReservationsRequest} returns this
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 organisation_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOwnerReservationsRequest} returns this
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool only_in_future = 4;
 * @return {boolean}
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.getOnlyInFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ListOwnerReservationsRequest} returns this
 */
proto.sip.storeroom.ListOwnerReservationsRequest.prototype.setOnlyInFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListOwnerReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOwnerReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOwnerReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOwnerReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.sip.storeroom.GetReservationResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOwnerReservationsResponse}
 */
proto.sip.storeroom.ListOwnerReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOwnerReservationsResponse;
  return proto.sip.storeroom.ListOwnerReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOwnerReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOwnerReservationsResponse}
 */
proto.sip.storeroom.ListOwnerReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOwnerReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOwnerReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOwnerReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetReservationResponse reservations = 1;
 * @return {!Array<!proto.sip.storeroom.GetReservationResponse>}
 */
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetReservationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetReservationResponse>} value
 * @return {!proto.sip.storeroom.ListOwnerReservationsResponse} returns this
*/
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetReservationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetReservationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListOwnerReservationsResponse} returns this
 */
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOwnerReservationsResponse} returns this
 */
proto.sip.storeroom.ListOwnerReservationsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListReservationsRequest}
 */
proto.sip.storeroom.ListReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListReservationsRequest;
  return proto.sip.storeroom.ListReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListReservationsRequest}
 */
proto.sip.storeroom.ListReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListReservationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListReservationsRequest} returns this
 */
proto.sip.storeroom.ListReservationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListReservationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListReservationsRequest} returns this
 */
proto.sip.storeroom.ListReservationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.sip.storeroom.GetReservationResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListReservationsResponse}
 */
proto.sip.storeroom.ListReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListReservationsResponse;
  return proto.sip.storeroom.ListReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListReservationsResponse}
 */
proto.sip.storeroom.ListReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetReservationResponse;
      reader.readMessage(value,proto.sip.storeroom.GetReservationResponse.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetReservationResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetReservationResponse reservations = 1;
 * @return {!Array<!proto.sip.storeroom.GetReservationResponse>}
 */
proto.sip.storeroom.ListReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetReservationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetReservationResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetReservationResponse>} value
 * @return {!proto.sip.storeroom.ListReservationsResponse} returns this
*/
proto.sip.storeroom.ListReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetReservationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetReservationResponse}
 */
proto.sip.storeroom.ListReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetReservationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListReservationsResponse} returns this
 */
proto.sip.storeroom.ListReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListReservationsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListReservationsResponse} returns this
 */
proto.sip.storeroom.ListReservationsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalStorageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    storageUnitId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes}
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes;
  return proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes}
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExternalStorageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStorageUnitId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExternalStorageId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStorageUnitId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 external_storage_id = 2;
 * @return {number}
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.getExternalStorageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.setExternalStorageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 storage_unit_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.getStorageUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.prototype.setStorageUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ExternalStorageAttributes.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ExternalStorageAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ExternalStorageAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ExternalStorageAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTimeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTimeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    organisationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    storageUnitRelationList: jspb.Message.toObjectList(msg.getStorageUnitRelationList(),
    proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ExternalStorageAttributes}
 */
proto.sip.storeroom.ExternalStorageAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ExternalStorageAttributes;
  return proto.sip.storeroom.ExternalStorageAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ExternalStorageAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ExternalStorageAttributes}
 */
proto.sip.storeroom.ExternalStorageAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartTimeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndTimeId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    case 6:
      var value = new proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes;
      reader.readMessage(value,proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.deserializeBinaryFromReader);
      msg.addStorageUnitRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ExternalStorageAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ExternalStorageAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ExternalStorageAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTimeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEndTimeId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStorageUnitRelationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ExternalStorageAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string request_text = 2;
 * @return {string}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.getRequestText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ExternalStorageAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.setRequestText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 start_time_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.getStartTimeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ExternalStorageAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.setStartTimeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 end_time_id = 4;
 * @return {number}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.getEndTimeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ExternalStorageAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.setEndTimeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 organisation_id = 5;
 * @return {number}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ExternalStorageAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated ExternalStorageStorageUnitRelationAttributes storage_unit_relation = 6;
 * @return {!Array<!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes>}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.getStorageUnitRelationList = function() {
  return /** @type{!Array<!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes, 6));
};


/**
 * @param {!Array<!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes>} value
 * @return {!proto.sip.storeroom.ExternalStorageAttributes} returns this
*/
proto.sip.storeroom.ExternalStorageAttributes.prototype.setStorageUnitRelationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes}
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.addStorageUnitRelation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sip.storeroom.ExternalStorageStorageUnitRelationAttributes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ExternalStorageAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageAttributes.prototype.clearStorageUnitRelationList = function() {
  return this.setStorageUnitRelationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateExternalStorageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateExternalStorageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateExternalStorageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorageInfo: (f = msg.getExternalStorageInfo()) && proto.sip.storeroom.ExternalStorageAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateExternalStorageRequest}
 */
proto.sip.storeroom.CreateExternalStorageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateExternalStorageRequest;
  return proto.sip.storeroom.CreateExternalStorageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateExternalStorageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateExternalStorageRequest}
 */
proto.sip.storeroom.CreateExternalStorageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.ExternalStorageAttributes;
      reader.readMessage(value,proto.sip.storeroom.ExternalStorageAttributes.deserializeBinaryFromReader);
      msg.setExternalStorageInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateExternalStorageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateExternalStorageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateExternalStorageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorageInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.ExternalStorageAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalStorageAttributes external_storage_info = 1;
 * @return {?proto.sip.storeroom.ExternalStorageAttributes}
 */
proto.sip.storeroom.CreateExternalStorageRequest.prototype.getExternalStorageInfo = function() {
  return /** @type{?proto.sip.storeroom.ExternalStorageAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ExternalStorageAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.ExternalStorageAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateExternalStorageRequest} returns this
*/
proto.sip.storeroom.CreateExternalStorageRequest.prototype.setExternalStorageInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateExternalStorageRequest} returns this
 */
proto.sip.storeroom.CreateExternalStorageRequest.prototype.clearExternalStorageInfo = function() {
  return this.setExternalStorageInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateExternalStorageRequest.prototype.hasExternalStorageInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateExternalStorageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateExternalStorageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateExternalStorageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorage: (f = msg.getExternalStorage()) && proto.sip.storeroom.GetExternalStorageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateExternalStorageResponse}
 */
proto.sip.storeroom.CreateExternalStorageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateExternalStorageResponse;
  return proto.sip.storeroom.CreateExternalStorageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateExternalStorageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateExternalStorageResponse}
 */
proto.sip.storeroom.CreateExternalStorageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageResponse;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageResponse.deserializeBinaryFromReader);
      msg.setExternalStorage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateExternalStorageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateExternalStorageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateExternalStorageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetExternalStorageResponse external_storage = 1;
 * @return {?proto.sip.storeroom.GetExternalStorageResponse}
 */
proto.sip.storeroom.CreateExternalStorageResponse.prototype.getExternalStorage = function() {
  return /** @type{?proto.sip.storeroom.GetExternalStorageResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetExternalStorageResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetExternalStorageResponse|undefined} value
 * @return {!proto.sip.storeroom.CreateExternalStorageResponse} returns this
*/
proto.sip.storeroom.CreateExternalStorageResponse.prototype.setExternalStorage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateExternalStorageResponse} returns this
 */
proto.sip.storeroom.CreateExternalStorageResponse.prototype.clearExternalStorage = function() {
  return this.setExternalStorage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateExternalStorageResponse.prototype.hasExternalStorage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteExternalStorageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteExternalStorageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteExternalStorageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteExternalStorageRequest}
 */
proto.sip.storeroom.DeleteExternalStorageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteExternalStorageRequest;
  return proto.sip.storeroom.DeleteExternalStorageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteExternalStorageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteExternalStorageRequest}
 */
proto.sip.storeroom.DeleteExternalStorageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteExternalStorageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteExternalStorageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteExternalStorageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteExternalStorageRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteExternalStorageRequest} returns this
 */
proto.sip.storeroom.DeleteExternalStorageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteExternalStorageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteExternalStorageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteExternalStorageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteExternalStorageResponse}
 */
proto.sip.storeroom.DeleteExternalStorageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteExternalStorageResponse;
  return proto.sip.storeroom.DeleteExternalStorageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteExternalStorageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteExternalStorageResponse}
 */
proto.sip.storeroom.DeleteExternalStorageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteExternalStorageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteExternalStorageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteExternalStorageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteExternalStorageResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteExternalStorageResponse} returns this
 */
proto.sip.storeroom.DeleteExternalStorageResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateExternalStorageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateExternalStorageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalStorageInfo: (f = msg.getExternalStorageInfo()) && proto.sip.storeroom.ExternalStorageAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateExternalStorageRequest}
 */
proto.sip.storeroom.UpdateExternalStorageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateExternalStorageRequest;
  return proto.sip.storeroom.UpdateExternalStorageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateExternalStorageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateExternalStorageRequest}
 */
proto.sip.storeroom.UpdateExternalStorageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ExternalStorageAttributes;
      reader.readMessage(value,proto.sip.storeroom.ExternalStorageAttributes.deserializeBinaryFromReader);
      msg.setExternalStorageInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateExternalStorageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateExternalStorageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExternalStorageInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ExternalStorageAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateExternalStorageRequest} returns this
 */
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ExternalStorageAttributes external_storage_info = 2;
 * @return {?proto.sip.storeroom.ExternalStorageAttributes}
 */
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.getExternalStorageInfo = function() {
  return /** @type{?proto.sip.storeroom.ExternalStorageAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ExternalStorageAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ExternalStorageAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateExternalStorageRequest} returns this
*/
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.setExternalStorageInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateExternalStorageRequest} returns this
 */
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.clearExternalStorageInfo = function() {
  return this.setExternalStorageInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateExternalStorageRequest.prototype.hasExternalStorageInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateExternalStorageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateExternalStorageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateExternalStorageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorage: (f = msg.getExternalStorage()) && proto.sip.storeroom.GetExternalStorageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateExternalStorageResponse}
 */
proto.sip.storeroom.UpdateExternalStorageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateExternalStorageResponse;
  return proto.sip.storeroom.UpdateExternalStorageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateExternalStorageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateExternalStorageResponse}
 */
proto.sip.storeroom.UpdateExternalStorageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageResponse;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageResponse.deserializeBinaryFromReader);
      msg.setExternalStorage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateExternalStorageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateExternalStorageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateExternalStorageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetExternalStorageResponse external_storage = 1;
 * @return {?proto.sip.storeroom.GetExternalStorageResponse}
 */
proto.sip.storeroom.UpdateExternalStorageResponse.prototype.getExternalStorage = function() {
  return /** @type{?proto.sip.storeroom.GetExternalStorageResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetExternalStorageResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetExternalStorageResponse|undefined} value
 * @return {!proto.sip.storeroom.UpdateExternalStorageResponse} returns this
*/
proto.sip.storeroom.UpdateExternalStorageResponse.prototype.setExternalStorage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateExternalStorageResponse} returns this
 */
proto.sip.storeroom.UpdateExternalStorageResponse.prototype.clearExternalStorage = function() {
  return this.setExternalStorage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateExternalStorageResponse.prototype.hasExternalStorage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ConfirmExternalStorageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ConfirmExternalStorageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    confirmed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ConfirmExternalStorageRequest}
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ConfirmExternalStorageRequest;
  return proto.sip.storeroom.ConfirmExternalStorageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ConfirmExternalStorageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ConfirmExternalStorageRequest}
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExternalStorageId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ConfirmExternalStorageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ConfirmExternalStorageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorageId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getConfirmed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 external_storage_id = 1;
 * @return {number}
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.prototype.getExternalStorageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ConfirmExternalStorageRequest} returns this
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.prototype.setExternalStorageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool confirmed = 2;
 * @return {boolean}
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.prototype.getConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ConfirmExternalStorageRequest} returns this
 */
proto.sip.storeroom.ConfirmExternalStorageRequest.prototype.setConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ConfirmExternalStorageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ConfirmExternalStorageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorage: (f = msg.getExternalStorage()) && proto.sip.storeroom.GetExternalStorageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ConfirmExternalStorageResponse}
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ConfirmExternalStorageResponse;
  return proto.sip.storeroom.ConfirmExternalStorageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ConfirmExternalStorageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ConfirmExternalStorageResponse}
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageResponse;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageResponse.deserializeBinaryFromReader);
      msg.setExternalStorage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ConfirmExternalStorageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ConfirmExternalStorageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetExternalStorageResponse external_storage = 1;
 * @return {?proto.sip.storeroom.GetExternalStorageResponse}
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.prototype.getExternalStorage = function() {
  return /** @type{?proto.sip.storeroom.GetExternalStorageResponse} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetExternalStorageResponse, 1));
};


/**
 * @param {?proto.sip.storeroom.GetExternalStorageResponse|undefined} value
 * @return {!proto.sip.storeroom.ConfirmExternalStorageResponse} returns this
*/
proto.sip.storeroom.ConfirmExternalStorageResponse.prototype.setExternalStorage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ConfirmExternalStorageResponse} returns this
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.prototype.clearExternalStorage = function() {
  return this.setExternalStorage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ConfirmExternalStorageResponse.prototype.hasExternalStorage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetExternalStorageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetExternalStorageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetExternalStorageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetExternalStorageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetExternalStorageRequest}
 */
proto.sip.storeroom.GetExternalStorageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetExternalStorageRequest;
  return proto.sip.storeroom.GetExternalStorageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetExternalStorageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetExternalStorageRequest}
 */
proto.sip.storeroom.GetExternalStorageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetExternalStorageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetExternalStorageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetExternalStorageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetExternalStorageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetExternalStorageRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetExternalStorageRequest} returns this
 */
proto.sip.storeroom.GetExternalStorageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetExternalStorageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetExternalStorageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetExternalStorageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalStorageInfo: (f = msg.getExternalStorageInfo()) && proto.sip.storeroom.ExternalStorageAttributes.toObject(includeInstance, f),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createUser: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateUser: jspb.Message.getFieldWithDefault(msg, 6, ""),
    confirmationTime: (f = msg.getConfirmationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    confirmationUser: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetExternalStorageResponse}
 */
proto.sip.storeroom.GetExternalStorageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetExternalStorageResponse;
  return proto.sip.storeroom.GetExternalStorageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetExternalStorageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetExternalStorageResponse}
 */
proto.sip.storeroom.GetExternalStorageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ExternalStorageAttributes;
      reader.readMessage(value,proto.sip.storeroom.ExternalStorageAttributes.deserializeBinaryFromReader);
      msg.setExternalStorageInfo(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateUser(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateUser(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConfirmationTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetExternalStorageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetExternalStorageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetExternalStorageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExternalStorageInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ExternalStorageAttributes.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUser();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConfirmationTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConfirmationUser();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ExternalStorageAttributes external_storage_info = 2;
 * @return {?proto.sip.storeroom.ExternalStorageAttributes}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getExternalStorageInfo = function() {
  return /** @type{?proto.sip.storeroom.ExternalStorageAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ExternalStorageAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ExternalStorageAttributes|undefined} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
*/
proto.sip.storeroom.GetExternalStorageResponse.prototype.setExternalStorageInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.clearExternalStorageInfo = function() {
  return this.setExternalStorageInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.hasExternalStorageInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp create_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
*/
proto.sip.storeroom.GetExternalStorageResponse.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string create_user = 4;
 * @return {string}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getCreateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.setCreateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp update_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
*/
proto.sip.storeroom.GetExternalStorageResponse.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string update_user = 6;
 * @return {string}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getUpdateUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.setUpdateUser = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp confirmation_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getConfirmationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
*/
proto.sip.storeroom.GetExternalStorageResponse.prototype.setConfirmationTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.clearConfirmationTime = function() {
  return this.setConfirmationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.hasConfirmationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string confirmation_user = 8;
 * @return {string}
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.getConfirmationUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.GetExternalStorageResponse} returns this
 */
proto.sip.storeroom.GetExternalStorageResponse.prototype.setConfirmationUser = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationExternalStoragesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    onlyInFuture: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationExternalStoragesRequest;
  return proto.sip.storeroom.ListOrganisationExternalStoragesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganisationId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyInFuture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationExternalStoragesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOnlyInFuture();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} returns this
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} returns this
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 organisation_id = 3;
 * @return {number}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.getOrganisationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} returns this
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool only_in_future = 4;
 * @return {boolean}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.getOnlyInFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} returns this
 */
proto.sip.storeroom.ListOrganisationExternalStoragesRequest.prototype.setOnlyInFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListOrganisationExternalStoragesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStoragesList: jspb.Message.toObjectList(msg.getExternalStoragesList(),
    proto.sip.storeroom.GetExternalStorageResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListOrganisationExternalStoragesResponse;
  return proto.sip.storeroom.ListOrganisationExternalStoragesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageResponse;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageResponse.deserializeBinaryFromReader);
      msg.addExternalStorages(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListOrganisationExternalStoragesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStoragesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetExternalStorageResponse external_storages = 1;
 * @return {!Array<!proto.sip.storeroom.GetExternalStorageResponse>}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.getExternalStoragesList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetExternalStorageResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetExternalStorageResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetExternalStorageResponse>} value
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse} returns this
*/
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.setExternalStoragesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetExternalStorageResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetExternalStorageResponse}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.addExternalStorages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetExternalStorageResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse} returns this
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.clearExternalStoragesList = function() {
  return this.setExternalStoragesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListOrganisationExternalStoragesResponse} returns this
 */
proto.sip.storeroom.ListOrganisationExternalStoragesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListExternalStoragesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListExternalStoragesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStoragesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    onlyInFuture: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListExternalStoragesRequest}
 */
proto.sip.storeroom.ListExternalStoragesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListExternalStoragesRequest;
  return proto.sip.storeroom.ListExternalStoragesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListExternalStoragesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListExternalStoragesRequest}
 */
proto.sip.storeroom.ListExternalStoragesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyInFuture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListExternalStoragesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListExternalStoragesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStoragesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOnlyInFuture();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListExternalStoragesRequest} returns this
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListExternalStoragesRequest} returns this
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool only_in_future = 3;
 * @return {boolean}
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.getOnlyInFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ListExternalStoragesRequest} returns this
 */
proto.sip.storeroom.ListExternalStoragesRequest.prototype.setOnlyInFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListExternalStoragesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListExternalStoragesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListExternalStoragesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListExternalStoragesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStoragesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStoragesList: jspb.Message.toObjectList(msg.getExternalStoragesList(),
    proto.sip.storeroom.GetExternalStorageResponse.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListExternalStoragesResponse}
 */
proto.sip.storeroom.ListExternalStoragesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListExternalStoragesResponse;
  return proto.sip.storeroom.ListExternalStoragesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListExternalStoragesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListExternalStoragesResponse}
 */
proto.sip.storeroom.ListExternalStoragesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageResponse;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageResponse.deserializeBinaryFromReader);
      msg.addExternalStorages(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListExternalStoragesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListExternalStoragesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListExternalStoragesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStoragesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStoragesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageResponse.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetExternalStorageResponse external_storages = 1;
 * @return {!Array<!proto.sip.storeroom.GetExternalStorageResponse>}
 */
proto.sip.storeroom.ListExternalStoragesResponse.prototype.getExternalStoragesList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetExternalStorageResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetExternalStorageResponse, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetExternalStorageResponse>} value
 * @return {!proto.sip.storeroom.ListExternalStoragesResponse} returns this
*/
proto.sip.storeroom.ListExternalStoragesResponse.prototype.setExternalStoragesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetExternalStorageResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetExternalStorageResponse}
 */
proto.sip.storeroom.ListExternalStoragesResponse.prototype.addExternalStorages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetExternalStorageResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListExternalStoragesResponse} returns this
 */
proto.sip.storeroom.ListExternalStoragesResponse.prototype.clearExternalStoragesList = function() {
  return this.setExternalStoragesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListExternalStoragesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListExternalStoragesResponse} returns this
 */
proto.sip.storeroom.ListExternalStoragesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ExternalStorageDateAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ExternalStorageDateAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ExternalStorageDateAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ExternalStorageDateAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ExternalStorageDateAttributes}
 */
proto.sip.storeroom.ExternalStorageDateAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ExternalStorageDateAttributes;
  return proto.sip.storeroom.ExternalStorageDateAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ExternalStorageDateAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ExternalStorageDateAttributes}
 */
proto.sip.storeroom.ExternalStorageDateAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ExternalStorageDateAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ExternalStorageDateAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ExternalStorageDateAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ExternalStorageDateAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.ExternalStorageDateAttributes.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.ExternalStorageDateAttributes} returns this
*/
proto.sip.storeroom.ExternalStorageDateAttributes.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.ExternalStorageDateAttributes} returns this
 */
proto.sip.storeroom.ExternalStorageDateAttributes.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.ExternalStorageDateAttributes.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateExternalStorageDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateExternalStorageDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorageDateInfo: (f = msg.getExternalStorageDateInfo()) && proto.sip.storeroom.ExternalStorageDateAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateExternalStorageDateRequest}
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateExternalStorageDateRequest;
  return proto.sip.storeroom.CreateExternalStorageDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateExternalStorageDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateExternalStorageDateRequest}
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.ExternalStorageDateAttributes;
      reader.readMessage(value,proto.sip.storeroom.ExternalStorageDateAttributes.deserializeBinaryFromReader);
      msg.setExternalStorageDateInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateExternalStorageDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateExternalStorageDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorageDateInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.ExternalStorageDateAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalStorageDateAttributes external_storage_date_info = 1;
 * @return {?proto.sip.storeroom.ExternalStorageDateAttributes}
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.prototype.getExternalStorageDateInfo = function() {
  return /** @type{?proto.sip.storeroom.ExternalStorageDateAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ExternalStorageDateAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.ExternalStorageDateAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateExternalStorageDateRequest} returns this
*/
proto.sip.storeroom.CreateExternalStorageDateRequest.prototype.setExternalStorageDateInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateExternalStorageDateRequest} returns this
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.prototype.clearExternalStorageDateInfo = function() {
  return this.setExternalStorageDateInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateExternalStorageDateRequest.prototype.hasExternalStorageDateInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.CreateExternalStorageDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.CreateExternalStorageDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorageDate: (f = msg.getExternalStorageDate()) && proto.sip.storeroom.GetExternalStorageDateAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.CreateExternalStorageDateResponse}
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.CreateExternalStorageDateResponse;
  return proto.sip.storeroom.CreateExternalStorageDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.CreateExternalStorageDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.CreateExternalStorageDateResponse}
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageDateAttributes;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageDateAttributes.deserializeBinaryFromReader);
      msg.setExternalStorageDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.CreateExternalStorageDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.CreateExternalStorageDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorageDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageDateAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetExternalStorageDateAttributes external_storage_date = 1;
 * @return {?proto.sip.storeroom.GetExternalStorageDateAttributes}
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.prototype.getExternalStorageDate = function() {
  return /** @type{?proto.sip.storeroom.GetExternalStorageDateAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetExternalStorageDateAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.GetExternalStorageDateAttributes|undefined} value
 * @return {!proto.sip.storeroom.CreateExternalStorageDateResponse} returns this
*/
proto.sip.storeroom.CreateExternalStorageDateResponse.prototype.setExternalStorageDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.CreateExternalStorageDateResponse} returns this
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.prototype.clearExternalStorageDate = function() {
  return this.setExternalStorageDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.CreateExternalStorageDateResponse.prototype.hasExternalStorageDate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteExternalStorageDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteExternalStorageDateRequest}
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteExternalStorageDateRequest;
  return proto.sip.storeroom.DeleteExternalStorageDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteExternalStorageDateRequest}
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteExternalStorageDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteExternalStorageDateRequest} returns this
 */
proto.sip.storeroom.DeleteExternalStorageDateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.DeleteExternalStorageDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.DeleteExternalStorageDateResponse}
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.DeleteExternalStorageDateResponse;
  return proto.sip.storeroom.DeleteExternalStorageDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.DeleteExternalStorageDateResponse}
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.DeleteExternalStorageDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.DeleteExternalStorageDateResponse} returns this
 */
proto.sip.storeroom.DeleteExternalStorageDateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateExternalStorageDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalStorageDateInfo: (f = msg.getExternalStorageDateInfo()) && proto.sip.storeroom.ExternalStorageDateAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateRequest}
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateExternalStorageDateRequest;
  return proto.sip.storeroom.UpdateExternalStorageDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateRequest}
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sip.storeroom.ExternalStorageDateAttributes;
      reader.readMessage(value,proto.sip.storeroom.ExternalStorageDateAttributes.deserializeBinaryFromReader);
      msg.setExternalStorageDateInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateExternalStorageDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExternalStorageDateInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.storeroom.ExternalStorageDateAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateRequest} returns this
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ExternalStorageDateAttributes external_storage_date_info = 2;
 * @return {?proto.sip.storeroom.ExternalStorageDateAttributes}
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.getExternalStorageDateInfo = function() {
  return /** @type{?proto.sip.storeroom.ExternalStorageDateAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.ExternalStorageDateAttributes, 2));
};


/**
 * @param {?proto.sip.storeroom.ExternalStorageDateAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateRequest} returns this
*/
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.setExternalStorageDateInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateRequest} returns this
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.clearExternalStorageDateInfo = function() {
  return this.setExternalStorageDateInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateExternalStorageDateRequest.prototype.hasExternalStorageDateInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateExternalStorageDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorageDate: (f = msg.getExternalStorageDate()) && proto.sip.storeroom.GetExternalStorageDateAttributes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateResponse}
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateExternalStorageDateResponse;
  return proto.sip.storeroom.UpdateExternalStorageDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateResponse}
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageDateAttributes;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageDateAttributes.deserializeBinaryFromReader);
      msg.setExternalStorageDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateExternalStorageDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorageDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageDateAttributes.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetExternalStorageDateAttributes external_storage_date = 1;
 * @return {?proto.sip.storeroom.GetExternalStorageDateAttributes}
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.prototype.getExternalStorageDate = function() {
  return /** @type{?proto.sip.storeroom.GetExternalStorageDateAttributes} */ (
    jspb.Message.getWrapperField(this, proto.sip.storeroom.GetExternalStorageDateAttributes, 1));
};


/**
 * @param {?proto.sip.storeroom.GetExternalStorageDateAttributes|undefined} value
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateResponse} returns this
*/
proto.sip.storeroom.UpdateExternalStorageDateResponse.prototype.setExternalStorageDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.UpdateExternalStorageDateResponse} returns this
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.prototype.clearExternalStorageDate = function() {
  return this.setExternalStorageDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.UpdateExternalStorageDateResponse.prototype.hasExternalStorageDate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.GetExternalStorageDateAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.GetExternalStorageDateAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.GetExternalStorageDateAttributes}
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.GetExternalStorageDateAttributes;
  return proto.sip.storeroom.GetExternalStorageDateAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.GetExternalStorageDateAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.GetExternalStorageDateAttributes}
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.GetExternalStorageDateAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.GetExternalStorageDateAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.GetExternalStorageDateAttributes} returns this
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.storeroom.GetExternalStorageDateAttributes} returns this
*/
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.storeroom.GetExternalStorageDateAttributes} returns this
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.storeroom.GetExternalStorageDateAttributes.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListExternalStorageDatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListExternalStorageDatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    constrainTime: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListExternalStorageDatesRequest}
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListExternalStorageDatesRequest;
  return proto.sip.storeroom.ListExternalStorageDatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListExternalStorageDatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListExternalStorageDatesRequest}
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConstrainTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListExternalStorageDatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListExternalStorageDatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConstrainTime();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListExternalStorageDatesRequest} returns this
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListExternalStorageDatesRequest} returns this
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool constrain_time = 3;
 * @return {boolean}
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.getConstrainTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.storeroom.ListExternalStorageDatesRequest} returns this
 */
proto.sip.storeroom.ListExternalStorageDatesRequest.prototype.setConstrainTime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListExternalStorageDatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListExternalStorageDatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalStorageDateList: jspb.Message.toObjectList(msg.getExternalStorageDateList(),
    proto.sip.storeroom.GetExternalStorageDateAttributes.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListExternalStorageDatesResponse}
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListExternalStorageDatesResponse;
  return proto.sip.storeroom.ListExternalStorageDatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListExternalStorageDatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListExternalStorageDatesResponse}
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.GetExternalStorageDateAttributes;
      reader.readMessage(value,proto.sip.storeroom.GetExternalStorageDateAttributes.deserializeBinaryFromReader);
      msg.addExternalStorageDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListExternalStorageDatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListExternalStorageDatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalStorageDateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.GetExternalStorageDateAttributes.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated GetExternalStorageDateAttributes external_storage_date = 1;
 * @return {!Array<!proto.sip.storeroom.GetExternalStorageDateAttributes>}
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.getExternalStorageDateList = function() {
  return /** @type{!Array<!proto.sip.storeroom.GetExternalStorageDateAttributes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.GetExternalStorageDateAttributes, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.GetExternalStorageDateAttributes>} value
 * @return {!proto.sip.storeroom.ListExternalStorageDatesResponse} returns this
*/
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.setExternalStorageDateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.GetExternalStorageDateAttributes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.GetExternalStorageDateAttributes}
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.addExternalStorageDate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.GetExternalStorageDateAttributes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListExternalStorageDatesResponse} returns this
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.clearExternalStorageDateList = function() {
  return this.setExternalStorageDateList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListExternalStorageDatesResponse} returns this
 */
proto.sip.storeroom.ListExternalStorageDatesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListUsersRequest}
 */
proto.sip.storeroom.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListUsersRequest;
  return proto.sip.storeroom.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListUsersRequest}
 */
proto.sip.storeroom.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.sip.storeroom.ListUsersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.ListUsersRequest} returns this
 */
proto.sip.storeroom.ListUsersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListUsersRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListUsersRequest} returns this
 */
proto.sip.storeroom.ListUsersRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.storeroom.ListUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.sip.storeroom.UserAttributes.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.ListUsersResponse}
 */
proto.sip.storeroom.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.ListUsersResponse;
  return proto.sip.storeroom.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.ListUsersResponse}
 */
proto.sip.storeroom.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.storeroom.UserAttributes;
      reader.readMessage(value,proto.sip.storeroom.UserAttributes.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.storeroom.UserAttributes.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated UserAttributes users = 1;
 * @return {!Array<!proto.sip.storeroom.UserAttributes>}
 */
proto.sip.storeroom.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.sip.storeroom.UserAttributes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.storeroom.UserAttributes, 1));
};


/**
 * @param {!Array<!proto.sip.storeroom.UserAttributes>} value
 * @return {!proto.sip.storeroom.ListUsersResponse} returns this
*/
proto.sip.storeroom.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.storeroom.UserAttributes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.storeroom.UserAttributes}
 */
proto.sip.storeroom.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.storeroom.UserAttributes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.storeroom.ListUsersResponse} returns this
 */
proto.sip.storeroom.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.storeroom.ListUsersResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.ListUsersResponse} returns this
 */
proto.sip.storeroom.ListUsersResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activeOrganisation: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UpdateUserRequest}
 */
proto.sip.storeroom.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UpdateUserRequest;
  return proto.sip.storeroom.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UpdateUserRequest}
 */
proto.sip.storeroom.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActiveOrganisation();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string language = 1;
 * @return {string}
 */
proto.sip.storeroom.UpdateUserRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.UpdateUserRequest} returns this
 */
proto.sip.storeroom.UpdateUserRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 active_organisation = 2;
 * @return {number}
 */
proto.sip.storeroom.UpdateUserRequest.prototype.getActiveOrganisation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UpdateUserRequest} returns this
 */
proto.sip.storeroom.UpdateUserRequest.prototype.setActiveOrganisation = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.storeroom.UserAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.storeroom.UserAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.storeroom.UserAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UserAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    language: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activeOrganisation: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.storeroom.UserAttributes}
 */
proto.sip.storeroom.UserAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.storeroom.UserAttributes;
  return proto.sip.storeroom.UserAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.storeroom.UserAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.storeroom.UserAttributes}
 */
proto.sip.storeroom.UserAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.storeroom.UserAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.storeroom.UserAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.storeroom.UserAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.storeroom.UserAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActiveOrganisation();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.sip.storeroom.UserAttributes.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UserAttributes} returns this
 */
proto.sip.storeroom.UserAttributes.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.sip.storeroom.UserAttributes.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.UserAttributes} returns this
 */
proto.sip.storeroom.UserAttributes.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.sip.storeroom.UserAttributes.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.storeroom.UserAttributes} returns this
 */
proto.sip.storeroom.UserAttributes.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 active_organisation = 4;
 * @return {number}
 */
proto.sip.storeroom.UserAttributes.prototype.getActiveOrganisation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.storeroom.UserAttributes} returns this
 */
proto.sip.storeroom.UserAttributes.prototype.setActiveOrganisation = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.sip.storeroom.StorageUnitTypes = {
  OTHER: 0,
  BOX: 1,
  PALLET: 2
};

goog.object.extend(exports, proto.sip.storeroom);
