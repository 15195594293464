// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box, Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    createOrganisationMessage 
} from '../../features/organisation/organisationsSlice'

import { 
    OrganisationValidationSchema 
} from './OrganisationValidationSchema'

import { 
    OrganisationFormLayout 
} from '../../layouts/forms/OrganisationFormLayout'

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'


/**
 * The OrganisationCreateForm component renders the create Organisation Form of the application.
 */
export const OrganisationCreateForm = () => { 
    const dispatch = useAppDispatch()
    
    const form = useForm({
        initialValues: {
            shortName: '',
            title: '',
            logoPath: '',
            contactMail: '',
            contactPhone: '',
            contactAddress: '',
            contactAddressBuilding: '',
            contactAddressFloor: '',
            contactAddressRoom: '',
            contactAddressStreet: '',
            contactAddressNumber: '',
            contactAddressPLZ: '',
            contactAddressCity: '',
            editRole: '',
            viewRole: '',
        },

        validate: yupResolver(OrganisationValidationSchema),

        transformValues: (values) => ({
            shortName: values.shortName,
            title: values.title,
            logoPath: values.logoPath,
            contactMail: values.contactMail,
            contactPhone: values.contactPhone,
            contactAddress: `${values.contactAddressBuilding + "," + values.contactAddressFloor + "," + values.contactAddressRoom + "," + values.contactAddressStreet + "," + values.contactAddressNumber + "," + values.contactAddressPLZ + "," + values.contactAddressCity}`,
            editRole: values.editRole,
            viewRole: values.viewRole,
        }),

    })


    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(createOrganisationMessage(values)))}
            >                
                <OrganisationFormLayout
                    form={form}
                />
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}