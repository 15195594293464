// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import * as Yup from "yup"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The Page component renders the admin page of the application.
 */
export const TagValidationSchema = Yup.object().shape({
    tagName: Yup.string()
      .required('Please input name'),
    }
)