// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui



// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminReservationsPage 
} from "../../pages/reservations/AdminReservationsPage"

import { 
    CreateReservationsPage 
} from "../../pages/reservations/CreateReservationsPage"

import { 
    UpdateReservationsPage 
} from "../../pages/reservations/UpdateReservationsPage"

import { 
    DeleteReservationsPage 
} from "../../pages/reservations/DeleteReservationsPage"



export const AdminRoutesReservations = () => {
    return (
        <Routes>               
            <Route
                index
                element={<AdminReservationsPage/>}                            
            />
            <Route
                path="create"
                element={<CreateReservationsPage/>}                            
            />
            <Route
                path="update"
                element={<UpdateReservationsPage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteReservationsPage/>}                            
            />
        </Routes>
    )
}

