import { Button } from "@mantine/core";
import { useAuth } from "../../hooks/useAuth";

export const LoginButton = ({ outline }: { outline?: boolean }) => {
	const { login, logout, authState } = useAuth();
	const isLoggedIn = authState && authState.isAuthenticated;
	return (
		<Button
			variant={outline ? "outline" : "filled"}
			style={{ backgroundColor: outline ? "white" : undefined }}
			onClick={() => {
				if (isLoggedIn) {
					// TODO: do proper logout: redirct to home, delete cookie etc.
					logout();
				} else {
					login();
				}
			}}
		>
			{isLoggedIn ? "Logout" : " Login"}
		</Button>
	);
};
