// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces
interface ErrorScreenProps {
    customText?: string
}


/**
 * The ErrorScreen component renders the ErrorScreen of the application.
 */
export const ErrorScreen: React.FC<ErrorScreenProps> = ({ customText }) => {
    const { t } = useTranslation()
    return (
        <div
            className="d-flex justify-content-center"
            style={{ height: "80vh" }}
        >
            <form className="text-center my-auto">
                <h1>{t("ErrorScreen.title")}</h1>
                <p>{customText || t("ErrorScreen.text")}</p>
            </form>
        </div>
    )
}
