// Imports from external sources
import { 
    useTranslation
} from "react-i18next"

import {  
    useNavigate 
} from "react-router-dom"

import {
    Button
} from "@mantine/core"


/**
 * The GoFromToViewPageButton component renders a button which navigate from the viewpage to the updatepage.
 */
export const GoFromToViewPageButton = () => {    
    const { t } = useTranslation()
    const navigate = useNavigate()    
  
    return (
        <Button 
            onClick={() => navigate("../view")}
            fullWidth
        >
            {t("Storeroom.viewButton")}
        </Button>   
    )
}