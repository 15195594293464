//Imports from external sources
import { 
    Fragment,
    useEffect 
} from "react"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    ReservationTable 
} from "../../components/reservation/ReservationTable"

import { 
    fetchAllMyConfirmedReservationsMessage,
    selectReservationsById,
    selectIsLoadingReservations,
    selectErrorReservations,
    GetReservationResponse
} from "../../features/reservation/reservationsSlice"

import { 
    selectReservationSingleId, 
} from "../../features/reservation/reservationSingleIdSlice"

import { 
    ConfirmBar 
} from "../../layouts/menuBars/general/ConfirmBar"

import { 
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

/**
 * The AdminReservationsPage component renders the AdminReservationsPage page of the application.
 */
export const MyConfirmedReservationsPage = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    
    //Selectors
    const reservations = useAppSelector(selectReservationsById)
    const isLoadingReservations = useAppSelector(selectIsLoadingReservations)
    const isErrorReservations = useAppSelector(selectErrorReservations)

    const editReservationId = useAppSelector(selectReservationSingleId).reservationId
    let isDisabled = editReservationId===0 ? true : false
    
   
   
    useEffect(() => {
        dispatch(fetchAllMyConfirmedReservationsMessage())
    }, [dispatch])

       
    //Loading Screen
    if (isLoadingReservations) {
        return <LoadScreen />
    }


    //Error Screen
    if (isErrorReservations) {
        return <ErrorScreen 
            customText={isErrorReservations?.message} 
        />       
    } 

    const reservationsBeforeStart = new Array<GetReservationResponse>()
    const reservationsCurrent = new Array<GetReservationResponse>()
    const reservationsArchive = new Array<GetReservationResponse>()
 
    if (reservations !== undefined) {
        for (var i in reservations) {
            const curStartTime = reservations[i].reservationInfo!.startTime!.seconds          
            const curEndTime = reservations[i].reservationInfo!.endTime!.seconds
            const curTime = new Date().getTime() / 1000

            if (curStartTime > curTime) {
                reservationsBeforeStart.push(reservations[i])
            } else if (curEndTime > curTime) {
                reservationsCurrent.push(reservations[i])
            } else {
                reservationsArchive.push(reservations[i])
            }
        }
    }
        
    return(
        <Fragment>            
            <CreateBar/>  
            <ConfirmBar
                isDisabled={isDisabled}
                isConfirmed={false}
            />
            <h3>{t("Reservations.beforeStart")}</h3>
            <ReservationTable 
                reservationsForTable = {Object.values(reservationsBeforeStart)}
                isBeforeStart = {true}
            />
            <hr/> 
            <h3>{t("Reservations.beforeEnd")}</h3>
            <ReservationTable 
                reservationsForTable = {Object.values(reservationsCurrent)}                      
                isBeforeEnd = {true}
            />           
        
            <hr/> 
            
            <h3>{t("Reservations.archive")}</h3>
            <ReservationTable 
                reservationsForTable={Object.values(reservationsArchive)}
                isBeforeStart = {true}
                isBeforeEnd = {true}
                isArchive = {true}
            />
        </Fragment>      
    )
    
}