// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment, 
    useState 
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import { 
    Link,
} from "react-router-dom"

import {  
    createStyles,
    Flex, 
    NavLink,
    Space
} from "@mantine/core"

// Imports from vseth-canine-ui


// Styles
const useStyles = createStyles((theme, _params, getRef) => {
    return {
        link: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.sm,
            color: theme.colorScheme === 'light' ? theme.colors.dark[9] : theme.colors.gray[2],
            borderRadius: theme.radius.sm,
            fontWeight: 500,
            border: '2px solid',
            borderColor: theme.colors.vsethMain,
            margin: '0px',
          
            '&:hover': {
                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.gray[0],
                color: theme.colorScheme === 'light' ? theme.black : theme.white,          
            },
        },    
    }
})




export const AdminMenuBar = () => {
    const { t } = useTranslation()
    const [active, setActive] = useState('')
    const {classes, cx } = useStyles()

    return (
        <Fragment>
           <Flex 
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: 'sm', sm: 'lg' }}
                justify={{ sm: 'flex-end' }}
            >                        
                <NavLink
                    className={cx(classes.link)}
                    key="organisations"
                    label = {t("Storeroom.organisations")}   
                    component = {Link}
                    to = "organisations"
                    active = {active === "organisations"}
                    onClick = {() => setActive("organisations")}
                    variant="filled"
                />           
                <NavLink
                    className={cx(classes.link)}
                    key="places"
                    label = {t("Storeroom.places")}   
                    component = {Link}
                    to = "places"
                    active = {active === "places"}
                    onClick = {() => setActive("places")}
                    variant="filled"
                />          
                <NavLink
                    className={cx(classes.link)}
                    key="storageUnits"
                    label = {t("Storeroom.storageUnits")}   
                    component = {Link}
                    to = "storageunits"
                    active = {active === "storageUnits"}
                    onClick = {() => setActive("storageUnits")}
                    variant="filled"
                />
            </Flex>
            <Space
                h="xs" 
            />
            <Flex 
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: 'sm', sm: 'lg' }}
                justify={{ sm: 'flex-end' }}
            >
                <NavLink
                    className={cx(classes.link)}
                    key="items"
                    label = {t("Storeroom.items")}   
                    component = {Link}
                    to = "items"
                    active = {active === "items"}
                    onClick = {() => setActive("items")}
                    variant="filled"
                />            
                <NavLink
                    className={cx(classes.link)}
                    key="tags"
                    label = {t("Storeroom.tags")}   
                    component = {Link}
                    to = "tags"
                    active = {active === "tags"}
                    onClick = {() => setActive("tags")}
                    variant="filled"
                />          
                <NavLink
                    className={cx(classes.link)}
                    key="reservations"
                    label = {t("Storeroom.reservations")}   
                    component = {Link}
                    to = "reservations"
                    active = {active === "reservations"}
                    onClick = {() => setActive("reservations")}
                    variant="filled"
                />                    
            </Flex>
            <Space
                h="xs" 
            />
            <Flex 
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: 'sm', sm: 'lg' }}
                justify={{ sm: 'flex-end' }}
            >
                <NavLink
                    className={cx(classes.link)}
                    key="externalStorage"
                    label = {t("Storeroom.externalStorage")}   
                    component = {Link}
                    to = "externalstorage"
                    active = {active === "externalStorage"}
                    onClick = {() => setActive("externalStorage")}
                    variant="filled"
                />                
                <NavLink
                    className={cx(classes.link)}
                    key="externalStorageDates"
                    label = {t("Storeroom.externalStorageDates")}   
                    component = {Link}
                    to = "externalstoragedates"
                    active = {active === "externalStorageDates"}
                    onClick = {() => setActive("externalStorageDates")}
                    variant="filled"
                />   
                <NavLink
                    className={cx(classes.link)}
                    key="users"
                    label = {t("Storeroom.users")}   
                    component = {Link}
                    to = "users"
                    active = {active === "users"}
                    onClick = {() => setActive("users")}
                    variant="filled"
                />          
            </Flex>
        </Fragment>    
    )
}