// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageCreateForm 
} from "../../components/externalStorage/forms/ExternalStorageCreateForm"


/**
 * The CreateExternalStorageRequestsPage component renders the CreateExternalStorageRequests page of the application.
 */
export const CreateExternalStoragePage = () => {
    const { t } = useTranslation()

    return(
        <Card>  
            <ExternalStorageCreateForm 
                title={`` + t("ExternalStorages.createText")}
            />
        </Card>      
    )
}