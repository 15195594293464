// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    StorageUnitCreateForm
} from "../../components/storageunit/StorageUnitCreateForm"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"

import { 
    useIsOnAdminPage 
} from "../../hooks/useIsOnAdminPage"


/**
 * The CreateStorageUnitsPage component renders the CreateStorageUnits page of the application.
 */
export const CreateStorageUnitsPage = () => {
    const { t } = useTranslation()
    const activeOrganisationId = useActiveOrganisationId() 
    const isOnAdminPage = useIsOnAdminPage()  


    const storageUnitCreateForm = () => {
        if (isOnAdminPage) {
            return (
                <StorageUnitCreateForm 
                organisationId = {0}
            />  
            )
        } else if (activeOrganisationId !== 0) {
            return (
                <StorageUnitCreateForm 
                organisationId = {activeOrganisationId}
            />  
            )
        }
    }

   
    return(
        <Card>  
            <h1>{t("StorageUnits.createText")}</h1>   
            {storageUnitCreateForm()}  
        </Card>     
    )
}