// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"

import { 
    Route,
    Routes
} from "react-router-dom"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ReservationsLayoutPage 
} from "../../layouts/pages/ReservationsLayoutPage"

import { 
    ReservationsRoutesMyRequests 
} from "./ReservationsRoutesMyRequests"

import { 
    ReservationsRoutesMyConfirmed 
} from "./ReservationsRoutesMyConfirmed"

import { 
    NotFoundPage
} from "../../pages/NotFoundPage"



export const ReservationsRoutes = () => {
    return (
        <Routes>
            <Route 
                index
                element={<ReservationsLayoutPage/>}
            />
            <Route 
                path=":organisationShortName"
                element={<ReservationsLayoutPage/>}
            >
                <Route
                    index
                    element={<Fragment></Fragment>}                                                                    
                />
                <Route
                    path="myrequests/*"
                    element={<ReservationsRoutesMyRequests/>}                            
                />               
                <Route
                    path="myconfirmed/*"
                    element={<ReservationsRoutesMyConfirmed/>}                            
                />
                <Route
                    path="*"
                    element={<NotFoundPage/>}                            
                />
            </Route>
        </Routes>
    )
}      
