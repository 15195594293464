// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {
    Navigate 
} from "react-router-dom"


// Imports from this projects
// Pages, Components, Features, Hooks




/**
* The ViewPlacePage component renders the ViewPlacePage page of the application.
*/
export const ErrorNavigator = () => {
        return(
            <Navigate
                to = ".."
                replace = {true}        
            />
        )
}