// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Outlet
} from "react-router-dom"

import {
     Container
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    OrganisationsMenuBar 
} from "../menuBars/general/OrganisationsMenuBar"

import {
    useActiveOrganisationId
} from "../../hooks/useActiveOrganisationId"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    NoUserRolePage 
} from "../../pages/NoUserRolePage"

import { 
    useNoUserRoleStatus 
} from "../../hooks/useNoUserRoleStatus"


/**
 * The OrganisationsLayoutPage component renders the Layout of the Organisation page of the application.
 */
export const OrganisationsLayoutPage = () => {
    const organisations = useAppSelector(selectOrganisationsById)
    const activeOrganisationId = useActiveOrganisationId()     
    const hasNoUserRole = useNoUserRoleStatus()

    const organisationShortName = (organisations[activeOrganisationId] === undefined) ? " " : organisations[activeOrganisationId].organisationInfo!.shortName
    const organisationTitleName = (organisations[activeOrganisationId] === undefined) ? " " : organisations[activeOrganisationId].organisationInfo!.title
     

    if (hasNoUserRole) {
        return <NoUserRolePage />
    }
    
    
    return (
        <Container>
            <h1> {organisationTitleName} </h1>
            <OrganisationsMenuBar 
                activeOrganisationShortName={organisationShortName}
            /> 
            <hr/>
            <Outlet/>
        </Container>
    )
}
