// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminTagsPage
} from "../../pages/tags/AdminTagsPage"

import { 
    CreateTagsPage 
} from "../../pages/tags/CreateTagsPage"

import { 
    UpdateTagsPage 
} from "../../pages/tags/UpdateTagsPage"

import { 
    DeleteTagsPage
} from "../../pages/tags/DeleteTagsPage"

import { 
    ViewTagsPage 
} from "../../pages/tags/ViewTagsPage"


export const AdminRoutesTags = () => {
    return ( 
        <Routes>                
            <Route
                index
                element={<AdminTagsPage/>}                            
            />
            <Route
                path="create"
                element={<CreateTagsPage/>}                            
            />
            <Route
                path="view"
                element={<ViewTagsPage/>}                            
            />
            <Route
                path="update"
                element={<UpdateTagsPage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteTagsPage/>}                            
            />                   
        </Routes>

    )
}     
