// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define which objects the tag State have. 
interface tagSingleIdState {
    tagId: number,
}


// Define initial state
const initialState: tagSingleIdState = {
    tagId: 0,
}


// Define state
const tagSingleId = createSlice({
    name: "tagSingleId",
    initialState,
    reducers: {
        setTagSingleId(state, {payload}: PayloadAction<number>) {
            state.tagId = payload
        },
    },
})


// Export Reducers
export default tagSingleId.reducer


// Define Actions
const {
    setTagSingleId,
} = tagSingleId.actions


// Change handler
export {
    setTagSingleId, 
}


// Selectors
type SingleTagIdSliceRoot = {
    tagSingleId: ReturnType<typeof tagSingleId.reducer>
}

export const selectTagSingleId = (state: SingleTagIdSliceRoot) =>
    state.tagSingleId.tagId