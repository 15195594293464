// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    timeObjToMomentTime
} from "../../util/proto"

import { 
    Timestamp
 } from "google-protobuf/google/protobuf/timestamp_pb"


// Define interfaces
interface DateProps {
    date: Timestamp.AsObject,    
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const DateFormatter = (props: DateProps) => {
    return (
        <div>
            {timeObjToMomentTime(props.date).format("DD.MM.YYYY")}
        </div>
    )
}