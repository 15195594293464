// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {   
    Flex, 
} from '@mantine/core'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    CancelButton 
} from '../../../components/buttons/form/CancelButton'

import { 
    DeleteTagButton 
} from '../../../components/buttons/delete/DeleteTagButton'


interface DeleteTagBarProps {
    editTagId: number,
}


/**
 * This menu bar renders the save, save&close and cancel button.
 */
export const DeleteTagBar = (props: DeleteTagBarProps) => {   
    return (
        <Flex 
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'sm', sm: 'lg' }}
            justify={{ sm: 'flex-end' }}
        >
            <DeleteTagButton
                editTagId = {props.editTagId}
            />
            <CancelButton/>
        </Flex>   
    )
} 