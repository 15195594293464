// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect 
} from "react"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../LoadScreen"

import {
    ErrorScreen
} from "../ErrorScreen"

import { 
    ItemTable 
} from "../../components/item/ItemTable"

import { 
    selectItemsById,
    fetchAllOrganisationItemsMessage,
    selectIsLoadingItems,
    selectErrorItems,
    GetItemResponse
} from "../../features/item/itemsSlice"

import { 
    fetchAllTagsMessage,
    selectIsLoadingTags,
    selectErrorTags
} from "../../features/tag/tagsSlice"

import { 
    fetchAllOrganisationStorageUnitsMessage,
    selectIsLoadingStorageUnits,
    selectErrorStorageUnits,
} from "../../features/storageunit/storageUnitSlice"

import { 
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    useEditorStatus 
} from "../../hooks/useEditorStatus"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"


/**
 * The AdminItemsPage component renders the AdminItems page of the application.
 */
export const OrganisationItemsPage = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()  
   
    //Selectors
    const items = useAppSelector(selectItemsById)
    const isLoadingItems = useAppSelector(selectIsLoadingItems)
    const errorItems = useAppSelector(selectErrorItems)

    const isLoadingTags = useAppSelector(selectIsLoadingTags)
    const errorTags = useAppSelector(selectErrorTags)

    const isLoadingStorageUnits = useAppSelector(selectIsLoadingStorageUnits)
    const errorStorageUnits = useAppSelector(selectErrorStorageUnits)

    const activeOrganisationId = useActiveOrganisationId()  
    const isEditor = useEditorStatus(activeOrganisationId)


    useEffect(() => {
        if (activeOrganisationId !== 0) {
            dispatch(fetchAllOrganisationItemsMessage(activeOrganisationId))
            dispatch(fetchAllTagsMessage())
            dispatch(fetchAllOrganisationStorageUnitsMessage(activeOrganisationId))
        }      
    }, [dispatch, activeOrganisationId])

    
    //Loading Screen
    if (isLoadingItems || isLoadingTags || isLoadingStorageUnits) {
        return <LoadScreen />
    }

    //Error Screen
    if (errorItems) {
        return <ErrorScreen 
            customText={errorItems?.message} 
        />
    } else  if (errorStorageUnits) {
        return <ErrorScreen 
            customText={errorStorageUnits?.message} 
        />
    } else if (errorTags) {
        return <ErrorScreen
            customText={errorTags.message}    
        />
    } 
    
    
    const deletedItems = new Array<GetItemResponse>()
    const realItems = new Array<GetItemResponse>()
 
    if (items !== undefined) {
        for (var i in items) {
            if (items[i].deleted) {
                deletedItems.push(items[i])
            } else {
                realItems.push(items[i])
            }
        }
    }


   // Menu Buttons           
   const itemsContent = () => {
        if (isEditor) {
            return (
                <Fragment>            
                   <CreateBar/>

                    <ItemTable 
                        itemsForTable={Object.values(realItems)}                        
                    />

                    <hr/> 
                    <h3>{t("Items.deletedItems")}</h3>
                    <ItemTable 
                        itemsForTable={Object.values(deletedItems)}
                    />                    
                </Fragment>
            ) 
        } else {
            return(
                <ItemTable 
                    itemsForTable={Object.values(realItems)}
                />
            )
        }
    }
    
      
    return(
        <Fragment>         
            {itemsContent()}            
        </Fragment>        
    )       
} 