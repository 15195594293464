// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import { 
    Grid
} from "@mantine/core"

import { 
    DatePickerInput 
} from '@mantine/dates';

import { 
    UseFormReturnType 
} from "@mantine/form"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageDateAttributes
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    timeObjToLocalDate 
} from "../../util/proto";

// Define interfaces

/**
 * The Page component renders the admin page of the application.
 */
//Organisation Form
export const ExternalStorageDateFormLayout = ({ form }:{ form: UseFormReturnType<ExternalStorageDateAttributes>}) => {
    const { t } = useTranslation()  


    const getValue = () => {
        const curValue = form.getInputProps("date").value
               
        if (curValue !== undefined) {
            return (    
                timeObjToLocalDate(curValue)
            )  
        } else {
            return (
                new Date()
            )
        }        
    }

    const onChange = (value: Date) => {      
        var myDate = new Date(value).getTime() //Time in milliseconds    
        form.setFieldValue("date", {seconds: myDate/1000, nanos: 0})
    }
    

    return (
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DatePickerInput
                    label = {t("ExternalStorageDates.date")}
                    placeholder = {t("ExternalStorageDates.date") + ''}
                    dropdownType="modal"
                    minDate={new Date()}
                    value={getValue()}
                    onChange={onChange}                    
                />
             </Grid.Col>
         </Grid>
    )
}