// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {
    useEffect 
} from "react"

import { 
    Outlet
} from "react-router-dom"

import { 
    useTranslation 
} from "react-i18next"

import {
    Container, 
    Grid
} from "@mantine/core"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    MyOrganisationsList 
} from "../../components/user/MyOrganisationsList"

import { 
    selectIsAuthenticated
} from "../../features/auth/authSlice"

import {     
    fetchAllOrganisationsMessage,
    selectIsLoadingOrganisations,
    selectErrorOrganisations,
} from "../../features/organisation/organisationsSlice"

import { 
    updateUserMessage,
    fetchUserMessage,
    selectIsLoadingUsers,
    selectErrorUsers,
    UserAttributes,
} from "../../features/user/usersSlice"




/**
 * The MyOrganisationListLayoutPage component renders the layout page with your list of organisations.
 */
export const MyOrganisationListLayoutPage = () => {
    const dispatch = useAppDispatch()    
    const {i18n} = useTranslation()
    const isAuthenticated = useAppSelector(selectIsAuthenticated)
  

    //Selectors
    const isLoadingOrganisations = useAppSelector(selectIsLoadingOrganisations)
    const errorOrganisations = useAppSelector(selectErrorOrganisations)  

    const isLoadingUsers = useAppSelector(selectIsLoadingUsers)
    const errorUsers = useAppSelector(selectErrorUsers)
    
   
    useEffect(() => {
        if (!isAuthenticated) {
            return
        }
    }, [isAuthenticated])

    
    //Fetch Data    
    useEffect(() => {
        dispatch(fetchAllOrganisationsMessage())
        dispatch(fetchUserMessage()) 
    }, [dispatch])

    
    //Loading Screen
    if (isLoadingOrganisations || isLoadingUsers) {
        return <LoadScreen />
    }


    //Error Screen
    if (errorOrganisations) {
        return <ErrorScreen 
            customText={errorOrganisations.message}
        />
    } else if (errorUsers) {
        if (errorUsers.message === "[getUser()] Error: Element does not exist!") { 
            const defaultUser: UserAttributes = {
                id: 0,
                uuid: "abc",
                language: i18n.language, 
                activeOrganisation: 0,
            }
            dispatch(updateUserMessage(defaultUser))
        } else {
            return (
                <ErrorScreen 
                    customText={errorUsers.message}
                />
            )
        }
    } 
 
    
    //Return My OrganisationList Layout Page
    return (
        <Container>
            <Grid gutter="xs">
                <Grid.Col 
                    span={3}
                > 
                    <MyOrganisationsList/> 
                </Grid.Col>
                <Grid.Col
                    span={9}
                >  
                    <Outlet/>  
                </Grid.Col>
            </Grid> 
        </Container>
    )
}