import moment from "moment-timezone"
import * as google_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb"

export function getAuthMetadata(token?: string) {
    return {
        authorization: `Bearer ${token}`,
    }
}

/*
 * Helper function to turn a protobuf Timestamp.AsObject into Date in local time
 */
export const timeObjToLocalDate = (
    time?: google_timestamp_pb.Timestamp.AsObject
): Date => {
    const date = new Date(0)
    if (time) date.setSeconds(time.seconds)
    return date
}

/*
 * Helper function to turn a protobuf Timestamp.AsObject into Moment in Zurich time
 */
export const timeObjToMomentTime = (
    time?: google_timestamp_pb.Timestamp.AsObject
): moment.Moment => {
    const date = moment(0)
    if (time) date.seconds(time.seconds)
    return date.tz("Europe/London")
}

/**
 * Helper method that turns Timestamp.AsObject into Timestamp
 */
export const timeObjToMessage = (
    time?: google_timestamp_pb.Timestamp.AsObject
): google_timestamp_pb.Timestamp => {
    const timestamp = new google_timestamp_pb.Timestamp()
    if (time) {
        timestamp.setSeconds(time.seconds)
        timestamp.setNanos(time.nanos)
    }
    return timestamp
}

/**
 * Helper method that turns Date into Timestamp.AsObject
 */
export const dateToTimestampObj = (
    date?: Date
): google_timestamp_pb.Timestamp.AsObject => {
    const timestamp = new google_timestamp_pb.Timestamp()
    if (date) {
        timestamp.fromDate(date)
    }
    return timestamp.toObject()
}
