// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminPlacesPage 
} from "../../pages/places/AdminPlacesPage"

import {
    CreatePlacePage
} from "../../pages/places/CreatePlacePage"

import { 
    UpdatePlacePage
} from "../../pages/places/UpdatePlacePage"

import { 
    DeletePlacePage
} from "../../pages/places/DeletePlacePage"

import { 
    ViewPlacePage 
} from "../../pages/places/ViewPlacePage"


export const AdminRoutesPlaces = () => {
    return (
        <Routes>
            <Route
                index
                element={<AdminPlacesPage/>}                                                                    
            />            
            <Route
                path="create"
                element={<CreatePlacePage/>}                            
            />
            <Route
                path="view"
                element={<ViewPlacePage/>}                            
            />
            <Route
                path="update"
                element={<UpdatePlacePage/>}                            
            />
            <Route
                path="delete"
                element={<DeletePlacePage/>}                            
            />                
        </Routes>      
    )
}   
