// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
  useTranslation 
} from "react-i18next"

import { 
  Container 
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The NotAuthorizedPage component renders the NotAuthorizedPage page of the application.
 */
export const NoUserRolePage = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <p>{t("NoUserRolePage.info")}</p>
    </Container>
  )
}