// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {
    Container,
    LoadingOverlay
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


/**
 * The LoadScreen component renders the LoadScreen page of the application.
 */
export const LoadScreen = () => {
    return (
        <Container>
            <LoadingOverlay visible/>
        </Container>
    )
}
