// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    ReactNode, 
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import {  
    Route,
    Routes,
    Link,
    useLocation
} from "react-router-dom"

import {
    MantineProvider 
} from "@mantine/core"

// Imports from vseth-canine-ui
import {
	makeVsethTheme,
	useConfig,
	VSETHExternalApp,
	VSETHThemeProvider,
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import {
     LoginPage 
} from "../pages/LoginPage"

import { 
    NotAuthorizedPage
} from "../pages/NotAuthorizedPage"

import { 
    NoUserRolePage 
} from "../pages/NoUserRolePage"

import { 
    HomePage
} from "../pages/HomePage"

import { 
    CataloguePage 
} from "../pages/catalogue/CataloguePage"

import { 
    FaqPage 
} from "../pages/faq/FaqPage"

import { 
    NotFoundPage
} from "../pages/NotFoundPage"

import { 
    MyOrganisationListLayoutPage 
} from "../layouts/pages/MyOrganisationListLayout"

import { 
    AdminRoutes 
} from "../routes/adminRoutes/AdminRoutes"

import { 
    OrganisationsRoutes 
} from "../routes/organisationsRoutes/OrganisationsRoutes"

import { 
    ReservationsRoutes 
} from "../routes/reservationRoutes/ReservationsRoutes"

import { 
    NavbarConfig 
} from "./NavbarConfig"
import { ProtectedRoute } from "../components/auth/ProtectedRoute"


/**
 * `App` is the main component of the application it set up the main structural components
 * such as the navigation bar and the Routes.
 */
function App () {
    const { i18n } = useTranslation()

      
   
    //load config data from your vseth org
	let { data, } = useConfig(
		"https://static.vseth.ethz.ch/assets/vseth-0000-vseth/config.json"
	)

	//create the mantine theme-object with your org's primary color
	const theme = makeVsethTheme()

	

    return (     
        <MantineProvider 
            theme={theme}
        >
            <VSETHThemeProvider 
                theme={theme}
            >
                <VSETHExternalApp
                    selectedLanguage={i18n.language}
                    onLanguageSelect={(lang) => i18n.changeLanguage(lang)}
                    languages={data?.languages}
                    title="VSETH Storeroom"
                    appNav={NavbarConfig()}
                    organizationNav={data.externalNav}
                    //makeWrapper allows you to use a custom Link component, in this example we use react-router-6
                    makeWrapper={(url: string | undefined, child: ReactNode) => (
                        <Link
                            to={url!}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            {child}
                        </Link>
                    )}
                    privacyPolicy={data?.privacy}
                    disclaimer={data?.copyright}
                    //activeHref is the current active url path. Required to style the active page in the Nav
                    activeHref={useLocation().pathname}
                    socialMedia={data?.socialMedia}
                    logo={data?.logo}
                >
                    <Routes>
                        <Route 
                            path="/"
                            element={<HomePage/>} 
                        />                           
                        <Route
                            element={                                        
                                <ProtectedRoute>
                                    <MyOrganisationListLayoutPage/>
                                </ProtectedRoute>   
                            }                            
                        >
                            <Route
                                path="catalogue/*"
                                element={<CataloguePage/>}                     
                            />
                            <Route
                                path="reservations/*"
                                element={<ReservationsRoutes/>}                                                               
                            />
                            <Route
                                path="organisations/*"
                                element={<OrganisationsRoutes/>}                            
                            />
                        </Route>
                        <Route
                            path="faq"
                            element={<FaqPage/>}                                                    
                        />    
                        <Route
                            path="admin/*"
                            element={
                                <ProtectedRoute>
                                    <AdminRoutes/>
                                </ProtectedRoute> 
                            }  
                        />     
                        <Route
                            path="login"
                            element={<LoginPage/>}
                        />
                        <Route
                            path="notAuthorized"
                            element={<NotAuthorizedPage/>}
                        />
                        <Route
                            path="noUserRole"
                            element={<NoUserRolePage/>}
                        />
                        <Route
                            path="*"
                            element={<NotFoundPage/>}
                        />                                   
                    </Routes> 
                </VSETHExternalApp>
            </VSETHThemeProvider>
        </MantineProvider>	
    )
}

export default App