// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect,
    useState 
} from "react"

import { 
    useLocation 
} from "react-router-dom"

// Imports from this projects
// Pages, Components, Features, Hooks



/**
 * The Function useIsOnAdminPage return true if the user is on the admin page
 * 
 */
export const useIsOnAdminPage = () => {
    const [isOnAdminPage, setIsOnAdminPage] = useState(false)

    const location = useLocation()
    const splittedPathName = location.pathname.split('/')


    useEffect(() => {
        setIsOnAdminPage(splittedPathName.indexOf("admin") > -1)
    }, [splittedPathName])
    
    return isOnAdminPage
}