// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect 
} from "react"


import {
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../../pages/LoadScreen"

import {
    ErrorScreen
} from "../../pages/ErrorScreen"

import { 
    ItemTable 
} from "../../components/item/ItemTable"

import { 
    selectItemsById,
    fetchAllItemsMessage,
    selectIsLoadingItems,
    selectErrorItems,
    GetItemResponse
} from "../../features/item/itemsSlice"

import { 
    fetchAllOrganisationsMessage,
    selectIsLoadingOrganisations,
    selectErrorOrganisations,
} from "../../features/organisation/organisationsSlice"

import { 
    fetchAllTagsMessage,
    selectIsLoadingTags,
    selectErrorTags
} from "../../features/tag/tagsSlice"

import { 
    fetchAllStorageUnitsMessage,
    selectIsLoadingStorageUnits,
    selectErrorStorageUnits,
} from "../../features/storageunit/storageUnitSlice"

import {
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


/**
 * The AdminItemsPage component renders the AdminItems page of the application.
 */
export const AdminItemsPage = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
   
    //Selectors
    const items = useAppSelector(selectItemsById)
    const isLoadingItems = useAppSelector(selectIsLoadingItems)
    const errorItems = useAppSelector(selectErrorItems)
   
    const isLoadingOrganisations= useAppSelector(selectIsLoadingOrganisations)
    const errorOrganisations = useAppSelector(selectErrorOrganisations)  

    const isLoadingTags = useAppSelector(selectIsLoadingTags)
    const errorTags = useAppSelector(selectErrorTags)

    const isLoadingStorageUnits = useAppSelector(selectIsLoadingStorageUnits)
    const errorStorageUnits = useAppSelector(selectErrorStorageUnits)

     //Fetch Data    
    useEffect(() => {
        dispatch(fetchAllOrganisationsMessage())        
        dispatch(fetchAllItemsMessage())
        dispatch(fetchAllStorageUnitsMessage())
        dispatch(fetchAllTagsMessage())
    }, [dispatch])

   
    //Loading Screen
    if (isLoadingItems || isLoadingOrganisations || isLoadingTags || isLoadingStorageUnits) {
        return <LoadScreen />
    }

    //Error Screen
    if (errorItems) {
        return <ErrorScreen 
            customText={errorItems?.message} 
        />
    } else if (errorOrganisations) {
        return <ErrorScreen 
            customText={errorOrganisations?.message} 
        />
    } else  if (errorStorageUnits) {
        return <ErrorScreen 
            customText={errorStorageUnits?.message} 
        />
    } else if (errorTags) {
        return <ErrorScreen
            customText={errorTags.message}    
        />
    }  

    const deletedItems = new Array<GetItemResponse>()
    const realItems = new Array<GetItemResponse>()
 
    if (items !== undefined) {
        for (var i in items) {
            if (items[i].deleted) {
                deletedItems.push(items[i])
            } else {
                realItems.push(items[i])
            }
        }
    }    
      
    
    return(      
        <Fragment>
            <CreateBar/>
            <ItemTable 
                itemsForTable={Object.values(realItems)}
            />

            <hr/> 
            
            <h3>{t("Items.deletedItems")}</h3>
            <ItemTable 
                itemsForTable={Object.values(deletedItems)}
            />
        </Fragment>    
    )      
}