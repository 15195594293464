// Imports from this projects
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectPlacesById
} from "../../features/place/placesSlice"


/**
 * The PlaceFormatter component returns the room and the rack of one place.
 */
export const PlaceFormatter = (placeId: number) => {
    const places = useAppSelector(selectPlacesById)
    let placeText = ''

    if (places[placeId] !== undefined) {
        placeText = `${places[placeId].placeInfo?.room}, ${places[placeId].placeInfo?.rack}`
    }
  
    
    return (
        placeText
    )
}