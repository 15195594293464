// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    OrganisationCreateForm
} from "../../components/organisation/OrganisationCreateForm"


/**
 * The CreateOrganisationPage component renders the CreateOrganisation page of the application.
 */
export const CreateOrganisationPage = () => {
    const { t } = useTranslation()

    return(
        <Card>   
            <h1>{t("Organisations.createText")}</h1>
            <OrganisationCreateForm/>        
        </Card>        
    )
}