// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {   
    Flex, 
} from '@mantine/core'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    CancelButton 
} from '../../../components/buttons/form/CancelButton'

import { 
    DeleteExternalStorageDateButton 
} from '../../../components/buttons/delete/DeleteExternalStorageDateButton'


interface DeleteExternalStorageDateBarProps {
    editExternalStorageDateId: number,
}


/**
 * This menu bar renders the save, save&close and cancel button.
 */
export const DeleteExternalStorageDateBar = (props: DeleteExternalStorageDateBarProps) => {   
    return (
        <Flex 
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'sm', sm: 'lg' }}
            justify={{ sm: 'flex-end' }}
        >
            <DeleteExternalStorageDateButton
                editExternalStorageDateId = {props.editExternalStorageDateId}
            />
            <CancelButton/>
        </Flex>   
    )
}    