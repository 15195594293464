// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    combineReducers
} from "@reduxjs/toolkit"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import 
    auth
from "../features/auth/authSlice"

import 
    organisations 
from "../features/organisation/organisationsSlice"

import 
    organisationSingleId
from "../features/organisation/organisationSingleIdSlice"

import 
    places
from "../features/place/placesSlice"

import 
    placeSingleId 
from "../features/place/placeSingleIdSlice"

import
    storageUnits 
from "../features/storageunit/storageUnitSlice"

import 
    storageUnitItems 
from "../features/storageunit/storageUnitItemsSlices"

import 
    storageUnitSingleId 
from "../features/storageunit/storageUnitSingleIdSlices"

import 
    tags 
from "../features/tag/tagsSlice"

import 
    tagSingleId 
from "../features/tag/tagSingleIdSlice"

import 
    items 
from "../features/item/itemsSlice"

import 
    itemsCatalogue 
from "../features/catalogue/itemsCatalogueSlice"

import 
    itemSingleId 
from "../features/item/itemSingleIdSlice"

import
    externalStorages
from "../features/externalStorage/externalStoragesSlice"

import
    externalStorageSingleId
from "../features/externalStorage/externalStorageSingleIdSlice"

import
    externalStorageDates
from "../features/externalStorageDate/externalStorageDatesSlice"

import
    externalStorageDateSingleId
from "../features/externalStorageDate/externalStorageDateSingleIdSlice"

import 
    reservations
from "../features/reservation/reservationsSlice"

import  
    reservationSingleId
from "../features/reservation/reservationSingleIdSlice"

import
    users
from "../features/user/usersSlice"


// Define interfaces


/**
 * Construct and export the root reducer used in this redux application
 */
const rootReducer = combineReducers({
    auth,

    organisations,
    organisationSingleId,

    places,
    placeSingleId,

    storageUnits,
    storageUnitItems,
    storageUnitSingleId,

    tags,
    tagSingleId,

    items,
    itemSingleId,

    itemsCatalogue,

    externalStorages,
    externalStorageSingleId,

    externalStorageDates,
    externalStorageDateSingleId,

    reservations,
    reservationSingleId,

    users,
    
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer