// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box, 
    Space
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    createStorageUnitMessage 
} from "../../features/storageunit/storageUnitSlice"

import {
    StorageUnitFormLayout
} from "../../layouts/forms/StorageUnitFormLayout"

import {
    StorageUnitValidationSchema
} from "./StorageUnitValidationSchema"

import { 
    StorageUnitTypes 
} from "../../proto/sip/storeroom/storeroom_pb"

import {
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'


// Define interfaces
interface StorageUnitCreateFormProps {
    organisationId?: number,
}

/**
 * The Page component renders the admin page of the application.
 */
export const StorageUnitCreateForm = (props: StorageUnitCreateFormProps) => { 
    const dispatch = useAppDispatch()

    const form = useForm({
        initialValues: {
            displayName: '',
            storageUnitType: 0,
            description: '',
            isInStorageUnitId: -1,
            placeId: 0,
            organisationId: props.organisationId || 0,
        },

        validate: yupResolver(StorageUnitValidationSchema),

        transformValues: (values) => ({
            displayName: values.displayName,
            storageUnitType: values.storageUnitType as unknown as StorageUnitTypes ,
            description: values.description,
            isInStorageUnitId: Number(values.isInStorageUnitId) || 0,
            placeId: Number(values.placeId) || 0,
            organisationId: Number(values.organisationId) || 0,
        }),
    })

    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(createStorageUnitMessage(values)))}
            >
                <StorageUnitFormLayout
                    form={form}
                />
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}