
// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui



// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    StoreageUnitPrintAll 
} from "../../components/storageunit/StorageUnitPrintAll"

import { 
    CancelButton 
} from "../../components/buttons/form/CancelButton"


/**
 * The AllPrintStorageUnitsPage component renders the AllPrintStorageUnitsPage page of the application.
 */
export const AllPrintStorageUnitsPage = () => {
    const { t } = useTranslation()

    const printButtons = () => {
        return (
            <div className="row" >
                <div className="position-relativ col-lg-10">
                </div>
                <div className="position-relativ col-lg-2">
                    <CancelButton/>
                </div>
            </div>  
        )
    }
    return (
        <Card>         
            <h3>{t("StorageUnits.printAllText")}</h3>
            {printButtons()}
            <h6>{StoreageUnitPrintAll()}</h6>
        </Card>     
    )           
}