// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ExternalStorageStorageUnitRelationAttributes, 
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    selectStorageUnitsById 
} from "../../features/storageunit/storageUnitSlice"



/**
 * The DeleteStorageUnitsPage component renders the DeleteStorageUnits page of the application.
 */

export const StorageUnitRelationsFormatter = (externalStorageUnitRelation: ExternalStorageStorageUnitRelationAttributes[]) => {
    const storageUnits = useAppSelector(selectStorageUnitsById)
       
    
    let storageUnitRelationsText = "" 
   
    if (externalStorageUnitRelation !== undefined ) {       
        externalStorageUnitRelation.forEach(relationId => {
            if (storageUnits[relationId.storageUnitId] !== undefined) {
                storageUnitRelationsText += storageUnits[relationId.storageUnitId].storageUnitInfo?.displayName + ", "
            }
        })            
    }

    return(
        storageUnitRelationsText
    )
}