// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    TagUpdateForm
} from "../../components/tag/TagUpdateForm"

import { 
    selectTagsById
} from "../../features/tag/tagsSlice"

import { 
    selectTagSingleId, 
} from "../../features/tag/tagSingleIdSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar 
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoFromToViewPageButton 
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"

import { 
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

import { 
    GoToTableButton 
} from "../../components/buttons/goTo/GoToTableButton"

import { 
    LastEntryTagButton 
} from "../../components/buttons/lastEntry/LastEntryTagButton"

import {
    NextEntryTagButton 
} from "../../components/buttons/nextEntry/NextEntryTagButton"


/**
 * The UpdateTagsPage component renders the UpdateTags page of the application.
 */
export const UpdateTagsPage = () => {
    const { t } = useTranslation()
    
    const tags = useAppSelector(selectTagsById)
    const editTagId = useAppSelector(selectTagSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editTagId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }

    
    return(
        <Card>    
            <h1>{t("Tags.updateText")}</h1> 
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryTagButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryTagButton/>}
            /> 
            <TagUpdateForm 
                tagId = {editTagId}
                initialName = {tags[editTagId].tagName}  
            />
        </Card> 
    )
}