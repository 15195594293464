// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
// import { 
//     useTranslation 
// } from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ReservationCreateForm 
} from "../../components/reservation/forms/ReservationCreateForm"


/**
 * The CreateReservationsPage component renders the CreateReservations page of the application.
 */
export const CreateReservationsPage = () => {
    // const { t } = useTranslation()

    return(
        <Card>  
            <ReservationCreateForm 
                // title={t("Organisations.createText")}
            />
        </Card>      
    )
}