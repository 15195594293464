// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Flex 
} from "@mantine/core"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    GoToCreatePageButton 
} from "../../../components/buttons/goTo/GoToCreatePageButton"



export const CreateBar = () => {   
    return (
        <Flex 
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'sm', sm: 'lg' }}
            justify={{ sm: 'flex-end' }}
        >
            <GoToCreatePageButton /> 
        </Flex>
    )
} 
