// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"


import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui



// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    UserAttributes
} from "../../features/user/usersSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"




// Define interfaces
interface UserTableProps {
    users: UserAttributes[],
}



/**
 * The AdminPage component renders the admin page of the application.
 */
export const UserTable: React.FC<UserTableProps> = ({
    users,  
}) => {
    const { t } = useTranslation()
    
   
    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(users.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })


    useEffect(() => {
        setPage(1)
    }, [pageSize])  

    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(users, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, users])


    const columns = [
        {
            accessor: "id",
            title: t("Users.id"),
            sortable: true,
        },
        {
            accessor: "uuid",
            title: t("Users.uuid"),
            sortable: true,
        },
        {
            accessor: "language",
            title: t("Users.language"),
            sortable: true,
        },
        {
            accessor: "activeOrganisation",
            title: t("Users.activeOrganisation"),
            sortable: true,
        },
    ]    

    return (
            <DataTable
                striped
                highlightOnHover
                verticalSpacing="sm"
                fontSize="xs"
                records={records}
                columns={columns}
                totalRecords={users.length}
                recordsPerPage={pageSize}
                page={page}
                onPageChange={(p) => setPage(p)}
                recordsPerPageOptions={PAGE_SIZES}
                onRecordsPerPageChange={setPageSize}
                sortStatus={sortableStatus}
                onSortStatusChange={setSortStatus}
            />       
    )
}  