// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    OrganisationTable 
} from "../../components/organisation/OrganisationTable"

import { 
    selectOrganisationsById,
    fetchAllOrganisationsMessage,
    selectIsLoadingOrganisations,
    selectErrorOrganisations
} from "../../features/organisation/organisationsSlice"

import {
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


/**
 * The AdminOrganisationPage component renders the AdminOrganisation page of the application.
 */
export const AdminOrganisationPage = () => {
    const dispatch = useAppDispatch()
    
    //Selectors
    const organisations = useAppSelector(selectOrganisationsById)
    const isLoadingOrganisations= useAppSelector(selectIsLoadingOrganisations)
    const errorOrganisations = useAppSelector(selectErrorOrganisations) 
          

    //Fetch Data    
    useEffect(() => {
        dispatch(fetchAllOrganisationsMessage())
    }, [dispatch])

    
    //Loading Screen
    if (isLoadingOrganisations) {
        return <LoadScreen />
    }

    //Error Screen
    if (errorOrganisations) {
        return (
            <ErrorScreen 
                customText={errorOrganisations.message}
            />
        )
    } 

    return(      
        <Fragment>
            <CreateBar/>
            <OrganisationTable 
                organisationsForTable={Object.values(organisations)}
            />
        </Fragment>
    )      
}