// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"

import { 
    useNavigate
} from "react-router-dom"

import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    GetItemResponse
} from "../../features/item/itemsSlice"

import { 
    setItemSingleId 
} from "../../features/item/itemSingleIdSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"

import { 
    TagsFormatter 
} from "../../layouts/formatter/TagsFormatter"

import { 
    OrganisationShortNameFormatter 
} from "../../layouts/formatter/OrganisationShortNameFormatter"

import { 
    ExternalRentFormatter 
} from "../../layouts/formatter/ExternalRentFormatter"

import { 
    useEditorStatus
} from "../../hooks/useEditorStatus"

import { 
    useIsOnAdminPage 
} from '../../hooks/useIsOnAdminPage'

import { 
    useActiveOrganisationId 
} from '../../hooks/useActiveOrganisationId'


// Define interfaces
interface ItemTableProps {
    itemsForTable: GetItemResponse[],
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const ItemTable: React.FC<ItemTableProps> = ({
    itemsForTable
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(itemsForTable.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })
   

    useEffect(() => {
         setPage(1)
    }, [pageSize])  
  
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(itemsForTable, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, itemsForTable])

    
    const activeOrganisationId = useActiveOrganisationId()
    const isEditor = useEditorStatus(activeOrganisationId)
    const isOnAdminPage = useIsOnAdminPage()
            
   
    const actionItems = (itemId: number) => {
        if (isEditor || isOnAdminPage) {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setItemSingleId(itemId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />                  
                    </ActionIcon>
                    <ActionIcon                      
                        onClick={() => {  
                            dispatch(setItemSingleId(itemId))           
                            navigate("update")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.EDIT} 
                            size = {16}
                            color="blue"
                        />      
                    </ActionIcon>
                    <ActionIcon                     
                        onClick={() => {
                            dispatch(setItemSingleId(itemId))
                            navigate("delete")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.DELETE} 
                            size = {16}
                            color="red" 
                        />   
                    </ActionIcon>
                </Group>
            )
        } else {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setItemSingleId(itemId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />    
                    </ActionIcon>    
                </Group>
            )
        }      
   }

    
    const columns = [
        {
            accessor: "id",
            title: t("Items.id"),
            sortable: true,
            hidden: !isOnAdminPage
        },
        {
            accessor: "itemInfo.displayName",
            title: t("Items.displayName"),
            sortable: true,
           
        },
       {
            accessor: "itemInfo.tagsList",
            title: t("Storeroom.tags"),
            sortable: true,
            render: (item: GetItemResponse) => TagsFormatter(item.itemInfo!.tagsList)          
        },
        {
            accessor: "itemInfo.organisationId",
            title: t("Storeroom.organisation"),
            sortable: true,
            render: (item: GetItemResponse) => OrganisationShortNameFormatter(item.itemInfo!.organisationId),
            hidden: !isOnAdminPage,
        },
        {
            accessor: "itemInfo.externalRent",
            title: t("Items.externalRent"),
            sortable: true,
            render: (item: GetItemResponse) => ExternalRentFormatter(item.itemInfo!.externalRent),            
        },
        // {
        //     accessor: "reservationsList",
        //     title: t("ItemTable.columnReservationList"),
        //     sort: true,
        // },   
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            render: (item: GetItemResponse) => actionItems(item.id)       
            
        },       
    ]


    return (       
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={itemsForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
        />  
    )
}  