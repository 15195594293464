// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card, 
    Space
} from "@mantine/core"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    OrganisationViewLayout 
} from "../../layouts/views/OrganisationViewLayout"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectOrganisationSingleId, 
} from "../../features/organisation/organisationSingleIdSlice"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar 
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    LastEntryOrganisationButton
} from '../../components/buttons/lastEntry/LastEntryOrganisationButton'

import { 
    NextEntryOrganisationButton 
} from '../../components/buttons/nextEntry/NextEntryOrganisationButton'

import {
    GoFromToUpdatePageButton 
} from '../../components/buttons/goFromTo/GoFromToUpdatePageButton'

import { 
    GoFromToDeletePageButton 
} from '../../components/buttons/goFromTo/GoFromToDeletePageButton'


/**
* The DeleteItemsPage component renders the DeleteItems page of the application.
*/
export const ViewOrganisationPage = () => {
    const { t } = useTranslation() 

    const organisations = useAppSelector(selectOrganisationsById)
    const editOrganisationId = useAppSelector(selectOrganisationSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editOrganisationId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }

        
    return (
        <Card>  
            <h1>{t("Organisations.viewText")}</h1>  
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryOrganisationButton/>}
                navigationButton1 = {<GoFromToUpdatePageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryOrganisationButton/>}
            /> 
            <Space 
                 h="xl" 
            />   
            <OrganisationViewLayout 
                organisationId = {editOrganisationId}
                organisationInfo = {organisations[editOrganisationId].organisationInfo!}
                updateUser = {organisations[editOrganisationId].updateUser}
                updateTime = {organisations[editOrganisationId].updateTime!}
            />          
        </Card>                  
    )   
}