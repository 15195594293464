// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Button
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../../hooks/useAppSelector"

import { 
    selectExternalStorageDatesById 
} from "../../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    selectExternalStorageDateSingleId, 
    setExternalStorageDateSingleId 
} from "../../../features/externalStorageDate/externalStorageDateSingleIdSlice"

import {
    useAppDispatch 
} from "../../../hooks/useAppDispatch"


export const NextEntryExternalStorageDateButton = () => {  
    const { t } = useTranslation()    
    const dispatch = useAppDispatch()
    
    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    const editExternalStorageDateId = useAppSelector(selectExternalStorageDateSingleId)
   

    //initialise options
    const externalStorageDateIds = [] as number[]

    //For Loop over all organisations
    for (var i in externalStorageDates) {
        const currValue = externalStorageDates[i].id
       
        externalStorageDateIds.push(currValue)
    }        
   
    const lengthArr = externalStorageDateIds.length
    const currIndex = externalStorageDateIds.indexOf(editExternalStorageDateId)


    const onChangeForward = () => {
         return(
            dispatch(setExternalStorageDateSingleId(externalStorageDateIds[currIndex+1]))
        )
    }

   
    const isDisabledForward = () => {
        if (externalStorageDateIds[lengthArr-1] === editExternalStorageDateId) {
            return(
                true
            )
        } else {
            return(
                false
            )
        }    
    }

    return (       
        <Button 
            onClick={() => {onChangeForward()}}
            disabled = {isDisabledForward()}
            fullWidth
            variant="outline"
        >
            {t("Storeroom.nextEntryButton")}
        </Button> 
           
    )
} 