// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminOrganisationPage 
} from "../../pages/organisations/AdminOrganisationPage"

import { 
    CreateOrganisationPage 
} from "../../pages/organisations/CreateOrganisationPage"

import { 
    UpdateOrganisationPage 
} from "../../pages/organisations/UpdateOrganisationPage"

import { 
    DeleteOrganisationPage
} from "../../pages/organisations/DeleteOrganisationPage"

import { 
    ViewOrganisationPage 
} from "../../pages/organisations/ViewOrganisationPage"


export const AdminRoutesOrganisations = () => {
    return (
        <Routes>
            <Route
                index
                element={<AdminOrganisationPage/>}                                                                    
            />
            <Route
                path="create"
                element={<CreateOrganisationPage/>}                            
            />
            <Route
                path="view"
                element={<ViewOrganisationPage/>}                            
            />
            <Route
                path="update"
                element={<UpdateOrganisationPage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteOrganisationPage/>}                            
            />                     
        </Routes>
    )
}       
