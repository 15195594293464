// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect,
    useState 
} from "react"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../hooks/useAppSelector"

import { 
    selectUsersByUUID
} from "../features/user/usersSlice"

import { 
    selectSub 
} from "../features/auth/authSlice"


/**
 * The Function useActiveOrganisationId return the activeOrganisationId of this user
 */
export const useActiveOrganisationId = () => {
    const [activeOrganisationId, setActiveOrganisationId] = useState(0)

    const users = useAppSelector(selectUsersByUUID)
    const sub = useAppSelector(selectSub).sub 
    
    useEffect(() => {
        if (users[sub] !== undefined) {
            setActiveOrganisationId(users[sub].activeOrganisation)         
        }
    }, [users, sub])
    
    return activeOrganisationId
}