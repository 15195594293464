// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {
	StrictMode
} from "react"

import {
	createRoot
} from "react-dom/client"

import {
	Provider
} from "react-redux"

import { 
	BrowserRouter 
} from "react-router-dom"


// Imports from vseth-canine-ui

// Imports from this projects
// Pages, Components, Features, Hooks
import "./index.css"
import "./locals/i18n"

import App from "./app/App"

import store from "./app/store"

import {
	CustomAuthProvider 
} from "./components/auth/CustomAuthProvider"


const root = createRoot(
	document.getElementById("root") as HTMLElement
)

root.render(
	<StrictMode>
		<BrowserRouter>
			<Provider 
				store={store}
			>
				<CustomAuthProvider>
					<App />
				</CustomAuthProvider>
			</Provider>
		</BrowserRouter>
	</StrictMode>
)