// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata 
} from "../../util/proto"

import { 
    selectAccessToken 
} from "../auth/authSlice"

import { 
    AppThunk 
} from "../../app/store"

import {
    CreateReservationRequest as createReservationRequest, 
    CreateReservationResponse as createReservationResponse,
    DeleteReservationRequest as deleteReservationRequest,
    DeleteReservationResponse as deleteReservationResponse,
    UpdateReservationRequest as updateReservationRequest,
    UpdateReservationResponse as updateReservationResponse,
    ConfirmReservationRequest as confirmReservationRequest,
    ConfirmReservationResponse as confirmReservationResponse,
    ConfirmReturnReservationRequest as confirmReturnReservationRequest,
    ConfirmReturnReservationResponse as confirmReturnReservationResponse,
    GetReservationRequest as getReservationRequest,
    GetReservationResponse as getReservationResponse,
    ListMyUserReservationsRequest as listMyUserReservationsRequest,
    ListMyUserReservationsResponse as listMyUserReservationsResponse,
    ListMyConfirmedReservationsRequest as listMyConfirmedReservationsRequest,
    ListMyConfirmedReservationsResponse as listMyConfirmedReservationsResponse,
    ListOwnerReservationsRequest as listOwnerReservationsRequest,
    ListOwnerReservationsResponse as listOwnerReservationsResponse,
    ListOrganisationReservationsRequest as listOrganisationReservationsRequest,
    ListOrganisationReservationsResponse as listOrganisationReservationsResponse,
    ListReservationsRequest as listReservationRequest,
    ListReservationsResponse as listReservationResponse,
    ReservationAttributes as reservationAttributes,
    ReservationItemRelationAttributes as reservationItemRelationAttributes
} from "../../proto/sip/storeroom/storeroom_pb"


import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"





// Define the Types
export type ReservationAttributes = reservationAttributes.AsObject
type CreateReservationResponse = createReservationResponse.AsObject
type UpdateReservationResponse = updateReservationResponse.AsObject
type DeleteReservationResponse = deleteReservationResponse.AsObject
type ConfirmReservationResponse = confirmReservationResponse.AsObject
type ConfirmReturnReservationResponse = confirmReturnReservationResponse.AsObject
type ListOrganisationExternalResponse = listOrganisationReservationsResponse.AsObject
type ListMyUserReservationsResponse = listMyUserReservationsResponse.AsObject
type ListMyConfirmedReservationsResponse = listMyConfirmedReservationsResponse.AsObject
type ListOwnerReservationsResponse = listOwnerReservationsResponse.AsObject
export type GetReservationResponse = getReservationResponse.AsObject
export type ListReservationResponse = listReservationResponse.AsObject


type ReservationRequestDict = {
    [Key: number]: GetReservationResponse
}


// Define which objects the reservationRequest State have. 
interface ReservationRequestState {
    items: ReservationRequestDict
    isLoading: boolean
    error?: Error
}


// Define intital state
const initialState: ReservationRequestState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the State
const reservations = createSlice({
    name: "reservations",
    initialState,
    reducers: { 

        // Fetch all reservation
        fetchAllReservationsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllReservationsSuccess: (state, { payload }: PayloadAction<ListReservationResponse>) => {
            state.items = {}
            payload.reservationsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllReservationsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch all reservation of an organisationb
        fetchAllOrganisationReservationsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllOrganisationReservationsSuccess: (state, { payload }: PayloadAction<ListOrganisationExternalResponse>) => {
            state.items = {}
            payload.reservationsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllOrganisationReservationsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

         // Fetch all reservation of an organisationb
        fetchAllMyUserReservationsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllMyUserReservationsSuccess: (state, { payload }: PayloadAction<ListMyUserReservationsResponse>) => {
            state.items = {}
            payload.reservationsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllMyUserReservationsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

         // Fetch all reservation of an organisationb
        fetchAllMyConfirmedReservationsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllMyConfirmedReservationsSuccess: (state, { payload }: PayloadAction<ListMyConfirmedReservationsResponse>) => {
            state.items = {}
            payload.reservationsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllMyConfirmedReservationsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

         // Fetch all reservation of an organisationb
        fetchAllOwnerReservationsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllOwnerReservationsSuccess: (state, { payload }: PayloadAction<ListOwnerReservationsResponse>) => {
            state.items = {}
            payload.reservationsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllOwnerReservationsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch one reservation
        fetchReservationStart: (state) => {
            state.isLoading = true            
            state.error = undefined
        },
        fetchReservationSuccess: (state, { payload }: PayloadAction<GetReservationResponse>) => {
            state.items[payload.id] = payload
            state.isLoading = false 
            state.error = undefined           
        },
        fetchReservationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        
        // Create Reservation
        createReservationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createReservationSuccess: (state, { payload }: PayloadAction<CreateReservationResponse>) => {
            state.items[payload.reservation!.id] = payload.reservation!
            state.isLoading = false
            state.error = undefined
        },
        createReservationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Update Reservation      
        updateReservationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateReservationSuccess: (state, { payload }: PayloadAction<UpdateReservationResponse>) => {
            state.items[payload.reservation!.id] = payload.reservation!
            state.isLoading = false
            state.error = undefined
        },
        updateReservationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete Reservation
        deleteReservationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deleteReservationSuccess: (state, { payload }: PayloadAction<DeleteReservationResponse>) => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deleteReservationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Confirm Reservation      
        confirmReservationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        confirmReservationSuccess: (state, { payload }: PayloadAction<ConfirmReservationResponse>) => {
            state.items[payload.reservation!.id] = payload.reservation!
            state.isLoading = false
            state.error = undefined
        },
        confirmReservationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Confirm Rerturn Reservation      
        confirmReturnReservationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        confirmReturnReservationSuccess: (state, { payload }: PayloadAction<ConfirmReturnReservationResponse>) => {
            state.items[payload.reservation!.id] = payload.reservation!
            state.isLoading = false
            state.error = undefined
        },
        confirmReturnReservationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

    },
})


// Export Reducers
export default reservations.reducer


// Define Actions
const {
    fetchAllReservationsStart,
    fetchAllReservationsSuccess,
    fetchAllReservationsFailure,

    fetchAllOrganisationReservationsStart,
    fetchAllOrganisationReservationsSuccess,
    fetchAllOrganisationReservationsFailure,

    fetchAllMyUserReservationsStart,
    fetchAllMyUserReservationsSuccess,
    fetchAllMyUserReservationsFailure,

    fetchAllMyConfirmedReservationsStart,
    fetchAllMyConfirmedReservationsSuccess,
    fetchAllMyConfirmedReservationsFailure,
    
    fetchAllOwnerReservationsStart,
    fetchAllOwnerReservationsSuccess,
    fetchAllOwnerReservationsFailure,
    
    fetchReservationStart,
    fetchReservationSuccess,
    fetchReservationFailure,

    createReservationStart,
    createReservationSuccess,
    createReservationFailure,    
        
    updateReservationStart,
    updateReservationSuccess,
    updateReservationFailure,

    deleteReservationStart,
    deleteReservationSuccess,
    deleteReservationFailure,

    confirmReservationStart,
    confirmReservationSuccess,
    confirmReservationFailure,

    confirmReturnReservationStart,
    confirmReturnReservationSuccess,
    confirmReturnReservationFailure,
} = reservations.actions


// Fetch all reservations
export const fetchAllReservationsMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllReservationsStart())
    const token = selectAccessToken(getState())
    const request = new listReservationRequest()

    return storeroomClient
        .listReservations(request, getAuthMetadata(token))
        .then((response: listReservationResponse) => {
            dispatch(fetchAllReservationsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllReservationsFailure(err))
        })
}


// Fetch all reservations of an organisation
export const fetchAllMyUserReservationsMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllMyUserReservationsStart())
    const token = selectAccessToken(getState())
    const request = new listMyUserReservationsRequest()

    return storeroomClient
        .listMyUserReservations(request, getAuthMetadata(token))
        .then((response: listOrganisationReservationsResponse) => {
            dispatch(fetchAllMyUserReservationsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllMyUserReservationsFailure(err))
        })
}

// Fetch all reservations of an organisation
export const fetchAllMyConfirmedReservationsMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllMyConfirmedReservationsStart())
    const token = selectAccessToken(getState())
    const request = new listMyConfirmedReservationsRequest()

    return storeroomClient
        .listMyConfirmedReservations(request, getAuthMetadata(token))
        .then((response: listMyConfirmedReservationsResponse) => {
            dispatch(fetchAllMyConfirmedReservationsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllMyConfirmedReservationsFailure(err))
        })
}

// Fetch all reservations of an organisation
export const fetchAllOwnerReservationsMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllOwnerReservationsStart())
    const token = selectAccessToken(getState())
    const request = new listOwnerReservationsRequest()
    request.setOrganisationId(organisationId)

    return storeroomClient
        .listOwnerReservations(request, getAuthMetadata(token))
        .then((response: listOwnerReservationsResponse) => {
            dispatch(fetchAllOwnerReservationsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllOwnerReservationsFailure(err))
        })
}

// Fetch all reservations of an organisation
export const fetchAllOrganisationReservationsMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllOrganisationReservationsStart())
    const token = selectAccessToken(getState())
    const request = new listOrganisationReservationsRequest()
    request.setOrganisationId(organisationId)

    return storeroomClient
        .listOrganisationReservations(request, getAuthMetadata(token))
        .then((response: listOrganisationReservationsResponse) => {
            dispatch(fetchAllOrganisationReservationsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllOrganisationReservationsFailure(err))
        })
}


// Fetch one reservation
export const fetchReservationMessage = (reservationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchReservationStart())
    const token = selectAccessToken(getState())
    const request = new getReservationRequest()
    request.setId(reservationId)

    return storeroomClient
        .getReservation(request, getAuthMetadata(token))
        .then((response: getReservationResponse) => {
            dispatch(fetchReservationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchReservationFailure(err))
        })
}


// Create reservation
export const createReservationMessage = (reservation: ReservationAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createReservationStart())
    const token = selectAccessToken(getState())
     

    const itemRelation = new Array<reservationItemRelationAttributes>()
    //For Loop over all storageunitrelations
    for (var i in reservation.itemRelationsList) {
        const currItemId = reservation.itemRelationsList[i].itemId
        const currQuantity = reservation.itemRelationsList[i].quantity
        
        let itemRelationItem = new reservationItemRelationAttributes()
        itemRelationItem.setId(0)
        itemRelationItem.setItemId(currItemId)
        itemRelationItem.setQuantity(currQuantity)
        itemRelationItem.setReservationId(0)

        itemRelation.push(itemRelationItem)
    } 
    
    const startTime = new Timestamp()
    startTime.setSeconds(reservation.startTime!.seconds)
    startTime.setNanos(0)

    const endTime = new Timestamp()
    startTime.setSeconds(reservation.endTime!.seconds)
    startTime.setNanos(0)
    
    const reservationData = new reservationAttributes()
    reservationData.setSubject(reservation.subject)
    reservationData.setRequestText(reservation.requestText)
    reservationData.setStartTime(startTime)
    reservationData.setEndTime(endTime)
    reservationData.setOrganisationId(reservation.organisationId)
    reservationData.setItemRelationsList(itemRelation)
         
    const request = new createReservationRequest()
    request.setReservationInfo(reservationData)

    return storeroomClient
        .createReservation(request, getAuthMetadata(token))
        .then((response: createReservationResponse) => {
            dispatch(createReservationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createReservationFailure(err))
        })
}


// Update reservation
export const updateReservationMessage = (reservationId: number, reservation: ReservationAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateReservationStart())
    const token = selectAccessToken(getState())

    const itemRelation = new Array<reservationItemRelationAttributes>()
    //For Loop over all storageunitrelations
    for (var i in reservation.itemRelationsList) {
        const currItemId = reservation.itemRelationsList[i].itemId
        const currQuantity = reservation.itemRelationsList[i].quantity
        
        let itemRelationItem = new reservationItemRelationAttributes()
        itemRelationItem.setId(0)
        itemRelationItem.setItemId(currItemId)
        itemRelationItem.setQuantity(currQuantity)
        itemRelationItem.setReservationId(0)

        itemRelation.push(itemRelationItem)
    } 

    const startTime = new Timestamp()
    startTime.setSeconds(reservation.startTime!.seconds)
    startTime.setNanos(0)

    const endTime = new Timestamp()
    startTime.setSeconds(reservation.endTime!.seconds)
    startTime.setNanos(0)
    
    const reservationData = new reservationAttributes()
    reservationData.setSubject(reservation.subject)
    reservationData.setRequestText(reservation.requestText)
    reservationData.setStartTime(startTime)
    reservationData.setEndTime(endTime)
    reservationData.setOrganisationId(reservation.organisationId)
    reservationData.setItemRelationsList(itemRelation)
   

    const request = new updateReservationRequest()
    request.setId(reservationId)
    request.setReservationInfo(reservationData)

    return storeroomClient
        .updateReservation(request, getAuthMetadata(token))
        .then((response: updateReservationResponse) => {
            dispatch(updateReservationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateReservationFailure(err))
        })
}


// Delete reservation
export const deleteReservationMessage = (reservationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deleteReservationStart())
    const token = selectAccessToken(getState())
    
    const request = new deleteReservationRequest()
    request.setId(reservationId)

    return storeroomClient
        .deleteReservation(request, getAuthMetadata(token))
        .then((response: deleteReservationResponse) => {
            dispatch(deleteReservationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deleteReservationFailure(err))
        })
}


// Update reservation
export const confirmReservationMessage = (reservationId: number, confirmed: boolean): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(confirmReservationStart())
    const token = selectAccessToken(getState())
     
    const request = new confirmReservationRequest()
    request.setReservationId(reservationId)
    request.setConfirmed(confirmed)

    return storeroomClient
        .confirmReservation(request, getAuthMetadata(token))
        .then((response: confirmReservationResponse) => {
            dispatch(confirmReservationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(confirmReservationFailure(err))
        })
}

// Update reservation
export const confirmReturnReservationMessage = (reservationId: number, returned: boolean, returnText: string): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(confirmReturnReservationStart())
    const token = selectAccessToken(getState())
     
    const request = new confirmReturnReservationRequest()
    request.setReservationId(reservationId)
    request.setReturned(returned)
    request.setReturnText(returnText)


    return storeroomClient
        .confirmReturnReservation(request, getAuthMetadata(token))
        .then((response: confirmReturnReservationResponse) => {
            dispatch(confirmReturnReservationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(confirmReturnReservationFailure(err))
        })
}


// Selectors
type ReservationSliceRoot = {
    reservations: ReturnType<typeof reservations.reducer>
}

export const selectReservationsById = (state: ReservationSliceRoot) =>
    state.reservations.items
    
export const selectIsLoadingReservations = (state: ReservationSliceRoot) => state.reservations.isLoading
export const selectErrorReservations = (state: ReservationSliceRoot) => state.reservations.error