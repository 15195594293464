// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"

import { 
    useNavigate
} from "react-router-dom"

import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    RentableItem
} from "../../features/catalogue/itemsCatalogueSlice"

import { 
    setItemSingleId 
} from "../../features/item/itemSingleIdSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"

import { 
    TagsFormatter 
} from "../../layouts/formatter/TagsFormatter"

import { 
    OrganisationShortNameFormatter 
} from "../../layouts/formatter/OrganisationShortNameFormatter"


// Define interfaces
interface CatalogueTableProps {
    itemsCatalogueForTable: RentableItem[],
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const CatalogueTable: React.FC<CatalogueTableProps> = ({
    itemsCatalogueForTable,  
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

   
    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(itemsCatalogueForTable.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })
   

    useEffect(() => {
         setPage(1)
    }, [pageSize])  
  
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(itemsCatalogueForTable, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, itemsCatalogueForTable])
  
        
    const actionItems = (itemId: number) => {
        return (
            <Group spacing={2} position="right" noWrap>
                <ActionIcon 
                    onClick={() => {
                        dispatch(setItemSingleId(itemId))
                        navigate("view")
                    }}
                >
                    <Icon 
                        icon = {ICONS.VIEW} 
                        size = {16}                        
                        color="green" 
                    />    
                </ActionIcon>    
            </Group>
        )
    }      
                     
        
    const columns = [
        {
            accessor: "itemInfo.displayName",
            title: t("Items.displayName"),
            sortable: true,
            
        },
        {
            accessor: "itemInfo.tagsList",
            title: t("Storeroom.tags"),
            sortable: true,
            render: (item: RentableItem) => TagsFormatter(item.itemInfo!.tagsList)          
        },
        {
            accessor: "itemInfo.organisationId",
            title: t("Storeroom.organisation"),
            sortable: true,
            render: (item: RentableItem) => OrganisationShortNameFormatter(item.itemInfo!.organisationId),
        },
        // {
        //     accessor: "reservationsList",
        //     title: t("ItemTable.columnReservationList"),
        //     sort: true,
        // },   
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            render: (item: RentableItem) => actionItems(item.id)       
            
        },         
    ]
    
    
    return (       
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={itemsCatalogueForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
        />  
    )
}