// Imports from external sources
import { 
    useTranslation
} from "react-i18next"


/**
 * The StorageUnitTypeFormatter component returns the storageunittype
 */
export const StorageUnitTypeFormatter = (storageUnitType: number) => {
    const { t } = useTranslation()
    let storageUnitTypeText = ''
   
    if (storageUnitType !== undefined) {
        if (storageUnitType === 0) {
            return (
                storageUnitTypeText = t("StorageUnits.typeOther")
            )
        } else if (storageUnitType === 1) {
            return (
                storageUnitTypeText = t("StorageUnits.typeBox")
            )
        } else if (storageUnitType === 2) {
            return (
                storageUnitTypeText = t("StorageUnits.typePalett")
            )
        }
    }
    
    return (
        storageUnitTypeText
    )
}