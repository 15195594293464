import { 
    TypedUseSelectorHook, 
    useSelector 
} from 'react-redux'

import {
     RootState 
} from '../app/rootReducer'

// Use throughout your app instead of plain `useAppSelector`
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector