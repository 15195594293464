// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    timeObjToMomentTime 
} from "../../util/proto"


/**
 * The DeleteStorageUnitsPage component renders the DeleteStorageUnits page of the application.
 */
export const ConfirmedFormatter = (confirmationTime: Timestamp.AsObject ) => {
    if (confirmationTime.seconds > 3600) {        
        return (
            <Fragment>
                <Icon 
                    icon = {ICONS.CHECK} 
                    color = "green"
                    size = {16}
                /> 
                <Fragment>  (</Fragment>
                {timeObjToMomentTime(confirmationTime).format("DD.MM.YYYY")}
                <Fragment>)</Fragment>
            </Fragment>
        )                
    } else {
        return (
            <Icon 
                icon = {ICONS.CLOSE} 
                color = "red"
                size = {16}
            /> 
        )      
    }     
}