// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    connect 
} from "react-redux"

import {
    withFormik,
} from "formik"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    updateExternalStorageMessage,
    ExternalStorageAttributes 
} from "../../../features/externalStorage/externalStoragesSlice"

import {
    ExternalStorageForm
} from "./ExternalStorageForm"

import {
    ExternalStorageValidationSchema
} from "./ExternalStorageValidationSchema"

import { 
    Timestamp
} from "google-protobuf/google/protobuf/timestamp_pb"


// Define interfaces
interface DispatchProps {
    update: any
}

//Title and Initial values of the form 
interface MyFormBaseProps {
    title?: string 
    externalStorageId?: number
    initialExternalStorageData: ExternalStorageAttributes
    lastchangeuser?: string,
    lastchangedate?: Timestamp.AsObject,
}

/**
 * The ExternalStorageRequestEditAdminForm component renders the ExternalStorageRequestEditAdminForm page of the application.
 */
 type MyFormProps = MyFormBaseProps & DispatchProps


 // Export Component
 const formikEnhancer = withFormik<MyFormProps, ExternalStorageAttributes>({
     // Transform outer props into form values
     mapPropsToValues: props => {
        return {            
            subject: props.initialExternalStorageData.subject ||'',
            requestText: props.initialExternalStorageData.requestText || '',
            startTimeId: props.initialExternalStorageData.startTimeId || 0,
            endTimeId: props.initialExternalStorageData.endTimeId || 0,
            organisationId: props.initialExternalStorageData.organisationId || 0,
            storageUnitRelationList: props.initialExternalStorageData.storageUnitRelationList || []
         }
     },
   
 
     // Validation function
     validationSchema:ExternalStorageValidationSchema,
 
     handleSubmit: (values, { props, setSubmitting, resetForm }) => {        
        setSubmitting(true)
        props.update(props.externalStorageId, values)
        setSubmitting(false)        
        resetForm({values})
     },
 
     displayName: 'Create External Storage Form',
 
 })(ExternalStorageForm)
 
 
 
 const mapDispatchToProps = (dispatch: any) => ({
     update: (externalStorageId: number, payload: ExternalStorageAttributes) => dispatch(updateExternalStorageMessage(externalStorageId,payload))
 })
 
 
 export const ExternalStorageEditForm = connect(
     null,
     mapDispatchToProps
 )(formikEnhancer)
 
 export default ExternalStorageEditForm