// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect 
} from "react"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../LoadScreen"

import { 
    ErrorScreen 
} from "../ErrorScreen"

import { 
    PlaceTable 
} from "../../components/place/PlaceTable"

import { 
    selectPlacesById,
    fetchAllOrganisationPlacesMessage,
    selectIsLoadingPlaces,
    selectErrorPlaces,
} from "../../features/place/placesSlice"

import { 
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    useEditorStatus 
} from "../../hooks/useEditorStatus"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


/**
 * The OrganisationPlacesPage component renders the OrganisationPlacesPage of the application.
 */
export const OrganisationPlacesPage = () => {
    const dispatch = useAppDispatch()
   
    const places = useAppSelector(selectPlacesById)
    const isLoadingPlaces = useAppSelector(selectIsLoadingPlaces)
    const errorPlaces = useAppSelector(selectErrorPlaces)

    const activeOrganisationId = useActiveOrganisationId() 
    const isEditor = useEditorStatus(activeOrganisationId)


    useEffect(() => {
        if (activeOrganisationId !== 0) {
            dispatch(fetchAllOrganisationPlacesMessage(activeOrganisationId))
        }
        
    }, [dispatch, activeOrganisationId])

            
    //Loading Screen
    if (isLoadingPlaces) {
        return <LoadScreen />
    }

    //Error Screen
    if (errorPlaces) {
        return <ErrorScreen 
            customText={errorPlaces?.message} 
        /> 
    } 


    // Menu Buttons           
    const placeContent = () => {       
        if (isEditor) {
            return (
                <Fragment>
                    <CreateBar/>
                    <PlaceTable 
                        placesForTable={Object.values(places)}
                    />
                </Fragment>
            )
        } else {
            return (
                <PlaceTable 
                    placesForTable={Object.values(places)}
                />
            )
        }
    } 


    return(
        <Fragment>
            {placeContent()} 
        </Fragment>            
    )
}