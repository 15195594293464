// Imports from this projects
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import {
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

/**
 * The OrganisationShortNameFormatter component returns the shortname of one organisation.
 */
export const OrganisationShortNameFormatter = (organisationId: number) => {
    const organisations = useAppSelector(selectOrganisationsById)
    let organisationShortNameText = ''

    if (organisations[organisationId] !== undefined) {
        organisationShortNameText = organisations[organisationId].organisationInfo!.shortName
    }
  
    return (
        organisationShortNameText
    )
}