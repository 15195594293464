// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next" 

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    StoreageUnitPrintSingle 
} from "../../components/storageunit/StorageUnitPrintSingle"

import { 
    CancelButton 
} from "../../components/buttons/form/CancelButton"

import { 
    selectStorageUnitSingleId
} from "../../features/storageunit/storageUnitSingleIdSlices"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"


/**
 * The SinglePrintStorageUnitsPage component renders the SinglePrintStorageUnits page of the application.
 */
export const SinglePrintStorageUnitsPage = () => {
    const { t } = useTranslation()

    const editStorageUnitId = useAppSelector(selectStorageUnitSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editStorageUnitId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }


    const printButtons = () => {
        return (
            <div className="row" >
                <div className="position-relativ col-lg-10">
                </div>
                <div className="position-relativ col-lg-2">
                    <CancelButton/>
                </div>
            </div>  
        )
    }
    return (
        <Card>       
            <h3>{t("StorageUnits.printSingleText")}</h3>
            {printButtons()}
            <h6>{StoreageUnitPrintSingle(editStorageUnitId)}</h6>
        </Card>     
    )           
}