// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import { 
    NavLink
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


//Interface
interface OrganisationReservationsMenuBarProps {
    activeOrganisationShortName: string,
}


export const OrganisationReservationsMenuBar = (props: OrganisationReservationsMenuBarProps) => {
    const { t } = useTranslation()

    return (
        <Fragment>
            <div className="row" >
                <div className="position-relativ col-sm-4">
                    <NavLink       
                        className={ ( {isActive}) => 
                            isActive 
                            ? "btn btn-primary btn-block" 
                            : "btn btn-secondary btn-block" 
                            }
                        to={"/organisations/" + props.activeOrganisationShortName + "/reservations/requests/"}
                    >{t("Reservations.organisationRequests")}                                                   
                    </NavLink>                        
                </div>
                <div className="position-relativ col-sm-4">
                    <NavLink       
                        className={ ( {isActive}) => 
                            isActive 
                            ? "btn btn-primary btn-block" 
                            : "btn btn-secondary btn-block" 
                            }
                        to={"/organisations/" + props.activeOrganisationShortName + "/reservations/owneditems/"}  
                    >{t("Reservations.ownedItems")}                                                   
                    </NavLink>     
                </div>   
            </div> 
        </Fragment>            
    )
}
