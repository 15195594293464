// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {   
    Flex 
} from '@mantine/core'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    SaveButton 
} from "../../../components/buttons/form/SaveButton"

import { 
    SaveCloseButton
} from '../../../components/buttons/form/SaveBackButton'

import { 
    CancelButton 
} from '../../../components/buttons/form/CancelButton'


/**
 * This menu bar renders the save, save&close and cancel button.
 */
export const FormButtonBar = () => {   
    return (
        <Flex 
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'sm', sm: 'lg' }}
            justify={{ sm: 'flex-end' }}
        >
            <SaveButton/>
            <SaveCloseButton/>
            <CancelButton/>
        </Flex>   
    )
} 