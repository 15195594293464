// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import sortBy from "lodash/sortBy"


import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"


import { 
    useNavigate
} from "react-router-dom"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from VSETH
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    GetOrganisationResponse
} from "../../features/organisation/organisationsSlice"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    setOrganisationSingleId 
} from "../../features/organisation/organisationSingleIdSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"


// Define interfaces
interface OrganisationTableProps {
    organisationsForTable: GetOrganisationResponse[],
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const OrganisationTable: React.FC<OrganisationTableProps> = ({
    organisationsForTable, 
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    

    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(organisationsForTable.slice(0, pageSize))
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })
   

    useEffect(() => {
        setPage(1)
    }, [pageSize])  
  
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(organisationsForTable, sortStatus.columnAccessor)
        setRecords(sortStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortStatus, organisationsForTable])
    
   

    const columns = [
        {
            accessor: "id",
            title: t("Organisations.id"),
            sortable: true
        },
        {
            accessor: "organisationInfo.shortName",
            title: t("Organisations.shortName"),
            sortable: true
        },
        {
            accessor: "organisationInfo.title",
            title: t("Organisations.title"),
            sortable: true,
        },
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            render: (organisation: GetOrganisationResponse) => (
              <Group spacing={2} position="right" noWrap>
                <ActionIcon 
                    onClick={() => {
                        dispatch(setOrganisationSingleId(organisation.id))
                        navigate("view")
                    }}
                >
                    <Icon 
                        icon = {ICONS.VIEW} 
                        size = {16}                        
                        color="green" 
                    />                  
                </ActionIcon>
                <ActionIcon                      
                    onClick={() => {  
                        dispatch(setOrganisationSingleId(organisation.id))           
                        navigate("update")
                    }}
                >
                    <Icon 
                        icon = {ICONS.EDIT} 
                        size = {16}
                        color="blue"
                    />      
                </ActionIcon>
                <ActionIcon                     
                    onClick={() => {
                        dispatch(setOrganisationSingleId(organisation.id))
                        navigate("delete")
                    }}
                >
                    <Icon 
                        icon = {ICONS.DELETE} 
                        size = {16}
                        color="red" 
                    />   
                </ActionIcon>
              </Group>
            ),
        },

    ]

    return (      
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={organisationsForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
        /> 
    )
}  
