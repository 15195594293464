// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    MyConfirmedReservationsPage 
} from "../../pages/reservations/MyConfirmedReservationsPage"

import { 
    CreateReservationsPage 
} from "../../pages/reservations/CreateReservationsPage"

import { 
    UpdateReservationsPage 
} from "../../pages/reservations/UpdateReservationsPage"

import { 
    DeleteReservationsPage 
} from "../../pages/reservations/DeleteReservationsPage"


export const ReservationsRoutesMyConfirmed = () => {
    return (
        <Routes>
            <Route
                index
                element={<MyConfirmedReservationsPage/>}                                                                    
            />            
            <Route
                path="create"
                element={<CreateReservationsPage/>}                            
            />
            <Route
                path="update"
                element={<UpdateReservationsPage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteReservationsPage/>}                            
            />                
        </Routes>   
    )
}  
