// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import { 
    Page,
    Document,
    Text,
    View, 
    StyleSheet, 
    //Font,
    Image, 
    PDFDownloadLink 
} from '@react-pdf/renderer'

import
    QRCode
from "qrcode"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

// Define interfaces

/**
 * The AdminPage component renders the admin page of the application.
 */
/**
 * useful links:
 * https://react-pdf.org/
 * https://www.npmjs.com/package/jsbarcode
 * https://github.com/diegomura/react-pdf/issues/1028
 */

export const StoreageUnitPrintAll = () =>  {  
    const { t } = useTranslation()

    //Selectors
    const storageUnits = useAppSelector(selectStorageUnitsById)
    const organisations = useAppSelector(selectOrganisationsById)
    

    // register font fo the pdf
    // Font.register({
    //     family: 'Source Sans Pro',
    //     src: `https://fonts.googleapis.com/css?family=Source+Code+Pro:400,600,700|Source+Sans+Pro:400,600,700`,
    // })


    // Create styles for pdf
    const styles = StyleSheet.create({
        body:{
            fontSize: 28,
            // fontFamily: 'Source Sans Pro',
            width: '85%',
            height: '100%',
            borderRight: '1pt dotted #000000',
        },
        header:{
            height: '20%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around', 
            padding: 10,
        },
        logoArea:{
            objectFit: 'contain',            
            width: '50%',
        },
        nameArea:{
            textAlign: 'center',
            fontSize: 32,
            paddingHorizontal: 40
        },
        qrcodeArea:{
            objectFit: 'contain',
            width: '25%',
        },
        itemArea:{
            height: '80%',
            padding: 40,
            flexDirection: 'row',
            flexWrap: 'wrap',
            lineHeight: 3.0
        },
        footer:{
            textAlign: 'right',
            fontSize: '20',
            padding: 10,
            position: 'absolute',
            bottom: '0pt',
            backgroundColor: '#FFF',
            width: '100%'
        }
    })


    //Define current Date
    const current = new Date()
    const currentDate = `${current.getDate()}. ${current.getMonth()+1}. ${current.getFullYear()}`

    
    //Define List of blanks
    let numberOfBlank = 324
    let blankLine = new Array(numberOfBlank)
    for(var i=0; i<numberOfBlank; i++){
        blankLine[i]=' '
    }
 
    //Initialising page   
    let pages = [
        <Page size="A4" orientation="landscape" >
            <Text>Do not print me!!</Text>
        </Page> ,   
    ]

    pages.pop()
    
  
    //Create pages for PDF
    for (var j in storageUnits) {
        if (storageUnits[j] !== undefined) {

            const storageunitId = storageUnits[j].id    
            const currQRCodeNumber = storageunitId.toLocaleString('de-CH', {minimumIntegerDigits: 5, useGrouping: false}) 
            const organisationIdOfStorageUnit = storageUnits[storageunitId].storageUnitInfo!.organisationId
        
            // create qrcode
            let canvas
            canvas = document.createElement('canvas')
            const qrcode = QRCode.toDataURL(canvas, currQRCodeNumber)

            //Add Page to PDF
            pages.push(
                <Page size="A4" orientation="landscape" >
                    <View style={styles.body}>
                        <View style={styles.header}>
                                <Image src={`https://static.vseth.ethz.ch/assets/${organisations[organisationIdOfStorageUnit].organisationInfo?.logoPath}/logo.png`} style={styles.logoArea}/>
                                <Image src={qrcode} style={styles.qrcodeArea}/>
                                <Text>{currQRCodeNumber}</Text>
                        </View>
                        <View style={styles.nameArea}>
                            <Text>{storageUnits[storageunitId].storageUnitInfo?.displayName}</Text>
                        </View>
                        <View style={styles.itemArea}>
                            {blankLine.map((item, index)=>{
                                return <Text style={{textDecoration: 'underline'}}>{item}</Text>
                            })}
                        </View>
                        <View style={styles.footer}>
                            <Text>{currentDate}</Text>
                        </View>
                    </View>
                </Page>
            )
        }

    }


    // Create Document Component
    const MyDocument = () => (
        <Document>
            {pages}
        </Document>
    )


    //Return PDF Download Link
    return (
        <PDFDownloadLink document={<MyDocument/>} fileName='Labels.pdf'>
            {({ blob, url, loading, error }) =>
                loading ? t("StorageUnits.loadingDocument") : t("StorageUnits.downloadNow")
            }
        </PDFDownloadLink>
    )
}