// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect,
    useState 
} from "react"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "./useAppSelector"

import { 
    selectUserRoles 
} from "../features/auth/authSlice"


/**
 * The Function isAdmin return true if the user has the userrole "admin"
 * 
 */
export const useAdminStatus = () => {
    const [isAdmin, setIsAdmin] = useState(false)
    const userRoles = useAppSelector(selectUserRoles)

    useEffect(() => {
        setIsAdmin(userRoles.indexOf("admin") > -1 )
    }, [userRoles])
    
    return isAdmin
}