// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect 
} from "react"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    PlaceTable 
} from "../../components/place/PlaceTable"

import { 
    selectPlacesById,
    fetchAllPlacesMessage,
    selectIsLoadingPlaces,
    selectErrorPlaces,
} from "../../features/place/placesSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    fetchAllOrganisationsMessage,
    selectIsLoadingOrganisations,
    selectErrorOrganisations,
} from "../../features/organisation/organisationsSlice"

import { 
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"


/**
 * The AdminPlacesPage component renders the AdminPlaces page of the application.
 */
export const AdminPlacesPage = () => {
    const dispatch = useAppDispatch()   
   
    //Selectors
    const places = useAppSelector(selectPlacesById)
    const isLoadingPlaces = useAppSelector(selectIsLoadingPlaces)
    const errorPlaces = useAppSelector(selectErrorPlaces)

    const isLoadingOrganisations= useAppSelector(selectIsLoadingOrganisations)
    const errorOrganisations = useAppSelector(selectErrorOrganisations)     
 
    //Fetch Data    
    useEffect(() => {
        dispatch(fetchAllOrganisationsMessage())        
        dispatch(fetchAllPlacesMessage())
    }, [dispatch])
  
        
    //Loading Screen
    if (isLoadingPlaces || isLoadingOrganisations) {
        return <LoadScreen />
    }

    //Error Screen
    if (errorPlaces) {
        return <ErrorScreen 
            customText={errorPlaces?.message} 
        />    
    } else if (errorOrganisations) {
        return <ErrorScreen 
            customText={errorOrganisations?.message} 
    />  
    }    


    return(      
        <Fragment>
                <CreateBar/>
                <PlaceTable 
                    placesForTable={Object.values(places)}
                />
        </Fragment> 
    )      
}