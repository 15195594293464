// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect 
} from "react"


//import { 
//     useTranslation 
// } from "react-i18next"

import {
    Container 
} from "@mantine/core"


// Imports from vseth-canine-ui
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectIsAuthenticated 
} from "../../features/auth/authSlice"

import 
    TestMeme
from "../../components/faq/TestMeme"

import { 
    TableOfFaq 
} from "../../components/faq/TableOfFaq"


/**
 * The FaqPage component renders the fAQ page of the application.
 */
export const FaqPage = () => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated)
    //const { t } = useTranslation()

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }
    }, [isAuthenticated])

    return (
        <Container>            
            <div className="h-1000 m-0 row"> 
                <div className="col-lg-3">
                    <TableOfFaq />          
                </div>
                <div className="col-lg-9">
                    
                    FAQ
                    <TestMeme />
                    
                </div>
            </div>      
        </Container>
    )
}