// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    OrganisationExternalStoragePage 
} from "../../pages/externalStorage/OrganisationExternalStoragePage"

import { 
    CreateExternalStoragePage 
} from "../../pages/externalStorage/CreateExternalStoragePage"

import { 
    UpdateExternalStoragePage 
} from "../../pages/externalStorage/UpdateExternalStoragePage"

import { 
    DeleteExternalStoragePage 
} from "../../pages/externalStorage/DeleteExternalStoragePage"


export const OrganisationsRoutesExternalStorages = () => {
    return (
        <Routes>                
            <Route
                index
                element={<OrganisationExternalStoragePage/>}                            
            />
            <Route
                path="create"
                element={<CreateExternalStoragePage/>}                            
            />
            <Route
                path="update"
                element={<UpdateExternalStoragePage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteExternalStoragePage/>}                            
            />
        </Routes>
    )
}  
