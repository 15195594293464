import { 
    useDispatch
} from 'react-redux'

import { 
    AppDispatch 
} from '../app/store'


// Use throughout your app instead of plain `useAppDispatch` 
export const useAppDispatch = () =>  useDispatch<AppDispatch>()