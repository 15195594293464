// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box,
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    ExternalStorageDateFormLayout
} from "../../layouts/forms/ExternalStorageDateFormLayout"

import {
    ExternalStorageDatesValidationSchema
} from "./ExternalStorageDateValidationSchema"

import { 
    updateExternalStorageDateMessage
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'

import { 
    Timestamp 
} from 'google-protobuf/google/protobuf/timestamp_pb'


// Define interfaces
interface ExternalStorageDateUpdateFormProps {
    externalStorageDateId: number,
    initialDate: Timestamp.AsObject,
}


/**
 * The ExternalStorageDatesCreateAdminForm component renders the ExternalStorageDatesCreateAdminForm page of the application.
 */
export const ExternalStorageDateUpdateForm = (props: ExternalStorageDateUpdateFormProps) => { 
    const dispatch = useAppDispatch()
    
    const form = useForm({
        validate: yupResolver(ExternalStorageDatesValidationSchema),
    })

    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(updateExternalStorageDateMessage(props.externalStorageDateId,values)))}
            >
                <ExternalStorageDateFormLayout
                    form={form}
                />
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}