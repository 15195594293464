// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks



// Define which objects the item State have. 
interface itemSingleIdState {
    itemId: number,
}


//Define initial state
const initialState: itemSingleIdState = {
    itemId: 0,
}
  

// Define State
const itemSingleId = createSlice({
    name: "itemSingleId",
    initialState,
    reducers: {
        setItemSingleId(state, {payload}: PayloadAction<number>) {
            state.itemId = payload
        },
    },
})


// Export Reducer
export default itemSingleId.reducer


// Define Actions
const {
    setItemSingleId,
} = itemSingleId.actions


// Change handler
export {
    setItemSingleId, 
}


// Selectors
type SingleItemIdSliceRoot = {
    itemSingleId: ReturnType<typeof itemSingleId.reducer>
}

export const selectItemSingleId = (state: SingleItemIdSliceRoot) =>
    state.itemSingleId.itemId