// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Button
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../../hooks/useAppSelector"

import { 
    selectPlacesById
} from "../../../features/place/placesSlice"

import {
    selectPlaceSingleId, 
    setPlaceSingleId,
} from "../../../features/place/placeSingleIdSlice"

import {
    useAppDispatch 
} from "../../../hooks/useAppDispatch"


export const LastEntryPlaceButton = () => {  
    const { t } = useTranslation()    
    const dispatch = useAppDispatch()
    
    const places = useAppSelector(selectPlacesById)
    const editPlaceId = useAppSelector(selectPlaceSingleId)


    //initialise options
    const placeIds = [] as number[]

    //For Loop over all organisations
    for (var i in places) {
        const currValue = places[i].id
       
        placeIds.push(currValue)
    }        
   
    const currIndex = placeIds.indexOf(editPlaceId)


    const onChangeBackward = () => {
        return(
            dispatch(setPlaceSingleId(placeIds[currIndex-1]))
        )
    }

    
    const isDisabledBackward = () => { 
        if (placeIds[0] === editPlaceId) {
            return(
                true
            )
        } else {
            return(
                false
            )
        }
    }

    
    return (       
        <Button 
            onClick={() => {onChangeBackward()}}
            disabled = {isDisabledBackward()}
            fullWidth
            variant="outline"
        >
            {t("Storeroom.lastEntryButton")}
        </Button>     
    )
} 
