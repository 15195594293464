// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"

import { 
    Outlet
} from "react-router-dom"


// Imports from vseth-canine-ui
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    OrganisationReservationsMenuBar
} from "../menuBars/general/OrganisationReservationsMenuBar"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"


/**
 * The OrganisationsPage component renders the Organisation page of the application.
 */
export const OrganisationReservationsLayoutPage = () => {
     //Selectors
     const organisations = useAppSelector(selectOrganisationsById)
     const activeOrganisationId = useActiveOrganisationId() 
  
    const organisationShortName = (organisations[activeOrganisationId] === undefined) ? " " : organisations[activeOrganisationId].organisationInfo!.shortName
   
    //Return Organisation Page
    return (
        <Fragment>
            <OrganisationReservationsMenuBar            
                activeOrganisationShortName={organisationShortName}
            /> 
            <hr/>
            <Outlet/>
        </Fragment>
    )
}