// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    GoToConfirmPageButton 
} from "../../../components/buttons/goTo/GoToConfirmPageButton"

//Interface
interface CreateUpdateDeleteBarProps {
    isDisabled: boolean,    
    isConfirmed: boolean,
}


export const ConfirmBar = (props: CreateUpdateDeleteBarProps) => {

    return (
        <div className="row" >
            <div className="position-relativ col-lg-10"></div>
            <div className="position-relativ col-lg-2">
                <GoToConfirmPageButton
                    isDisabled = {props.isDisabled} 
                    isConfirmed = {props.isConfirmed}
                /> 
            </div>
        </div>  
    )
} 
