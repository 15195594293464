// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const SelectoptionOrganisationStorageUnitsName = (organisationId: number, storageUnitId: number, storageUnitType?: number) => {
    const { t } = useTranslation() 
    
    const storageUnits = useAppSelector(selectStorageUnitsById)
   

    //initialise options
    const options = [
        {value: -1, label: t("Storeroom.selectOption"), disabled: true},
        {value: 0, label: t("StorageUnits.notInOne"),},
    ] as any
      

   //If storageUnitType is Europalett (=2) than is cannot be in another StorageUnit
    if (storageUnitType !== undefined &&  storageUnitType === 2) {
        return(
            options
        )       
    }

    //For Loop over all storageunits
    for (var i in storageUnits) {
        if (storageUnits[i].storageUnitInfo?.organisationId === organisationId) {
            const currValue = storageUnits[i].id
            const currName = storageUnits[i].storageUnitInfo?.displayName
            const currStorageUnitType = Number(storageUnits[i].storageUnitInfo?.storageUnitType)
    
            if (storageUnitType === undefined) {
                options.push(
                    {label: currName, value: currValue, }
                )
            } else if (storageUnits[i].id !== storageUnitId && currStorageUnitType === 2) {
                options.push(
                    {label: currName, value: currValue, }
                )
            }
        }
        
    }        

    return(
        options
    )        
}