// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import { 
    Grid
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    DisabledTextInput 
} from "../formatter/DisabledTextInput"


// Define interfaces
interface TagViewProps {
    tagName: string
}

/**
 * The Page component renders the admin page of the application.
 */
//Organisation Form
export const TagViewLayout = (props: TagViewProps) => {
    const { t } = useTranslation()  

    return (
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Tags.name")}
                    value = {props.tagName}
                />
            </Grid.Col>
        </Grid>
    )
}