// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box,
    Grid, 
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui



// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    StorageUnitAttributes 
} from "../../features/storageunit/storageUnitSlice"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    DisabledTextInput 
} from "../formatter/DisabledTextInput"

import { 
    ChangeDateUserInfoNew 
} from '../formatter/ChangeDateUserInfoNew'

import {
    selectPlacesById
} from "../../features/place/placesSlice"

import { 
    Timestamp
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import { 
    StorageUnitItemsList 
} from "../formatter/StorageUnitItemsList"


// Define interfaces
interface StorageUnitViewProps {
    storageunitId: number,
    storageUnitInfo: StorageUnitAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
}


/**
 * The Page component renders the admin page of the application.
 */
export const StorageUnitViewLayout = (props: StorageUnitViewProps) => {
    const { t } = useTranslation()

    const organisations = useAppSelector(selectOrganisationsById)
    const places = useAppSelector(selectPlacesById)
    const storageUnits = useAppSelector(selectStorageUnitsById)
   
    const organisationIdValue = props.storageUnitInfo.organisationId
    const organisationShortName  = (organisations[organisationIdValue] === undefined) ? "" : organisations[organisationIdValue].organisationInfo!.shortName

    const placeIdValue = props.storageUnitInfo.placeId
    const placeRoomRack = (places[placeIdValue] === undefined) ? "" : `${places[placeIdValue].placeInfo?.room}, ${places[placeIdValue].placeInfo?.rack}`

    const storageUnitTypeValue = props.storageUnitInfo.storageUnitType
    
    const storageUnitTypeName = () => {
        if (storageUnitTypeValue === 0) {
            return(
                "Other"   
            )
        } else if (storageUnitTypeValue === 1) {
            return(
                "Eurobox"   
            )
        } else if (storageUnitTypeValue === 2) {
            return(
                "Europalette"   
            )
        } else {
            return (
                ""
            )
        }
    }

    const isInStorageUnitIdValue = props.storageUnitInfo.isInStorageUnitId
    const storageUnitName = (storageUnits[isInStorageUnitIdValue] === undefined) ? "" : storageUnits[isInStorageUnitIdValue].storageUnitInfo!.displayName
      
     
    return (
        <Box 
            mx="auto"
        >
            <Grid>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("StorageUnits.displayName")}
                        value = {props.storageUnitInfo.displayName}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("StorageUnits.description")}
                        value = {props.storageUnitInfo.description}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("StorageUnits.storageUnitType")}
                        value = {storageUnitTypeName()}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("StorageUnits.isInStorageUnitId")}
                        value = {storageUnitName}
                    />   
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("Storeroom.organisation")} 
                        value = {organisationShortName}                
                    />
                </Grid.Col> 
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("Places.room") + ", " + t("Places.rack")}
                        value = {placeRoomRack}
                    />                       
                </Grid.Col>                    
            </Grid>
            <StorageUnitItemsList
                storageUnitId = {props.storageunitId}             
            />
            <ChangeDateUserInfoNew
                lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
                lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
            />   
        </Box>
    )
}