// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"


import { 
    useTranslation
} from "react-i18next"

import { 
    useLocation
} from "react-router-dom"

import {
    FormikProps,
    Form,
    Field,
    FieldArray
} from "formik"

import {
    Button,
    Input,
    Text,
} from "@mantine/core"



// Imports from vseth-canine-ui


import 
    Select
from "react-select"


import { 
    useAppSelector 
} from "../../../hooks/useAppSelector"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageAttributes
} from "../../../features/externalStorage/externalStoragesSlice"

import { 
    SaveButton 
} from "../../buttons/form/SaveButton"

import { 
    SaveCloseButton
} from "../../buttons/form/SaveBackButton"

import { 
    CancelButton 
} from "../../buttons/form/CancelButton"

import { 
    selectOrganisationsById
} from "../../../features/organisation/organisationsSlice"

import { 
    selectUsersByUUID
} from "../../../features/user/usersSlice"


import { 
    selectSub
} from "../../../features/auth/authSlice"


import 
    CustomSelect 
from "../../general/CustomSelect"

import { 
    SelectoptionOrganisationStorageUnitsName 
} from "../../selectoptions/SelectoptionOrganisationStorageUnitsName"

import { 
    SelectoptionsAllOrganisationsName 
} from "../../selectoptions/SelectoptionAllOrganisationsName"
import { SelectoptionExternalStorageDates } from "../../selectoptions/SelectoptionExternalStorageDates"


// Define interfaces
interface OtherProps {
    title?: string 
}


/**
 * The Page component renders the admin page of the application.
 */
//Organisation Form
export const ExternalStorageForm = (props: OtherProps & FormikProps<ExternalStorageAttributes>) => {
    const { t } = useTranslation()
    const location = useLocation()
      
    const organisations = useAppSelector(selectOrganisationsById)   
    
    const users = useAppSelector(selectUsersByUUID)   

    const sub = useAppSelector(selectSub).sub
    const activeOrganisationId = (users[sub] === undefined) ? 1 : users[sub].activeOrganisation

    
    const {  
        values,
        touched, 
        errors, 
        title,
        setFieldValue,
        setFieldTouched
    } = props

  
    const selectoptionOrganisationStorageUnitsName = SelectoptionOrganisationStorageUnitsName(values.organisationId,0)
   

    const organisationField = () => {
        if (location.pathname === "/admin/externalstorage/create" || location.pathname === "/admin/externalstorage/update") {
            return (
                <div>
                    <Select 
                        name="organisationId"
                        id="organisationId"
                        value={(organisations[values.organisationId] === undefined) ? {label: '', value: 0} : {label: `${organisations[values.organisationId].organisationInfo?.shortName}` , value: values.organisationId}}
                        onBlur={() => setFieldTouched("organisationId", true)}
                        onChange={(option:any) => {
                        setFieldValue("organisationId", option.value)  
                        }}
                        options={SelectoptionsAllOrganisationsName()} 
                    />                             
                    {touched.organisationId && errors.organisationId && <div>{errors.organisationId}</div>} 
                </div>                
            )
        } else {
            values.organisationId = activeOrganisationId
            return (
                <p>{(organisations[activeOrganisationId] === undefined) ? "": organisations[activeOrganisationId].organisationInfo?.shortName}</p>
            )
        }
    }



    return (
        <Form onSubmit={props.handleSubmit}>
            <h3>{title}</h3>
            <div className="row" >
                <div className="position-relativ col-lg-6">
                </div>
                <div className="position-relativ col-lg-2">
                    <SaveButton />
                </div>
                <div className="position-relativ col-lg-2">
                    <SaveCloseButton /> 
                </div>
                <div className="position-relativ col-lg-2">
                    <CancelButton/>
                </div>
            </div>  
            <div className="row" >
                <div className="position-relativ col-lg-12">
                    <h6>{t("ExternalStorages.subject")}</h6>
                    <Field 
                        type="text"
                        name="subject" 
                        placeholder="subject"
                        as={Input}
                    />
                    {touched.subject && errors.subject && <div>{errors.subject}</div>}    


                    <h6>{t("ExternalStorages.requestText")}</h6>
                    <Field 
                        type="text"
                        name="requestText" 
                        placeholder= "requestText"
                        as={Input}
                    />
                    {touched.requestText && errors.requestText && <div>{errors.requestText}</div>}                   


                    <h6>{t("ExternalStorages.startTime")}</h6>
                    <Field
                        name="startTimeId"
                        id="startTimeId"
                        options={SelectoptionExternalStorageDates()} 
                        component={CustomSelect}
                        placeholder="Select start time..."
                        isMulti={false}
                    />      
                    {touched.startTimeId && errors.startTimeId && <div>{errors.startTimeId}</div>}    
                  

                    <h6>{t("ExternalStorages.endTime")}</h6>
                    <Field
                        name="endTimeId"
                        id="endTimeId"
                        options={SelectoptionExternalStorageDates()} 
                        component={CustomSelect}
                        placeholder="Select end time..."
                        isMulti={false}
                    />      
                    {touched.endTimeId && errors.endTimeId && <div>{errors.endTimeId}</div>}    

                    <h6>{t("Storeroom.organisation")}</h6>
                    {organisationField()}  

                     <h6>{t("Storeroom.storageUnits")}</h6>
                    <FieldArray
                        name="storageUnitRelationList"                        
                        render={(helpers) => (
                            <div>
                                {values.storageUnitRelationList && values.storageUnitRelationList.length > 0 ? (
                                    values.storageUnitRelationList.map( (storageUnitRelation, index) => (
                                        <Fragment key={index}>
                                            <div className="row" >
                                                <div className="position-relativ col-lg-2">
                                                    <Text>
                                                        {t("Storeroom.storageUnit")}
                                                    </Text>
                                                </div>
                                                <div className="position-relativ col-lg-4">
                                                    <Field
                                                        name={`storageUnitRelationList.${index}.storageUnitId`}
                                                        options={selectoptionOrganisationStorageUnitsName} 
                                                        component={CustomSelect}
                                                        placeholder="Select storage unit..."
                                                    />   
                                                </div> 
                                                <div className="position-relativ col-lg-1">
                                                    <Button
                                                        color="secondary"
                                                        size="sm"
                                                        fullWidth
                                                        onClick={() => helpers.remove(index)}
                                                    > - 
                                                    </Button>
                                                </div>  
                                                <div className="position-relativ col-lg-1">                                
                                                    <Button
                                                        color="secondary"
                                                        size="sm"
                                                        fullWidth
                                                        onClick={() => helpers.insert(index, "")} 
                                                    > + 
                                                    </Button>                                                  
                                                </div>  
                                            </div>  
                                        </Fragment>
                                    ))
                                ) : (
                                        <Button 
                                            color="secondary"
                                            size="sm"
                                            onClick={() => helpers.push("")}
                                        >
                                            {t("Items.addNewOne")}
                                        </Button>
                                )}
                            </div>
                        )}/>

                    
                    {touched.storageUnitRelationList && errors.storageUnitRelationList && <div>errors.storageUnitRelationList</div>}                                                                            
  


                </div>
            </div>
        </Form>
    )
}
