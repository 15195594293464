// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {
     Fragment 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import {  
    useNavigate 
} from "react-router-dom"

import {
    Button
} from "@mantine/core"


// Imports from vseth-canine-ui




// Imports from this projects
// Pages, Components, Features, Hooks

interface ConfirmButtonProps {
    isDisabled: boolean,
    isConfirmed: boolean,
}

/**
 * The CreateButton component renders the admin page of the application.
 */
export const GoToConfirmPageButton = (props: ConfirmButtonProps) => {    
    const { t } = useTranslation()
    const navigate = useNavigate()

    const buttonText = () => {
        if (props.isConfirmed) { //true = confirmed --> Button unconfirmed
            return (
                <Fragment>{t("Storeroom.unconfirmButton")}</Fragment>
            )
        } else {
            return (                
                <Fragment>{t("Storeroom.confirmButton")}</Fragment>
            )
        }
    }

   
    return (
        <Button 
            color={props.isDisabled ? "secondary" : "dark" }
            onClick={() => navigate("confirm")}
            fullWidth
        >
            {buttonText()}
        </Button>
    )
}