// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {  
    useNavigate 
} from "react-router-dom"

import {
    Button
} from "@mantine/core"


// Imports from vseth-canine-ui



// Imports from this projects
// Pages, Components, Features, Hooks


/**
 * The CreateButton component renders the admin page of the application.
 */
export const GoToCreatePageButton = () => {    
    const { t } = useTranslation()
    const navigate = useNavigate()    


    return (
        <Button 
            onClick={() => navigate("create")}
            fullWidth
        >
            {t("Storeroom.createButton")}
        </Button>
    )
}