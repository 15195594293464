// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminItemsPage 
} from "../../pages/items/AdminItemsPage"    

import { 
    CreateItemsPage 
} from "../../pages/items/CreateItemsPage"

import { 
    UpdateItemsPage
} from "../../pages/items/UpdateItemsPage"

import { 
    DeleteItemsPage 
} from "../../pages/items/DeleteItemsPage"

import { 
    ViewItemsPage 
} from "../../pages/items/ViewItemsPage"


export const AdminRoutesItems = () => {
    return (
        <Routes>               
            <Route
                index
                element={<AdminItemsPage/>}                            
            />
            <Route
                path="create"
                element={<CreateItemsPage/>}                            
            />
            <Route
                path="view"
                element={<ViewItemsPage/>}                            
            />
            <Route
                path="update"
                element={<UpdateItemsPage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteItemsPage/>}                            
            />                  
        </Routes>    
    )
} 
