// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {
    Button
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks



/**
 * The SaveButton component renders the admin page of the application.
 */
export const SaveButton = () => {    
    const { t } = useTranslation()
   
    return (
        <Button 
            type="submit"
            color="primary"
        >
            {t("Storeroom.saveButton")}
        </Button>  
    )
}