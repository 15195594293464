// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminStorageUnitsPage 
} from "../../pages/storageUnits/AdminStorageUnitsPage"

import { 
    CreateStorageUnitsPage 
} from "../../pages/storageUnits/CreateStorageUnitsPage"

import { 
    UpdateStorageUnitsPage
} from "../../pages/storageUnits/UpdateStorageUnitsPage"

import { 
    DeleteStorageUnitsPage
} from "../../pages/storageUnits/DeleteStorageUnitsPage"

import { 
    SinglePrintStorageUnitsPage 
} from "../../pages/storageUnits/SinglePrintStorageUnitsPage"

import { 
    AllPrintStorageUnitsPage 
} from "../../pages/storageUnits/AllPrintStorageUnitsPage"

import { 
    ViewStorageUnitsPage 
} from "../../pages/storageUnits/ViewStorageUnitsPage"


export const AdminRoutesStorageUnits = () => {
    return (
        <Routes>
            <Route
                index
                element={<AdminStorageUnitsPage/>}                            
            />
            <Route
                path="create"
                element={<CreateStorageUnitsPage/>}                            
            />
            <Route
                path="view"
                element={<ViewStorageUnitsPage/>}                            
            />
            <Route
                path="update"
                element={<UpdateStorageUnitsPage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteStorageUnitsPage/>}                            
            />
            <Route
                path="printSingle"
                element={<SinglePrintStorageUnitsPage/>}                            
            />
            <Route
                path="printAll"
                element={<AllPrintStorageUnitsPage/>}                            
            />
        </Routes>
    )
}   
