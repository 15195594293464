// import { ColumnToggle } from "react-bootstrap-table2-toolkit"
export const i18n_german = {
    translation: {
        lang: "de",

        HomePage: {
            title: "Lagerverwaltungstool des VSETH",
            reservationTitle: "Reservationsverwaltung",
            catalogueText: "Die Katalogseite zeigt alle Gegenstände auf, welche du von deiner eigenen Organisation oder allen anderen Organisation, welche als extern reservierbar markiert sind, reservieren kannst.", 
            reservationText: "Die Reservationsseite zeigt alle Reservationen, welche du selber gestellt oder bestätigt hast.",
            organisationTitle: "Lagerverwaltung eurer Organisationen",
            organisationText: "Auf dieser Seite kannst du die Orte, Lagerbehälter, Gegenstände, Reservationen und Anfragen an das Externe Lager deiner Organisationen verwalten.",
            faqTitle: "Häufig gestellte Fragen (FAQ)",
            faqText: "Die FAQ-Seite stellt Informationen zu häufigen Fragen oder Anliegen zur Verfügung.",
        },

        Navbar: {
            adminItem: "Admin",
            baseHome: "Start",
            baseCatalogue: "Katalog",
            baseOrganisations: "Organisationen",
            baseReservations: "Meine Reservationen",
            baseFAQ: "FAQ",
        },

        Footer: {
            privacy: "Datenschutz",
            impressum: "Impressum",
        },

        LoadScreen: {
            loading: "",
        },

        ErrorScreen: {
            title: "Fehler",
            text: "",
        },

        //General Translations
            //for the whole Site
            Storeroom: {
                organisations: "Organisationen",
                organisation: "Organisation",
                places: "Orte",
                storageUnits: "Lagerbehälter",
                storageUnit: "Lagerbehälter",
                items: "Gegenstände",
                item: "Gegenstand",
                tags: "Schlagwörter",
                reservations: "Reservationen",
                externalStorage: "Externes Lager",
                externalStorageDates: "Externe Lager - Daten",
                users: "Benutzer",
                createButton: "Erstellen",
                viewButton: "Ansehen",
                tableButton: "Tabelle",
                updateButton: "Anpassen",
                deleteButton: "Löschen",
                saveButton: "Speichern",
                cancelButton: "Abbrechen",
                backButton: "Zurück",
                lastEntryButton: "Letzter Eintrag",
                nextEntryButton: "Nächster Eintrag",
                confirmButton: "Bestätigen",                
                unconfirmButton: "Nicht Bestätigen",
                saveCloseButton: "Speichern & Zurück",
                fullTableButton: "Vollständige Tabelle",
                normalViewTableButton: "Reduzierte Tabelle",
                lastChangeInfo: "Letzte Änderung",
                lastChangeDate: "Datum",
                lastChangeUser: "Benutzer",
                lastChangeDateCombined: "Letzte Anpassung: Datum",
                lastChangeUserCombined: "Letzte Anpassung: Nutzer",
                created: "Erstellt",
                chooseOne: 'Wähle eins',
                yes: 'Ja',
                no: 'Nein',
                selectOption: 'Wähle eine Option',
            },

            //General Translations for Organisations
            Organisations: {
                id: "ID",
                shortName: "Kürzel",
                title: "Name",
                logoPath: "Gruppe für Logo",
                contactMail: "E-Mail für Verleih",
                contactPhone: "Telefonnummer für Verleih",
                contactAddress: "Adresse für Verleih",
                contactAddressBuilding: 'Gebäude',
                contactAddressFloor: 'Stockwerk',
                contactAddressRoom: 'Raum',
                contactAddressStreet: 'Strasse',
                contactAddressNumber: 'Nummer',
                contactAddressPLZ: 'PLZ',
                contactAddressCity: 'Ort',
                updateRole: "Bearbeitungsrolle",
                viewRole: "Anzeigerolle",
                createText: "Organisation erstellen",
                viewText: "Organiation ansehen",
                updateText: "Organisation anpassen",
                deleteText: "Bitte bestätige das Löschen dieser Organisation",  
                myOrganisations: "Meine Organisationen",
            }, 

            //General Translations for Places
            Places: {
                id: "ID",
                room: "Raum",
                rack: "Regal",
                description: "Beschreibung",
                createText: "Ort erstellen",
                viewText: "Ort ansehen",
                updateText: "Ort anpassen",
                deleteText: "Bitte bestätige das Löschen dieses Ortes",
            },

            //General Translations for Storage Units
            StorageUnits: {
                id: "ID",
                displayName: "Name",
                description: "Beschreibung",
                storageUnitType: "Typ der Lagereinheit",
                isInStorageUnitId: "Ist in Lagereinheit",
                notInOne: "in keinem Lagerbehälter",
                typeOther: "Andere",
                typeBox: "Eurobox",
                typePalett: "Europalette",
                createText: "Lagereinheit erstellen",
                viewText: " Lagereinheit ansehen",
                updateText: "Lagereinheit anpassen",
                deleteText: "Bitte bestätige das Löschen dieser Lagereinheit",
                printSingleLabelButton: "Einzelne Etikette",
                printAllLabelButton: "Alle Etikette",
                printSingleText: "Drucke eine Etikette für einen Lagerbehälter",
                printAllText: "Drucke Etikette für alle Lagerbehälter",
                loadingDocument: "Etiketten werden erstellt...",
                downloadNow: "Jetzt herunterladen!",
            },

            //General Translations for Items
            Items: {
                id: "ID",
                displayName: "Name",
                description: "Beschreibung",
                externalRent: "Extern reservierbar",
                createTime: "Erstellungsdatum",
                deleted: "Gelöscht",
                reservationList: "Liste Reservationen",
                createText: "Gegenstand erstellen",
                viewText: "Gegenstand ansehen",
                updateText: "Gegenstand anpassen",
                deleteText: "Bitte bestätige das Löschen dieses Gegenstandes",
                storageUnitRelation: "In Lagereinheit (Anzahl)",
                quantity: "Anzahl",
                addNewOne: "Füge neue hinzu",
                deletedItems: "Übersicht der gelöschten Gegenständen",
            },

            //General Translations for Tags
            Tags: {
                id: "ID",
                name: "Name",
                createText: "Schlagwort erstellen",
                updateText: "Schlagwort anpassen",
                deleteText: "Bitte bestätige das Löschen dieses Schlagwortes",
            },

            //General Translations for Reservations
            Reservations: {
                id: "ID",
                subject: "Betreff",
                requestText: "Anfrage",
                startTime: "Start Datum",
                endTime: "Enddatum",
                createTime: "Erstellungsdatum",
                createUser: "Erstellungs User",
                updateTime: "Änderungszeit",
                updateUser: "Änderungsbenutzer",
                itemRelationsList: "Gegenstände (Bestätigt?)",   
                createText: "Anfrage für das externe Lager erstellen",
                updateText: "Anfrage für das externe Lager anpassen",
                deleteText: "Bitte bestätige das Löschen dieser Anfrage",
                confirmText: "Bitte bestätige diese Reservationsanfrage",
                beforeStart: "Bevorstehende Reservationen",
                beforeEnd: "Laufende Reservationen", 
                archive: "Archiv",
                organisationRequests: "Anfragen deiner Organisation",
                ownedItems: "Anfragen mit Gegenständen der Organisation",
                myRequests: "Meine Reservationsanfragen",
                myConfirmed: "Meine bestätigten Reservationsanfragen",
            },

            //General Translations for ExternalStorageRequests
            ExternalStorages: {
                id: "ID",
                subject: "Betreff",
                requestText: "Anfrage",
                startTime: "Start Datum",
                endTime: "Enddatum",
                createTime: "Erstellungsdatum",
                createUser: "Erstellungs User",
                updateTime: "Änderungszeit",
                updateUser: "Änderungsbenutzer",
                confirmed: "Bestätigt",
                confirmationTime: "Bestätigungszeit",
                confirmationUser: "Bestätigungsbenutzer",
                createText: "Anfrage für das externe Lager erstellen",
                updateText: "Anfrage für das externe Lager anpassen",
                deleteText: "Bitte bestätige das Löschen dieser Anfrage",
                confirmText: "Bitte bestätige diese Anfrage für das externe Lager",
                beforeStart: "Versand in externes Lager",
                beforeEnd: "Im externen Lager", 
                archive: "Archiv",
            },

            //General Translations for ExternalStorageDates
            ExternalStorageDates: {
                id: "ID",
                date: "Datum",
                createText: "Datum für den Transport externes Lager erstellen",
                updateText: "Datum für den Transport externes Lager anpassen",
                deleteText: "Bitte bestätige das Löschen dieses Datums für den TRansport externes Lager",
                futureTransports: "Zukünftige Transportdaten",
                archiveTransports: "Vergangene Transportdaten",
            },

            //General Translations for Users
            Users: {
                id: "ID",
                uuid: "uuid",
                language: "Sprache",
                activeOrganisation: "Aktive Organisation"
            },


        //Specific Translations for Components
            //Admin
            AdminPage: {
                title: "Admin Bereich",
            },  

            Catalogue: {
                title: "Katalog",
                noUserRole: "Es sind keine User-Rollen für deinen Account definert. Deshalb ist es nicht möglich, Reservationen zu tätigen.",
            },

            NoUserRolePage: {
                info: "Es sind keine User-Rollen für deinen Account definert. Deshalb bist du nicht berechtigt, auf diese Seite zuzugreifen.",
            },

            NotAuthorizedPage: {
                info: "Du bist nicht berechtigt, auf diese Seite zuzugreifen."
            }


             
    },
}
