// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {  
    useNavigate 
} from "react-router-dom"

import {
    Button
} from "@mantine/core"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../../hooks/useAppDispatch"

import { 
    deleteTagMessage
} from "../../../features/tag/tagsSlice"



interface DeleteTagButtonProps {
    editTagId: number,
}


/**
 * The CancelButton component renders the admin page of the application.
 */
export const DeleteTagButton = (props: DeleteTagButtonProps) => {    
    const { t } = useTranslation()
    const navigate = useNavigate()    
    const dispatch = useAppDispatch()
    
   
    return (
        <Button
            color="red"
            onClick={() => {
                dispatch(deleteTagMessage(props.editTagId))
                navigate(-1)
            }}
            fullWidth
        >
            {t("Storeroom.deleteButton")}
        </Button>
    )
}