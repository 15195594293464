// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectTagsById
} from "../../features/tag/tagsSlice"

import { 
    selectTagSingleId, 
} from "../../features/tag/tagSingleIdSlice"

import { 
    TagViewLayout 
} from "../../layouts/views/TagViewLayout"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar 
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

import { 
    GoToTableButton 
} from "../../components/buttons/goTo/GoToTableButton"

import { 
    LastEntryTagButton 
} from "../../components/buttons/lastEntry/LastEntryTagButton"

import {
    NextEntryTagButton 
} from "../../components/buttons/nextEntry/NextEntryTagButton"

import { 
    GoFromToUpdatePageButton 
} from "../../components/buttons/goFromTo/GoFromToUpdatePageButton"


/**
 * The ViewTagsPage component renders the ViewTags page of the application.
 */
export const ViewTagsPage = () => {
    const { t } = useTranslation()
    
    const tags = useAppSelector(selectTagsById)
    const editTagId = useAppSelector(selectTagSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editTagId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }


    return (
        <Card>    
            <h1>{t("Tags.deleteText")}</h1>
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryTagButton/>}
                navigationButton1 = {<GoFromToUpdatePageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryTagButton/>}
            /> 
            <TagViewLayout
                tagName = {tags[editTagId].tagName}
            />
        </Card>                    
    )                  
}       