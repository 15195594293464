// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectOrganisationSingleId, 
} from "../../features/organisation/organisationSingleIdSlice"

import { 
    OrganisationViewLayout 
} from "../../layouts/views/OrganisationViewLayout"

import { 
    DeleteOrganisationBar 
} from "../../layouts/menuBars/organisation/DeleteOrganisationBar"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    LastEntryOrganisationButton
} from '../../components/buttons/lastEntry/LastEntryOrganisationButton'

import { 
    NextEntryOrganisationButton 
} from '../../components/buttons/nextEntry/NextEntryOrganisationButton'

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToUpdatePageButton 
} from '../../components/buttons/goFromTo/GoFromToUpdatePageButton'

import { 
    GoFromToViewPageButton
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"



/**
 * The DeleteOrganisationPage component renders the DeleteOrganisation page of the application.
 */
export const DeleteOrganisationPage = () => {
    const { t } = useTranslation()
    
    //Selectors
    const organisations = useAppSelector(selectOrganisationsById)
    const editOrganisationId = useAppSelector(selectOrganisationSingleId)

    
    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editOrganisationId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }


    return(
        <Card>    
            <h1>{t("Organisations.deleteText")}</h1>
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryOrganisationButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToUpdatePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryOrganisationButton/>}
            /> 
            <OrganisationViewLayout 
                organisationId = {editOrganisationId}
                organisationInfo = {organisations[editOrganisationId].organisationInfo!}
                updateUser = {organisations[editOrganisationId].updateUser}
                updateTime = {organisations[editOrganisationId].updateTime!}
            />   
            <DeleteOrganisationBar
                editOrganisationId = {editOrganisationId}
            />       
        </Card> 
    )
}