// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata 
} from "../../util/proto"

import { 
    selectAccessToken 
} from "../auth/authSlice"

import { 
    AppThunk 
} from "../../app/store"

import {
    CreateExternalStorageDateRequest as createExternalStorageDateRequest, 
    CreateExternalStorageDateResponse as createExternalStorageDateResponse,
    DeleteExternalStorageDateRequest as deleteExternalStorageDateRequest,
    DeleteExternalStorageDateResponse as deleteExternalStorageDateResponse,
    UpdateExternalStorageDateRequest as updateExternalStorageDateRequest,
    UpdateExternalStorageDateResponse as updateExternalStorageDateResponse,
    ListExternalStorageDatesRequest as listExternalStorageDatesRequest,
    ListExternalStorageDatesResponse as listExternalStorageDatesResponse,
    ExternalStorageDateAttributes as externalStorageDateAttributes,
    GetExternalStorageDateAttributes as getExternalStorageDateAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"


// Define the Types
export type ExternalStorageDateAttributes = externalStorageDateAttributes.AsObject
type CreateExternalStorageDateResponse = createExternalStorageDateResponse.AsObject
type UpdateExternalStorageDateResponse = updateExternalStorageDateResponse.AsObject
type DeleteExternalStorageDateResponse = deleteExternalStorageDateResponse.AsObject
export type GetExternalStorageDateAttributes = getExternalStorageDateAttributes.AsObject
export type ListExternalStorageDatesResponse = listExternalStorageDatesResponse.AsObject

type ExternalStorageDateDict = {
    [Key: number]: GetExternalStorageDateAttributes
}


// Define which objects the organisation State have. 
interface ExternalStorageDateState {
    items: ExternalStorageDateDict
    isLoading: boolean
    error?: Error
}


// Define intital state
const initialState: ExternalStorageDateState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the State
const externalStorageDates = createSlice({
    name: "externalStorageDates",
    initialState,
    reducers: { 

        // Fetch all externalStorageDates
        fetchAllExternalStorageDatesStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllExternalStorageDatesSuccess: (state, { payload }: PayloadAction<ListExternalStorageDatesResponse>) => {
            state.items = {}
            payload.externalStorageDateList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllExternalStorageDatesFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
               
        // Create ExternalStorageDate
        createExternalStorageDateStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createExternalStorageDateSuccess: (state, { payload }: PayloadAction<CreateExternalStorageDateResponse>) => {
            state.items[payload.externalStorageDate!.id] = payload.externalStorageDate!
            state.isLoading = false
            state.error = undefined
        },
        createExternalStorageDateFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Update ExternalStorageDate       
        updateExternalStorageDateStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateExternalStorageDateSuccess: (state, { payload }: PayloadAction<UpdateExternalStorageDateResponse>) => {
            state.items[payload.externalStorageDate!.id] = payload.externalStorageDate!
            state.isLoading = false
            state.error = undefined
        },
        updateExternalStorageDateFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete ExternalStorageDate
        deleteExternalStorageDateStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deleteExternalStorageDateSuccess: (state, { payload }: PayloadAction<DeleteExternalStorageDateResponse>) => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deleteExternalStorageDateFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
    },
})


// Export Reducers
export default externalStorageDates.reducer


// Define Actions
const {
    fetchAllExternalStorageDatesStart,
    fetchAllExternalStorageDatesSuccess,
    fetchAllExternalStorageDatesFailure,
    
    createExternalStorageDateStart,
    createExternalStorageDateSuccess,
    createExternalStorageDateFailure,    
        
    updateExternalStorageDateStart,
    updateExternalStorageDateSuccess,
    updateExternalStorageDateFailure,

    deleteExternalStorageDateStart,
    deleteExternalStorageDateSuccess,
    deleteExternalStorageDateFailure,
} = externalStorageDates.actions


// Fetch all externalStorageDates
export const fetchAllExternalStorageDatesMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllExternalStorageDatesStart())
    const token = selectAccessToken(getState())
    const request = new listExternalStorageDatesRequest()

    return storeroomClient
        .listExternalStorageDates(request, getAuthMetadata(token))
        .then((response: listExternalStorageDatesResponse) => {
            dispatch(fetchAllExternalStorageDatesSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllExternalStorageDatesFailure(err))
        })
}


// Create organisation
export const createExternalStorageDateMessage = (externalStorageDate: ExternalStorageDateAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createExternalStorageDateStart())
    const token = selectAccessToken(getState())  
        
    const timestamp = new Timestamp()
    timestamp.setSeconds(externalStorageDate.date!.seconds)
    timestamp.setNanos(externalStorageDate.date!.nanos)

    const externalStorageDateData = new externalStorageDateAttributes()
    externalStorageDateData.setDate(timestamp)

    const request = new createExternalStorageDateRequest()
    request.setExternalStorageDateInfo(externalStorageDateData)
     
    return storeroomClient
        .createExternalStorageDate(request, getAuthMetadata(token))
        .then((response: createExternalStorageDateResponse) => {
            dispatch(createExternalStorageDateSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createExternalStorageDateFailure(err))
        })
}


// Update organisation
export const updateExternalStorageDateMessage = (externalStorageDatesId: number, externalStorageDate: ExternalStorageDateAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateExternalStorageDateStart())
    const token = selectAccessToken(getState())

    const timestamp = new Timestamp()
    timestamp.setSeconds(externalStorageDate.date!.seconds)
    timestamp.setNanos(externalStorageDate.date!.nanos)

    const externalStorageDateData = new externalStorageDateAttributes()
    externalStorageDateData.setDate(timestamp)
   
    const request = new updateExternalStorageDateRequest()
    request.setId(externalStorageDatesId)
    request.setExternalStorageDateInfo(externalStorageDateData)

    return storeroomClient
        .updateExternalStorageDate(request, getAuthMetadata(token))
        .then((response: updateExternalStorageDateResponse) => {
            dispatch(updateExternalStorageDateSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateExternalStorageDateFailure(err))
        })
}


// Delete organisation
export const deleteExternalStorageDateMessage = (externalStorageDatesId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deleteExternalStorageDateStart())
    const token = selectAccessToken(getState())
    
    const request = new deleteExternalStorageDateRequest()
    request.setId(externalStorageDatesId)

    return storeroomClient
        .deleteExternalStorageDate(request, getAuthMetadata(token))
        .then((response: deleteExternalStorageDateResponse) => {
            dispatch(deleteExternalStorageDateSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deleteExternalStorageDateFailure(err))
        })
}


// Selectors
type ExternalStorageDateSliceRoot = {
    externalStorageDates: ReturnType<typeof externalStorageDates.reducer>
}

export const selectExternalStorageDatesById = (state: ExternalStorageDateSliceRoot) =>
    state.externalStorageDates.items
    
export const selectIsLoadingExternalStorageDates = (state: ExternalStorageDateSliceRoot) => state.externalStorageDates.isLoading
export const selectErrorExternalStorageDates = (state: ExternalStorageDateSliceRoot) => state.externalStorageDates.error