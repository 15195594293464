// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"

import { 
    useNavigate
} from "react-router-dom"

import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    GetExternalStorageDateAttributes 
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    setExternalStorageDateSingleId
} from "../../features/externalStorageDate/externalStorageDateSingleIdSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"

import { 
    timeObjToMomentTime 
} from "../../util/proto"



// Define interfaces
interface ExternalStorageDatesTableProps {
    externalStorageDatesForTable: GetExternalStorageDateAttributes[],
    isFuture?: boolean
    isArchive?: boolean
}

/**
 * The ExternalStorageDatesTable component renders the ExternalStorageDatesTable page of the application.
 */
 export const ExternalStorageDateTable: React.FC<ExternalStorageDatesTableProps> = ({
    externalStorageDatesForTable, 
    isFuture,
    isArchive,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()     
    const navigate = useNavigate()

    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(externalStorageDatesForTable.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'date', direction: 'asc' })
   
    
    useEffect(() => {
        setPage(1)
    }, [pageSize])  

    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(externalStorageDatesForTable, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, externalStorageDatesForTable])

    const columns = [
        {
            accessor: "id",
            title: t("ExternalStorageDates.id"),
            sortable: true,
        },
        {
            accessor: "date",
            title: t("ExternalStorageDates.date"),
            sortable: true,
            render: (externalStorageDate: GetExternalStorageDateAttributes) => (
                timeObjToMomentTime(externalStorageDate.date).format("DD.MM.YYYY")
            )
        },
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            hidden: isArchive,
            render: (externalStorageDate: GetExternalStorageDateAttributes) => (
              <Group spacing={2} position="right" noWrap>
                <ActionIcon                      
                    onClick={() => {  
                        dispatch(setExternalStorageDateSingleId(externalStorageDate.id))           
                        navigate("update")
                    }}
                >
                    <Icon 
                        icon = {ICONS.EDIT} 
                        size = {16}
                        color="blue"
                    />      
                </ActionIcon>
                <ActionIcon                     
                    onClick={() => {
                        dispatch(setExternalStorageDateSingleId(externalStorageDate.id))
                        navigate("delete")
                    }}
                >
                    <Icon 
                        icon = {ICONS.DELETE} 
                        size = {16}
                        color="red" 
                    />   
                </ActionIcon>
              </Group>
            ),
        },
    ]      
    

    return (       
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={externalStorageDatesForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
        />     
    )
}  