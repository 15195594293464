// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata
} from "../../util/proto"

import { 
    selectAccessToken
} from "../auth/authSlice"

import { 
    AppThunk 
} from "../../app/store"

import {
    CreateItemRequest as createItemRequest, 
    CreateItemResponse as createItemResponse,
    DeleteItemRequest as deleteItemRequest,
    DeleteItemResponse as deleteItemResponse,
    UpdateItemRequest as updateItemRequest,
    UpdateItemResponse as updateItemResponse,
    GetItemRequest as getItemRequest,
    GetItemResponse as getItemResponse,
    ListItemsRequest as listItemsRequest,
    ListItemsResponse as listItemsResponse,
    ListOrganisationItemsRequest as listOrganisationItemsRequest,
    ListOrganisationItemsResponse as listOrganisationItemsResponse,
    ItemAttributes as itemAttributes,
    ItemStorageUnitRelationAttributes as itemStorageUnitRelationAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"


// Define the Types
export type ItemAttributes = itemAttributes.AsObject
type CreateItemResponse = createItemResponse.AsObject
type UpdateItemResponse = updateItemResponse.AsObject
type DeleteItemResponse = deleteItemResponse.AsObject
export type GetItemResponse = getItemResponse.AsObject
export type ItemStorageUnitRelationAttributes = itemStorageUnitRelationAttributes.AsObject
type ListItemsResponse = listItemsResponse.AsObject
type ListOrganisationItemsResponse = listOrganisationItemsResponse.AsObject


type ItemDict = {
    [Key: number]: GetItemResponse
}


// Define which objects the item State have. 
interface ItemState {
    items: ItemDict
    isLoading: boolean
    error?: Error
}


// Define intial state
export const initialState: ItemState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the state
const items = createSlice({
    name: "items",
    initialState,
    reducers: {

        // Fetch all items
        fetchAllItemsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllItemsSuccess: (state, { payload }: PayloadAction<ListItemsResponse>) => {
            state.items = {}
            payload.itemsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllItemsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch all items of one organisation
        fetchAllOrganisationItemsStart: (state) => {
            state.isLoading = true            
            state.error = undefined
        },
        fetchAllOrganisationItemsSuccess: (state, { payload }: PayloadAction<ListOrganisationItemsResponse>) => {
            state.items = {}
            payload.itemsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllOrganisationItemsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch one item
        fetchItemStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchItemSuccess: (state, { payload }: PayloadAction<GetItemResponse>) => {
            state.items[payload.id] = payload
            state.isLoading = false
            state.error = undefined
        },
        fetchItemFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        
        // Create Item
        createItemStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createItemSuccess: (state, { payload }: PayloadAction<CreateItemResponse>) => {
            state.items[payload.item!.id] = payload.item!
            state.isLoading = false
            state.error = undefined
        },
        createItemFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        
        // Update Item
        updateItemStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateItemSuccess: (state, { payload }: PayloadAction<UpdateItemResponse>) => {
            state.items[payload.item!.id] = payload.item!
            state.isLoading = false
            state.error = undefined
        },
        updateItemFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete Item
        deleteItemStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deleteItemSuccess: (state, { payload }: PayloadAction<DeleteItemResponse>) => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deleteItemFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },       
    },
})


// Export Reducer
export default items.reducer


// Define Actions
const {
    fetchAllItemsStart,
    fetchAllItemsSuccess,
    fetchAllItemsFailure,

    fetchAllOrganisationItemsStart,
    fetchAllOrganisationItemsSuccess,
    fetchAllOrganisationItemsFailure,

    fetchItemStart,
    fetchItemSuccess,
    fetchItemFailure,

    createItemStart,
    createItemSuccess,
    createItemFailure,  
        
    updateItemStart,
    updateItemSuccess,
    updateItemFailure,

    deleteItemStart,
    deleteItemSuccess,
    deleteItemFailure,
} = items.actions


// Fetch all items
export const fetchAllItemsMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllItemsStart())
    const token = selectAccessToken(getState())
    const request = new listItemsRequest()
    
    return storeroomClient
        .listItems(request, getAuthMetadata(token))
        .then((response: listItemsResponse) => {
            dispatch(fetchAllItemsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllItemsFailure(err))
        })
}


// Fetch all items of one organisation
export const fetchAllOrganisationItemsMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllOrganisationItemsStart())
    const token = selectAccessToken(getState())
    const request = new listOrganisationItemsRequest()
    request.setOrganisationId(organisationId)

    return storeroomClient
        .listOrganisationItems(request, getAuthMetadata(token))
        .then((response: listOrganisationItemsResponse) => {
            dispatch(fetchAllOrganisationItemsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllOrganisationItemsFailure(err))
        })
}


// Fetch one item
export const fetchItemMessage = (itemId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchItemStart())
    const token = selectAccessToken(getState())
    const request = new getItemRequest()
    request.setId(itemId)

    return storeroomClient
        .getItem(request, getAuthMetadata(token))
        .then((response: getItemResponse) => {
            dispatch(fetchItemSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchItemFailure(err))
        })
}


// Create item
export const createItemMessage = (item: ItemAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createItemStart())
    const token = selectAccessToken(getState())
    
    const storageunitrelation = new Array<itemStorageUnitRelationAttributes>()
    //For Loop over all storageunitrelations
    for (var i in item.storageUnitRelationsList) {
        const currStorageunit = item.storageUnitRelationsList[i].storageUnitId
        const currQuanity = item.storageUnitRelationsList[i].quantity
        
        let storageunitrelationItem = new itemStorageUnitRelationAttributes()
        storageunitrelationItem.setItemId(0)
        storageunitrelationItem.setStorageUnitId(currStorageunit)
        storageunitrelationItem.setQuantity(currQuanity)

        storageunitrelation.push(storageunitrelationItem)
    }    

    
    const itemData = new itemAttributes()
    itemData.setDisplayName(item.displayName)
    itemData.setDescription(item.description)
    itemData.setTagsList(item.tagsList)
    itemData.setOrganisationId(item.organisationId)
    itemData.setExternalRent(item.externalRent)
    itemData.setStorageUnitRelationsList(storageunitrelation)
 
    const request = new createItemRequest()
    request.setItemInfo(itemData)

       

    return storeroomClient
        .createItem(request, getAuthMetadata(token))
        .then((response: updateItemResponse) => {
            dispatch(createItemSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createItemFailure(err))
        })
}


// Update item
export const updateItemMessage = (itemId: number, item: ItemAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateItemStart())
    const token = selectAccessToken(getState())

    const storageunitrelation = new Array<itemStorageUnitRelationAttributes>()
    //For Loop over all storageunitrelations
    for (var i in item.storageUnitRelationsList) {
        const currStorageunit = item.storageUnitRelationsList[i].storageUnitId
        const currQuanity = item.storageUnitRelationsList[i].quantity
        const currItemId = item.storageUnitRelationsList[i].itemId
        
        let storageunitrelationItem = new itemStorageUnitRelationAttributes()
        storageunitrelationItem.setItemId(currItemId)
        storageunitrelationItem.setStorageUnitId(currStorageunit)
        storageunitrelationItem.setQuantity(currQuanity)

        storageunitrelation.push(storageunitrelationItem)
    }    

    
    const itemData = new itemAttributes()
    itemData.setDisplayName(item.displayName)
    itemData.setDescription(item.description)
    itemData.setTagsList(item.tagsList)
    itemData.setOrganisationId(item.organisationId)
    itemData.setExternalRent(item.externalRent)
    itemData.setStorageUnitRelationsList(storageunitrelation)
 
    const request = new updateItemRequest()
    request.setItemInfo(itemData)
    request.setId(itemId)


    return storeroomClient
        .updateItem(request, getAuthMetadata(token))
        .then((response: updateItemResponse) => {
            dispatch(updateItemSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateItemFailure(err))
        })
}


// Delete item
export const deleteItemMessage = (itemId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deleteItemStart())
    const token = selectAccessToken(getState())
    
    const request = new deleteItemRequest()
    request.setId(itemId)
    request.setDeleting(true)

    return storeroomClient
        .deleteItem(request, getAuthMetadata(token))
        .then((response: deleteItemResponse) => {
            dispatch(deleteItemSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deleteItemFailure(err))
        })
}


// Selectors
type ItemSliceRoot = {
    items: ReturnType<typeof items.reducer>
}

export const selectItemsById = (state: ItemSliceRoot) =>
    state.items.items
    
export const selectIsLoadingItems = (state: ItemSliceRoot) => state.items.isLoading
export const selectErrorItems = (state: ItemSliceRoot) => state.items.error