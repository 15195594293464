// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice,
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata
} from "../../util/proto"

import { 
    selectAccessToken 
} from "../auth/authSlice"

import { 
    AppThunk 
} from "../../app/store"

import {
    CreatePlaceRequest as createPlaceRequest, 
    CreatePlaceResponse as createPlaceResponse,
    DeletePlaceRequest as deletePlaceRequest,
    DeletePlaceResponse as deletePlaceResponse,
    UpdatePlaceRequest as updatePlaceRequest,
    UpdatePlaceResponse as updatePlaceResponse,
    GetPlaceRequest as getPlaceRequest,
    GetPlaceResponse as getPlaceResponse,
    ListPlacesRequest as listPlacesRequest,
    ListPlacesResponse as listPlacesResponse,    
    ListOrganisationPlacesRequest as listOrganisationPlacesRequest,
    ListOrganisationPlacesResponse as listOrganisationPlacesResponse,
    PlaceAttributes as placeAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"


//Define the Types
export type PlaceAttributes = placeAttributes.AsObject
type CreatePlaceResponse = createPlaceResponse.AsObject
type UpdatePlaceResponse = updatePlaceResponse.AsObject
type DeletePlaceResponse = deletePlaceResponse.AsObject
export type GetPlaceResponse = getPlaceResponse.AsObject
type ListPlacesResponse = listPlacesResponse.AsObject
type ListOrganisationPlacesResponse = listOrganisationPlacesResponse.AsObject

type PlaceDict = {
    [Key: number]: GetPlaceResponse
}


// Define which objects the place State have. 
interface PlaceState {
    items: PlaceDict
    isLoading: boolean
    error?: Error
}


// Define initial state
const initialState: PlaceState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the State
const places = createSlice({
    name: "places",
    initialState,
    reducers: {  

        // Fetch all places
        fetchAllPlacesStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllPlacesSuccess: (state, { payload }: PayloadAction<ListPlacesResponse>) => {
            state.items = {}
            payload.placesList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllPlacesFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch all places of an organisationb
        fetchAllOrganisationPlacesStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllOrganisationPlacesSuccess: (state, { payload }: PayloadAction<ListOrganisationPlacesResponse>) => {
            state.items = {}
            payload.placesList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllOrganisationPlacesFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetach one place
        fetchPlaceStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchPlaceSuccess: (state, { payload }: PayloadAction<GetPlaceResponse>) => {
            state.items[payload.id] = payload
            state.isLoading = false
            state.error = undefined
        },
        fetchPlaceFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Create Place
        createPlaceStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createPlaceSuccess: (state, { payload }: PayloadAction<CreatePlaceResponse>) => {
            state.items[payload.place!.id] = payload.place!
            state.isLoading = false
            state.error = undefined
        },
        createPlaceFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        
        // Update Place
        updatePlaceStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updatePlaceSuccess: (state, { payload }: PayloadAction<UpdatePlaceResponse>) => {
            state.items[payload.place!.id] = payload.place!
            state.isLoading = false
            state.error = undefined
        },
        updatePlaceFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete Place
        deletePlaceStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deletePlaceSuccess: (state, { payload }: PayloadAction<DeletePlaceResponse>) => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deletePlaceFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
    },
})


// Export Reducers
export default places.reducer


// Define Actions
const {
    fetchAllPlacesStart,
    fetchAllPlacesSuccess,
    fetchAllPlacesFailure,

    fetchAllOrganisationPlacesStart,
    fetchAllOrganisationPlacesSuccess,
    fetchAllOrganisationPlacesFailure,

    fetchPlaceStart,
    fetchPlaceSuccess,
    fetchPlaceFailure,

    createPlaceStart,
    createPlaceSuccess,
    createPlaceFailure,    
      
    updatePlaceStart,
    updatePlaceSuccess,
    updatePlaceFailure,

    deletePlaceStart,
    deletePlaceSuccess,
    deletePlaceFailure,
} = places.actions


// Fetch all places
export const fetchAllPlacesMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllPlacesStart())
    const token = selectAccessToken(getState())
    const request = new listPlacesRequest()

    return storeroomClient
        .listPlaces(request, getAuthMetadata(token))
        .then((response: listPlacesResponse) => {
            dispatch(
                fetchAllPlacesSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllPlacesFailure(err))
        })
}


// Fetch all places of an organisation
export const fetchAllOrganisationPlacesMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllOrganisationPlacesStart())
    const token = selectAccessToken(getState())
    const request = new listOrganisationPlacesRequest()
    request.setOrganisationId(organisationId)

    return storeroomClient
        .listOrganisationPlaces(request, getAuthMetadata(token))
        .then((response: listOrganisationPlacesResponse) => {
            dispatch(fetchAllOrganisationPlacesSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllOrganisationPlacesFailure(err))
        })
}


// Fetch one place
export const fetchPlaceMessage = (placeId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchPlaceStart())
    const token = selectAccessToken(getState())
    const request = new getPlaceRequest()
    request.setId(placeId)

    return storeroomClient
        .getPlace(request, getAuthMetadata(token))
        .then((response: getPlaceResponse) => {
            dispatch(fetchPlaceSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchPlaceFailure(err))
        })
}


// Create place
export const createPlaceMessage = (place: PlaceAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createPlaceStart())
    const token = selectAccessToken(getState())
    
    const placeData = new placeAttributes()
    placeData.setRoom(place.room)
    placeData.setRack(place.rack)
    placeData.setDescription(place.description)
    placeData.setOrganisationId(place.organisationId)
 
    const request = new createPlaceRequest()
    request.setPlaceInfo(placeData)
     
    return storeroomClient
        .createPlace(request, getAuthMetadata(token))
        .then((response: updatePlaceResponse) => {
            dispatch(createPlaceSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createPlaceFailure(err))
        })
}


// Update place
export const updatePlaceMessage = (placeId: number, place: GetPlaceResponse): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updatePlaceStart())
    const token = selectAccessToken(getState()) 
    
    const placeData = new placeAttributes()
    placeData.setRoom(place.placeInfo!.room)
    placeData.setRack(place.placeInfo!.rack)
    placeData.setDescription(place.placeInfo!.description)
    placeData.setOrganisationId(place.placeInfo!.organisationId) 

    const request = new updatePlaceRequest()
    request.setId(placeId)
    request.setPlaceInfo(placeData)

    return storeroomClient
        .updatePlace(request, getAuthMetadata(token))
        .then((response: updatePlaceResponse) => {
            dispatch(updatePlaceSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updatePlaceFailure(err))
        })
}


// Delete place
export const deletePlaceMessage = (placeId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deletePlaceStart())
    const token = selectAccessToken(getState())
    
    const request = new deletePlaceRequest()
    request.setId(placeId)

    return storeroomClient
        .deletePlace(request, getAuthMetadata(token))
        .then((response: deletePlaceResponse) => {
            dispatch(deletePlaceSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deletePlaceFailure(err))
        })
}


// Selectors
type PlaceSliceRoot = {
    places: ReturnType<typeof places.reducer>
}

export const selectPlacesById = (state: PlaceSliceRoot) =>
    state.places.items
    
export const selectIsLoadingPlaces = (state: PlaceSliceRoot) => state.places.isLoading
export const selectErrorPlaces = (state: PlaceSliceRoot) => state.places.error