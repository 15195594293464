// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    OrganisationsPage 
} from "../../pages/organisations/OrganisationsPage"

import { 
    UpdateOrganisationPage 
} from "../../pages/organisations/UpdateOrganisationPage"


export const OrganisationsRoutesOrganisations = () => {
    return (
        <Routes>
            <Route
                index
                element={<OrganisationsPage/>}                                                                    
            />
            <Route
                path="update"
                element={<UpdateOrganisationPage/>}                            
            />              
        </Routes>
    )
}  
