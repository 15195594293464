// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define which objects the reservation State have. 
interface reservationSingleIdState {
    reservationId: number,
}


// Define initial state
const initialState: reservationSingleIdState = {
    reservationId: 0,
}


// Define state
const reservationSingleId = createSlice({
    name: "reservationSingleId",
    initialState,
    reducers: {
        setReservationSingleId(state, {payload}: PayloadAction<number>) {
            state.reservationId = payload
        },
    },
})


// Export Reducers
export default reservationSingleId.reducer


// Define Actions
const {
    setReservationSingleId,
} = reservationSingleId.actions


// Change handler
export {
    setReservationSingleId, 
}


// Selectors
type SingleReservationIdSliceRoot = {
    reservationSingleId: ReturnType<typeof reservationSingleId.reducer>
}

export const selectReservationSingleId = (state: SingleReservationIdSliceRoot) =>
    state.reservationSingleId
