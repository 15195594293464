// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const SelectoptionsAllOrganisationsNameNew = () => {
    const { t } = useTranslation() 

    const organisations = useAppSelector(selectOrganisationsById)
    
    //initialise options
    const options= [
        {value: 0, label: t("Storeroom.selectOption"), disabled: true },
    ] as any

    //For Loop over all organisations
    for (var i in organisations) {
        const currValue = organisations[i].id
        const currName = organisations[i].organisationInfo?.shortName

        options.push(
            {label: currName, value: currValue }
        )
    }        

    return(
        options
    )        
}