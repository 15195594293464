// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"

import { 
    useNavigate
} from "react-router-dom"

import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    GetPlaceResponse 
} from "../../features/place/placesSlice"

import { 
    setPlaceSingleId
} from "../../features/place/placeSingleIdSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"

import { 
    OrganisationShortNameFormatter 
} from "../../layouts/formatter/OrganisationShortNameFormatter"

import { 
    useEditorStatus
} from "../../hooks/useEditorStatus"

import { 
    useIsOnAdminPage 
} from '../../hooks/useIsOnAdminPage'

import { 
    useActiveOrganisationId 
} from '../../hooks/useActiveOrganisationId'


// Define interfaces

interface PlaceTableProps {
    placesForTable: GetPlaceResponse[],
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const PlaceTable: React.FC<PlaceTableProps> = ({
    placesForTable
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(placesForTable.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })
   

    useEffect(() => {
        setPage(1)
    }, [pageSize])  
  
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(placesForTable, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, placesForTable])


    const activeOrganisationId = useActiveOrganisationId()
    const isEditor = useEditorStatus(activeOrganisationId)
    const isOnAdminPage = useIsOnAdminPage()
          

    const actionItems = (placeId: number) => {
        if (isEditor || isOnAdminPage) {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setPlaceSingleId(placeId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />                  
                    </ActionIcon>
                    <ActionIcon                      
                        onClick={() => {  
                            dispatch(setPlaceSingleId(placeId))           
                            navigate("update")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.EDIT} 
                            size = {16}
                            color="blue"
                        />      
                    </ActionIcon>
                    <ActionIcon                     
                        onClick={() => {
                            dispatch(setPlaceSingleId(placeId))
                            navigate("delete")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.DELETE} 
                            size = {16}
                            color="red" 
                        />   
                    </ActionIcon>
                </Group>

            )
        } else {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setPlaceSingleId(placeId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />                  
                    </ActionIcon>
                </Group>

            )
        }
    }


    const columns = [
        {
            accessor: "id",
            title: t("Places.id"),
            sortable: true,
            hidden: !isOnAdminPage
        },
        {
            accessor: "placeInfo.room",
            title: t("Places.room"),
            sortable: true,
        },
        {
            accessor: "placeInfo.rack",
            title: t("Places.rack"),
            sortable: true,
        },
        {
            accessor: "placeInfo.description",
            title: t("Places.description"),
            sortable: true,
        },
        {
            accessor: "placeInfo.organisationId",
            title: `${t("Storeroom.organisation")}`,
            sortable: true,
            render: (place: GetPlaceResponse) => OrganisationShortNameFormatter(place.placeInfo!.organisationId),
            hidden: !isOnAdminPage
        },
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            render: (place: GetPlaceResponse) => actionItems(place.id)
        },
    ]


    return (     
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={placesForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
        />       
    )
}