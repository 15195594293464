// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
// import { 
//     useTranslation 
// } from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ReservationEditForm 
} from "../../components/reservation/forms/ReservationEditForm"


/**
 * The UpdateReservationsPage component renders the UpdateReservations page of the application.
 */
export const UpdateReservationsPage = () => {
    // const { t } = useTranslation()

    return(
        <Card>        
            <ReservationEditForm 
                // title={t("Organisations.updateText")}
                // organisationId = {editOrganisationId}
                // initialOrganisationdata = {organisations[editOrganisationId].organisationInfo!}
                // lastchangeuser = {organisations[editOrganisationId].updateUser}
                // lastchangedate = {organisations[editOrganisationId].updateTime!}
                />
        </Card>            
    )
}