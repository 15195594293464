// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice,
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    getAuthMetadata 
} from "../../util/proto"

import { 
    selectAccessToken 
} from "../auth/authSlice"

import {
    AppThunk 
} from "../../app/store"

import {
    CreateTagRequest as createTagRequest, 
    CreateTagResponse as createTagResponse,
    DeleteTagRequest as deleteTagRequest,
    DeleteTagResponse as deleteTagResponse,
    UpdateTagRequest as updateTagRequest,
    UpdateTagResponse as updateTagResponse,
    ListTagsRequest as listTagsRequest,
    ListTagsResponse as listTagsResponse,
    GetTagResponse as getTagResponse,
    TagAttributes as tagAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"


//Define the Types
export type TagAttributes = tagAttributes.AsObject
type CreateTagResponse = createTagResponse.AsObject
type UpdateTagResponse = updateTagResponse.AsObject
type DeleteTagResponse = deleteTagResponse.AsObject
export type GetTagResponse = getTagResponse.AsObject
type ListTagsResponse = listTagsResponse.AsObject

export type TagDict = {
    [Key: number]: GetTagResponse
}


// Define which objects the tag State have. 
interface TagState {
    items: TagDict
    isLoading: boolean
    error?: Error
}


//Define inital state
export const initialState: TagState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the State
const tags = createSlice({
    name: "tags",
    initialState,
    reducers: {  

        // Fetch all tags
        fetchAllTagsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllTagsSuccess: (state, { payload }: PayloadAction<ListTagsResponse>) => {
            state.items = {}
            payload.tagsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllTagsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Create Tag
        createTagStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createTagSuccess: (state, { payload }: PayloadAction<CreateTagResponse>) => {
            state.items[payload.tag!.id] = payload.tag!
            state.isLoading = false
            state.error = undefined
        },
        createTagFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        
        // Update Tag
        updateTagStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateTagSuccess: (state, { payload }: PayloadAction<UpdateTagResponse>) => {
            state.items[payload.tag!.id] = payload.tag!
            state.isLoading = false
            state.error = undefined
        },
        updateTagFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete Tag
        deleteTagStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deleteTagSuccess: (state, { payload }: PayloadAction<DeleteTagResponse>) => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deleteTagFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },        
    },
})


// Export Reducer
export default tags.reducer


// Define Actions
const {
    fetchAllTagsStart,
    fetchAllTagsSuccess,
    fetchAllTagsFailure,

    createTagStart,
    createTagSuccess,
    createTagFailure,    
        
    updateTagStart,
    updateTagSuccess,
    updateTagFailure,

    deleteTagStart,
    deleteTagSuccess,
    deleteTagFailure,    
} = tags.actions


// Fetch all Tags
export const fetchAllTagsMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllTagsStart())
    const token = selectAccessToken(getState())
    const request = new listTagsRequest()

    return storeroomClient
        .listTags(request, getAuthMetadata(token))
        .then((response: listTagsResponse) => {
            dispatch(fetchAllTagsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllTagsFailure(err))
        })
}


// Create tag
export const createTagMessage = (tag: TagAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createTagStart())
    const token = selectAccessToken(getState())
    
    const tagData = new tagAttributes()
    tagData.setTagName(tag.tagName)

    const request = new createTagRequest()
    request.setTagInfo(tagData)
     
    return storeroomClient
        .createTag(request, getAuthMetadata(token))
        .then((response: createTagResponse) => {
            dispatch(createTagSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createTagFailure(err))
        })
}


// Update tag
export const updateTagMessage = (tagId: number, tag: GetTagResponse): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateTagStart())
    const token = selectAccessToken(getState())

    const tagData = new tagAttributes()
    tagData.setTagName(tag.tagName)
  

    const request = new updateTagRequest()
    request.setId(tagId)
    request.setTagInfo(tagData)

    return storeroomClient
        .updateTag(request, getAuthMetadata(token))
        .then((response: updateTagResponse) => {
            dispatch(updateTagSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateTagFailure(err))
        })
}


// Delete tag
export const deleteTagMessage = (tagId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deleteTagStart())
    const token = selectAccessToken(getState())
    
    const request = new deleteTagRequest()
    request.setId(tagId)

    return storeroomClient
        .deleteTag(request, getAuthMetadata(token))
        .then((response: deleteTagResponse) => {
            dispatch(deleteTagSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deleteTagFailure(err))
        })
}


// Selectors
type TagSliceRoot = {
    tags: ReturnType<typeof tags.reducer>
}

export const selectTagsById = (state: TagSliceRoot) =>
    state.tags.items
    
export const selectIsLoadingTags = (state: TagSliceRoot) => state.tags.isLoading
export const selectErrorTags = (state: TagSliceRoot) => state.tags.error