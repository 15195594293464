// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define which objects the externalStorage State have. 
interface externalStorageSingleIdState {
    externalStorageId: number,
}


// Define initial state
const initialState: externalStorageSingleIdState = {
    externalStorageId: 0,
}


// Define state
const externalStorageSingleId = createSlice({
    name: "externalStorageSingleId",
    initialState,
    reducers: {
        setExternalStorageSingleId(state, {payload}: PayloadAction<number>) {
            state.externalStorageId = payload
        },
    },
})


// Export Reducers
export default externalStorageSingleId.reducer


// Define Actions
const {
    setExternalStorageSingleId,
} = externalStorageSingleId.actions


// Change handler
export {
    setExternalStorageSingleId, 
}


// Selectors
type SingleExternalStorageIdSliceRoot = {
    externalStorageSingleId: ReturnType<typeof externalStorageSingleId.reducer>
}

export const selectExternalStorageSingleId = (state: SingleExternalStorageIdSliceRoot) =>
    state.externalStorageSingleId
