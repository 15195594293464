// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Button
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../../hooks/useAppSelector"

import { 
    selectItemsById
} from "../../../features/item/itemsSlice"

import {
    selectItemSingleId, 
    setItemSingleId,
} from "../../../features/item/itemSingleIdSlice"

import {
    useAppDispatch 
} from "../../../hooks/useAppDispatch"


export const NextEntryItemButton = () => {  
    const { t } = useTranslation()    
    const dispatch = useAppDispatch()
    
    const items = useAppSelector(selectItemsById)
    const editItemId = useAppSelector(selectItemSingleId)


    //initialise options
    const itemIds = [] as number[]

    //For Loop over all organisations
    for (var i in items) {
        const currValue = items[i].id
       
        itemIds.push(currValue)
    }        
   
    const lengthArr = itemIds.length
    const currIndex = itemIds.indexOf(editItemId)


    const onChangeForward = () => {
       return(
            dispatch(setItemSingleId(itemIds[currIndex+1]))
        )
    }


    const isDisabledForward = () => {
        if (itemIds[lengthArr-1] === editItemId) {
            return(
                true
            )
        } else {
            return(
                false
            )
        }    
    }

    
    return (
        <Button 
            onClick={() => {onChangeForward()}}
            disabled = {isDisabledForward()}
            fullWidth
            variant="outline"
        >
            {t("Storeroom.nextEntryButton")}
        </Button>
    )
} 