// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    ExternalStorageDateTable
} from "../../components/externalStorageDates/ExternalStorageDateTable"

import {
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    GetExternalStorageDateAttributes,
    selectExternalStorageDatesById,
    fetchAllExternalStorageDatesMessage,
    selectIsLoadingExternalStorageDates,
    selectErrorExternalStorageDates
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


/**
 * The AdminExternalStorageDatesPage component renders the AdminExternalStorageDatesPage page of the application.
 */
export const AdminExternalStorageDatesPage = () => {
    const dispatch = useAppDispatch()    
    const { t } = useTranslation()
   
    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    const isLoadingExternalStorageDates = useAppSelector(selectIsLoadingExternalStorageDates)
    const isErrorExternalStorageDates = useAppSelector(selectErrorExternalStorageDates)


    useEffect(() => {
        dispatch(fetchAllExternalStorageDatesMessage())
    }, [dispatch])

       
    //Loading Screen
    if (isLoadingExternalStorageDates) {
        return <LoadScreen />
    }


    //Error Screen
    if (isErrorExternalStorageDates) {
        return <ErrorScreen 
            customText={isErrorExternalStorageDates?.message} 
        />       
    } 

    const externalStorageDatesFuture = new Array<GetExternalStorageDateAttributes>()
    const externalStorageDatesArchive = new Array<GetExternalStorageDateAttributes>()
 
    if (externalStorageDates !== undefined) {
        for (var i in externalStorageDates) {
            const curStartTime = externalStorageDates[i].date!.seconds
            const curTime = new Date().getTime() / 1000

            if (curStartTime > curTime) {
                externalStorageDatesFuture.push(externalStorageDates[i])
            } else {
                externalStorageDatesArchive.push(externalStorageDates[i])
            }
        }
    }
        
    
    return(      
        <Fragment>                      
            <CreateBar/>        
            <h3>{t("ExternalStorageDates.futureTransports")}</h3>
            <ExternalStorageDateTable 
                externalStorageDatesForTable = {Object.values(externalStorageDatesFuture)}
                isFuture = {true}
            />
            <hr/> 
            <h3>{t("ExternalStorageDates.archiveTransports")}</h3>
            <ExternalStorageDateTable 
                externalStorageDatesForTable={Object.values(externalStorageDatesArchive)}
                isArchive = {true}
            />
        </Fragment> 
    )      
}