// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"

import { 
    useTranslation
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    timeObjToMomentTime
} from "../../util/proto"

import { 
    Timestamp
 } from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    SubstituteTimestamp
} from "../../components/general/SubstituteTimestamp"


// Define interfaces
interface DateUserProps {
    lastchangeuser: string,
    lastchangedate: Timestamp.AsObject,
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const ChangeDateUserInfo = (props: DateUserProps) => {
    const { t } = useTranslation()

    let changeDate = timeObjToMomentTime((props.lastchangedate === undefined) ? SubstituteTimestamp()  : props.lastchangedate)
   
    //Lastchange Date and User Information
    return (
        <Fragment>
            <div className="row">
                <div className="position-relativ col-lg-3">
                    <p>
                        {t("Storeroom.lastChangeDate")}: <br /> 
                        {t("Storeroom.lastChangeUser")}:
                    </p>
                </div>
                <div className="position-relativ col-lg-7">
                    <p>
                        {changeDate.format("DD.MM.YYYY HH:mm")} <br /> 
                        {(props.lastchangeuser === undefined) ? "" : props.lastchangeuser}
                    </p>
                </div>
            </div>
        </Fragment>
    )
}