// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import * as Yup from "yup"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The Page component renders the admin page of the application.
 */
export const PlaceValidationSchema = Yup.object().shape({
    room: Yup.string()
      .required('Please input room'),
    rack: Yup.string()
      .required('Please input rack'),
    description: Yup.string()
        .required('Please input logoPath'),    
    }
)