// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect 
} from "react"

import {
    Card, Group,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    GoToUpdatePageButton 
} from "../../components/buttons/goTo/GoToUpdatePageButton"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    setOrganisationSingleId, 
} from "../../features/organisation/organisationSingleIdSlice"

import { 
    useEditorStatus
} from "../../hooks/useEditorStatus"

import { 
    useActiveOrganisationId
} from "../../hooks/useActiveOrganisationId"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import {
    OrganisationViewLayout 
} from "../../layouts/views/OrganisationViewLayout"


/**
 * The OrganisationsPage component renders the Organisation page of the application.
 */
export const OrganisationsPage = () => {
    const dispatch = useAppDispatch()

    const organisations = useAppSelector(selectOrganisationsById)
    const activeOrganisationId = useActiveOrganisationId()
    const isEditor = useEditorStatus(activeOrganisationId)

    useEffect(() => {
        dispatch(setOrganisationSingleId(activeOrganisationId))
    }, [dispatch, activeOrganisationId])       
    

    //Update Button
    const updateButton = () => {        
        if (isEditor) {
            return (
                <Group 
                    position="right"
                    mt="md"
                >
                    <GoToUpdatePageButton/>
                </Group>   
            ) 
        }
    }


    // OrganisationContent
    const OrganisationContent = () => {
        if (organisations[activeOrganisationId] !== undefined) {
            return (
                <Card>     
                    {updateButton()}
                    <OrganisationViewLayout 
                        organisationId = {activeOrganisationId}
                        organisationInfo = {organisations[activeOrganisationId].organisationInfo!}
                        updateUser = {organisations[activeOrganisationId].updateUser}
                        updateTime = {organisations[activeOrganisationId].updateTime!}
                    />    
                </Card> 
            ) 
        }        
    }

    //Return Organisation Page
    return(  
        <Fragment>            
            {OrganisationContent()} 
        </Fragment>          
    )
}