// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    selectExternalStorageDatesById
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    timeObjToMomentTime 
} from "../../util/proto"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const SelectoptionExternalStorageDates = () => {
    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    

    //initialise options
    const options= [
        {label: '', value: 0},
    ] as any
    options.pop()

    //For Loop over all organisations
    for (var i in externalStorageDates) {
        const currValue = externalStorageDates[i].id

        const currDate = externalStorageDates[i].date
        const currName = timeObjToMomentTime(currDate).format("DD.MM.YYYY")


        if (currDate!.seconds > new Date().getTime() / 1000) {           
            options.push(
                {label: currName, value: currValue, }
            )
        }

    }
    

    return(
        options
    )
}