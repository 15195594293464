// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card 
} from "@mantine/core"
   

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    PlaceViewLayout 
} from "../../layouts/views/PlaceViewLayout"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectPlacesById
} from "../../features/place/placesSlice"

import {
    selectPlaceSingleId,
} from "../../features/place/placeSingleIdSlice"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar 
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    LastEntryPlaceButton
} from "../../components/buttons/lastEntry/LastEntryPlaceButton"

import { 
    GoFromToUpdatePageButton 
} from "../../components/buttons/goFromTo/GoFromToUpdatePageButton"

import {
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

import { 
    GoToTableButton
} from "../../components/buttons/goTo/GoToTableButton"

import { 
    NextEntryPlaceButton 
} from "../../components/buttons/nextEntry/NextEntryPlaceButton"


/**
* The ViewPlacePage component renders the ViewPlacePage page of the application.
*/
export const ViewPlacePage = () => {   
    const { t } = useTranslation() 
    
    const places = useAppSelector(selectPlacesById)
    const editPlaceId = useAppSelector(selectPlaceSingleId)
     

    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editPlaceId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }
 
    return (
        <Card>    
            <h1>{t("Places.viewText")}</h1>  
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryPlaceButton/>}
                navigationButton1 = {<GoFromToUpdatePageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryPlaceButton/>}
            /> 
            <PlaceViewLayout 
                placeId = {editPlaceId}
                initialPlaceInfo = {places[editPlaceId].placeInfo!}
                updateUser = {places[editPlaceId].updateUser}
                updateTime = {places[editPlaceId].updateTime!}
            />
        </Card>                   
    )   
}