// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment, 
    useEffect
} from "react"

import { 
    useNavigate 
} from "react-router-dom"

import { 
    useTranslation
} from "react-i18next"

import { 
    ActionIcon,
    Grid, 
    Space,
} from "@mantine/core"


// Imports from vseth-canine-ui
import { 
    ICONS, 
    Icon 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    fetchStorageUnitItemsMessage,
    selectAllStorageUnitItemsById,
    selectErrorStorageUnitItems,
    selectIsLoadingStorageUnitItems 
} from "../../features/storageunit/storageUnitItemsSlices"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import {
    DisabledTextInput 
} from "./DisabledTextInput"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    setItemSingleId 
} from "../../features/item/itemSingleIdSlice"

import { 
    timeObjToMomentTime 
} from "../../util/proto"



// Define interfaces
interface StorageUnitItemsListProps {
    storageUnitId: number,
}


/**
 * The DeleteStorageUnitsPage component renders the DeleteStorageUnits page of the application.
 */
export const StorageUnitItemsList = (props: StorageUnitItemsListProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const storageUnitItems = useAppSelector(selectAllStorageUnitItemsById)
    const isLoadingStorageUnitItems = useAppSelector(selectIsLoadingStorageUnitItems)
    const errorStorageUnitItems = useAppSelector(selectErrorStorageUnitItems)  
  

    useEffect(() => {
        if (props.storageUnitId !== 0) {
            dispatch(fetchStorageUnitItemsMessage(props.storageUnitId))
        }
    }, [dispatch, props.storageUnitId])


       //Loading Screen
    if (isLoadingStorageUnitItems) {
        return <LoadScreen />
    }


    //Error Screen
    if (errorStorageUnitItems) {
        return <ErrorScreen 
            customText={errorStorageUnitItems?.message} 
        />
    } 

    
    //initialise options
    const storageUnitItemsList = []
    var currentTimeInSeconds = Math.floor(Date.now()/1000)
   
  
    if (storageUnitItems !== undefined) {

        for (let i in storageUnitItems) {
            const currItemList = storageUnitItems[i].itemsList

            if (currItemList !== undefined) {   
                for (let j in currItemList) {
                    const currItemId = currItemList[j].id
                    const currDisplayname = currItemList[j].itemInfo!.displayName   
                    const currStorageUnitRelationsList = currItemList[j].itemInfo!.storageUnitRelationsList
                    const reservationList = currItemList[j].reservationsList
                    let currQuanity = 0
                    
                    for (let ii in currStorageUnitRelationsList) {
                        if (currStorageUnitRelationsList[ii].storageUnitId === props.storageUnitId) {
                            currQuanity = currStorageUnitRelationsList[ii].quantity
                        }
                    }
                    
                    let reservationText = ""

                    for (let jj in reservationList) {
                        if (reservationList[jj].startTime! !== undefined && reservationList[jj].endTime! !== undefined) {
                            if (reservationList[jj].startTime!.seconds > currentTimeInSeconds) {
                                let reservationStartDate = timeObjToMomentTime(reservationList[jj].startTime!)
                                let reservationEndDate = timeObjToMomentTime(reservationList[jj].endTime!)
                                reservationText += reservationStartDate.format("DD.MM.YYYY HH:mm") + "-" + reservationEndDate.format("DD.MM.YYYY HH:mm") + ", " 
                            }     

                        }    
                    }

                            
                    storageUnitItemsList.push(
                        <Grid
                            key = {j}
                        >
                            <Grid.Col 
                                md={3} 
                                lg={3}
                            >
                                <DisabledTextInput
                                    label = {t("Storeroom.item")}
                                    value = {currDisplayname}
                                />
                            </Grid.Col>   
                            <Grid.Col 
                                md={3} 
                                lg={3}
                            >
                                <DisabledTextInput
                                    label = {t("Items.quantity")}
                                    value = {String(currQuanity)}                
                                />
                            </Grid.Col>  
                            <Grid.Col 
                                md={3} 
                                lg={3}
                            >
                                <DisabledTextInput
                                    label = {t("Items.reservationList")}
                                    value = {reservationText}                
                                />
                            </Grid.Col>    
                            <Grid.Col 
                                md={3} 
                                lg={3}
                            >
                                <ActionIcon 
                                    onClick={() => {
                                        dispatch(setItemSingleId(currItemId))
                                        navigate("../../items/view")
                                    }}
                                >
                                    <Icon 
                                        icon = {ICONS.VIEW} 
                                        size = {16}                        
                                        color="green" 
                                    />                  
                                </ActionIcon>
                            </Grid.Col>   
                        </Grid>
                    )
                }
            }
        }
    }

           

    return(
        <Fragment> 
            <Space 
                h="xl" 
            />
            {t("Storeroom.items")}   
            {storageUnitItemsList}  
            <Space 
                h="xl" 
            />
        </Fragment>    
    )
}