// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Grid, 
    Group, 
    Box
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ItemAttributes 
} from "../../features/item/itemsSlice"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import { 
    DisabledTextInput 
} from "../formatter/DisabledTextInput"

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import {
    ChangeDateUserInfoNew 
} from "../formatter/ChangeDateUserInfoNew"

import { 
    ExternalRentFormatter 
} from "../formatter/ExternalRentFormatter"

import { 
    TagsFormatter 
} from "../formatter/TagsFormatter"


interface ItemViewProps {
    itemId: number,
    itemInfo: ItemAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
    deleted: boolean
}

/**
 * The Page component renders the admin page of the application.
 */
export const ItemViewLayout = (props: ItemViewProps) => {
    const { t } = useTranslation()
        
    const organisations = useAppSelector(selectOrganisationsById)
    const storageUnits = useAppSelector(selectStorageUnitsById) 

    const organisationIdValue = props.itemInfo.organisationId
    const organisationShortName  = (organisations[organisationIdValue] === undefined) ? "" : organisations[organisationIdValue].organisationInfo!.shortName

    const tagsListValue = props.itemInfo.tagsList

    const externalRentValue = props.itemInfo.externalRent

    const storageUnitRelationsListValue = props.itemInfo.storageUnitRelationsList

    const storageUnitRelations = storageUnitRelationsListValue.map((storageUnitRelation, index) => (
        <Group key={storageUnitRelation.storageUnitId} mt="xs">
            <DisabledTextInput
                    label = {t("Storeroom.storageUnit")}
                    value = {storageUnits[storageUnitRelation.storageUnitId].storageUnitInfo!.displayName}
            />  
            <DisabledTextInput
                    label = {t("Items.quantity")}
                    value = {String(storageUnitRelation.quantity)}
            />   
        </Group>
      ))


    return(
        <Box 
            mx="auto"
        >
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Items.displayName")}
                    value = {props.itemInfo.displayName}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Items.description")}
                    value = {props.itemInfo.description}
                />               
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Storeroom.tags")}
                    value = {TagsFormatter(tagsListValue)}
                />  
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Storeroom.organisation")} 
                    value = {organisationShortName}                
                />
            </Grid.Col>   
            <Grid.Col 
                md={12} 
                lg={12}
            >
                <DisabledTextInput
                    label = {t("Items.externalRent")}
                    value = {ExternalRentFormatter(externalRentValue)}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={12}
            >
                {storageUnitRelations}
            </Grid.Col>              
        </Grid>
        <ChangeDateUserInfoNew
            lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
            lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
        />   
        </Box>
    )
} 