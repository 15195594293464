// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata 
} from "../../util/proto"

import { 
    selectAccessToken 
} from "../auth/authSlice"

import { 
    AppThunk 
} from "../../app/store"

import {
    CreateOrganisationRequest as createOrganisationRequest, 
    CreateOrganisationResponse as createOrganisationResponse,
    DeleteOrganisationRequest as deleteOrganisationRequest,
    DeleteOrganisationResponse as deleteOrganisationResponse,
    UpdateOrganisationRequest as updateOrganisationRequest,
    UpdateOrganisationResponse as updateOrganisationResponse,
    GetOrganisationRequest as getOrganisationRequest,
    GetOrganisationResponse as getOrganisationResponse,
    ListOrganisationsRequest as listOrganisationsRequest,
    ListOrganisationsResponse as listOrganisationsResponse,
    OrganisationAttributes as organisationAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"


// Define the Types
export type OrganisationAttributes = organisationAttributes.AsObject
type CreateOrganisationResponse = createOrganisationResponse.AsObject
type UpdateOrganisationResponse = updateOrganisationResponse.AsObject
type DeleteOrganisationResponse = deleteOrganisationResponse.AsObject
export type GetOrganisationResponse = getOrganisationResponse.AsObject
export type ListOrganisationsResponse = listOrganisationsResponse.AsObject

type OrganisationDict = {
    [Key: number]: GetOrganisationResponse
}


// Define which objects the organisation State have. 
interface OrganisationState {
    items: OrganisationDict
    isLoading: boolean
    error?: Error
}


// Define intital state
const initialState: OrganisationState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the State
const organisations = createSlice({
    name: "organisations",
    initialState,
    reducers: { 

        // Fetch all organisations
        fetchAllOrganisationsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllOrganisationsSuccess: (state, { payload }: PayloadAction<ListOrganisationsResponse>) => {
            state.items = {}
            payload.organisationsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllOrganisationsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch one organisation
        fetchOrganisationStart: (state) => {
            state.isLoading = true            
            state.error = undefined
        },
        fetchOrganisationSuccess: (state, { payload }: PayloadAction<GetOrganisationResponse>) => {
            state.items[payload.id] = payload
            state.isLoading = false 
            state.error = undefined           
        },
        fetchOrganisationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        
        // Create Organisation
        createOrganisationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createOrganisationSuccess: (state, { payload }: PayloadAction<CreateOrganisationResponse>) => {
            state.items[payload.organisation!.id] = payload.organisation!
            state.isLoading = false
            state.error = undefined
        },
        createOrganisationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Update Organisation       
        updateOrganisationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateOrganisationSuccess: (state, { payload }: PayloadAction<UpdateOrganisationResponse>) => {
            state.items[payload.organisation!.id] = payload.organisation!
            state.isLoading = false
            state.error = undefined
        },
        updateOrganisationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete Organisation
        deleteOrganisationStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deleteOrganisationSuccess: (state, { payload }: PayloadAction<DeleteOrganisationResponse>) => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deleteOrganisationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
    },
})


// Export Reducers
export default organisations.reducer


// Define Actions
const {
    fetchAllOrganisationsStart,
    fetchAllOrganisationsSuccess,
    fetchAllOrganisationsFailure,
    
    fetchOrganisationStart,
    fetchOrganisationSuccess,
    fetchOrganisationFailure,

    createOrganisationStart,
    createOrganisationSuccess,
    createOrganisationFailure,    
        
    updateOrganisationStart,
    updateOrganisationSuccess,
    updateOrganisationFailure,

    deleteOrganisationStart,
    deleteOrganisationSuccess,
    deleteOrganisationFailure,
} = organisations.actions


// Fetch all organisations
export const fetchAllOrganisationsMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllOrganisationsStart())
    const token = selectAccessToken(getState())
    const request = new listOrganisationsRequest()

    return storeroomClient
        .listOrganisations(request, getAuthMetadata(token))
        .then((response: listOrganisationsResponse) => {
            dispatch(fetchAllOrganisationsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllOrganisationsFailure(err))
        })
}


// Fetch one organisation
export const fetchOrganisationMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchOrganisationStart())
    const token = selectAccessToken(getState())
    const request = new getOrganisationRequest()
    request.setId(organisationId)

    return storeroomClient
        .getOrganisation(request, getAuthMetadata(token))
        .then((response: getOrganisationResponse) => {
            dispatch(fetchOrganisationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchOrganisationFailure(err))
        })
}


// Create organisation
export const createOrganisationMessage = (organisation: OrganisationAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createOrganisationStart())
    const token = selectAccessToken(getState())
    
    const organisationData = new organisationAttributes()
    organisationData.setShortName(organisation.shortName)
    organisationData.setTitle(organisation.title)
    organisationData.setLogoPath(organisation.logoPath)
    organisationData.setContactMail(organisation.contactMail)
    organisationData.setContactPhone(organisation.contactPhone)
    organisationData.setContactAddress(organisation.contactAddress)
    organisationData.setEditRole(organisation.editRole)
    organisationData.setViewRole(organisation.viewRole) 

    

    const request = new createOrganisationRequest()
    request.setOrganisationInfo(organisationData)
     
    return storeroomClient
        .createOrganisation(request, getAuthMetadata(token))
        .then((response: createOrganisationResponse) => {
            dispatch(createOrganisationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createOrganisationFailure(err))
        })
}


// Update organisation
export const updateOrganisationMessage = (organisationId: number, organisation: GetOrganisationResponse): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateOrganisationStart())
    const token = selectAccessToken(getState())
   
    const organisationData = new organisationAttributes()
    organisationData.setShortName(organisation.organisationInfo!.shortName)
    organisationData.setTitle(organisation.organisationInfo!.title)
    organisationData.setLogoPath(organisation.organisationInfo!.logoPath)
    organisationData.setContactMail(organisation.organisationInfo!.contactMail)
    organisationData.setContactPhone(organisation.organisationInfo!.contactPhone)
    organisationData.setContactAddress(organisation.organisationInfo!.contactAddress)
    organisationData.setEditRole(organisation.organisationInfo!.editRole)
    organisationData.setViewRole(organisation.organisationInfo!.viewRole) 

    const request = new updateOrganisationRequest()
    request.setId(organisationId)
    request.setOrganisationInfo(organisationData)

    return storeroomClient
        .updateOrganisation(request, getAuthMetadata(token))
        .then((response: updateOrganisationResponse) => {
            dispatch(updateOrganisationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateOrganisationFailure(err))
        })
}


// Delete organisation
export const deleteOrganisationMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deleteOrganisationStart())
    const token = selectAccessToken(getState())
    
    const request = new deleteOrganisationRequest()
    request.setId(organisationId)

    return storeroomClient
        .deleteOrganisation(request, getAuthMetadata(token))
        .then((response: deleteOrganisationResponse) => {
            dispatch(deleteOrganisationSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deleteOrganisationFailure(err))
        })
}


// Selectors
type OrganisationSliceRoot = {
    organisations: ReturnType<typeof organisations.reducer>
}

export const selectOrganisationsById = (state: OrganisationSliceRoot) => state.organisations.items    
export const selectIsLoadingOrganisations = (state: OrganisationSliceRoot) => state.organisations.isLoading
export const selectErrorOrganisations = (state: OrganisationSliceRoot) => state.organisations.error