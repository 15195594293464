// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"

import { 
    Outlet
} from "react-router-dom"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    ReservationsMenuBar
} from "../menuBars/general/ReservationsMenuBar"

import { 
    useActiveOrganisationId
} from "../../hooks/useActiveOrganisationId"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    NoUserRolePage 
} from "../../pages/NoUserRolePage"

import { 
    useNoUserRoleStatus 
} from "../../hooks/useNoUserRoleStatus"


/**
 * The OrganisationsPage component renders the Organisation page of the application.
 */
export const ReservationsLayoutPage = () => {
    const organisations = useAppSelector(selectOrganisationsById)
    const activeOrganisationId = useActiveOrganisationId()
    const hasNoUserRole = useNoUserRoleStatus()

    const organisationShortName = (organisations[activeOrganisationId] === undefined) ? " " : organisations[activeOrganisationId].organisationInfo!.shortName
    const organisationTitleName = (organisations[activeOrganisationId] === undefined) ? " " : organisations[activeOrganisationId].organisationInfo!.title
     
      
    if (hasNoUserRole) {
        return <NoUserRolePage />
    }


    //Return Organisation Page
    return (
        <Fragment>
            <h1> {organisationTitleName} </h1>
            <ReservationsMenuBar 
                activeOrganisationShortName={organisationShortName}
            /> 
            <hr/>
            <Outlet/>
        </Fragment>
    )
}
