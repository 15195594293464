// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useState, 
    Fragment
} from "react"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
function TestMeme () {
    const [answer, setAnswer] = useState(" ")

    const [topText, setTopText] = useState("One does simply")
    const [bottomText, setBottomText] = useState("walk into VSETH")
    const [randomImg, setRandomImg] = useState("http://i.imgflip.com/1bij.jpg")
    const allMemeImgs = [
        "https://imgflip.com/s/meme/One-Does-Not-Simply.jpg",
        "https://imgflip.com/s/meme/Disaster-Girl.jpg",
        "https://i.imgflip.com/6a9erc.jpg",
        "https://imgflip.com/s/meme/Distracted-Boyfriend.jpg",
        "https://imgflip.com/s/meme/Waiting-Skeleton.jpg",
        "https://imgflip.com/s/meme/Jack-Sparrow-Being-Chased.jpg",
        "https://imgflip.com/s/meme/Star-Wars-Yoda.jpg",
        "http://i.imgflip.com/1bij.jpg",
        "https://i.imgflip.com/8h9d1z.jpg",
        "https://imgflip.com/s/meme/Always-Has-Been.png",
        "https://imgflip.com/s/meme/Mocking-Spongebob.jpg",      
        "https://imgflip.com/s/meme/Ancient-Aliens.jpg",
        "https://imgflip.com/s/meme/Hide-the-Pain-Harold.jpg",        
    ] 

    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const randNum = Math.floor(Math.random() * allMemeImgs.length)
        const randMemeImg = allMemeImgs[randNum]
        setRandomImg(randMemeImg)
    }

    
    const styleDiv: React.CSSProperties = {
        position: "relative",
        width: "90%",
        margin: "auto"
    }

    const styleTopText: React.CSSProperties = {
        position: "absolute",
        width: "100%",
        margin: "15px",
        top: 0,
        textAlign: "center",
        color: "#009FE3",
        letterSpacing: "1px",
        textTransform: "uppercase",
    }
    
    const styleBottomText: React.CSSProperties = {
        position: "absolute",
        width: "100%",
        margin: "30px",
        bottom: 0,
        textAlign: "center",
        color: "#009FE3",
        letterSpacing: "1px",
        textTransform: "uppercase",
    }

    const styleImg: React.CSSProperties = {
        width: "100%",
    }

    
    return (
        <Fragment>
            <div>
                <h1>Is state important to know?</h1>
                <h3>{answer}</h3>
                <button onClick={() => setAnswer("Yes")}> Yes </button>
                <button onClick={() => setAnswer("No?! You have many things to learn.")}> No </button>
                <br/><br/>
            </div>


            <form onSubmit={handleSubmit}>
                <label>
                    Top Text:
                    <input
                        type="text"
                        name = "topText"
                        value={topText}
                        placeholder = "Top Text"
                        onChange={e => setTopText(e.target.value)}
                    />
                </label>
                <label>
                    Bottom Text:
                    <input
                        type="text"
                        name = "bottomText"
                        value={bottomText}
                        placeholder = "Bottom Text"
                        onChange={e => setBottomText(e.target.value)}
                    />
                </label>
                <input type="submit" value="Submit" />
            </form>


            <div style = {styleDiv}>
                <img src={randomImg} alt="" style={styleImg} />
                <h2 style={styleTopText}>{topText}</h2>
                <h2 style={styleBottomText}>{bottomText}</h2>
                <br/><br/>
            </div>
        </Fragment>
    )
}

export default TestMeme