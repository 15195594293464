// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card, 
   } from "@mantine/core"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    StorageUnitViewLayout 
} from "../../layouts/views/StorageUnitViewLayout"

import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import {
    selectStorageUnitSingleId,
} from "../../features/storageunit/storageUnitSingleIdSlices"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import {
    LastEntryStorageUnitButton 
} from "../../components/buttons/lastEntry/LastEntryStorageUnitButton"

import {
    NextEntryStorageUnitButton 
} from "../../components/buttons/nextEntry/NextEntryStorageUnitButton"

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToUpdatePageButton 
} from '../../components/buttons/goFromTo/GoFromToUpdatePageButton'

import { 
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"



/**
* The DeleteItemsPage component renders the DeleteItems page of the application.
*/
export const ViewStorageUnitsPage = () => {     
    const { t } = useTranslation() 
    
    const storageUnits = useAppSelector(selectStorageUnitsById)
    const editStorageUnitId = useAppSelector(selectStorageUnitSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editStorageUnitId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }
                  
      
    return (
        <Card> 
            <h1>{t("StorageUnits.viewText")}</h1> 
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryStorageUnitButton/>}
                navigationButton1 = {<GoFromToUpdatePageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryStorageUnitButton/>}
            /> 
            <StorageUnitViewLayout 
                storageunitId = {editStorageUnitId}
                storageUnitInfo = {storageUnits[editStorageUnitId].storageUnitInfo!}
                updateUser = {storageUnits[editStorageUnitId].updateUser}
                updateTime = {storageUnits[editStorageUnitId].updateTime!}
            />   
        </Card>                    
    )   
}