// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Grid
} from "@mantine/core"

import { 
    useTranslation
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    timeObjToMomentTime
} from "../../util/proto"

import { 
    Timestamp
 } from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    SubstituteTimestamp
} from "../../components/general/SubstituteTimestamp"

import {
    DisabledTextInput 
} from "./DisabledTextInput"



// Define interfaces
interface ChangeDateUserProps {
    lastchangeuser: string,
    lastchangedate: Timestamp.AsObject,
}


/**
 * The ChangeDateUserInfo component returns the changedate and changeuser in a grid.
 */
export const ChangeDateUserInfoNew = (props: ChangeDateUserProps) => {
    const { t } = useTranslation()

    let changeDate = timeObjToMomentTime((props.lastchangedate === undefined) ? SubstituteTimestamp()  : props.lastchangedate)
   
    //Lastchange Date and User Information
    return (
        <Grid>
              <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Storeroom.lastChangeUser")} 
                    value = {(props.lastchangeuser === undefined) ? "" : props.lastchangeuser}                
                />
            </Grid.Col>   
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Storeroom.lastChangeDate")} 
                    value = {changeDate.format("DD.MM.YYYY HH:mm")}                
                />
            </Grid.Col>   
        </Grid>
    )
}