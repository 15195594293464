// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    TagCreateForm
} from "../../components/tag/TagCreateForm"


/**
 * The CreateTagsPage component renders the CreateTags page of the application.
 */
export const CreateTagsPage = () => {
    const { t } = useTranslation()

    return(
        <Card> 
            <h1>{t("Tags.createText")}</h1>   
            <TagCreateForm/>
        </Card>   
    )
}