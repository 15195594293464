// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"

// Imports from vseth-canine-ui
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../../pages/LoadScreen"

import {
    ErrorScreen
} from "../../pages/ErrorScreen"

import { 
    selectUsersByUUID,
    fetchAllUsersMessage,
    selectIsLoadingUsers,
    selectErrorUsers
} from "../../features/user/usersSlice"

import {
    UserTable
} from "../../components/user/UserTable"

/**
 * The AdminUsersPage component renders the AdminUsers page of the application.
 */
export const AdminUsersPage = () => {
    const dispatch = useAppDispatch()

    const users = useAppSelector(selectUsersByUUID)
    const isLoadingUsers = useAppSelector(selectIsLoadingUsers)
    const errorUsers = useAppSelector(selectErrorUsers)

    useEffect(() => {       
        dispatch(fetchAllUsersMessage())
    }, [dispatch])   

    //Loading Screen
    if (isLoadingUsers) {
        return <LoadScreen />
    }

    //Error Screen
    if (errorUsers) {
        return <ErrorScreen
            customText={errorUsers.message}    
        />
    }  

    return(
        <Fragment>
            <UserTable 
                users={Object.values(users)}
            />
        </Fragment>
    )
}