// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import * as Yup from "yup"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const OrganisationValidationSchema = Yup.object().shape({
    shortName: Yup.string()
      .max(8, 'Please input 8 characters or less')
      .required('Please input shortName'),
    // title: Yup.string()
    //   .required('Please input name'),
    // logoPath: Yup.string()
    //     .max(16, 'Please input 16 characters or less')
    //     .required('Please input logoPath'),
    // contactMail: Yup.string().email()            
    //     .required('Please input contactMail'),
    // contactPhone: Yup.string()
    //     .min(12, 'Please input 13 characters or less')
    //     .max(16, 'Please input 16 characters or less')
    //     .required('Please input contactPhone +41xxxxxxxxx'),
    // contactAddress: Yup.string()
    //     .required('Please input contactAddress'),
    // editRole: Yup.string()
    //     .required('Please input editRole'),
    // viewRole: Yup.string()
    //     .required('Please input viewRole'),
    }
)