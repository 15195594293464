// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    ItemCreateForm
} from "../../components/item/ItemCreateForm"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"

import { 
    useIsOnAdminPage 
} from "../../hooks/useIsOnAdminPage"


/**
 * The CreateItemsPage component renders the CreateItemsPage page of the application.
 */
export const CreateItemsPage = () => {
    const { t } = useTranslation()
    const activeOrganisationId = useActiveOrganisationId() 
    const isOnAdminPage = useIsOnAdminPage() 
     

    const itemCreateForm = () => {
        if (isOnAdminPage) {
            return (
                <ItemCreateForm 
                    organisationId = {0}
                />
            )
        } else if (activeOrganisationId !== 0) {
            return (
                <ItemCreateForm 
                    organisationId = {activeOrganisationId}
                />
            )
        }
    }    

   
    return(
        <Card>   
            <h1>{t("Items.createText")} </h1>
            {itemCreateForm()}
        </Card>      
    )
}