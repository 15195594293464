// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import { 
    useLocation
} from "react-router-dom"




// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    Timestamp
} from "../../proto/google/protobuf/timestamp_pb"

import { 
    timeObjToMomentTime 
} from "../../util/proto"

import { 
    GetReservationResponse 
} from "../../features/reservation/reservationsSlice"

import {
    setReservationSingleId
} from "../../features/reservation/reservationSingleIdSlice"

import {
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectItemsById
} from "../../features/item/itemsSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    selectReservationsById
} from "../../features/reservation/reservationsSlice"

import { 
    useIsOnAdminPage 
} from '../../hooks/useIsOnAdminPage'

// Define interfaces
interface ReservationsTableProps {
    reservationsForTable: GetReservationResponse[],
    isBeforeStart?: boolean
    isBeforeEnd?: boolean
    isArchive?: boolean
    isFullTable?: boolean
}

// const { SearchBar, ClearSearchButton } = Search

/**
 * The ReservationRequestsTable component renders the ReservationRequestsTable page of the application.
 */
export const ReservationTable : React.FC<ReservationsTableProps> = ({
    reservationsForTable, 
    isBeforeStart,
    isBeforeEnd, 
    isArchive,
    isFullTable,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()   
    const location = useLocation()
    const isOnAdminPage = useIsOnAdminPage()

        //Selectors
    const reservations = useAppSelector(selectReservationsById)
    const organisations = useAppSelector(selectOrganisationsById)
    const items = useAppSelector(selectItemsById)


    const isArchiveChecked = (isArchive !== undefined) ? isArchive : false

    // row formatters
    const organisationFormatter = (value: number, row: GetReservationResponse) => {
        return(
            <p>{(organisations[value] === undefined) ? "": organisations[value].organisationInfo?.shortName}</p>
        )
    }


    const startTimeFormatter = (value: number, row: GetReservationResponse) => {
        const startTime = row.reservationInfo!.startTime!

        

        return(
            timeObjToMomentTime(startTime).format("DD.MM.YYYY")
        )
    }

    const endTimeFormatter = (value: number, row: GetReservationResponse) => {
        const endTime = row.reservationInfo!.endTime

        return(
            timeObjToMomentTime(endTime).format("DD.MM.YYYY")
        )
    }   
    
    
    const itemRelationsFormatter = (value: number, row: GetReservationResponse) => {
        let itemRelationsText = "" 
       
        if (reservations[row.id] !== undefined ) {
            let itemRelations = reservations[row.id].reservationInfo!.itemRelationsList
            
            itemRelations.forEach(relationId => {
                if (items[relationId.itemId] !== undefined) {
                    itemRelationsText += items[relationId.itemId].itemInfo!.displayName + " [" +  relationId.quantity + "] "

                    if (relationId.confirmationTime!.seconds > 3600 && !isArchiveChecked) {
                        const confirmationTime = relationId.confirmationTime
                        
                        return (
                            <Fragment>
                                <Icon 
                                    icon = {ICONS.CHECK} 
                                    color = "green"
                                    size = {16}
                                /> 
            
                                <Fragment>  (</Fragment>
                                {timeObjToMomentTime(confirmationTime).format("DD.MM.YYYY")}
                                <Fragment>),</Fragment>
            
                            </Fragment>
                          
                        )                
                    } else {
                        return (
                            <Icon 
                                icon = {ICONS.CLOSE} 
                                color = "red"
                                size = {16}
                            /> 
                        )      
                    }     

                }
            })            
        }

        return(
            itemRelationsText
        )
    }

   
    const timeFormatter = (value: Timestamp.AsObject) => {
        return (
            timeObjToMomentTime(value).format("DD.MM.YYYY HH:mm")
        )
    }    
    
        
    const columns = [
        {
            dataField: "id",
            text: t("Reservations.id"),
            sort: true,
            hidden: !isFullTable
        },
        {
            dataField: "reservationInfo.subject",
            text: t("Reservations.subject"),
            sort: true,
        },
        {
            dataField: "reservationInfo.requestText",
            text: t("Reservations.requestText"),
            sort: true,
            hidden: !isFullTable
        },
        {
            dataField: "reservationInfo.startTime",
            text: t("Reservations.startTime"),
            sort: true,
            formatter: startTimeFormatter,
            hidden: !isBeforeStart 
        },
        {
            dataField: "reservationInfo.endTime",
            text: t("Reservations.endTime"),
            sort: true,
            formatter: endTimeFormatter,
            hidden: !isBeforeEnd
        },
        {
            dataField: "reservationInfo.organisationId",
            text: t("Organisations.shortName"),
            sort: true,
            formatter: organisationFormatter,
            hidden: !isOnAdminPage,
        },
        {
            dataField: "reservationInfo.itemRelationsList",
            text: t("Reservations.itemRelationsList"),
            sort: true,
            formatter: itemRelationsFormatter, 
        },
        {
            dataField: "createTime",
            text: t("Reservations.createTime"),
            sort: true,
            formatter: timeFormatter,
            hidden: !isFullTable
        },
        {
            dataField: "createUser",
            text: t("Reservations.createUser"),
            sort: true,
            hidden: !isFullTable
        },
        {
            dataField: "updateTime",
            text: t("Reservations.updateTime"),
            sort: true,
            formatter: timeFormatter,
            hidden: !isFullTable
        },
        {
            dataField: "updateUser",
            text: t("Reservations.updateUser"),
            sort: true,
            hidden: !isFullTable
        },
    ]



    return(
        <Fragment>
            ReservationTable
        </Fragment>
    )
}