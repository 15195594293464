// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect 
} from "react"


import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    fetchAllOrganisationExternalStoragesMessage,
    selectExternalStoragesById,
    selectIsLoadingExternalStorages,
    selectErrorExternalStorages
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    ExternalStorageTable 
} from "../../components/externalStorage/ExternalStorageTable"

import { 
    selectUsersByUUID,
    selectIsLoadingUsers,
    selectErrorUsers,
} from "../../features/user/usersSlice"

import { 
    fetchAllExternalStorageDatesMessage,
    selectIsLoadingExternalStorageDates,
    selectErrorExternalStorageDates,
    selectExternalStorageDatesById
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import {  
    selectSub 
} from "../../features/auth/authSlice"

import { 
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    GetExternalStorageResponse 
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    fetchAllOrganisationStorageUnitsMessage 
} from "../../features/storageunit/storageUnitSlice"

import { 
    useEditorStatus 
} from "../../hooks/useEditorStatus"

/**
 * The AdminExternalStorageRequestsPage component renders the AdminExternalStorageRequests page of the application.
 */
export const OrganisationExternalStoragePage = () => {
    const dispatch = useAppDispatch()    
    const { t } = useTranslation() 
   
    //Selectors
    const externalStorages = useAppSelector(selectExternalStoragesById)
    const isLoadingExternalStorages = useAppSelector(selectIsLoadingExternalStorages)
    const isErrorExternalStorages = useAppSelector(selectErrorExternalStorages)

    const users = useAppSelector(selectUsersByUUID)
    const isLoadingUsers = useAppSelector(selectIsLoadingUsers)
    const errorUsers = useAppSelector(selectErrorUsers)

    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    const isLoadingExternalStorageDates = useAppSelector(selectIsLoadingExternalStorageDates)
    const isErrorExternalStorageDates = useAppSelector(selectErrorExternalStorageDates)

    const sub = useAppSelector(selectSub).sub
    const activeOrganisationId = (users[sub] === undefined) ? 1 : users[sub].activeOrganisation
    
    
    const isEditor = useEditorStatus(activeOrganisationId)
 

    useEffect(() => {
        dispatch(fetchAllOrganisationExternalStoragesMessage(activeOrganisationId))        
        dispatch(fetchAllExternalStorageDatesMessage())
        dispatch(fetchAllOrganisationStorageUnitsMessage(activeOrganisationId))
    }, [dispatch, activeOrganisationId])

            
    //Loading Screen
    if (isLoadingExternalStorages || isLoadingUsers || isLoadingExternalStorageDates) {
        return <LoadScreen />
    }

    //Error Screen
    if (isErrorExternalStorages) {
        return <ErrorScreen 
            customText={isErrorExternalStorages?.message} 
        /> 
    } else if (errorUsers) {
        return <ErrorScreen 
            customText={errorUsers?.message} 
        /> 
    } else if (isErrorExternalStorageDates) {
        return <ErrorScreen 
            customText={isErrorExternalStorageDates?.message} 
        />       
    }

    const requestsBeforeStart = new Array<GetExternalStorageResponse>()
    const requestsBeforeEnd = new Array<GetExternalStorageResponse>()
    const requestsArchive = new Array<GetExternalStorageResponse>()
 
    if (externalStorages !== undefined && externalStorageDates !== undefined) {
        for (var i in externalStorages) {
            const curStartTime = externalStorageDates[externalStorages[i].externalStorageInfo!.startTimeId].date!.seconds
            const curEndTime = externalStorageDates[externalStorages[i].externalStorageInfo!.endTimeId].date!.seconds
            const curTime = new Date().getTime() / 1000

            if (curStartTime > curTime) {
                requestsBeforeStart.push(externalStorages[i])
            } else if (curEndTime > curTime) {
                requestsBeforeEnd.push(externalStorages[i])
            } else {
                requestsArchive.push(externalStorages[i])
            }
        }
    }
    

    // Menu Buttons           
    const externalStorageContent = () => {       
        if (isEditor) {
            return (
                <Fragment>
                    <CreateBar/>              
                    <h3>{t("ExternalStorages.beforeStart")}</h3>
                    <ExternalStorageTable 
                        externalStoragesForTable = {Object.values(requestsBeforeStart)}
                        isBeforeStart = {true}
                    />
                    <hr/> 
                    <h3>{t("ExternalStorages.beforeEnd")}</h3>
                    <ExternalStorageTable 
                        externalStoragesForTable = {Object.values(requestsBeforeEnd)}                      
                        isBeforeEnd = {true}
                    />            
                
                    <hr/> 
                    
                    <h3>{t("ExternalStorages.archive")}</h3>
                    <ExternalStorageTable 
                        externalStoragesForTable={Object.values(requestsArchive)}
                        isBeforeStart = {true}
                        isBeforeEnd = {true}
                        isArchive = {true}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h3>{t("ExternalStorages.beforeStart")}</h3>
                        <ExternalStorageTable 
                            externalStoragesForTable = {Object.values(requestsBeforeStart)}
                            isBeforeStart = {true}
                        />
                        <hr/> 
                        <h3>{t("ExternalStorages.beforeEnd")}</h3>
                        <ExternalStorageTable 
                            externalStoragesForTable = {Object.values(requestsBeforeEnd)}                      
                            isBeforeEnd = {true}
                        />            
                    
                        <hr/> 
                        
                        <h3>{t("ExternalStorages.archive")}</h3>
                        <ExternalStorageTable 
                            externalStoragesForTable={Object.values(requestsArchive)}
                            isBeforeStart = {true}
                            isBeforeEnd = {true}
                            isArchive = {true}
                        />
                    </Fragment>
            )
        }
    } 


    return(
        <Fragment>
            {externalStorageContent()}  
        </Fragment>            
    )
}