// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import { 
    useNavigate 
} from "react-router-dom"

import {
    Button,
    Card
} from "@mantine/core"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"



// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    selectExternalStoragesById,
    confirmExternalStorageMessage
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    selectExternalStorageSingleId, 
} from "../../features/externalStorage/externalStorageSingleIdSlice"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import { 
    selectExternalStorageDatesById
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    ChangeDateUserInfo 
} from "../../layouts/formatter/ChangeDateUserInfo"

import { 
    SubstituteTimestamp 
} from "../../components/general/SubstituteTimestamp"
import { timeObjToMomentTime } from "../../util/proto"
import { CancelButton } from "../../components/buttons/form/CancelButton"
import { GoToUpdatePageButton } from "../../components/buttons/goTo/GoToUpdatePageButton"
import { DateFormatter } from "../../layouts/formatter/DateFormatter"

/**
 * The UpdateExternalStorageRequestsPage component renders the UpdateExternalStorageRequests page of the application.
 */
export const ConfirmExternalStoragePage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()    
    const dispatch = useAppDispatch()


    //Selectors
    const externalStorages = useAppSelector(selectExternalStoragesById)
    

    const editExternalStorageId = useAppSelector(selectExternalStorageSingleId).externalStorageId
    let isConfirmed = (externalStorages[editExternalStorageId]===undefined) ? false : (externalStorages[editExternalStorageId].confirmationTime!.seconds > 3600)
   
   
    const organisations = useAppSelector(selectOrganisationsById)
   

    const storageUnits = useAppSelector(selectStorageUnitsById)
    

    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
   
    
    // Buttons
    const buttonText = () => {
        if (isConfirmed) { //true = confirmed --> Button unconfirmed
            return (
                <Fragment>{t("Storeroom.unconfirmButton")}</Fragment>
            )
        } else {
            return (                
                <Fragment>{t("Storeroom.confirmButton")}</Fragment>
            )
        }
    }  
    
    const confirmButtons = () => {
        return (
            <div className="row" >
                <div className="position-relativ col-lg-6">
                </div>
                <div className="position-relativ col-lg-2">
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                            dispatch(confirmExternalStorageMessage(editExternalStorageId, !isConfirmed))
                            navigate(-1)
                        }}
                        fullWidth
                    >{buttonText()}</Button>
                </div>
                <div className="position-relativ col-lg-2">
                    <GoToUpdatePageButton
                       
                    />
                </div>
                <div className="position-relativ col-lg-2">
                    <CancelButton/>
                </div>
            </div>  
        )
    }


    const storageUnitRelations = () => {
        let storageUnitRelationsText = "" 
     
        let storageUnitRelations = externalStorages[editExternalStorageId].externalStorageInfo!.storageUnitRelationList
            
        storageUnitRelations.forEach(relationId => {
            if (storageUnits[relationId.storageUnitId] !== undefined) {
                storageUnitRelationsText += storageUnits[relationId.storageUnitId].storageUnitInfo?.displayName + ", "
            }
        })            
        

        return(
            storageUnitRelationsText
        )
    }


    const confirmed = () => {
        if (isConfirmed) {
            const confirmationTime = externalStorages[editExternalStorageId].confirmationTime
            
            return (
                <Fragment>
                      <Icon 
                        icon = {ICONS.CHECK} 
                        color = "green"
                        size = {16}
                    /> 

                    <Fragment>  (</Fragment>
                    {timeObjToMomentTime(confirmationTime).format("DD.MM.YYYY")}
                    <Fragment>)</Fragment>

                </Fragment>
              
            )                
        } else {
            return (
                <Icon 
                    icon = {ICONS.CLOSE} 
                    color = "red"
                    size = {16}
                /> 
            )      
        }     
    }


    
    return (
        <Card>     
            <h3>{t("ExternalStorages.confirmText")}</h3>
            {confirmButtons()}
            <div className="row" >
                <div className="position-relativ col-lg-12">
                    <b>{t("ExternalStorages.subject")}</b>
                    <p>{externalStorages[editExternalStorageId].externalStorageInfo?.subject}</p>             
                    <b>{t("ExternalStorages.requestText")}</b>
                    <p>{externalStorages[editExternalStorageId].externalStorageInfo?.requestText}</p> 
                    <b>{t("ExternalStorages.startTime")}</b>
                    <DateFormatter 
                        date = {(externalStorageDates[externalStorages[editExternalStorageId].externalStorageInfo!.startTimeId] === undefined) ? {seconds: 0, nanos: 0} : externalStorageDates[externalStorages[editExternalStorageId].externalStorageInfo!.startTimeId].date!}
                    />
                    <b>{t("ExternalStorages.endTime")}</b>
                    <DateFormatter 
                        date = {(externalStorageDates[externalStorages[editExternalStorageId].externalStorageInfo!.endTimeId] === undefined) ? {seconds: 0, nanos: 0} : externalStorageDates[externalStorages[editExternalStorageId].externalStorageInfo!.endTimeId].date!}
                    />      
                    <b>{t("Organisations.shortName")}</b>
                    <p>{organisations[externalStorages[editExternalStorageId].externalStorageInfo!.organisationId].organisationInfo?.shortName}</p>
                    <b>{t("Storeroom.storageUnits")}</b>
                    <p>{storageUnitRelations()}</p>

                    <b>{t("Storeroom.created")}</b>
                    <ChangeDateUserInfo 
                        lastchangedate = {(externalStorages[editExternalStorageId] === undefined) ? SubstituteTimestamp() : externalStorages[editExternalStorageId].createTime!}
                        lastchangeuser = {(externalStorages[editExternalStorageId] === undefined) ? "" : externalStorages[editExternalStorageId].createUser}
                    />
                        <b>{t("Storeroom.lastChangeInfo")}</b>
                    <ChangeDateUserInfo 
                        lastchangedate = {(externalStorages[editExternalStorageId] === undefined) ? SubstituteTimestamp() : externalStorages[editExternalStorageId].updateTime!}
                        lastchangeuser = {(externalStorages[editExternalStorageId] === undefined) ? "" : externalStorages[editExternalStorageId].updateUser}
                    />  
                    <b>{t("ExternalStorages.confirmed")}</b> 
                    <p>{confirmed()}</p>           
                    <ChangeDateUserInfo 
                        lastchangedate = {(externalStorages[editExternalStorageId] === undefined) ? SubstituteTimestamp() : externalStorages[editExternalStorageId].confirmationTime!}
                        lastchangeuser = {(externalStorages[editExternalStorageId] === undefined) ? "" : externalStorages[editExternalStorageId].confirmationUser}
                    />
                </div>
            </div>
        </Card>                    
    )   
}