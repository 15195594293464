// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    selectPlacesById
} from "../../features/place/placesSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"


// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const SelectoptionOrganisationPlacesName = (organisationId: number, isInStorageUnitIdValue: number) => {
    const { t } = useTranslation() 

    const places = useAppSelector(selectPlacesById)
    

    //initialise options
    const options= [
        {value: 0, label: t("Storeroom.selectOption"), disabled: true },
    ] as any

    //If Parent object
    if (isInStorageUnitIdValue > 0) { // 0 is default 
        const currValue = places[isInStorageUnitIdValue].id
        const currName = `${places[isInStorageUnitIdValue].placeInfo?.room}, ${places[isInStorageUnitIdValue].placeInfo?.rack}`

        return (
            [
                {label: currName, value: currValue, disabled: true}
            ] as any
        )
    }


    //For Loop over all organisations
    for (var i in places) {
        if (places[i].placeInfo?.organisationId === organisationId) {
            const currValue = places[i].id
            const currName = `${places[i].placeInfo?.room}, ${places[i].placeInfo?.rack}`

            options.push(
                {label: currName, value: currValue, }
            )
        }

    }   

    return(
        options
    )
}