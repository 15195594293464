// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    selectTagsById
} from "../../features/tag/tagsSlice"


// Define interfaces
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"



/**
 * The AdminPage component renders the admin page of the application.
 */
export const SelectoptionsAllTagsName = () => {
    const { t } = useTranslation() 

    const tags = useAppSelector(selectTagsById)
   
    //initialise options
    const options= [
        {value: 0, label: t("Storeroom.selectOption"), disabled: true },
    ] as any


    //For Loop over all organisations
    for (var i in tags) {
        const currValue = tags[i].id
        const currName = tags[i].tagName

        options.push(
            {label: currName, value: currValue, }
        )
    }        

    return(
        options
    )        
}