// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Grid, 
    Group, 
    Radio, 
    Select, 
    TextInput
} from "@mantine/core"

import { 
    UseFormReturnType 
} from "@mantine/form"

import { 
    useTranslation 
} from "react-i18next"

import { 
    useLocation 
} from "react-router-dom"

// Imports from vseth-canine-ui



// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    StorageUnitAttributes 
} from "../../features/storageunit/storageUnitSlice"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    SelectoptionOrganisationStorageUnitsName
} from "../../components/selectoptions/SelectoptionOrganisationStorageUnitsName"

import { 
    SelectoptionOrganisationPlacesName 
} from "../../components/selectoptions/SelectoptionOrganisationPlacesName"

import { 
    SelectoptionsAllOrganisationsNameNew 
} from "../../components/selectoptions/SelectoptionAllOrganisationsNameNew"

import { 
    DisabledTextInput 
} from "../formatter/DisabledTextInput"


/**
 * The Page component renders the admin page of the application.
 */
export const StorageUnitFormLayout = ({ form }:{ form: UseFormReturnType<StorageUnitAttributes>}) => {
    const { t } = useTranslation()  
    const location = useLocation()

    const organisations = useAppSelector(selectOrganisationsById)
    
    const organisationIdValue = {...form.getInputProps('organisationId')}.value
    const storageUnitIdValue = {...form.getInputProps('storageunitId')}.value !== undefined ? {...form.getInputProps('storageunitId')}.value : 0
    const storageUnitTypeValue = {...form.getInputProps('storageUnitType')}.value 
    const isInStorageUnitIdValue = {...form.getInputProps('isInStorageUnitId')}.value !== undefined ? {...form.getInputProps('storageunitId')}.value : 0

    

    const selectoptionOrganisationStorageUnitsName = SelectoptionOrganisationStorageUnitsName(organisationIdValue,storageUnitIdValue, storageUnitTypeValue)

    const organisationField = () => {
        if (location.pathname === "/admin/storageunits/create") {
            return (
                <div>
                    <Select 
                        label = {t("Storeroom.organisation")}
                        placeholder = {t("Storeroom.organisation") + ''}
                        searchable
                        data={SelectoptionsAllOrganisationsNameNew()} 
                        {...form.getInputProps('organisationId')}     
                    />                             
                </div>                    
            )
        } else {
            return (
                <DisabledTextInput
                    label = {t("Storeroom.organisation")} 
                    value = {(organisations[organisationIdValue] === undefined) ? "" : organisations[organisationIdValue].organisationInfo!.shortName}                
                />
            )
        }
    }
     
    return (
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("StorageUnits.displayName")}
                    placeholder = {t("StorageUnits.displayName") + ''}
                    {...form.getInputProps('displayName')}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("StorageUnits.description")}
                    placeholder = {t("StorageUnits.description") + ''}
                    {...form.getInputProps('description')}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <Radio.Group
                    label = {t("StorageUnits.storageUnitType")}
                    {...form.getInputProps('storageUnitType')}
                >
                    <Group mt="xs">
                        <Radio 
                            value = {0} 
                            label="Other"                            
                        />
                        <Radio 
                            value= {1} 
                            label="Eurobox"
                        />
                        <Radio
                            value= {2} 
                            label="Europalette" 
                        />
                    </Group>                    
                </Radio.Group>
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <Select 
                    label = {t("StorageUnits.isInStorageUnitId")}
                    placeholder = {t("StorageUnits.isInStorageUnitId") + ''}
                    searchable
                    data={selectoptionOrganisationStorageUnitsName} 
                    {...form.getInputProps('isInStorageUnitId')}      
                />       
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                {organisationField()}
            </Grid.Col> 
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <Select 
                    label = {t("Places.room") + ", " + t("Places.rack")}
                    placeholder = { t("Places.room") + ", " + t("Places.rack")}
                    searchable
                    data={SelectoptionOrganisationPlacesName(organisationIdValue, isInStorageUnitIdValue)} 
                    {...form.getInputProps('placeId')}      
                />                             
            </Grid.Col>                    
        </Grid>
    )
}