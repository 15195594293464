// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect 
} from 'react'

import { 
    Box, 
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import {
    TagFormLayout
} from "../../layouts/forms/TagFormLayout"

import {
    TagValidationSchema
} from "./TagValidationSchema"

import { 
    updateTagMessage
} from "../../features/tag/tagsSlice"

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'


// Define interfaces
interface TagUpdateFormProps {
    tagId: number,
    initialName: string,
}


/**
 * The Page component renders the admin page of the application.
 */
export const TagUpdateForm = (props: TagUpdateFormProps) => { 
    const dispatch = useAppDispatch()
      
    const form = useForm({
        initialValues: {
            tagName:  props.initialName ||'',
        },

        validate: yupResolver(TagValidationSchema)

    })

    useEffect(() => {
          form.setFieldValue("tagName", props.initialName || '')
    }, [props]);


    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => {
                    const payload = {
                        id: props.tagId,
                        tagName: values.tagName,
                    }
                                        
                    dispatch(updateTagMessage(props.tagId, payload))
                })}
            >
                <TagFormLayout
                    form={form}
                />
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}