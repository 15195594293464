import { createSlice, PayloadAction } from "@reduxjs/toolkit"


// Define which objects the organisation State have. 
interface organisationSingleIdState {
    organisationId: number,
}


// Define initial state
const initialState: organisationSingleIdState = {
    organisationId: 0,
}


// Define State
const organisationSingleId = createSlice({
    name: "organisationSingleId",
    initialState,
    reducers: {
        setOrganisationSingleId(state, {payload}: PayloadAction<number>) {
            state.organisationId = payload
        },
    },
})


// Export Reducer
export default organisationSingleId.reducer


// Define Actions
const {
    setOrganisationSingleId,
} = organisationSingleId.actions


// Change handler
export {
    setOrganisationSingleId, 
}


// Selectors
type SingleOrganisationIdSliceRoot = {
    organisationSingleId: ReturnType<typeof organisationSingleId.reducer>
}

export const selectOrganisationSingleId = (state: SingleOrganisationIdSliceRoot) =>
    state.organisationSingleId.organisationId