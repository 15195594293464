// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAdminStatus 
} from "../hooks/useAdminStatus"

import { 
    useNoUserRoleStatus 
} from "../hooks/useNoUserRoleStatus"


/**
 * Configuration of the NavBar
 * 
 */
export const NavbarConfig = () => {
    const { t } = useTranslation()
    const isAdmin = useAdminStatus()
    const hasNoUserRole = useNoUserRoleStatus()

    let baseNavbarConfig = [
        { key: 1, title: t("Navbar.baseHome"), href: "/" },
        { key: 2, title: t("Navbar.baseCatalogue"), href: "/catalogue" },
        { key: 5, title: t("Navbar.baseFAQ"), href: "/faq" },
    ]

    const userNavbarConfig = [
        { key: 3, title: t("Navbar.baseReservations"), href: "/reservations" },
        { key: 4, title: t("Navbar.baseOrganisations"), href: "/organisations" },
    ]
    
    const adminItemConfig = [
        { key: 6, title: t("Navbar.adminItem"), href: "/admin" }
    ]


    let navbarConfig = baseNavbarConfig

   
    if (!hasNoUserRole && isAdmin) {
        navbarConfig = baseNavbarConfig.concat(userNavbarConfig).concat(adminItemConfig)
    } else if (!hasNoUserRole) {
        navbarConfig = baseNavbarConfig.concat(userNavbarConfig)
    } else if (isAdmin) {
        navbarConfig = baseNavbarConfig.concat(adminItemConfig)
    }
  
    return navbarConfig
}