// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box,
    Grid
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {  
    OrganisationAttributes, 
} from '../../features/organisation/organisationsSlice'

import { 
    ChangeDateUserInfoNew 
} from '../formatter/ChangeDateUserInfoNew'

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    DisabledTextInput 
} from "../formatter/DisabledTextInput"



//Title and Initial values of the form 
interface OrganisationViewProps {
    organisationId: number,
    organisationInfo: OrganisationAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
}


//Styling
const styleImg: React.CSSProperties = {
    width: "50%",
    marginBottom: "1cm",
}


export const OrganisationViewLayout = (props: OrganisationViewProps) => {
    const { t } = useTranslation()  

    const contactAddressBuilding = props.organisationInfo.contactAddress.split(',')[0] || ''
    const contactAddressFloor = props.organisationInfo.contactAddress.split(',')[1] || ''
    const contactAddressRoom = props.organisationInfo.contactAddress.split(',')[2] || ''
    const contactAddressStreet = props.organisationInfo.contactAddress.split(',')[3] || ''
    const contactAddressNumber = props.organisationInfo.contactAddress.split(',')[4] || ''
    const contactAddressPLZ = props.organisationInfo.contactAddress.split(',')[5] ||''
    const contactAddressCity =  props.organisationInfo.contactAddress.split(',')[6] ||''

   
    return(
        <Box 
            mx="auto"
        >
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Organisations.shortName")}
                    value = {props.organisationInfo.shortName}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Organisations.title")}
                    value = {props.organisationInfo.title}
                />               
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Organisations.logoPath")}
                    value = {props.organisationInfo.logoPath}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <img
                    className="photo"
                    src={`https://static.vseth.ethz.ch/assets/${props.organisationInfo.logoPath}/logo.png`}
                    alt="Logo"
                    style={styleImg} 
                /> 
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactMail")}
                    value = {props.organisationInfo.contactMail}
                />
            </Grid.Col>
            <Grid.Col 
                md={4} 
                lg={2}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactAddressBuilding")}
                    value = {contactAddressBuilding}
                />
                
            </Grid.Col>
            <Grid.Col 
                md={4} 
                lg={2}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactAddressFloor")}
                    value = {contactAddressFloor}
                />
            </Grid.Col>
            <Grid.Col 
                md={4} 
                lg={2}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactAddressRoom")}
                    value = {contactAddressRoom}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactPhone")}
                    value = {props.organisationInfo.contactPhone}
                />
            </Grid.Col>    
            <Grid.Col 
                md={8} 
                lg={4}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactAddressStreet")}
                    value = {contactAddressStreet}
                />
            </Grid.Col>
            <Grid.Col 
                md={4} 
                lg={2}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactAddressNumber")}
                    value = {contactAddressNumber}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            />
            <Grid.Col 
                md={4} 
                lg={2}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactAddressPLZ")}
                    value = {contactAddressPLZ}
                />
            </Grid.Col>
            <Grid.Col 
                md={8} 
                lg={4}
            >
                <DisabledTextInput
                    label = {t("Organisations.contactAddressCity")}
                    value = {contactAddressCity}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Organisations.updateRole")}
                    value = {props.organisationInfo.editRole}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Organisations.viewRole")}
                    value = {props.organisationInfo.viewRole}
                />
            </Grid.Col>                          
        </Grid>
            <ChangeDateUserInfoNew
                lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
                lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
            />     
        </Box>
    )
}