// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect 
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import { 
    Outlet
} from "react-router-dom"

import {
    Container 
} from "@mantine/core"


// Imports from vseth-canine-ui
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectIsAuthenticated, 
} from "../../features/auth/authSlice"

import { 
    AdminMenuBar 
} from "../menuBars/general/AdminMenuBar"

import {
     NotAuthorizedPage 
} from "../../pages/NotAuthorizedPage"

import { 
    useAdminStatus 
} from "../../hooks/useAdminStatus"


/**
 * The AdminPage component renders the admin page of the application.
 */
export const AdminLayoutPage = () => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated)
    const { t } = useTranslation()
    const isAdmin = useAdminStatus()
   

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }
    }, [isAuthenticated])


    if (!isAdmin) {
        return <NotAuthorizedPage />
    }
    

    //Return Admin Page
    return (
        <Container>
            <h1>{t("AdminPage.title")}</h1>
            <AdminMenuBar/>
            <hr/>                 
            <Outlet/>      
        </Container>
    )
}