// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff

import { 
    useTranslation
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageEditForm 
} from "../../components/externalStorage/forms/ExternalStorageEditForm"

import { 
    selectExternalStoragesById
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    selectExternalStorageSingleId, 
} from "../../features/externalStorage/externalStorageSingleIdSlice"

import { 
    SubstituteTimestamp 
} from "../../components/general/SubstituteTimestamp"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"


/**
 * The UpdateExternalStorageRequestsPage component renders the UpdateExternalStorageRequests page of the application.
 */
export const UpdateExternalStoragePage = () => {
    const { t } = useTranslation()   
   
    //Selectors
    const externalStorages = useAppSelector(selectExternalStoragesById)
    const editExternalStorageId = useAppSelector(selectExternalStorageSingleId).externalStorageId

    
    return(
        <Card>    
            <ExternalStorageEditForm 
                title={`` + t("ExternalStorages.updateText")}
                externalStorageId = {editExternalStorageId}
                initialExternalStorageData = {externalStorages[editExternalStorageId].externalStorageInfo!}
                lastchangeuser = {(externalStorages[editExternalStorageId] === undefined) ? "" : externalStorages[editExternalStorageId].updateUser}
                lastchangedate = {(externalStorages[editExternalStorageId] === undefined) ? SubstituteTimestamp() : externalStorages[editExternalStorageId].updateTime!}
                />
        </Card>            
    )
}