// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"

import { 
    useNavigate
} from "react-router-dom"

import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    GetStorageUnitResponse
} from "../../features/storageunit/storageUnitSlice"

import { 
    setStorageUnitSingleId 
} from "../../features/storageunit/storageUnitSingleIdSlices"

import { 
    PAGE_SIZES 
} from "../../app/Consts"

import { 
    OrganisationShortNameFormatter 
} from "../../layouts/formatter/OrganisationShortNameFormatter"

import { 
    PlaceFormatter 
} from "../../layouts/formatter/PlaceFormatter"

import { 
    StorageUnitTypeFormatter 
} from "../../layouts/formatter/StorageUnitTypeFormatter"

import { 
    IsInStorageUnitIdFormatter 
} from "../../layouts/formatter/IsInStorageUnitIdFormatter"

import { 
    useEditorStatus
} from "../../hooks/useEditorStatus"

import { 
    useIsOnAdminPage 
} from '../../hooks/useIsOnAdminPage'

import { 
    useActiveOrganisationId 
} from '../../hooks/useActiveOrganisationId'


// Define interfaces
interface StorageUnitTableProps {
    storageUnitsForTable: GetStorageUnitResponse[],
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const StorageUnitTable: React.FC<StorageUnitTableProps> = ({
    storageUnitsForTable
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()    
    const navigate = useNavigate()

    
    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(storageUnitsForTable.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })

   
    useEffect(() => {
        setPage(1)
    }, [pageSize])  

  
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(storageUnitsForTable, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, storageUnitsForTable])

    
    const activeOrganisationId = useActiveOrganisationId()
    const isEditor = useEditorStatus(activeOrganisationId)
    const isOnAdminPage = useIsOnAdminPage()
              
    
    const actionItems = (storageUnitId: number) => {
        if (isEditor || isOnAdminPage) {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setStorageUnitSingleId(storageUnitId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />                  
                    </ActionIcon>
                    <ActionIcon                      
                        onClick={() => {  
                            dispatch(setStorageUnitSingleId(storageUnitId))           
                            navigate("update")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.EDIT} 
                            size = {16}
                            color="blue"
                        />      
                    </ActionIcon>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setStorageUnitSingleId(storageUnitId))
                            navigate("printSingle")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.PRINT} 
                            size = {16}                        
                            color="black" 
                        />                  
                    </ActionIcon>
                    <ActionIcon                     
                        onClick={() => {
                            dispatch(setStorageUnitSingleId(storageUnitId))
                            navigate("delete")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.DELETE} 
                            size = {16}
                            color="red" 
                        />   
                    </ActionIcon>
                </Group>
            )
        } else {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setStorageUnitSingleId(storageUnitId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />                  
                    </ActionIcon>
                </Group>
            )
        }
    }

    const columns = [
        {
            accessor: "id",
            title: "id",
            sortable: true,            
            hidden: !isOnAdminPage
        },
        {
            accessor: "storageUnitInfo.displayName",
            title: t("StorageUnits.displayName"),
            sortable: true,
        },
        {
            accessor: "storageUnitInfo.storageUnitType",
            title: t("StorageUnits.storageUnitType"),
            sortable: true,
            render: (storageUnit: GetStorageUnitResponse) => StorageUnitTypeFormatter(storageUnit.storageUnitInfo!.storageUnitType),
        },
        {
            accessor: "storageUnitInfo.isInStorageUnitId",
            title: t("StorageUnits.isInStorageUnitId"),
            sortable: true,
            render: (storageUnit: GetStorageUnitResponse) => IsInStorageUnitIdFormatter(storageUnit.storageUnitInfo!.isInStorageUnitId),            
        },
        {
            accessor: "storageUnitInfo.placeId",
            title: `${t("Places.room")}, ${t("Places.rack")}`,
            sortable: true,
            render: (storageUnit: GetStorageUnitResponse) => PlaceFormatter(storageUnit.storageUnitInfo!.placeId),
        },
        {
            accessor: "storageUnitInfo.organisationId",
            title: `${t("Storeroom.organisation")}`,
            sortable: true,
            render: (storageUnit: GetStorageUnitResponse) => OrganisationShortNameFormatter(storageUnit.storageUnitInfo!.organisationId),
            hidden: !isOnAdminPage,
        }, 
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            render: (storageUnit: GetStorageUnitResponse) => actionItems(storageUnit.id)
        },
    ]

    return (
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={storageUnitsForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
        /> 
    )
}  