// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation
} from "react-i18next"

import {
    Card,
} from "@mantine/core"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    ItemUpdateForm
} from "../../components/item/ItemUpdateForm"

import { 
    selectItemsById
} from "../../features/item/itemsSlice"

import {
    selectItemSingleId,
} from "../../features/item/itemSingleIdSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToViewPageButton
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"

import { 
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

import { 
    LastEntryItemButton 
} from "../../components/buttons/lastEntry/LastEntryItemButton"

import { 
    NextEntryItemButton 
} from "../../components/buttons/nextEntry/ForwardBackItemBar"



/**
 * The UpdateItemsPage component renders the UpdateItems page of the application.
 */
export const UpdateItemsPage = () => {
    const { t } = useTranslation()   
   
    const items = useAppSelector(selectItemsById)
    const editItemId = useAppSelector(selectItemSingleId)


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editItemId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }


    return(
        <Card>   
            <h1>{t("Items.updateText")}</h1>
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryItemButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryItemButton/>}
            /> 
            <ItemUpdateForm 
                itemId = {editItemId}
                initialItemInfo = {items[editItemId].itemInfo!}
                updateUser = {items[editItemId].updateUser}
                updateTime = {items[editItemId].updateTime!}
                deleted = {items[editItemId].deleted!}
            />
        </Card>      
    )
}