// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const TableOfFaq = () => {
    return (
        <nav aria-label="Table of contents">
            Hello world!
        </nav>
    )
}