// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    AdminExternalStoragePage 
} from "../../pages/externalStorage/AdminExternalStoragePage"

import { 
    CreateExternalStoragePage 
} from "../../pages/externalStorage/CreateExternalStoragePage"

import { 
    UpdateExternalStoragePage 
} from "../../pages/externalStorage/UpdateExternalStoragePage"

import { 
    DeleteExternalStoragePage 
} from "../../pages/externalStorage/DeleteExternalStoragePage"

import { 
    ConfirmExternalStoragePage
} from "../../pages/externalStorage/ConfirmExternalStoragePage"


export const AdminRoutesExternalStorages = () => {
    return (
        <Routes>                
            <Route
                index
                element={<AdminExternalStoragePage/>}                            
            />
            <Route
                path="create"
                element={<CreateExternalStoragePage/>}                            
            />
            <Route
                path="update"
                element={<UpdateExternalStoragePage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteExternalStoragePage/>}                            
            />
            <Route
                path="confirm/*"                           
            >
                <Route
                    index
                    element={<ConfirmExternalStoragePage/>}                            
                />
                <Route
                    path="update"
                    element={<UpdateExternalStoragePage/>}                            
                />
            </Route>
        </Routes>
    )
}    
