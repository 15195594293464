/* eslint-disable */ 
/**
 * @fileoverview gRPC-Web generated client stub for sip.storeroom
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: sip/storeroom/storeroom.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.sip = {};
proto.sip.storeroom = require('./storeroom_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sip.storeroom.StoreroomClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sip.storeroom.StoreroomPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreateOrganisationRequest,
 *   !proto.sip.storeroom.CreateOrganisationResponse>}
 */
const methodDescriptor_Storeroom_CreateOrganisation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreateOrganisation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreateOrganisationRequest,
  proto.sip.storeroom.CreateOrganisationResponse,
  /**
   * @param {!proto.sip.storeroom.CreateOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreateOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreateOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreateOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateOrganisation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreateOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeleteOrganisationRequest,
 *   !proto.sip.storeroom.DeleteOrganisationResponse>}
 */
const methodDescriptor_Storeroom_DeleteOrganisation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeleteOrganisation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeleteOrganisationRequest,
  proto.sip.storeroom.DeleteOrganisationResponse,
  /**
   * @param {!proto.sip.storeroom.DeleteOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeleteOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeleteOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeleteOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeleteOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deleteOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteOrganisation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeleteOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeleteOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deleteOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateOrganisationRequest,
 *   !proto.sip.storeroom.UpdateOrganisationResponse>}
 */
const methodDescriptor_Storeroom_UpdateOrganisation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateOrganisation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateOrganisationRequest,
  proto.sip.storeroom.UpdateOrganisationResponse,
  /**
   * @param {!proto.sip.storeroom.UpdateOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdateOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdateOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdateOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateOrganisation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdateOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.GetOrganisationRequest,
 *   !proto.sip.storeroom.GetOrganisationResponse>}
 */
const methodDescriptor_Storeroom_GetOrganisation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/GetOrganisation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.GetOrganisationRequest,
  proto.sip.storeroom.GetOrganisationResponse,
  /**
   * @param {!proto.sip.storeroom.GetOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.GetOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.GetOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.GetOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.GetOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.getOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetOrganisation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.GetOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.GetOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.getOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetOrganisation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListOrganisationsRequest,
 *   !proto.sip.storeroom.ListOrganisationsResponse>}
 */
const methodDescriptor_Storeroom_ListOrganisations = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListOrganisations',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListOrganisationsRequest,
  proto.sip.storeroom.ListOrganisationsResponse,
  /**
   * @param {!proto.sip.storeroom.ListOrganisationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListOrganisationsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListOrganisationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListOrganisationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listOrganisations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisations,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListOrganisationsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listOrganisations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreateItemRequest,
 *   !proto.sip.storeroom.CreateItemResponse>}
 */
const methodDescriptor_Storeroom_CreateItem = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreateItem',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreateItemRequest,
  proto.sip.storeroom.CreateItemResponse,
  /**
   * @param {!proto.sip.storeroom.CreateItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreateItemResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreateItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreateItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreateItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateItem,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreateItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreateItemResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeleteItemRequest,
 *   !proto.sip.storeroom.DeleteItemResponse>}
 */
const methodDescriptor_Storeroom_DeleteItem = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeleteItem',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeleteItemRequest,
  proto.sip.storeroom.DeleteItemResponse,
  /**
   * @param {!proto.sip.storeroom.DeleteItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeleteItemResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeleteItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeleteItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeleteItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deleteItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteItem,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeleteItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeleteItemResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deleteItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateItemRequest,
 *   !proto.sip.storeroom.UpdateItemResponse>}
 */
const methodDescriptor_Storeroom_UpdateItem = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateItem',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateItemRequest,
  proto.sip.storeroom.UpdateItemResponse,
  /**
   * @param {!proto.sip.storeroom.UpdateItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdateItemResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdateItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdateItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateItem,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdateItemResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.GetItemRequest,
 *   !proto.sip.storeroom.GetItemResponse>}
 */
const methodDescriptor_Storeroom_GetItem = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/GetItem',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.GetItemRequest,
  proto.sip.storeroom.GetItemResponse,
  /**
   * @param {!proto.sip.storeroom.GetItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.GetItemResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.GetItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.GetItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.GetItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.getItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetItem,
      callback);
};


/**
 * @param {!proto.sip.storeroom.GetItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.GetItemResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.getItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetItem',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListRentableItemsRequest,
 *   !proto.sip.storeroom.ListRentableItemsResponse>}
 */
const methodDescriptor_Storeroom_ListRentableItems = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListRentableItems',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListRentableItemsRequest,
  proto.sip.storeroom.ListRentableItemsResponse,
  /**
   * @param {!proto.sip.storeroom.ListRentableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListRentableItemsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListRentableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListRentableItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListRentableItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listRentableItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListRentableItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListRentableItems,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListRentableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListRentableItemsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listRentableItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListRentableItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListRentableItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListOrganisationItemsRequest,
 *   !proto.sip.storeroom.ListOrganisationItemsResponse>}
 */
const methodDescriptor_Storeroom_ListOrganisationItems = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListOrganisationItems',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListOrganisationItemsRequest,
  proto.sip.storeroom.ListOrganisationItemsResponse,
  /**
   * @param {!proto.sip.storeroom.ListOrganisationItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListOrganisationItemsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListOrganisationItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListOrganisationItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListOrganisationItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listOrganisationItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationItems,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListOrganisationItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListOrganisationItemsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listOrganisationItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListItemsRequest,
 *   !proto.sip.storeroom.ListItemsResponse>}
 */
const methodDescriptor_Storeroom_ListItems = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListItems',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListItemsRequest,
  proto.sip.storeroom.ListItemsResponse,
  /**
   * @param {!proto.sip.storeroom.ListItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListItemsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListItems,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListItemsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreateTagRequest,
 *   !proto.sip.storeroom.CreateTagResponse>}
 */
const methodDescriptor_Storeroom_CreateTag = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreateTag',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreateTagRequest,
  proto.sip.storeroom.CreateTagResponse,
  /**
   * @param {!proto.sip.storeroom.CreateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreateTagResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreateTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreateTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateTag',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateTag,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreateTagResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateTag',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeleteTagRequest,
 *   !proto.sip.storeroom.DeleteTagResponse>}
 */
const methodDescriptor_Storeroom_DeleteTag = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeleteTag',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeleteTagRequest,
  proto.sip.storeroom.DeleteTagResponse,
  /**
   * @param {!proto.sip.storeroom.DeleteTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeleteTagResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeleteTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeleteTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deleteTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteTag',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteTag,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeleteTagResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deleteTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteTag',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateTagRequest,
 *   !proto.sip.storeroom.UpdateTagResponse>}
 */
const methodDescriptor_Storeroom_UpdateTag = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateTag',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateTagRequest,
  proto.sip.storeroom.UpdateTagResponse,
  /**
   * @param {!proto.sip.storeroom.UpdateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdateTagResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdateTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdateTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateTag',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateTag,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdateTagResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateTag',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListTagsRequest,
 *   !proto.sip.storeroom.ListTagsResponse>}
 */
const methodDescriptor_Storeroom_ListTags = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListTags',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListTagsRequest,
  proto.sip.storeroom.ListTagsResponse,
  /**
   * @param {!proto.sip.storeroom.ListTagsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListTagsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListTagsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListTagsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListTags',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListTags,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListTagsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListTags',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreateStorageUnitRequest,
 *   !proto.sip.storeroom.CreateStorageUnitResponse>}
 */
const methodDescriptor_Storeroom_CreateStorageUnit = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreateStorageUnit',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreateStorageUnitRequest,
  proto.sip.storeroom.CreateStorageUnitResponse,
  /**
   * @param {!proto.sip.storeroom.CreateStorageUnitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreateStorageUnitResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreateStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreateStorageUnitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreateStorageUnitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createStorageUnit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateStorageUnit,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreateStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreateStorageUnitResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createStorageUnit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateStorageUnit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeleteStorageUnitRequest,
 *   !proto.sip.storeroom.DeleteStorageUnitResponse>}
 */
const methodDescriptor_Storeroom_DeleteStorageUnit = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeleteStorageUnit',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeleteStorageUnitRequest,
  proto.sip.storeroom.DeleteStorageUnitResponse,
  /**
   * @param {!proto.sip.storeroom.DeleteStorageUnitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeleteStorageUnitResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeleteStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeleteStorageUnitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeleteStorageUnitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deleteStorageUnit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteStorageUnit,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeleteStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeleteStorageUnitResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deleteStorageUnit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteStorageUnit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateStorageUnitRequest,
 *   !proto.sip.storeroom.UpdateStorageUnitResponse>}
 */
const methodDescriptor_Storeroom_UpdateStorageUnit = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateStorageUnit',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateStorageUnitRequest,
  proto.sip.storeroom.UpdateStorageUnitResponse,
  /**
   * @param {!proto.sip.storeroom.UpdateStorageUnitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdateStorageUnitResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdateStorageUnitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdateStorageUnitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateStorageUnit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateStorageUnit,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdateStorageUnitResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateStorageUnit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateStorageUnit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.GetStorageUnitRequest,
 *   !proto.sip.storeroom.GetStorageUnitResponse>}
 */
const methodDescriptor_Storeroom_GetStorageUnit = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/GetStorageUnit',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.GetStorageUnitRequest,
  proto.sip.storeroom.GetStorageUnitResponse,
  /**
   * @param {!proto.sip.storeroom.GetStorageUnitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.GetStorageUnitResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.GetStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.GetStorageUnitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.GetStorageUnitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.getStorageUnit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetStorageUnit,
      callback);
};


/**
 * @param {!proto.sip.storeroom.GetStorageUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.GetStorageUnitResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.getStorageUnit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetStorageUnit',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetStorageUnit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListOrganisationStorageUnitsRequest,
 *   !proto.sip.storeroom.ListOrganisationStorageUnitsResponse>}
 */
const methodDescriptor_Storeroom_ListOrganisationStorageUnits = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListOrganisationStorageUnits',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListOrganisationStorageUnitsRequest,
  proto.sip.storeroom.ListOrganisationStorageUnitsResponse,
  /**
   * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListOrganisationStorageUnitsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListOrganisationStorageUnitsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListOrganisationStorageUnitsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listOrganisationStorageUnits =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationStorageUnits',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationStorageUnits,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListOrganisationStorageUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListOrganisationStorageUnitsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listOrganisationStorageUnits =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationStorageUnits',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationStorageUnits);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListStorageUnitItemsRequest,
 *   !proto.sip.storeroom.ListStorageUnitItemsResponse>}
 */
const methodDescriptor_Storeroom_ListStorageUnitItems = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListStorageUnitItems',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListStorageUnitItemsRequest,
  proto.sip.storeroom.ListStorageUnitItemsResponse,
  /**
   * @param {!proto.sip.storeroom.ListStorageUnitItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListStorageUnitItemsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListStorageUnitItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListStorageUnitItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListStorageUnitItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listStorageUnitItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListStorageUnitItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListStorageUnitItems,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListStorageUnitItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListStorageUnitItemsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listStorageUnitItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListStorageUnitItems',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListStorageUnitItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListStorageUnitsRequest,
 *   !proto.sip.storeroom.ListStorageUnitsResponse>}
 */
const methodDescriptor_Storeroom_ListStorageUnits = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListStorageUnits',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListStorageUnitsRequest,
  proto.sip.storeroom.ListStorageUnitsResponse,
  /**
   * @param {!proto.sip.storeroom.ListStorageUnitsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListStorageUnitsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListStorageUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListStorageUnitsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListStorageUnitsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listStorageUnits =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListStorageUnits',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListStorageUnits,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListStorageUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListStorageUnitsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listStorageUnits =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListStorageUnits',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListStorageUnits);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreatePlaceRequest,
 *   !proto.sip.storeroom.CreatePlaceResponse>}
 */
const methodDescriptor_Storeroom_CreatePlace = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreatePlace',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreatePlaceRequest,
  proto.sip.storeroom.CreatePlaceResponse,
  /**
   * @param {!proto.sip.storeroom.CreatePlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreatePlaceResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreatePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreatePlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreatePlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createPlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreatePlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreatePlace,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreatePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreatePlaceResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createPlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreatePlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreatePlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeletePlaceRequest,
 *   !proto.sip.storeroom.DeletePlaceResponse>}
 */
const methodDescriptor_Storeroom_DeletePlace = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeletePlace',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeletePlaceRequest,
  proto.sip.storeroom.DeletePlaceResponse,
  /**
   * @param {!proto.sip.storeroom.DeletePlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeletePlaceResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeletePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeletePlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeletePlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deletePlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeletePlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeletePlace,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeletePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeletePlaceResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deletePlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeletePlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeletePlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdatePlaceRequest,
 *   !proto.sip.storeroom.UpdatePlaceResponse>}
 */
const methodDescriptor_Storeroom_UpdatePlace = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdatePlace',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdatePlaceRequest,
  proto.sip.storeroom.UpdatePlaceResponse,
  /**
   * @param {!proto.sip.storeroom.UpdatePlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdatePlaceResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdatePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdatePlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdatePlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updatePlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdatePlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdatePlace,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdatePlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdatePlaceResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updatePlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdatePlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdatePlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.GetPlaceRequest,
 *   !proto.sip.storeroom.GetPlaceResponse>}
 */
const methodDescriptor_Storeroom_GetPlace = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/GetPlace',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.GetPlaceRequest,
  proto.sip.storeroom.GetPlaceResponse,
  /**
   * @param {!proto.sip.storeroom.GetPlaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.GetPlaceResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.GetPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.GetPlaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.GetPlaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.getPlace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetPlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetPlace,
      callback);
};


/**
 * @param {!proto.sip.storeroom.GetPlaceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.GetPlaceResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.getPlace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetPlace',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetPlace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListPlaceStorageUnitsRequest,
 *   !proto.sip.storeroom.ListPlaceStorageUnitsResponse>}
 */
const methodDescriptor_Storeroom_ListPlaceStorageUnits = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListPlaceStorageUnits',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListPlaceStorageUnitsRequest,
  proto.sip.storeroom.ListPlaceStorageUnitsResponse,
  /**
   * @param {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListPlaceStorageUnitsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListPlaceStorageUnitsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListPlaceStorageUnitsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listPlaceStorageUnits =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListPlaceStorageUnits',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListPlaceStorageUnits,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListPlaceStorageUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListPlaceStorageUnitsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listPlaceStorageUnits =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListPlaceStorageUnits',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListPlaceStorageUnits);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListOrganisationPlacesRequest,
 *   !proto.sip.storeroom.ListOrganisationPlacesResponse>}
 */
const methodDescriptor_Storeroom_ListOrganisationPlaces = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListOrganisationPlaces',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListOrganisationPlacesRequest,
  proto.sip.storeroom.ListOrganisationPlacesResponse,
  /**
   * @param {!proto.sip.storeroom.ListOrganisationPlacesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListOrganisationPlacesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListOrganisationPlacesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListOrganisationPlacesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListOrganisationPlacesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listOrganisationPlaces =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationPlaces',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationPlaces,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListOrganisationPlacesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListOrganisationPlacesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listOrganisationPlaces =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationPlaces',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationPlaces);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListPlacesRequest,
 *   !proto.sip.storeroom.ListPlacesResponse>}
 */
const methodDescriptor_Storeroom_ListPlaces = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListPlaces',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListPlacesRequest,
  proto.sip.storeroom.ListPlacesResponse,
  /**
   * @param {!proto.sip.storeroom.ListPlacesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListPlacesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListPlacesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListPlacesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListPlacesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listPlaces =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListPlaces',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListPlaces,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListPlacesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListPlacesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listPlaces =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListPlaces',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListPlaces);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreateReservationRequest,
 *   !proto.sip.storeroom.CreateReservationResponse>}
 */
const methodDescriptor_Storeroom_CreateReservation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreateReservation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreateReservationRequest,
  proto.sip.storeroom.CreateReservationResponse,
  /**
   * @param {!proto.sip.storeroom.CreateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreateReservationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreateReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreateReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateReservation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreateReservationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeleteReservationRequest,
 *   !proto.sip.storeroom.DeleteReservationResponse>}
 */
const methodDescriptor_Storeroom_DeleteReservation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeleteReservation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeleteReservationRequest,
  proto.sip.storeroom.DeleteReservationResponse,
  /**
   * @param {!proto.sip.storeroom.DeleteReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeleteReservationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeleteReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeleteReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeleteReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deleteReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteReservation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeleteReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeleteReservationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deleteReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateReservationRequest,
 *   !proto.sip.storeroom.UpdateReservationResponse>}
 */
const methodDescriptor_Storeroom_UpdateReservation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateReservation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateReservationRequest,
  proto.sip.storeroom.UpdateReservationResponse,
  /**
   * @param {!proto.sip.storeroom.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdateReservationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdateReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdateReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateReservation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdateReservationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ConfirmReservationRequest,
 *   !proto.sip.storeroom.ConfirmReservationResponse>}
 */
const methodDescriptor_Storeroom_ConfirmReservation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ConfirmReservation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ConfirmReservationRequest,
  proto.sip.storeroom.ConfirmReservationResponse,
  /**
   * @param {!proto.sip.storeroom.ConfirmReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ConfirmReservationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ConfirmReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ConfirmReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ConfirmReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.confirmReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ConfirmReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ConfirmReservation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ConfirmReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ConfirmReservationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.confirmReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ConfirmReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ConfirmReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ConfirmReturnReservationRequest,
 *   !proto.sip.storeroom.ConfirmReturnReservationResponse>}
 */
const methodDescriptor_Storeroom_ConfirmReturnReservation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ConfirmReturnReservation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ConfirmReturnReservationRequest,
  proto.sip.storeroom.ConfirmReturnReservationResponse,
  /**
   * @param {!proto.sip.storeroom.ConfirmReturnReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ConfirmReturnReservationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ConfirmReturnReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ConfirmReturnReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ConfirmReturnReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.confirmReturnReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ConfirmReturnReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ConfirmReturnReservation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ConfirmReturnReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ConfirmReturnReservationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.confirmReturnReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ConfirmReturnReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ConfirmReturnReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.GetReservationRequest,
 *   !proto.sip.storeroom.GetReservationResponse>}
 */
const methodDescriptor_Storeroom_GetReservation = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/GetReservation',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.GetReservationRequest,
  proto.sip.storeroom.GetReservationResponse,
  /**
   * @param {!proto.sip.storeroom.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.GetReservationResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.GetReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.GetReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.getReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetReservation,
      callback);
};


/**
 * @param {!proto.sip.storeroom.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.GetReservationResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.getReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetReservation',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListMyUserReservationsRequest,
 *   !proto.sip.storeroom.ListMyUserReservationsResponse>}
 */
const methodDescriptor_Storeroom_ListMyUserReservations = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListMyUserReservations',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListMyUserReservationsRequest,
  proto.sip.storeroom.ListMyUserReservationsResponse,
  /**
   * @param {!proto.sip.storeroom.ListMyUserReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListMyUserReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListMyUserReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListMyUserReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListMyUserReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listMyUserReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListMyUserReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListMyUserReservations,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListMyUserReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListMyUserReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listMyUserReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListMyUserReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListMyUserReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListMyConfirmedReservationsRequest,
 *   !proto.sip.storeroom.ListMyConfirmedReservationsResponse>}
 */
const methodDescriptor_Storeroom_ListMyConfirmedReservations = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListMyConfirmedReservations',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListMyConfirmedReservationsRequest,
  proto.sip.storeroom.ListMyConfirmedReservationsResponse,
  /**
   * @param {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListMyConfirmedReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListMyConfirmedReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListMyConfirmedReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listMyConfirmedReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListMyConfirmedReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListMyConfirmedReservations,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListMyConfirmedReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListMyConfirmedReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listMyConfirmedReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListMyConfirmedReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListMyConfirmedReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListOrganisationReservationsRequest,
 *   !proto.sip.storeroom.ListOrganisationReservationsResponse>}
 */
const methodDescriptor_Storeroom_ListOrganisationReservations = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListOrganisationReservations',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListOrganisationReservationsRequest,
  proto.sip.storeroom.ListOrganisationReservationsResponse,
  /**
   * @param {!proto.sip.storeroom.ListOrganisationReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListOrganisationReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListOrganisationReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListOrganisationReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListOrganisationReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listOrganisationReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationReservations,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListOrganisationReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListOrganisationReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listOrganisationReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListOwnerReservationsRequest,
 *   !proto.sip.storeroom.ListOwnerReservationsResponse>}
 */
const methodDescriptor_Storeroom_ListOwnerReservations = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListOwnerReservations',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListOwnerReservationsRequest,
  proto.sip.storeroom.ListOwnerReservationsResponse,
  /**
   * @param {!proto.sip.storeroom.ListOwnerReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListOwnerReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListOwnerReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListOwnerReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListOwnerReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listOwnerReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOwnerReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOwnerReservations,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListOwnerReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListOwnerReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listOwnerReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOwnerReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOwnerReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListReservationsRequest,
 *   !proto.sip.storeroom.ListReservationsResponse>}
 */
const methodDescriptor_Storeroom_ListReservations = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListReservations',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListReservationsRequest,
  proto.sip.storeroom.ListReservationsResponse,
  /**
   * @param {!proto.sip.storeroom.ListReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListReservations,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListReservations',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreateExternalStorageRequest,
 *   !proto.sip.storeroom.CreateExternalStorageResponse>}
 */
const methodDescriptor_Storeroom_CreateExternalStorage = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreateExternalStorage',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreateExternalStorageRequest,
  proto.sip.storeroom.CreateExternalStorageResponse,
  /**
   * @param {!proto.sip.storeroom.CreateExternalStorageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreateExternalStorageResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreateExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreateExternalStorageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreateExternalStorageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createExternalStorage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateExternalStorage,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreateExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreateExternalStorageResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createExternalStorage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateExternalStorage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeleteExternalStorageRequest,
 *   !proto.sip.storeroom.DeleteExternalStorageResponse>}
 */
const methodDescriptor_Storeroom_DeleteExternalStorage = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeleteExternalStorage',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeleteExternalStorageRequest,
  proto.sip.storeroom.DeleteExternalStorageResponse,
  /**
   * @param {!proto.sip.storeroom.DeleteExternalStorageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeleteExternalStorageResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeleteExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeleteExternalStorageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeleteExternalStorageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deleteExternalStorage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteExternalStorage,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeleteExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeleteExternalStorageResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deleteExternalStorage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteExternalStorage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateExternalStorageRequest,
 *   !proto.sip.storeroom.UpdateExternalStorageResponse>}
 */
const methodDescriptor_Storeroom_UpdateExternalStorage = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateExternalStorage',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateExternalStorageRequest,
  proto.sip.storeroom.UpdateExternalStorageResponse,
  /**
   * @param {!proto.sip.storeroom.UpdateExternalStorageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdateExternalStorageResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdateExternalStorageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdateExternalStorageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateExternalStorage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateExternalStorage,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdateExternalStorageResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateExternalStorage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateExternalStorage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ConfirmExternalStorageRequest,
 *   !proto.sip.storeroom.ConfirmExternalStorageResponse>}
 */
const methodDescriptor_Storeroom_ConfirmExternalStorage = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ConfirmExternalStorage',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ConfirmExternalStorageRequest,
  proto.sip.storeroom.ConfirmExternalStorageResponse,
  /**
   * @param {!proto.sip.storeroom.ConfirmExternalStorageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ConfirmExternalStorageResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ConfirmExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ConfirmExternalStorageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ConfirmExternalStorageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.confirmExternalStorage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ConfirmExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ConfirmExternalStorage,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ConfirmExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ConfirmExternalStorageResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.confirmExternalStorage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ConfirmExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ConfirmExternalStorage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.GetExternalStorageRequest,
 *   !proto.sip.storeroom.GetExternalStorageResponse>}
 */
const methodDescriptor_Storeroom_GetExternalStorage = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/GetExternalStorage',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.GetExternalStorageRequest,
  proto.sip.storeroom.GetExternalStorageResponse,
  /**
   * @param {!proto.sip.storeroom.GetExternalStorageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.GetExternalStorageResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.GetExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.GetExternalStorageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.GetExternalStorageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.getExternalStorage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetExternalStorage,
      callback);
};


/**
 * @param {!proto.sip.storeroom.GetExternalStorageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.GetExternalStorageResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.getExternalStorage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetExternalStorage',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetExternalStorage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListOrganisationExternalStoragesRequest,
 *   !proto.sip.storeroom.ListOrganisationExternalStoragesResponse>}
 */
const methodDescriptor_Storeroom_ListOrganisationExternalStorages = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListOrganisationExternalStorages',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListOrganisationExternalStoragesRequest,
  proto.sip.storeroom.ListOrganisationExternalStoragesResponse,
  /**
   * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListOrganisationExternalStoragesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListOrganisationExternalStoragesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListOrganisationExternalStoragesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listOrganisationExternalStorages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationExternalStorages',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationExternalStorages,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListOrganisationExternalStoragesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListOrganisationExternalStoragesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listOrganisationExternalStorages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListOrganisationExternalStorages',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListOrganisationExternalStorages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListExternalStoragesRequest,
 *   !proto.sip.storeroom.ListExternalStoragesResponse>}
 */
const methodDescriptor_Storeroom_ListExternalStorages = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListExternalStorages',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListExternalStoragesRequest,
  proto.sip.storeroom.ListExternalStoragesResponse,
  /**
   * @param {!proto.sip.storeroom.ListExternalStoragesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListExternalStoragesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListExternalStoragesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListExternalStoragesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListExternalStoragesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listExternalStorages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListExternalStorages',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListExternalStorages,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListExternalStoragesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListExternalStoragesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listExternalStorages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListExternalStorages',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListExternalStorages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.CreateExternalStorageDateRequest,
 *   !proto.sip.storeroom.CreateExternalStorageDateResponse>}
 */
const methodDescriptor_Storeroom_CreateExternalStorageDate = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/CreateExternalStorageDate',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.CreateExternalStorageDateRequest,
  proto.sip.storeroom.CreateExternalStorageDateResponse,
  /**
   * @param {!proto.sip.storeroom.CreateExternalStorageDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.CreateExternalStorageDateResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.CreateExternalStorageDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.CreateExternalStorageDateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.CreateExternalStorageDateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.createExternalStorageDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateExternalStorageDate',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateExternalStorageDate,
      callback);
};


/**
 * @param {!proto.sip.storeroom.CreateExternalStorageDateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.CreateExternalStorageDateResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.createExternalStorageDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/CreateExternalStorageDate',
      request,
      metadata || {},
      methodDescriptor_Storeroom_CreateExternalStorageDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.DeleteExternalStorageDateRequest,
 *   !proto.sip.storeroom.DeleteExternalStorageDateResponse>}
 */
const methodDescriptor_Storeroom_DeleteExternalStorageDate = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/DeleteExternalStorageDate',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.DeleteExternalStorageDateRequest,
  proto.sip.storeroom.DeleteExternalStorageDateResponse,
  /**
   * @param {!proto.sip.storeroom.DeleteExternalStorageDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.DeleteExternalStorageDateResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.DeleteExternalStorageDateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.DeleteExternalStorageDateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.deleteExternalStorageDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteExternalStorageDate',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteExternalStorageDate,
      callback);
};


/**
 * @param {!proto.sip.storeroom.DeleteExternalStorageDateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.DeleteExternalStorageDateResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.deleteExternalStorageDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/DeleteExternalStorageDate',
      request,
      metadata || {},
      methodDescriptor_Storeroom_DeleteExternalStorageDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateExternalStorageDateRequest,
 *   !proto.sip.storeroom.UpdateExternalStorageDateResponse>}
 */
const methodDescriptor_Storeroom_UpdateExternalStorageDate = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateExternalStorageDate',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateExternalStorageDateRequest,
  proto.sip.storeroom.UpdateExternalStorageDateResponse,
  /**
   * @param {!proto.sip.storeroom.UpdateExternalStorageDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UpdateExternalStorageDateResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UpdateExternalStorageDateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UpdateExternalStorageDateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateExternalStorageDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateExternalStorageDate',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateExternalStorageDate,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateExternalStorageDateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UpdateExternalStorageDateResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateExternalStorageDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateExternalStorageDate',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateExternalStorageDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListExternalStorageDatesRequest,
 *   !proto.sip.storeroom.ListExternalStorageDatesResponse>}
 */
const methodDescriptor_Storeroom_ListExternalStorageDates = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListExternalStorageDates',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListExternalStorageDatesRequest,
  proto.sip.storeroom.ListExternalStorageDatesResponse,
  /**
   * @param {!proto.sip.storeroom.ListExternalStorageDatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListExternalStorageDatesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListExternalStorageDatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListExternalStorageDatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListExternalStorageDatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listExternalStorageDates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListExternalStorageDates',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListExternalStorageDates,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListExternalStorageDatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListExternalStorageDatesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listExternalStorageDates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListExternalStorageDates',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListExternalStorageDates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.sip.storeroom.UserAttributes>}
 */
const methodDescriptor_Storeroom_GetUser = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/GetUser',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.sip.storeroom.UserAttributes,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UserAttributes.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UserAttributes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UserAttributes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetUser',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetUser,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UserAttributes>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/GetUser',
      request,
      metadata || {},
      methodDescriptor_Storeroom_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.UpdateUserRequest,
 *   !proto.sip.storeroom.UserAttributes>}
 */
const methodDescriptor_Storeroom_UpdateUser = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.UpdateUserRequest,
  proto.sip.storeroom.UserAttributes,
  /**
   * @param {!proto.sip.storeroom.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.UserAttributes.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.UserAttributes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.UserAttributes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateUser,
      callback);
};


/**
 * @param {!proto.sip.storeroom.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.UserAttributes>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_Storeroom_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.storeroom.ListUsersRequest,
 *   !proto.sip.storeroom.ListUsersResponse>}
 */
const methodDescriptor_Storeroom_ListUsers = new grpc.web.MethodDescriptor(
  '/sip.storeroom.Storeroom/ListUsers',
  grpc.web.MethodType.UNARY,
  proto.sip.storeroom.ListUsersRequest,
  proto.sip.storeroom.ListUsersResponse,
  /**
   * @param {!proto.sip.storeroom.ListUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.storeroom.ListUsersResponse.deserializeBinary
);


/**
 * @param {!proto.sip.storeroom.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.storeroom.ListUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.storeroom.ListUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.storeroom.StoreroomClient.prototype.listUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListUsers',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListUsers,
      callback);
};


/**
 * @param {!proto.sip.storeroom.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.storeroom.ListUsersResponse>}
 *     Promise that resolves to the response
 */
proto.sip.storeroom.StoreroomPromiseClient.prototype.listUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.storeroom.Storeroom/ListUsers',
      request,
      metadata || {},
      methodDescriptor_Storeroom_ListUsers);
};


module.exports = proto.sip.storeroom;

