// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Grid
} from '@mantine/core'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useEditorStatus
} from "../../../hooks/useEditorStatus"

import { 
    useIsOnAdminPage 
} from '../../../hooks/useIsOnAdminPage'

import { 
    useActiveOrganisationId 
} from '../../../hooks/useActiveOrganisationId'



interface OrganisationPagesBarProps {
    lastEntryButton: React.ReactNode,
    navigationButton1: React.ReactNode,
    navigationButton2: React.ReactNode,
    navigationTableButton: React.ReactNode,
    nextEntryButton: React.ReactNode,
}


/**
 * This menu bar renders the save, save&close and cancel button.
 */
export const ViewUpdateDeletePagesBar = (props: OrganisationPagesBarProps) => {  
    const activeOrganisationId = useActiveOrganisationId()
    const isEditor = useEditorStatus(activeOrganisationId)
    const isOnAdminPage = useIsOnAdminPage()
    

    const ViewPageOrganisationBar = () => {
        if (isEditor || isOnAdminPage) {
            return (
                <Grid
                    justify="space-between"
                    grow 
                    align="center"
                    gutter="xl"
                >
                    <Grid.Col 
                        sm={4} 
                        md={2}
                    >
                        {props.lastEntryButton}
                    </Grid.Col>
                    <Grid.Col 
                        sm={4} 
                        md={2}
                    >
                        {props.navigationButton1}
                    </Grid.Col>
                    <Grid.Col 
                        sm={6} 
                        md={2}
                    >
                        {props.navigationButton2}
                    </Grid.Col>
                    <Grid.Col 
                        sm={6} 
                        md={2}
                    >
                        {props.navigationTableButton}
                    </Grid.Col>                   
                    <Grid.Col 
                        sm={6} 
                        md={2}
                    >
                        {props.nextEntryButton}
                    </Grid.Col>               
                </Grid>
            )
        } else {
            return (
                <Grid
                    justify="space-between"
                    grow 
                    align="center"
                    gutter="xl"
                >
                    <Grid.Col 
                        sm={4} 
                        md={2}
                    >
                        {props.lastEntryButton}
                    </Grid.Col>
                    <Grid.Col 
                        sm={6} 
                        md={2}
                    >
                        {props.navigationTableButton}
                    </Grid.Col>                   
                    <Grid.Col 
                        sm={6} 
                        md={2}
                    >
                        {props.nextEntryButton}
                    </Grid.Col>               
                </Grid>               
            )
        }
    }
    

    return (
        <ViewPageOrganisationBar/>
    )
} 