// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice,
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata
} from "../../util/proto"

import { 
    selectAccessToken
} from "../auth/authSlice"

import { 
    AppThunk
} from "../../app/store"

import {
    CreateStorageUnitRequest as createStorageUnitRequest, 
    CreateStorageUnitResponse as createStorageUnitResponse,
    DeleteStorageUnitRequest as deleteStorageUnitRequest,
    DeleteStorageUnitResponse as deleteStorageUnitResponse,
    UpdateStorageUnitRequest as updateStorageUnitRequest,
    UpdateStorageUnitResponse as updateStorageUnitResponse,
    GetStorageUnitRequest as getStorageUnitRequest,
    GetStorageUnitResponse as getStorageUnitResponse,
    ListStorageUnitsRequest as listStorageUnitsRequest,
    ListStorageUnitsResponse as listStorageUnitsResponse,    
    ListOrganisationStorageUnitsRequest as listOrganisationStorageUnitsRequest,
    ListOrganisationStorageUnitsResponse as listOrganisationStorageUnitsResponse,
    StorageUnitAttributes as storageUnitAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"


// Define the Types
export type StorageUnitAttributes = storageUnitAttributes.AsObject
type CreateStorageUnitResponse = createStorageUnitResponse.AsObject
type UpdateStorageUnitResponse = updateStorageUnitResponse.AsObject
type DeleteStorageUnitResponse = deleteStorageUnitResponse.AsObject
export type GetStorageUnitResponse = getStorageUnitResponse.AsObject
type ListStorageUnitsResponse = listStorageUnitsResponse.AsObject
type ListOrganisationStorageUnitsResponse = listOrganisationStorageUnitsResponse.AsObject

type StorageUnitDict = {
    [Key: number]: GetStorageUnitResponse
}


// Define which objects the storageunit State have. 
interface StorageUnitState {
    items: StorageUnitDict
    isLoading: boolean
    error?: Error
}


// Define initial state
export const initialState: StorageUnitState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the state
const storageUnits = createSlice({
    name: "storageUnits",
    initialState,
    reducers: {

        // Fetch all storageunits
        fetchAllStorageUnitsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllStorageUnitsSuccess: (state, { payload }: PayloadAction<ListStorageUnitsResponse>) => {
            state.items = {}
            payload.storageUnitsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false
            state.error = undefined
        },
        fetchAllStorageUnitsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch all storageunits of one organisation
        fetchAllOrganisationStorageUnitsStart: (state) => {
            state.isLoading = true            
            state.error = undefined
        },
        fetchAllOrganisationStorageUnitsSuccess: (state, { payload }: PayloadAction<ListOrganisationStorageUnitsResponse>) => {
            state.items = {}
            payload.storageUnitsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllOrganisationStorageUnitsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch one storageunit
        fetchStorageUnitStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchStorageUnitSuccess: (state, { payload }: PayloadAction<GetStorageUnitResponse>) => {
            state.items[payload.id] = payload
            state.isLoading = false
            state.error = undefined
        },
        fetchStorageUnitFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
       
        // Create StorageUnit
        createStorageUnitStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        createStorageUnitSuccess: (state, { payload }: PayloadAction<CreateStorageUnitResponse>) => {
            state.items[payload.storageUnit!.id] = payload.storageUnit!
            state.isLoading = false
            state.error = undefined
        },
        createStorageUnitFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },        
         
        // Update StorageUnit
        updateStorageUnitStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateStorageUnitSuccess: (state, { payload }: PayloadAction<UpdateStorageUnitResponse>) => {
            state.items[payload.storageUnit!.id] = payload.storageUnit!
            state.isLoading = false
            state.error = undefined
        },
        updateStorageUnitFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Delete StorageUnit
        deleteStorageUnitStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        deleteStorageUnitSuccess: (state , { payload }: PayloadAction<DeleteStorageUnitResponse>)  => {
            delete state.items[payload.id]
            state.isLoading = false
            state.error = undefined
        },
        deleteStorageUnitFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },      
    },
})


// Export Reducer
export default storageUnits.reducer


// Define Actions
const {
    fetchAllStorageUnitsStart,
    fetchAllStorageUnitsSuccess,
    fetchAllStorageUnitsFailure,

    fetchAllOrganisationStorageUnitsStart,
    fetchAllOrganisationStorageUnitsSuccess,
    fetchAllOrganisationStorageUnitsFailure,
    
    fetchStorageUnitStart,
    fetchStorageUnitSuccess,
    fetchStorageUnitFailure,

    createStorageUnitStart,
    createStorageUnitSuccess,
    createStorageUnitFailure,    
        
    updateStorageUnitStart,
    updateStorageUnitSuccess,
    updateStorageUnitFailure,

    deleteStorageUnitStart,
    deleteStorageUnitSuccess,
    deleteStorageUnitFailure,
} = storageUnits.actions


// Fetch all storageunits
export const fetchAllStorageUnitsMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllStorageUnitsStart())
    const token = selectAccessToken(getState())
    const request = new listStorageUnitsRequest()

    return storeroomClient
        .listStorageUnits(request, getAuthMetadata(token))
        .then((response: listStorageUnitsResponse) => {
            dispatch(
                fetchAllStorageUnitsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllStorageUnitsFailure(err))
        })
}


// Fetch all storageunits of an organisation
export const fetchAllOrganisationStorageUnitsMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllOrganisationStorageUnitsStart())
    const token = selectAccessToken(getState())
    const request = new listOrganisationStorageUnitsRequest()
    request.setOrganisationId(organisationId)

    return storeroomClient
        .listOrganisationStorageUnits(request, getAuthMetadata(token))
        .then((response: listOrganisationStorageUnitsResponse) => {
            dispatch(fetchAllOrganisationStorageUnitsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllOrganisationStorageUnitsFailure(err))
        })
}


// Fetch one storageunit
export const fetchStorageUnitMessage = (storageunitId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchStorageUnitStart())
    const token = selectAccessToken(getState())
    const request = new getStorageUnitRequest()
    request.setId(storageunitId)

    return storeroomClient
        .getStorageUnit(request, getAuthMetadata(token))
        .then((response: getStorageUnitResponse) => {
            dispatch(fetchStorageUnitSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchStorageUnitFailure(err))
        })
}

// Create one storageunit
export const createStorageUnitMessage = (storageunit: StorageUnitAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(createStorageUnitStart())
    const token = selectAccessToken(getState())
    
    const storageunitData = new storageUnitAttributes()
    storageunitData.setDisplayName(storageunit.displayName)
    storageunitData.setStorageUnitType(storageunit.storageUnitType)
    storageunitData.setIsInStorageUnitId(storageunit.isInStorageUnitId)
    storageunitData.setPlaceId(storageunit.placeId)
    storageunitData.setOrganisationId(storageunit.organisationId)
 
    const request = new createStorageUnitRequest()
    request.setStorageUnitInfo(storageunitData)
     
    return storeroomClient
        .createStorageUnit(request, getAuthMetadata(token))
        .then((response: createStorageUnitResponse) => {
            dispatch(createStorageUnitSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(createStorageUnitFailure(err))
        })
}


// Update one storageunit
export const updateStorageUnitMessage = (storageunitId: number, storageunit: GetStorageUnitResponse): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateStorageUnitStart())
    const token = selectAccessToken(getState())
   
    const storageunitData = new storageUnitAttributes()
    storageunitData.setDisplayName(storageunit.storageUnitInfo!.displayName)
    storageunitData.setStorageUnitType(storageunit.storageUnitInfo!.storageUnitType)
    storageunitData.setIsInStorageUnitId(storageunit.storageUnitInfo!.isInStorageUnitId)
    storageunitData.setPlaceId(storageunit.storageUnitInfo!.placeId)
    storageunitData.setOrganisationId(storageunit.storageUnitInfo!.organisationId) 

    const request = new updateStorageUnitRequest()
    request.setId(storageunitId)
    request.setStorageUnitInfo(storageunitData)

    return storeroomClient
        .updateStorageUnit(request, getAuthMetadata(token))
        .then((response: updateStorageUnitResponse) => {
            dispatch(updateStorageUnitSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateStorageUnitFailure(err))
        })
}


// Delete one storageunit
export const deleteStorageUnitMessage = (storageunitId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(deleteStorageUnitStart())
    const token = selectAccessToken(getState())
    
    const request = new deleteStorageUnitRequest()
    request.setId(storageunitId)

    return storeroomClient
        .deleteStorageUnit(request, getAuthMetadata(token))
        .then((response: deleteStorageUnitResponse) => {
            dispatch(deleteStorageUnitSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(deleteStorageUnitFailure(err))
        })
}


// Selectors
type StorageUnitSliceRoot = {
    storageUnits: ReturnType<typeof storageUnits.reducer>
}

export const selectStorageUnitsById = (state: StorageUnitSliceRoot) =>
    state.storageUnits.items
    
export const selectIsLoadingStorageUnits = (state: StorageUnitSliceRoot) => state.storageUnits.isLoading
export const selectErrorStorageUnits = (state: StorageUnitSliceRoot) => state.storageUnits.error