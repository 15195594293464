// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"

import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminLayoutPage 
} from "../../layouts/pages/AdminLayoutPage"

import { 
    AdminRoutesOrganisations 
} from "./AdminRoutesOrganisations"

import { 
    AdminRoutesPlaces
} from "./AdminRoutesPlaces"

import { 
    AdminRoutesStorageUnits 
} from "./AdminRoutesStorageUnits"

import { 
    AdminRoutesItems 
} from "./AdminRoutesItems"

import { 
    AdminRoutesTags 
} from "./AdminRoutesTags"

import { 
    AdminRoutesReservations 
} from "./AdminRoutesReservations"

import { 
    AdminRoutesExternalStorages 
} from "./AdminRoutesExternalStorages"

import {
    AdminRoutesExternalStorageDates
} from "./AdminRoutesExternalStorageDates"

import { 
    AdminUsersPage 
} from "../../pages/users/AdminUsersPage"

import { 
    NotFoundPage
} from "../../pages/NotFoundPage"


export const AdminRoutes = () => {
    return (
        <Routes>
            <Route element={<AdminLayoutPage/>}>
                <Route
                    index
                    element={<Fragment></Fragment>}                                                                    
                />
                    <Route
                    path="organisations/*"
                    element={<AdminRoutesOrganisations/>}                            
                />               
                <Route
                    path="places/*"
                    element={<AdminRoutesPlaces/>}                            
                />
                <Route
                    path="storageunits/*"
                    element={<AdminRoutesStorageUnits/>}                            
                />
                <Route
                    path="items/*"
                    element={<AdminRoutesItems/>}                            
                />
                <Route
                    path="tags/*"
                    element={<AdminRoutesTags/>}                            
                />
                <Route
                    path="reservations/*"
                    element={<AdminRoutesReservations/>}                            
                />
                <Route
                    path="externalstorage/*"
                    element={<AdminRoutesExternalStorages/>}                            
                />
                <Route
                    path="externalstoragedates/*"
                    element={<AdminRoutesExternalStorageDates/>}                            
                />
                <Route
                    path="users"
                    element={<AdminUsersPage/>}                            
                />
                <Route
                    path="*"
                    element={<NotFoundPage/>}                            
                />
            </Route>
        </Routes>
    )
}             
