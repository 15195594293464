// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff

import { 
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    StorageUnitUpdateForm
} from "../../components/storageunit/StorageUnitUpdateForm"

import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"

import {
    selectStorageUnitSingleId,
} from "../../features/storageunit/storageUnitSingleIdSlices"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import {
    LastEntryStorageUnitButton 
} from "../../components/buttons/lastEntry/LastEntryStorageUnitButton"

import {
    NextEntryStorageUnitButton 
} from "../../components/buttons/nextEntry/NextEntryStorageUnitButton"

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

import { 
    GoFromToViewPageButton
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"


/**
 * The UpdateStorageUnitsPage component renders the UpdateStorageUnitsPage page of the application.
 */
export const UpdateStorageUnitsPage = () => {
    const { t } = useTranslation()
    const storageUnits = useAppSelector(selectStorageUnitsById)
    const editStorageUnitId = useAppSelector(selectStorageUnitSingleId)

    
    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editStorageUnitId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }
  

    return(
        <Card> 
            <h1>{t("StorageUnits.updateText")}</h1>
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryStorageUnitButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryStorageUnitButton/>}
            /> 
            <StorageUnitUpdateForm 
                storageunitId = {editStorageUnitId}
                initialStorageUnitInfo = {storageUnits[editStorageUnitId].storageUnitInfo!}
                updateUser = {storageUnits[editStorageUnitId].updateUser}
                updateTime = {storageUnits[editStorageUnitId].updateTime!}
            />   
        </Card>          
    )
}