// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect,
    useState 
} from "react"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "./useAppSelector"

import { 
    selectUserRoles 
} from "../features/auth/authSlice"

import { 
    selectOrganisationsById 
} from "../features/organisation/organisationsSlice"

/**
 * The Function isAdmin return true if the user has the userrole "editor"
 * 
 */
export const useEditorStatus = (activeOrganisationId: number) => {
    const [îsEditor, setIsEditor] = useState(false)
    const userRoles = useAppSelector(selectUserRoles)
    const organisations = useAppSelector(selectOrganisationsById)

    useEffect(() => {
        if (organisations[activeOrganisationId] !== undefined) {
            setIsEditor(userRoles.indexOf(organisations[activeOrganisationId].organisationInfo!.editRole) > -1)
        }        
    }, [userRoles, organisations, activeOrganisationId])
    
    return îsEditor
}