// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../LoadScreen"

import {
    ErrorScreen
} from "../ErrorScreen"

import { 
    StorageUnitTable 
} from "../../components/storageunit/StorageUnitsTable"

import { 
    selectStorageUnitsById,
    fetchAllOrganisationStorageUnitsMessage,
    selectIsLoadingStorageUnits,
    selectErrorStorageUnits,
} from "../../features/storageunit/storageUnitSlice"

import { 
    fetchAllOrganisationPlacesMessage,
    selectIsLoadingPlaces,
    selectErrorPlaces,
} from "../../features/place/placesSlice"

import { 
    useEditorStatus 
} from "../../hooks/useEditorStatus"

import { 
    CreatePrintBar 
} from "../../layouts/menuBars/general/CreatePrintBar"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


/**
 * The StorageUnitsPage component renders the AdminStorageUnits page of the application.
 */
export const OrganisationStorageUnitsPage = () => {
    const dispatch = useAppDispatch()    
   
    const storageUnits = useAppSelector(selectStorageUnitsById)
    const isLoadingStorageUnits = useAppSelector(selectIsLoadingStorageUnits)
    const errorStorageUnits = useAppSelector(selectErrorStorageUnits)
    
    const isLoadingPlaces = useAppSelector(selectIsLoadingPlaces)
    const errorPlaces = useAppSelector(selectErrorPlaces)

    const activeOrganisationId = useActiveOrganisationId() 
    const isEditor = useEditorStatus(activeOrganisationId)
 
    
    useEffect(() => {
        if (activeOrganisationId !== 0) {
            dispatch(fetchAllOrganisationStorageUnitsMessage(activeOrganisationId))
            dispatch(fetchAllOrganisationPlacesMessage(activeOrganisationId))
        }
    }, [dispatch, activeOrganisationId])

  
    //Loading Screen
    if (isLoadingStorageUnits || isLoadingPlaces) {
        return <LoadScreen />
    }


    //Error Screen
    if (errorStorageUnits) {
        return <ErrorScreen 
            customText={errorStorageUnits?.message} 
        />
    } else if (errorPlaces) {
        return <ErrorScreen 
            customText={errorPlaces?.message} 
        />
    }


   // Menu Buttons           
   const StorageUnitsContent = () => {
        if (isEditor) {
            return(
                <Fragment>            
                    <CreatePrintBar/>   
                    <StorageUnitTable 
                        storageUnitsForTable={Object.values(storageUnits)}
                    />
                </Fragment>
            )            
        } else {
            return(              
                <StorageUnitTable 
                    storageUnitsForTable={Object.values(storageUnits)}
                />
            )
        }
   }      

    //StorageUnit Content Admin
    return(
        <Fragment>
            {StorageUnitsContent()} 
        </Fragment>   
    )    
}