// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment
} from "react"

import { 
    useTranslation 
} from "react-i18next"

import {
    Card, 
    Group
} from "@mantine/core"


// Imports from vseth-canine-ui



// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectOrganisationSingleId, 
} from "../../features/organisation/organisationSingleIdSlice"

import {
    OrganisationUpdateForm
} from "../../components/organisation/OrganisationUpdateForm"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useIsOnAdminPage 
} from "../../hooks/useIsOnAdminPage"

import { 
    GoToTableButton 
} from "../../components/buttons/goTo/GoToTableButton"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar 
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    LastEntryOrganisationButton 
} from "../../components/buttons/lastEntry/LastEntryOrganisationButton"

import { 
    GoFromToViewPageButton 
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"

import { 
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

import {
    NextEntryOrganisationButton 
} from "../../components/buttons/nextEntry/NextEntryOrganisationButton"


/**
 * The UpdateOrganisationPage component renders the UpdateOrganisation page of the application.
 */
export const UpdateOrganisationPage = () => {
    const { t } = useTranslation()
   
    const organisations = useAppSelector(selectOrganisationsById)    
    const editOrganisationId = useAppSelector(selectOrganisationSingleId)
    const isOnAdminPage = useIsOnAdminPage()


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editOrganisationId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }

    const buttonsUpdateOrganisationPage = () => {
        if (isOnAdminPage) {
            return ( 
                <Fragment>
                    <h1>{t("Organisations.updateText")}</h1>  
                    <ViewUpdateDeletePagesBar
                        lastEntryButton = {<LastEntryOrganisationButton/>}
                        navigationButton1 = {<GoFromToViewPageButton/>}
                        navigationButton2 = {<GoFromToDeletePageButton/>}
                        navigationTableButton = {<GoToTableButton/>}
                        nextEntryButton = {<NextEntryOrganisationButton/>}
                    /> 
                </Fragment>
            )
        } else {
            return(
                <Fragment>
                    <Group 
                        position="right"
                        mt="md"
                    >
                        <GoToTableButton/>
                    </Group>   
                    <h1>{t("Organisations.updateText")}</h1>  
                </Fragment>                
            )
        }
    }
    
    
    return(       
        <Card> 
            {buttonsUpdateOrganisationPage()}               
            <OrganisationUpdateForm 
                organisationId = {editOrganisationId}
                initialOrganisationdata = {organisations[editOrganisationId].organisationInfo!}
                updateUser = {organisations[editOrganisationId].updateUser}
                updateTime = {organisations[editOrganisationId].updateTime!}
            />
        </Card>                        
    )
}