// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    OrganisationReservationsLayoutPage 
} from "../../layouts/pages/OrganisationReservationsLayoutPage"

import { 
    OrganisationsRoutesReservationsItemOwner
} from "./OrganisationsRoutesReservationsItemOwner"

import { 
    OrganisationsRoutesReservationsRequests 
} from "./OrganisationsRoutesReservationsRequests"


export const OrganisationsRoutesReservations = () => {
    return (
        <Routes>               
            <Route
                index
                element={<OrganisationReservationsLayoutPage/>}                            
            />
            <Route 
                    element={<OrganisationReservationsLayoutPage/>}       
            >
                <Route
                    path="requests/*"
                    element={<OrganisationsRoutesReservationsRequests/>}                            
                />
                <Route
                    path="owneditems/*"
                    element={<OrganisationsRoutesReservationsItemOwner/>}                            
                />
            </Route>
        </Routes>
    )
}  
