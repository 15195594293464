// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    TextInput, 
    createStyles 
} from "@mantine/core"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks




// Define interfaces
interface DisabledTextInputProps {
    label: string, 
    value: string,   
}

const useStyles = createStyles((theme, _params, getRef) => {
    return {      

        TextInput: {
            backgroundColor: theme.colorScheme === 'dark' ? '#ffffff' : '#ffffff',
            color: theme.colorScheme === 'dark' ? '#000000' : '#000000',
            opacity: 1,
            cursor: 'default ',
            border: '0px solid' ,
                        
            '*:disabled': {
                backgroundColor: '#ffffff',
                color: '#000000',
                opacity: 1,
                cursor: 'default',
                border: '0px solid',
            },

        },    
    }
})


/**
 * The AdminPage component renders the admin page of the application.
 */
export const DisabledTextInput = (props: DisabledTextInputProps) => {
    const {classes, cx } = useStyles()

    return (
        <TextInput
            className={cx(classes.TextInput)}
            label = {props.label}
            value = {props.value} 
            disabled    
            variant="unstyled"        
        />
    )
}