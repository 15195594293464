// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define which objects the externalStorageDate State have. 
interface externalStorageDateSingleIdState {
    externalStorageDateId: number,
}


// Define initial state
const initialState: externalStorageDateSingleIdState = {
    externalStorageDateId: 0,
}


// Define state
const externalStorageDateSingleId = createSlice({
    name: "externalStorageDateSingleId",
    initialState,
    reducers: {
        setExternalStorageDateSingleId(state, {payload}: PayloadAction<number>) {
            state.externalStorageDateId = payload
        },
    },
})


// Export Reducers
export default externalStorageDateSingleId.reducer


// Define Actions
const {
    setExternalStorageDateSingleId,
} = externalStorageDateSingleId.actions


// Change handler
export {
    setExternalStorageDateSingleId, 
}


// Selectors
type SingleExternalStorageDateIdSliceRoot = {
    externalStorageDateSingleId: ReturnType<typeof externalStorageDateSingleId.reducer>
}

export const selectExternalStorageDateSingleId = (state: SingleExternalStorageDateIdSliceRoot) =>
    state.externalStorageDateSingleId.externalStorageDateId
