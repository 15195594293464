// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Route,
    Routes
} from "react-router-dom"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    AdminExternalStorageDatesPage 
} from "../../pages/externalStorageDates/AdminExternalStorageDatesPage"

import { 
    CreateExternalStorageDatesPage 
} from "../../pages/externalStorageDates/CreateExternalStorageDatesPage"

import { 
    UpdateExternalStorageDatesPage 
} from "../../pages/externalStorageDates/UpdateExternalStorageDatesPage"

import { 
    DeleteExternalStorageDatesPage 
} from "../../pages/externalStorageDates/DeleteExternalStorageDatesPage"



export const AdminRoutesExternalStorageDates = () => {
    return (
        <Routes>                
            <Route
                index
                element={<AdminExternalStorageDatesPage/>}                            
            />
            <Route
                path="create"
                element={<CreateExternalStorageDatesPage/>}                            
            />
            <Route
                path="update"
                element={<UpdateExternalStorageDatesPage/>}                            
            />
            <Route
                path="delete"
                element={<DeleteExternalStorageDatesPage/>}                            
            />  
        </Routes>
    )
}     
