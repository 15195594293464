// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    PlaceCreateForm
} from "../../components/place/PlaceCreateForm"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"

import { 
    useIsOnAdminPage 
} from "../../hooks/useIsOnAdminPage"


/**
 * The CreatePlacePage component renders the CreatePlacePage page of the application.
 */
export const CreatePlacePage = () => {
    const { t } = useTranslation()
    const activeOrganisationId = useActiveOrganisationId() 
    const isOnAdminPage = useIsOnAdminPage()  


    const placeCreateForm = () => {
        if (isOnAdminPage) {
            return (
                <PlaceCreateForm 
                    organisationId = {0}
                />
            )
        } else if (activeOrganisationId !== 0) {
            return (
                <PlaceCreateForm 
                    organisationId = {activeOrganisationId}
                />
            )
        }
    }

    
    return(
        <Card>
            <h1>{t("Places.createText")}</h1>    
            {placeCreateForm()}
        </Card>        
    )
}