// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import { 
    Grid
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    DisabledDateInput 
} from "../formatter/DisabledDateInput"

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    timeObjToLocalDate 
} from "../../util/proto"



// Define interfaces
interface ExternalStorageDateViewProps {
    externalStorageDate?: Timestamp.AsObject | undefined
}

/**
 * The Page component renders the admin page of the application.
 */
//Organisation Form
export const ExternalStorageDateViewLayout = (props: ExternalStorageDateViewProps) => {
    const { t } = useTranslation()  

    const date = (props.externalStorageDate === undefined ? new Date() : timeObjToLocalDate(props.externalStorageDate))  

    return (
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledDateInput 
                    label = {t("ExternalStorageDates.date")}
                    value = {date}
                />
            </Grid.Col>
        </Grid>
    )
}