// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectPlacesById
} from "../../features/place/placesSlice"

import {
    selectPlaceSingleId,
} from "../../features/place/placeSingleIdSlice"

import { 
    PlaceViewLayout 
} from "../../layouts/views/PlaceViewLayout"

import { 
    DeletePlaceBar 
} from "../../layouts/menuBars/place/DeletePlaceBar"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar 
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    LastEntryPlaceButton
} from "../../components/buttons/lastEntry/LastEntryPlaceButton"

import { 
    GoFromToUpdatePageButton 
} from "../../components/buttons/goFromTo/GoFromToUpdatePageButton"

import { 
    GoToTableButton
} from "../../components/buttons/goTo/GoToTableButton"

import { 
    NextEntryPlaceButton 
} from "../../components/buttons/nextEntry/NextEntryPlaceButton"

import {
    GoFromToViewPageButton 
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"


/**
 * The DeletePlacePage component renders the DeletePlace page of the application.
 */
export const DeletePlacePage = () => {
    const { t } = useTranslation()
   
    const places = useAppSelector(selectPlacesById)   
    const editPlaceId = useAppSelector(selectPlaceSingleId)
  

    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editPlaceId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }

   
    return (
        <Card>  
            <h1>{t("Places.deleteText")}</h1> 
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryPlaceButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToUpdatePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryPlaceButton/>}
            /> 
            <PlaceViewLayout 
                placeId = {editPlaceId}
                initialPlaceInfo = {places[editPlaceId].placeInfo!}
                updateUser = {places[editPlaceId].updateUser}
                updateTime = {places[editPlaceId].updateTime!}
            />
            <DeletePlaceBar
                editPlaceId = {editPlaceId}
            />  
        </Card>                         
    )                  
}