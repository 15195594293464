// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect
} from 'react'

import { 
    Box, 
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import {  
    OrganisationAttributes, 
    updateOrganisationMessage
} from '../../features/organisation/organisationsSlice'

import { 
    OrganisationValidationSchema 
} from './OrganisationValidationSchema'

import { 
    OrganisationFormLayout 
} from '../../layouts/forms/OrganisationFormLayout'

import { 
    ChangeDateUserInfoNew 
} from '../../layouts/formatter/ChangeDateUserInfoNew'

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'


//Title and Initial values of the form 
interface OrganisationUpdateFormProps {
    organisationId: number,
    initialOrganisationdata: OrganisationAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
}

/**
 * The OrganisationCreateForm component renders the create Organisation Form of the application.
 */
export const OrganisationUpdateForm = (props: OrganisationUpdateFormProps) => {  
    const dispatch = useAppDispatch()
    
    const form = useForm({
        initialValues: {
            shortName: props.initialOrganisationdata.shortName || '',
            title: props.initialOrganisationdata.title ||'',
            logoPath: props.initialOrganisationdata.logoPath ||'',
            contactMail: props.initialOrganisationdata.contactMail ||'',
            contactPhone: props.initialOrganisationdata.contactPhone ||'',
            contactAddress: props.initialOrganisationdata.contactAddress ||'',
            contactAddressBuilding: props.initialOrganisationdata.contactAddress.split(',')[0] || '',
            contactAddressFloor: props.initialOrganisationdata.contactAddress.split(',')[1] || '',
            contactAddressRoom: props.initialOrganisationdata.contactAddress.split(',')[2] || '',
            contactAddressStreet: props.initialOrganisationdata.contactAddress.split(',')[3] || '',
            contactAddressNumber: props.initialOrganisationdata.contactAddress.split(',')[4] || '',
            contactAddressPLZ: props.initialOrganisationdata.contactAddress.split(',')[5] ||'',
            contactAddressCity: props.initialOrganisationdata.contactAddress.split(',')[6] ||'',
            editRole: props.initialOrganisationdata.editRole ||'',
            viewRole: props.initialOrganisationdata.viewRole ||'',
        },

        validate: yupResolver(OrganisationValidationSchema),

        transformValues: (values) => ({
            shortName: values.shortName,
            title: values.title,
            logoPath: values.logoPath,
            contactMail: values.contactMail,
            contactPhone: values.contactPhone,
            contactAddress: `${values.contactAddressBuilding + "," + values.contactAddressFloor + "," + values.contactAddressRoom + "," + values.contactAddressStreet + "," + values.contactAddressNumber + "," + values.contactAddressPLZ + "," + values.contactAddressCity}`,
            editRole: values.editRole,
            viewRole: values.viewRole,
        }),
    })


    useEffect(() => {
        form.setFieldValue("shortName", props.initialOrganisationdata.shortName || '')
        form.setFieldValue("title", props.initialOrganisationdata.title || '')
        form.setFieldValue("logoPath", props.initialOrganisationdata.logoPath || '')
        form.setFieldValue("contactMail", props.initialOrganisationdata.contactMail || '')
        form.setFieldValue("contactPhone", props.initialOrganisationdata.contactPhone || '')
        form.setFieldValue("contactAddress", props.initialOrganisationdata.contactAddress || '')
        form.setFieldValue("contactAddressBuilding", props.initialOrganisationdata.contactAddress.split(',')[0] || '')
        form.setFieldValue("contactAddressFloor", props.initialOrganisationdata.contactAddress.split(',')[1] || '')
        form.setFieldValue("contactAddressRoom", props.initialOrganisationdata.contactAddress.split(',')[2] || '')
        form.setFieldValue("contactAddressStreet", props.initialOrganisationdata.contactAddress.split(',')[3] || '')
        form.setFieldValue("contactAddressNumber", props.initialOrganisationdata.contactAddress.split(',')[4] || '')
        form.setFieldValue("contactAddressPLZ", props.initialOrganisationdata.contactAddress.split(',')[5] || '')
        form.setFieldValue("contactAddressCity", props.initialOrganisationdata.contactAddress.split(',')[6] || '')
        form.setFieldValue("editRole", props.initialOrganisationdata.editRole || '')
        form.setFieldValue("viewRole", props.initialOrganisationdata.viewRole || '')
    }, [props]);


    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => {
                    const payload = {
                        id: props.organisationId,
                        organisationInfo: values,
                        updateUser: props.updateUser + '',
                    }
                    
                    dispatch(updateOrganisationMessage(props.organisationId, payload))
                })}                
            >         
                <OrganisationFormLayout
                    form={form}
                />
                <ChangeDateUserInfoNew
                     lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
                     lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
                />  
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>            
            </form>
        </Box>
    )
}