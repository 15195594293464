// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect,
    useState 
} from "react"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "./useAppSelector"

import { 
    selectUserRoles 
} from "../features/auth/authSlice"

import { 
    useAdminStatus 
} from "./useAdminStatus"


/**
 * The Function isAdmin return true if the user has the userrole "admin"
 * 
 */
export const useNoUserRoleStatus = () => {
    const [hasNoUserRole, setHasNoUserRole] = useState(false)
    const userRoles = useAppSelector(selectUserRoles)
    const isAdmin = useAdminStatus()

    
    useEffect(() => {
        setHasNoUserRole((userRoles.length === 0 || (userRoles.length === 1 && isAdmin)))
    }, [userRoles, isAdmin])
    
    return hasNoUserRole
}