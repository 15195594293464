// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff

import { 
    useTranslation 
  } from "react-i18next"
  
  
  // Imports from vseth-canine-ui
  
  
  // Imports from this projects
// Pages, Components, Features, Hooks
  
  
  // Define interfaces
  
  
  /**
   * The NotAuthorizedPage component renders the NotAuthorizedPage page of the application.
   */
  export const NotFoundPage = () => {
    const { t } = useTranslation()
  
    return (
      <div className="d-flex justify-content-center" style={{ height: "80vh" }}>
        <form className="text-center my-auto">
          <p>{t("NotFoundPage.info")}</p>
        </form>
    </div>
  
    )
  }
  