// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Grid, 
    TextInput
} from "@mantine/core"

import { 
    UseFormReturnType 
} from "@mantine/form"

import { 
    useTranslation 
} from "react-i18next"

import { 
    useIsOnAdminPage 
} from "../../hooks/useIsOnAdminPage";
import { DisabledTextInput } from "../formatter/DisabledTextInput";


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks



type OrganisationFormAttributes = {
    shortName: string;
    title: string;
    logoPath: string;
    contactMail: string;
    contactPhone: string;
    contactAddress: string;
    contactAddressBuilding: string,
    contactAddressFloor: string,
    contactAddressRoom: string,
    contactAddressStreet: string,
    contactAddressNumber: string,
    contactAddressPLZ: string,
    contactAddressCity: string,
    editRole: string;
    viewRole: string;
}


//Styling
const styleImg: React.CSSProperties = {
    width: "50%",
    marginBottom: "1cm",
}


export const OrganisationFormLayout = ({ form }:{ form: UseFormReturnType<OrganisationFormAttributes>}) => {
    const { t } = useTranslation()  
    const isOnAdminPage = useIsOnAdminPage()

    const shortNameValue = {...form.getInputProps('shortName')}.value
    const titleValue = {...form.getInputProps('title')}.value
    const logoPathValue = {...form.getInputProps('logoPath')}.value
    const editRoleValue = {...form.getInputProps('editRole')}.value
    const viewRoleValue = {...form.getInputProps('viewRole')}.value


    if (isOnAdminPage) {
        return(
            <Grid>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.shortName")}
                        placeholder = {t("Organisations.shortName") + ''}
                        {...form.getInputProps('shortName')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.title")}
                        placeholder = {t("Organisations.title") + ''}
                        {...form.getInputProps('title')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.logoPath")}
                        placeholder = {t("Organisations.logoPath") + ''}
                        {...form.getInputProps('logoPath')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <img
                        className="photo"
                        src={`https://static.vseth.ethz.ch/assets/${form.values.logoPath}/logo.png`}
                        alt="Logo"
                        style={styleImg} 
                    /> 
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.contactMail")}
                        placeholder = {t("Organisations.contactMail") + ''}
                        {...form.getInputProps('contactMail')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressBuilding")}
                        placeholder = {t("Organisations.contactAddressBuilding") + ''}
                        {...form.getInputProps('contactAddressBuilding')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressFloor")}
                        placeholder = {t("Organisations.contactAddressFloor") + ''}
                        {...form.getInputProps('contactAddressFloor')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressRoom")}
                        placeholder = {t("Organisations.contactAddressRoom") + ''}
                        {...form.getInputProps('contactAddressRoom')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.contactPhone")}
                        placeholder = {t("Organisations.contactPhone") + ''}
                        {...form.getInputProps('contactPhone')}
                    />
                </Grid.Col>    
                <Grid.Col 
                    md={8} 
                    lg={4}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressStreet")}
                        placeholder = {t("Organisations.contactAddressStreet") + ''}
                        {...form.getInputProps('contactAddressStreet')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressNumber")}
                        placeholder = {t("Organisations.contactAddressNumber") + ''}
                        {...form.getInputProps('contactAddressNumber')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                />
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressPLZ")}
                        placeholder = {t("Organisations.contactAddressPLZ") + ''}
                        {...form.getInputProps('contactAddressPLZ')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={8} 
                    lg={4}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressCity")}
                        placeholder = {t("Organisations.contactAddressCity") + ''}
                        {...form.getInputProps('contactAddressCity')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.updateRole")}
                        placeholder = {t("Organisations.updateRole") + ''}
                        {...form.getInputProps('editRole')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.viewRole")}
                        placeholder = {t("Organisations.viewRole") + ''}
                        {...form.getInputProps('viewRole')}
                    />
                </Grid.Col>                 
            </Grid>
        )
    } else {
        return(
            <Grid>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("Organisations.shortName")}
                        value = {shortNameValue}
                        />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("Organisations.title")}
                        value = {titleValue}
                    />  
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("Organisations.logoPath")}
                        value = {logoPathValue}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <img
                        className="photo"
                        src={`https://static.vseth.ethz.ch/assets/${form.values.logoPath}/logo.png`}
                        alt="Logo"
                        style={styleImg} 
                    /> 
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.contactMail")}
                        placeholder = {t("Organisations.contactMail") + ''}
                        {...form.getInputProps('contactMail')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressBuilding")}
                        placeholder = {t("Organisations.contactAddressBuilding") + ''}
                        {...form.getInputProps('contactAddressBuilding')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressFloor")}
                        placeholder = {t("Organisations.contactAddressFloor") + ''}
                        {...form.getInputProps('contactAddressFloor')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressRoom")}
                        placeholder = {t("Organisations.contactAddressRoom") + ''}
                        {...form.getInputProps('contactAddressRoom')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <TextInput
                        label = {t("Organisations.contactPhone")}
                        placeholder = {t("Organisations.contactPhone") + ''}
                        {...form.getInputProps('contactPhone')}
                    />
                </Grid.Col>    
                <Grid.Col 
                    md={8} 
                    lg={4}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressStreet")}
                        placeholder = {t("Organisations.contactAddressStreet") + ''}
                        {...form.getInputProps('contactAddressStreet')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressNumber")}
                        placeholder = {t("Organisations.contactAddressNumber") + ''}
                        {...form.getInputProps('contactAddressNumber')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                />
                <Grid.Col 
                    md={4} 
                    lg={2}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressPLZ")}
                        placeholder = {t("Organisations.contactAddressPLZ") + ''}
                        {...form.getInputProps('contactAddressPLZ')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={8} 
                    lg={4}
                >
                    <TextInput
                        label = {t("Organisations.contactAddressCity")}
                        placeholder = {t("Organisations.contactAddressCity") + ''}
                        {...form.getInputProps('contactAddressCity')}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("Organisations.updateRole")}
                        value = {editRoleValue}
                    />
                </Grid.Col>
                <Grid.Col 
                    md={12} 
                    lg={6}
                >
                    <DisabledTextInput
                        label = {t("Organisations.viewRole")}
                        value = {viewRoleValue}
                    />
                </Grid.Col>                 
            </Grid>
        )
    }
}