// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"

import { 
    useTranslation
} from "react-i18next"

import { 
    useNavigate
} from "react-router-dom"

import {
    Button
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../LoadScreen"

import {
    ErrorScreen
} from "../ErrorScreen"

import {
    selectSub
} from "../../features/auth/authSlice"

import { 
    selectIsLoadingOrganisations,
    selectErrorOrganisations
} from "../../features/organisation/organisationsSlice"

import { 
    fetchAllCatalogueItemsMessage,
    selectErrorItemsCatalogue, 
    selectIsLoadingItemsCatalogue, 
    selectItemsCatalogueById 
} from "../../features/catalogue/itemsCatalogueSlice"

import { 
    CatalogueTable 
} from "../../components/catalogue/CatalogueTable"

import { 
    selectUsersByUUID,
    selectIsLoadingUsers,
    selectErrorUsers,
} from "../../features/user/usersSlice"

import { 
    fetchAllTagsMessage,
    selectIsLoadingTags,
    selectErrorTags
} from "../../features/tag/tagsSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    useNoUserRoleStatus 
} from "../../hooks/useNoUserRoleStatus"


/**
 * The CataloguePage component renders the catalogue page of the application.
 */
export const CataloguePage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()   
    
      
    //Selectors
    const itemsCatalogue = useAppSelector(selectItemsCatalogueById)
    const isLoadingItemsCatalogue = useAppSelector(selectIsLoadingItemsCatalogue)
    const errorItemsCatalogue = useAppSelector(selectErrorItemsCatalogue)

    const isLoadingOrganisations= useAppSelector(selectIsLoadingOrganisations)
    const errorOrganisations = useAppSelector(selectErrorOrganisations)

    const users = useAppSelector(selectUsersByUUID)
    const isLoadingUsers = useAppSelector(selectIsLoadingUsers)
    const errorUsers = useAppSelector(selectErrorUsers)

    const isLoadingTags = useAppSelector(selectIsLoadingTags)
    const errorTags = useAppSelector(selectErrorTags)

    const sub = useAppSelector(selectSub).sub
    const activeOrganisationId = (users[sub] === undefined) ? 0 : users[sub].activeOrganisation

    const hasNoUserRole = useNoUserRoleStatus()
   


    useEffect(() => {
        dispatch(fetchAllCatalogueItemsMessage(activeOrganisationId))  
        dispatch(fetchAllTagsMessage())       
    }, [dispatch, activeOrganisationId])
   

    //Loading Screen
    if (isLoadingOrganisations || isLoadingItemsCatalogue || isLoadingUsers || isLoadingTags) {
        return <LoadScreen />
    }


    //Error Screen
    if (errorOrganisations) {
        return <ErrorScreen
            customText={errorOrganisations.message}    
        />
    }  else if (errorItemsCatalogue) {
        return <ErrorScreen
            customText={errorItemsCatalogue.message}    
        />
    } else if (errorUsers) {
        return <ErrorScreen 
                customText={errorUsers.message}
            />
    } else if (errorTags) {
        return <ErrorScreen 
                customText={errorTags.message}
            />
    }


    const menuButtons = () => {
        if (hasNoUserRole) {
            return (
                <div className="row" >
                    {t("Catalogue.noUserRole")}
                </div>
            )

        } else {
            return (
                <div className="row" >
                    <div className="position-relativ col-sm-4">
                        <Button
                            color="primary" 
                            onClick={() => navigate("../reservations")}
                            fullWidth
                        >{t("Storeroom.reservations")}</Button>
                    </div>
                </div>
            )
        }  
    }

    return (
        <Fragment>
            <h1>{t("Catalogue.title")}</h1>
            {menuButtons()}
            <CatalogueTable 
                itemsCatalogueForTable={Object.values(itemsCatalogue)}
            />
        </Fragment>
    )
}