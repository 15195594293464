// Imports from this projects
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectTagsById
} from "../../features/tag/tagsSlice"


/**
 * The TagsFormatter component returns tags of an item as list of strings.
 */
export const TagsFormatter = (itemTagsIds: number[]) => {
        const tags = useAppSelector(selectTagsById)
       
        let tagsText = ""   
       
        itemTagsIds.forEach(tagId => {
            if (tags[tagId] !== undefined) {
                tagsText += tags[tagId].tagName + ", "
            }
        })
           
        return(
            tagsText
        )
    }