// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../../pages/LoadScreen"

import {
    ErrorScreen
} from "../../pages/ErrorScreen"

import { 
    StorageUnitTable 
} from "../../components/storageunit/StorageUnitsTable"

import { 
    selectStorageUnitsById,
    fetchAllStorageUnitsMessage,
    selectIsLoadingStorageUnits,
    selectErrorStorageUnits,
} from "../../features/storageunit/storageUnitSlice"

import { 
    fetchAllOrganisationsMessage,
    selectIsLoadingOrganisations,
    selectErrorOrganisations
} from "../../features/organisation/organisationsSlice"

import { 
    fetchAllPlacesMessage,
    selectIsLoadingPlaces,
    selectErrorPlaces,
} from "../../features/place/placesSlice"

import { 
    CreatePrintBar 
} from "../../layouts/menuBars/general/CreatePrintBar"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"



/**
 * The AdminStorageUnitsPage component renders the AdminStorageUnits page of the application.
 */
export const AdminStorageUnitsPage = () => {
    const dispatch = useAppDispatch()
       
    //Selectors
    const storageUnits = useAppSelector(selectStorageUnitsById)
    const isLoadingStorageUnits = useAppSelector(selectIsLoadingStorageUnits)
    const errorStorageUnits = useAppSelector(selectErrorStorageUnits)

    const isLoadingOrganisations= useAppSelector(selectIsLoadingOrganisations)
    const errorOrganisations = useAppSelector(selectErrorOrganisations)  

    const isLoadingPlaces = useAppSelector(selectIsLoadingPlaces)
    const errorPlaces = useAppSelector(selectErrorPlaces)
   
       
    //Fetch Data    
    useEffect(() => {
        dispatch(fetchAllOrganisationsMessage())
        dispatch(fetchAllStorageUnitsMessage())
        dispatch(fetchAllPlacesMessage())
    },[dispatch])

   
    
    //Loading Screen
    if (isLoadingStorageUnits || isLoadingOrganisations || isLoadingPlaces) {
        return <LoadScreen />
    }


    //Error Screen
    if (errorStorageUnits) {
        return <ErrorScreen 
            customText={errorStorageUnits?.message} 
        />
    } else if (errorOrganisations) {
        return <ErrorScreen 
            customText={errorOrganisations?.message} 
        />
    } else if (errorPlaces) {
        return <ErrorScreen 
            customText={errorPlaces?.message} 
        />
    }
   

    return(      
        <Fragment>
            <CreatePrintBar/>        
            <StorageUnitTable 
                storageUnitsForTable={Object.values(storageUnits)}
            />
        </Fragment>       
    )      
}