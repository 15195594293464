// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment 
} from "react"

import { 
    Route,
    Routes
} from "react-router-dom"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    OrganisationsLayoutPage 
} from "../../layouts/pages/OrganisationsLayoutPage"

import {
    OrganisationsRoutesOrganisations 
} from "./OrganisationsRoutesOrganisations"

import { 
    OrganisationsRoutesPlaces 
} from "./OrganisationsRoutesPlaces"

import { 
    OrganisationsRoutesStorageUnits
} from "./OrganisationsRoutesStorageUnits"

import { 
    OrganisationsRoutesItems 
} from "./OrganisationsRoutesItems"

import { 
    OrganisationsRoutesReservations 
} from "./OrganisationsRoutesReservations"

import { 
    OrganisationsRoutesExternalStorages 
} from "./OrganisationsRoutesExternalStorages"

import { 
    NotFoundPage
} from "../../pages/NotFoundPage"

export const OrganisationsRoutes = () => {
    return (
        <Routes>
            <Route 
                index
                element={<OrganisationsLayoutPage/>}
            />
            <Route 
                path=":organisationShortName"
                element={<OrganisationsLayoutPage/>}
            >
                <Route
                    index
                    element={<Fragment></Fragment>}                                                                    
                />
                    <Route
                    path="info/*"
                    element={<OrganisationsRoutesOrganisations/>}                            
                />               
                <Route
                    path="places/*"
                    element={<OrganisationsRoutesPlaces/>}                            
                />
                <Route
                    path="storageunits/*"
                    element={<OrganisationsRoutesStorageUnits/>}                            
                />
                <Route
                    path="items/*"
                    element={<OrganisationsRoutesItems/>}                            
                />
                <Route
                    path="reservations/*"
                    element={<OrganisationsRoutesReservations/>}                            
                />
                <Route
                    path="externalstorage/*"
                    element={<OrganisationsRoutesExternalStorages/>}                            
                />

                <Route
                    path="*"
                    element={<NotFoundPage/>}                            
                />
            </Route>
        </Routes>
    )
}      
