// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"

import { 
    useNavigate
} from "react-router-dom"

import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    GetTagResponse
} from "../../features/tag/tagsSlice"

import {
    setTagSingleId
} from "../../features/tag/tagSingleIdSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"


// Define interfaces
interface TagTableProps {
    tagsForTable: GetTagResponse[],
}


/**
 * The AdminPage component renders the admin page of the application.
 */
export const TagTable: React.FC<TagTableProps> = ({
    tagsForTable
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()     
    const navigate = useNavigate()

    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(tagsForTable.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })
   

    useEffect(() => {
        setPage(1)
    }, [pageSize])  
  
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(tagsForTable, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, tagsForTable])
    
    
    const columns = [
        {
            accessor: "id",
            title: t("Tags.id"),
            sortable: true,
        },
        {
            accessor: "tagName",
            title: t("Tags.name"),
            sortable: true,
        },
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            render: (tag: GetTagResponse) => (
              <Group spacing={2} position="right" noWrap>
                <ActionIcon                      
                    onClick={() => {  
                        dispatch(setTagSingleId(tag.id))           
                        navigate("update")
                    }}
                >
                    <Icon 
                        icon = {ICONS.EDIT} 
                        size = {16}
                        color="blue"
                    />      
                </ActionIcon>
                <ActionIcon                     
                    onClick={() => {
                        dispatch(setTagSingleId(tag.id))
                        navigate("delete")
                    }}
                >
                    <Icon 
                        icon = {ICONS.DELETE} 
                        size = {16}
                        color="red" 
                    />   
                </ActionIcon>
              </Group>
            ),
        },
    ]      
    

    return (       
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={tagsForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
        />     
    )
}  