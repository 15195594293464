// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectItemsById
} from "../../features/item/itemsSlice"

import {
    selectItemSingleId,
} from "../../features/item/itemSingleIdSlice"

import { 
    DeleteItemBar
} from "../../layouts/menuBars/item/DeleteItemBar"

import { 
    ItemViewLayout 
} from "../../layouts/views/ItemViewLayout"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator 
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToUpdatePageButton 
} from '../../components/buttons/goFromTo/GoFromToUpdatePageButton'

import { 
    GoFromToViewPageButton
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"

import { 
    LastEntryItemButton 
} from "../../components/buttons/lastEntry/LastEntryItemButton"

import { 
    NextEntryItemButton 
} from "../../components/buttons/nextEntry/ForwardBackItemBar"


/**
 * The DeleteItemsPage component renders the DeleteItems page of the application.
 */
export const DeleteItemsPage = () => {
    const { t } = useTranslation()

    const items = useAppSelector(selectItemsById)
    const editItemId = useAppSelector(selectItemSingleId)

    
    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editItemId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }

           
    return (
        <Card>     
            <h1>{t("Items.deleteText")}</h1> 
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryItemButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToUpdatePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryItemButton/>}
            /> 
            <ItemViewLayout 
                itemId = {editItemId}
                itemInfo = {items[editItemId].itemInfo!}
                updateUser = {items[editItemId].updateUser}
                updateTime = {items[editItemId].updateTime!}
                deleted =  {items[editItemId].deleted!}
            />
            <DeleteItemBar
                editItemId = {editItemId}
            />
        </Card>  
    )   
}