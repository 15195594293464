import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { getAuthMetadata } from "../../util/proto"
import { selectAccessToken } from "../auth/authSlice"
import { AppThunk } from "../../app/store"

import {
    ListRentableItemsRequest as listCatalogueItemsRequest,
    ListRentableItemsResponse as listCatalogueItemsResponse,
    RentableItem as rentableItem,
    ItemAttributes as itemAttributes,
    ItemStorageUnitRelationAttributes as itemStorageUnitRelationAttributes,
    ShortReservationAttributes as shortReservationAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"

//Define the Types
export type ItemAttributes = itemAttributes.AsObject
export type RentableItem = rentableItem.AsObject
export type ListCatalogueItemsResponse = listCatalogueItemsResponse.AsObject
export type ItemStorageUnitRelationAttributes = itemStorageUnitRelationAttributes.AsObject
export type ShortReservationAttributes = shortReservationAttributes.AsObject

export type ItemRentableDict = {
    [Key: number]: RentableItem
}


// Define which objects the item State have. 
interface ItemState {
    items: ItemRentableDict
    isLoading: boolean
    error?: Error
}

export const initialState: ItemState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the Slice
const itemsCatalogue = createSlice({
    name: "itemsCatalogue",
    initialState,
    reducers: {  
        fetchAllCatalogueItemsStart: (state) => {
            state.isLoading = true            
            state.error = undefined
        },
        fetchAllCatalogueItemsSuccess: (state, { payload }: PayloadAction<ListCatalogueItemsResponse>) => {
            state.items = {}
            payload.itemsList.forEach((u) => {
                state.items[u.id] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllCatalogueItemsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
    },
})

const {
    fetchAllCatalogueItemsStart,
    fetchAllCatalogueItemsSuccess,
    fetchAllCatalogueItemsFailure,
} = itemsCatalogue.actions

export default itemsCatalogue.reducer


// Actions

/**
 * fetches all storage units of an organisation
 */
export const fetchAllCatalogueItemsMessage = (organisationId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllCatalogueItemsStart())
    const token = selectAccessToken(getState())
    const request = new listCatalogueItemsRequest()
    request.setOrganisationId(organisationId)

    return storeroomClient
        .listRentableItems(request, getAuthMetadata(token))
        .then((response: listCatalogueItemsResponse) => {
            dispatch(
                fetchAllCatalogueItemsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllCatalogueItemsFailure(err))
        })
}



// Selectors
type ItemSliceRoot = {
    itemsCatalogue: ReturnType<typeof itemsCatalogue.reducer>
}

export const selectItemsCatalogueById = (state: ItemSliceRoot) =>
    state.itemsCatalogue.items
    
export const selectIsLoadingItemsCatalogue = (state: ItemSliceRoot) => state.itemsCatalogue.isLoading
export const selectErrorItemsCatalogue = (state: ItemSliceRoot) => state.itemsCatalogue.error