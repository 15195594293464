// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define which objects the place State have. 
interface placeSingleIdState {
    placeId: number,
}


//Define initial state
const initialState: placeSingleIdState = {
    placeId: 0,
}
  

// Define State
const placeSingleId = createSlice({
    name: "placeSingleId",
    initialState,
    reducers: {
        setPlaceSingleId(state, {payload}: PayloadAction<number>) {
            state.placeId = payload
        },
    },
})


// Export Reducer
export default placeSingleId.reducer


// Define Actions
const {
    setPlaceSingleId,
} = placeSingleId.actions


// Change handler
export {
    setPlaceSingleId, 
}


// Selectors
type SinglePlaceIdSliceRoot = {
    placeSingleId: ReturnType<typeof placeSingleId.reducer>
}

export const selectPlaceSingleId = (state: SinglePlaceIdSliceRoot) =>
    state.placeSingleId.placeId