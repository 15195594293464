// Imports from external sources
import { 
    useTranslation
} from "react-i18next"


// Imports from this projects
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectStorageUnitsById
} from "../../features/storageunit/storageUnitSlice"


/**
 * The IsInStorageUnitIdFormatter component returns the information, if the storageunit ist in an other storageunit.
 */
export const IsInStorageUnitIdFormatter = (storageUnitId: number) => {
    const { t } = useTranslation()
    const storageUnits = useAppSelector(selectStorageUnitsById)
   
    let isInStorageUnitText = ''      

    if (storageUnitId !== undefined) {             
        if (storageUnitId === 0) {
            isInStorageUnitText = t("StorageUnits.notInOne")
        } else if (storageUnits[storageUnitId] !== undefined) {  
            isInStorageUnitText = storageUnits[storageUnitId].storageUnitInfo!.displayName
        }
    }

    return (
        isInStorageUnitText
    )
}