// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"


import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen 
} from "../../pages/LoadScreen"

import { 
    ErrorScreen 
} from "../../pages/ErrorScreen"

import { 
    ExternalStorageTable 
} from "../../components/externalStorage/ExternalStorageTable"

import { 
    fetchAllExternalStoragesMessage,
    selectExternalStoragesById,
    selectIsLoadingExternalStorages,
    selectErrorExternalStorages
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    selectExternalStorageSingleId, 
} from "../../features/externalStorage/externalStorageSingleIdSlice"

import { 
    fetchAllOrganisationsMessage,
    selectIsLoadingOrganisations,
    selectErrorOrganisations,
} from "../../features/organisation/organisationsSlice"

import { 
    fetchAllStorageUnitsMessage,
    selectIsLoadingStorageUnits,
    selectErrorStorageUnits,
} from "../../features/storageunit/storageUnitSlice"

import { 
    fetchAllExternalStorageDatesMessage,
    selectIsLoadingExternalStorageDates,
    selectErrorExternalStorageDates,
    selectExternalStorageDatesById
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    GetExternalStorageResponse 
} from "../../features/externalStorage/externalStoragesSlice"

import { ConfirmBar } from "../../layouts/menuBars/general/ConfirmBar"

import { 
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"


/**
 * The AdminExternalStorageRequestsPage component renders the AdminExternalStorageRequests page of the application.
 */
export const AdminExternalStoragePage = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    
    //Selectors
    const externalStorages = useAppSelector(selectExternalStoragesById)
    const isLoadingExternalStorages = useAppSelector(selectIsLoadingExternalStorages)
    const isErrorExternalStorages = useAppSelector(selectErrorExternalStorages)

    const editExternalStorageId = useAppSelector(selectExternalStorageSingleId).externalStorageId
    let isDisabled = editExternalStorageId===0 ? true : false
    let isConfirmed = (externalStorages[editExternalStorageId]===undefined) ? false : (externalStorages[editExternalStorageId].confirmationTime!.seconds > 3600)
   
    const isLoadingOrganisations= useAppSelector(selectIsLoadingOrganisations)
    const errorOrganisations = useAppSelector(selectErrorOrganisations)  

    const isLoadingStorageUnits = useAppSelector(selectIsLoadingStorageUnits)
    const errorStorageUnits = useAppSelector(selectErrorStorageUnits)

    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    const isLoadingExternalStorageDates = useAppSelector(selectIsLoadingExternalStorageDates)
    const isErrorExternalStorageDates = useAppSelector(selectErrorExternalStorageDates)


     //Fetch Data        
    useEffect(() => {
        dispatch(fetchAllOrganisationsMessage())
        dispatch(fetchAllExternalStoragesMessage())
        dispatch(fetchAllStorageUnitsMessage())
        dispatch(fetchAllExternalStorageDatesMessage())
    }, [dispatch])

       
    //Loading Screen
    if (isLoadingExternalStorages || isLoadingOrganisations || isLoadingStorageUnits || isLoadingExternalStorageDates) {
        return <LoadScreen />
    }


    //Error Screen
    if (isErrorExternalStorages) {
        return <ErrorScreen 
            customText={isErrorExternalStorages?.message} 
        />       
    } else if (errorOrganisations) {
        return <ErrorScreen 
            customText={errorOrganisations?.message} 
        />
    } else  if (errorStorageUnits) {
        return <ErrorScreen 
            customText={errorStorageUnits?.message} 
        />
    } else if (isErrorExternalStorageDates) {
        return <ErrorScreen 
            customText={isErrorExternalStorageDates?.message} 
        />       
    }
    
    const requestsBeforeStart = new Array<GetExternalStorageResponse>()
    const requestsBeforeEnd = new Array<GetExternalStorageResponse>()
    const requestsArchive = new Array<GetExternalStorageResponse>()
 
    if (externalStorages !== undefined && externalStorageDates !== undefined) {
        for (var i in externalStorages) {
            const curStartTime = externalStorageDates[externalStorages[i].externalStorageInfo!.startTimeId].date!.seconds
            const curEndTime = externalStorageDates[externalStorages[i].externalStorageInfo!.endTimeId].date!.seconds
            const curTime = new Date().getTime() / 1000

            if (curStartTime > curTime) {
                requestsBeforeStart.push(externalStorages[i])
            } else if (curEndTime > curTime) {
                requestsBeforeEnd.push(externalStorages[i])
            } else {
                requestsArchive.push(externalStorages[i])
            }
        }
    }
    
    return(
        <Fragment>
            <CreateBar/>
            <ConfirmBar
                isDisabled={isDisabled}
                isConfirmed={isConfirmed}
            />
            <h3>{t("ExternalStorages.beforeStart")}</h3>
            <ExternalStorageTable 
                externalStoragesForTable = {Object.values(requestsBeforeStart)}
                isBeforeStart = {true}
            />
            <hr/> 
            <h3>{t("ExternalStorages.beforeEnd")}</h3>
            <ExternalStorageTable 
                externalStoragesForTable = {Object.values(requestsBeforeEnd)}                      
                isBeforeEnd = {true}
            />            
        
            <hr/> 
            
            <h3>{t("ExternalStorages.archive")}</h3>
            <ExternalStorageTable 
                externalStoragesForTable={Object.values(requestsArchive)}
                isBeforeStart = {true}
                isBeforeEnd = {true}
                isArchive = {true}
            />
        </Fragment> 
    )
}