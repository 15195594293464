// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define which objects the storageunit State have. 
interface storageUnitSingleIdState {
    storageUnitId: number,
}


//Define initial state
const initialState: storageUnitSingleIdState = {
    storageUnitId: 0,
}
  

//Define state
const storageUnitSingleId = createSlice({
    name: "storagUunitSingleId",
    initialState,
    reducers: {
        setStorageUnitSingleId(state, {payload}: PayloadAction<number>) {
            state.storageUnitId = payload
        },
    },
})


// Export Reducer
export default storageUnitSingleId.reducer


// Define Actions
const {
    setStorageUnitSingleId,
} = storageUnitSingleId.actions


// Change handler
export {
    setStorageUnitSingleId, 
}


// Selectors
type SingleStorageUnitIdSliceRoot = {
    storageUnitSingleId: ReturnType<typeof storageUnitSingleId.reducer>
}

export const selectStorageUnitSingleId = (state: SingleStorageUnitIdSliceRoot) =>
    state.storageUnitSingleId.storageUnitId