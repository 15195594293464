// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Grid, 
    Select, 
    TextInput
} from "@mantine/core"

import { 
    UseFormReturnType 
} from "@mantine/form"

import { 
    useTranslation 
} from "react-i18next"

import { 
    useLocation 
} from "react-router-dom"


// Imports from vseth-canine-ui

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"


import { 
    SelectoptionsAllOrganisationsNameNew 
} from "../../components/selectoptions/SelectoptionAllOrganisationsNameNew"

import { 
    PlaceAttributes 
} from "../../features/place/placesSlice"

import { 
    DisabledTextInput 
} from "../formatter/DisabledTextInput"


/**
 * The Page component renders the admin page of the application.
 */
export const PlaceFormLayout = ({ form }:{ form: UseFormReturnType<PlaceAttributes>}) => {
    const { t } = useTranslation()  
    
    const location = useLocation()

    const organisations = useAppSelector(selectOrganisationsById)
  
    const organisationIdValue = {...form.getInputProps('organisationId')}.value
   
    const organisationField = () => {
        if (location.pathname === "/admin/places/create") {
            return (
                <div>
                    <Select 
                        label = {t("Storeroom.organisation")}
                        placeholder = {t("Storeroom.organisation") + ''}
                        searchable
                        data={SelectoptionsAllOrganisationsNameNew()} 
                        {...form.getInputProps('organisationId')}      
                    />                             
                </div>                    
            )
        } else {            
            return (
                <DisabledTextInput
                    label = {t("Storeroom.organisation")} 
                    value = {(organisations[organisationIdValue] === undefined) ? "" : organisations[organisationIdValue].organisationInfo!.shortName}                
                />
            )
        }
    }

    return(
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("Places.room")}
                    placeholder = {t("Places.room") + ''}
                    {...form.getInputProps('room')}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("Places.rack")}
                    placeholder = {t("Places.rack") + ''}
                    {...form.getInputProps('rack')}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("Places.description")}
                    placeholder = {t("Places.description") + ''}
                    {...form.getInputProps('description')}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                {organisationField()}
            </Grid.Col>             
        </Grid>
    )
}