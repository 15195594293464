// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect
} from "react"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    LoadScreen
} from "../../pages/LoadScreen"

import {
    ErrorScreen
} from "../../pages/ErrorScreen"

import {
    TagTable
} from "../../components/tag/TagTable"

import { 
    selectTagsById,
    fetchAllTagsMessage,
    selectIsLoadingTags,
    selectErrorTags
} from "../../features/tag/tagsSlice"

import { 
    CreateBar 
} from "../../layouts/menuBars/general/CreateBar"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

 

/**
 * The AdminTagsPage component renders the AdminTags page of the application.
 */
export const AdminTagsPage = () => {
    const dispatch = useAppDispatch()
    
    const tags = useAppSelector(selectTagsById)
    const isLoadingTags = useAppSelector(selectIsLoadingTags)
    const errorTags = useAppSelector(selectErrorTags)   


    useEffect(() => {       
        dispatch(fetchAllTagsMessage())
    }, [dispatch])   


    //Loading Screen
    if (isLoadingTags) {
        return <LoadScreen />
    }


    //Error Screen
    if (errorTags) {
        return <ErrorScreen
            customText={errorTags.message}    
        />
    }  
    
    return(      
        <Fragment>
            <CreateBar/> 
            <TagTable 
                tagsForTable={Object.values(tags)}
            />
        </Fragment>      
    )      
}