// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    configureStore, 
    Action, 
    // getDefaultMiddleware, 
} from "@reduxjs/toolkit"

import { 
    ThunkAction
} from "redux-thunk"

import { 
    createLogger 
} from "redux-logger"


// Imports from this projects
// Pages, Components, Features, Hooks
import 
    rootReducer,
    { RootState } 
from "./rootReducer"

import { 
    StoreroomPromiseClient 
} from "../proto/sip/storeroom/storeroom_grpc_web_pb"

import { 
    webapi 
} from "./Consts"


// Define interfaces
/**
 * Construct the redux store used in the application.
 */

let storeroomClient = new StoreroomPromiseClient(
    webapi,
    {},
    {}
)

export const useStoreroomClient = () => {
    return storeroomClient
}


// let customizedMiddleware = getDefaultMiddleware({
//     thunk: {
//         extraArgument: storeroomClient,
//     },
// })

// if (process.env.NODE_ENV === "development") {
//     const logger = createLogger()
//     customizedMiddleware = customizedMiddleware.prepend(logger)
// }

const logger = createLogger()
       
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: {extraArgument: storeroomClient}}).concat(logger),
   // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat({thunk: {extraArgument: storeroomClient}}).concat(logger),
})



if (process.env.NOVE_ENV === "development" && module.hot) {
    module.hot.accept("./rootReducer.ts", () => {
        const newRootReducer = require("./rootReducer.ts").default
        store.replaceReducer(newRootReducer)
    })
}

// Infer the `RootState` and `AppDispatch` types from the st
export type AppThunk = ThunkAction<
    void,
    RootState,
    StoreroomPromiseClient,
    Action<string>
>

export type AppDispatch = typeof store.dispatch

export default store