// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    connect 
} from "react-redux"

import {
    withFormik,
} from "formik"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    createExternalStorageMessage,
    ExternalStorageAttributes 
} from "../../../features/externalStorage/externalStoragesSlice"

import {
    ExternalStorageForm
} from "./ExternalStorageForm"

import {
    ExternalStorageValidationSchema
} from "./ExternalStorageValidationSchema"

import { 
    Timestamp
} from "google-protobuf/google/protobuf/timestamp_pb"


// Define interfaces


/**
 * The Page component renders the admin page of the application.
 */
// DEFINE INTERFACES
//Map Dispatch function to 
interface DispatchProps {
    create: any
}

//Title and Initial values of the form 
interface MyFormBaseProps {
    title?: string 
    externalstorageid?: number
    organisationId?: number
    lastchangeuser?: string,
    lastchangedate?: Timestamp.AsObject,
}

type MyFormProps = MyFormBaseProps & DispatchProps


// Export Component
const formikEnhancer = withFormik<MyFormProps, ExternalStorageAttributes>({
    // Transform outer props into form values
    mapPropsToValues: props => {
        return {            
            subject: '',
            requestText: '',
            startTimeId: 0,
            endTimeId: 0,
            organisationId: props.organisationId || 0,
            storageUnitRelationList: []
        }
    },
  

    // Validation function
    validationSchema:ExternalStorageValidationSchema,

    handleSubmit: (values, { props, setSubmitting, resetForm }) => {        
        setSubmitting(true)
        props.create(values)
        setSubmitting(false)        
        resetForm({values})
    },

    displayName: 'Create External Storage Form',

})(ExternalStorageForm)



const mapDispatchToProps = (dispatch: any) => ({
    create: (values: ExternalStorageAttributes) => dispatch(createExternalStorageMessage(values))
})


export const ExternalStorageCreateForm = connect(
    null,
    mapDispatchToProps
)(formikEnhancer)

export default ExternalStorageCreateForm