// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import {   
    Flex, 
} from '@mantine/core'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    DeleteOrganisationButton
} from '../../../components/buttons/delete/DeleteOrganisationButton'

import { 
    CancelButton 
} from '../../../components/buttons/form/CancelButton'


interface DeleteOrganisationBarProps {
    editOrganisationId: number,
}


/**
 * This menu bar renders the save, save&close and cancel button.
 */
export const DeleteOrganisationBar = (props: DeleteOrganisationBarProps) => {   
    return (
        <Flex 
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'sm', sm: 'lg' }}
            justify={{ sm: 'flex-end' }}
        >
            <DeleteOrganisationButton
                editOrganisationId = {props.editOrganisationId}
            />
            <CancelButton/>
        </Flex>   
    )
} 