import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { getAuthMetadata } from "../../util/proto"
import { selectAccessToken } from "../auth/authSlice"
import { AppThunk } from "../../app/store"

import {
    ListStorageUnitItemsRequest as listStorageUnitItemsRequest,
    ListStorageUnitItemsResponse as listStorageUnitItemsResponse,
    GetItemResponse as getItemResponse,
} from "../../proto/sip/storeroom/storeroom_pb"


//Define the Types
export type ListStorageUnitItemsResponse = listStorageUnitItemsResponse.AsObject
export type GetItemResponse = getItemResponse.AsObject


type StorageUnitItemsDict = {
    [Key: number]: ListStorageUnitItemsResponse
}


// Define which objects the storageunit State have. 
interface StorageUnitState {
    items: StorageUnitItemsDict
    isLoading: boolean
    error?: Error
}

export const initialState: StorageUnitState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the Slice
const storageUnitItems = createSlice({
    name: "storageUnitItems",
    initialState,
    reducers: {  
        fetchStorageUnitItemsStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchStorageUnitItemsSuccess: (state, { payload }: PayloadAction<ListStorageUnitItemsResponse>) => {
            state.items = {}
            state.items[payload.id] = payload
            state.isLoading = false
            state.error = undefined
        },
        fetchStorageUnitItemsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
    },
})

const {   
    fetchStorageUnitItemsStart,
    fetchStorageUnitItemsSuccess,
    fetchStorageUnitItemsFailure,
} = storageUnitItems.actions

export default storageUnitItems.reducer



 //fetches the items of one storage unit.
 export const fetchStorageUnitItemsMessage = (storageunitId: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchStorageUnitItemsStart())
    const token = selectAccessToken(getState())
    const request = new listStorageUnitItemsRequest()
    request.setId(storageunitId)

    return storeroomClient
        .listStorageUnitItems(request, getAuthMetadata(token))
        .then((response: listStorageUnitItemsResponse) => {
            dispatch(fetchStorageUnitItemsSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchStorageUnitItemsFailure(err))
        })
}


// Selectors
type StorageUnitITemsSliceRoot = {
    storageUnitItems: ReturnType<typeof storageUnitItems.reducer>
}

export const selectAllStorageUnitItemsById = (state: StorageUnitITemsSliceRoot) =>
    state.storageUnitItems.items
    
export const selectIsLoadingStorageUnitItems = (state: StorageUnitITemsSliceRoot) => state.storageUnitItems.isLoading
export const selectErrorStorageUnitItems = (state: StorageUnitITemsSliceRoot) => state.storageUnitItems.error