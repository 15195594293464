// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSlice,
    PayloadAction 
} from "@reduxjs/toolkit"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    getAuthMetadata
} from "../../util/proto"

import { 
    selectAccessToken 
} from "../auth/authSlice"

import { 
    AppThunk 
} from "../../app/store"

import {
    UpdateUserRequest as updateUserRequest,
    ListUsersRequest as listUsersRequest,
    ListUsersResponse as listUsersResponse,
    UserAttributes as userAttributes,
} from "../../proto/sip/storeroom/storeroom_pb"
import { Empty } from "google-protobuf/google/protobuf/empty_pb"


//Define the Types
export type UserAttributes = userAttributes.AsObject
type ListUsersResponse = listUsersResponse.AsObject

type UserDict = {
    [uuid: string]: UserAttributes
}


// Define which objects the place State have. 
interface UserState {
    items: UserDict
    isLoading: boolean
    error?: Error
}


// Define initial state
const initialState: UserState = {
    items: {},
    isLoading: false,
    error: undefined,
}


// Define the State
const users = createSlice({
    name: "users",
    initialState,
    reducers: {  
        // Fetch all users
        fetchAllUsersStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchAllUsersSuccess: (state, { payload }: PayloadAction<ListUsersResponse>) => {
            state.items = {}
            payload.usersList.forEach((u) => {
                state.items[u.uuid] = u
            })
            state.isLoading = false            
            state.error = undefined
        },
        fetchAllUsersFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },

        // Fetch one user
        fetchUserStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        fetchUserSuccess: (state, { payload }: PayloadAction<UserAttributes>) => {
            state.items[payload.uuid] = payload
            state.isLoading = false
            state.error = undefined
        },
        fetchUserFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
               
        // Update User
        updateUserStart: (state) => {
            state.isLoading = true
            state.error = undefined
        },
        updateUserSuccess: (state, { payload }: PayloadAction<UserAttributes>) => {
            state.items[payload.uuid] = payload
            state.isLoading = false
            state.error = undefined
        },
        updateUserFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
    },
})


// Export Reducers
export default users.reducer


// Define Actions
const {
    fetchAllUsersStart,
    fetchAllUsersSuccess,
    fetchAllUsersFailure,

    fetchUserStart,
    fetchUserSuccess,
    fetchUserFailure,  
      
    updateUserStart,
    updateUserSuccess,
    updateUserFailure,
} = users.actions


// Fetch all users
export const fetchAllUsersMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchAllUsersStart())
    const token = selectAccessToken(getState())
    const request = new listUsersRequest()

    return storeroomClient
        .listUsers(request, getAuthMetadata(token))
        .then((response: listUsersResponse) => {
            dispatch(
                fetchAllUsersSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchAllUsersFailure(err))
        })
}


// Fetch one place
export const fetchUserMessage = (): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(fetchUserStart())
    const token = selectAccessToken(getState())
    const request = new Empty()

    return storeroomClient
        .getUser(request, getAuthMetadata(token))
        .then((response: userAttributes) => {
            dispatch(fetchUserSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(fetchUserFailure(err))
        })
}

// Update User
export const updateUserMessage = (user: UserAttributes): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateUserStart())
    const token = selectAccessToken(getState())

    const userData = new updateUserRequest()
    userData.setLanguage(user.language)     
    userData.setActiveOrganisation(user.activeOrganisation)

    return storeroomClient
        .updateUser(userData, getAuthMetadata(token))
        .then((response: userAttributes) => {
            dispatch(updateUserSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateUserFailure(err))
        })
}


// Update User
export const updateUserLanguageMessage = (user: UserAttributes, language: string): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateUserStart())
    const token = selectAccessToken(getState())

    const userData = new updateUserRequest()
    userData.setLanguage(language)   
    userData.setActiveOrganisation(user.activeOrganisation)

    return storeroomClient
        .updateUser(userData, getAuthMetadata(token))
        .then((response: userAttributes) => {
            dispatch(updateUserSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateUserFailure(err))
        })
}

// Update User
export const updateUserActiveOrganisationMessage = (user: UserAttributes, activeOrganisation: number): AppThunk => async (
    dispatch,
    getState,
    storeroomClient
) => {
    dispatch(updateUserStart())
    const token = selectAccessToken(getState())

    const userData = new updateUserRequest()
    userData.setLanguage(user.language)   
    userData.setActiveOrganisation(activeOrganisation)

    return storeroomClient
        .updateUser(userData, getAuthMetadata(token))
        .then((response: userAttributes) => {
            dispatch(updateUserSuccess(response.toObject()))
        })
        .catch((err) => {
            dispatch(updateUserFailure(err))
        })
}



// Selectors
type UserSliceRoot = {
    users: ReturnType<typeof users.reducer>
}

export const selectUsersByUUID = (state: UserSliceRoot) =>
    state.users.items
    
export const selectIsLoadingUsers = (state: UserSliceRoot) => state.users.isLoading
export const selectErrorUsers = (state: UserSliceRoot) => state.users.error