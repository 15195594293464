// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box, 
    Grid 
} from '@mantine/core'

import { 
    useTranslation 
} from 'react-i18next'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    PlaceAttributes
} from "../../features/place/placesSlice"

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    ChangeDateUserInfoNew 
} from '../formatter/ChangeDateUserInfoNew'

import { 
    DisabledTextInput
} from '../formatter/DisabledTextInput'

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"


// Define interfaces

//Title and Initial values of the form 
interface PlaceViewProps {
    placeId: number,
    initialPlaceInfo: PlaceAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
}



export const PlaceViewLayout = (props: PlaceViewProps) => {
    const { t } = useTranslation()      
    const organisationIdValue = props.initialPlaceInfo.organisationId

    const organisations = useAppSelector(selectOrganisationsById)
    
    
    return(
        <Box 
            mx="auto"
        >
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Places.room")}
                    value = {props.initialPlaceInfo.room}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Places.rack")}
                    value = {props.initialPlaceInfo.rack}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Places.description")}
                    value = {props.initialPlaceInfo.description}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <DisabledTextInput
                    label = {t("Storeroom.organisation")}
                    value = {(organisations[organisationIdValue] === undefined) ? "" : organisations[organisationIdValue].organisationInfo!.shortName}     
                />
            </Grid.Col>             
        </Grid>
        <ChangeDateUserInfoNew
            lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
            lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
        />  
        </Box>
    )
}