// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import * as Yup from "yup"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The Page component renders the admin page of the application.
 */
export const ItemValidationSchema = Yup.object().shape({
    displayName: Yup.string()
      .required('Please input name'),
    description: Yup.string()
      .required('Please input description'),
       
    // storageunitrelationList: Yup.array().of(
    //   Yup.object({
    //     storageunitid: Yup.number().required(),
    //     quantity: Yup.number().required().min(1)
    //   })
    // )
    
    }
)