import { useState, useEffect } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { AuthState } from "../../features/auth/authSlice";
import { getAuthStateFromToken } from "../../util/getAuthStateFromToken";
import React from "react";

export type AuthProviderFC = React.FC<
	Partial<any> & {
		authState?: AuthState;
		setAuthState?: (authState: AuthState) => void;
		onReady?: () => void;
		onAuthSuccess?: () => void;
		onAuthError?: (err: Error) => void;
		onAuthRefreshSuccess?: () => void;
		onAuthRefreshError?: (err: Error) => void;
		onAuthLogout?: () => void;
		onTokenExpired?: () => void;
	}
>;

/*
export type AuthProviderSettings = typeof defaultSettings;
const defaultSettings = {
  url: "https://auth.vseth.ethz.ch/auth",
  realm: "VSETH",
  clientId: "default-frontend-client-id"
};
*/

const defaultValue = getAuthStateFromToken();

export const AuthContext = React.createContext({
	authState: defaultValue,
	setAuthState: (_authState: AuthState) => {},
});

/**
 * AuthProvider is a Component that wraps the KeycloakProvider Component from https://github.com/panz3r/react-keycloak
 * with useful defaults specific to VSETH
 * In case you use redux and would like to put the state in there, you can pass a function in
 * the updateAuthObject prop that gets called with the AuthState every time it updates
 */
export const AuthProvider: AuthProviderFC = (props) => {
	const [localAuthState, localSetAuthState] = useState(
		props.authState ? props.authState : defaultValue
	);

	const {
		authState = localAuthState,
		setAuthState = localSetAuthState,
		onTokenExpired = () => {
			console.log(
				"You've been automatically logged out because your token expired. To override this behaviour, you can pass onTokenExpired to AuthProvider"
			);
			setAuthState(getAuthStateFromToken());
			// keycloak.logout();
		},
		...rest
	} = props;

	useEffect(() => {
		if (authState && authState.userInfo && authState.isAuthenticated) {
			const tokenExpirationDelta = authState.userInfo.exp - Date.now() / 1000;
			console.log("Token is valid another ", Math.floor(tokenExpirationDelta));

			const timer = setTimeout(() => {
				onTokenExpired();
			}, tokenExpirationDelta * 1000 - 2000);
			return () => clearTimeout(timer);
		}
		return;
	}, [authState]);

	return (
		<AuthContext.Provider
			value={{
				authState: authState ? authState : defaultValue,
				setAuthState,
			}}
		>
			<AuthContext.Consumer>
				{({ authState, setAuthState }) => (
					<ReactKeycloakProvider
						initOptions={{
							flow: "implicit",
							checkLoginIframe: false,
							onLoad: undefined,
						}}
						authClient={keycloak}
						onTokens={(tokens: any) => {
							const newAuthState = getAuthStateFromToken(tokens);
							if (newAuthState.isAuthenticated && !authState.isAuthenticated) {
								setAuthState(newAuthState);
							}
						}}
						onEvent={(type, err) => {
							if ((rest as any)[type]) {
								(rest as any)[type]!(err!);
							}
						}}
						{...rest}
					/>
				)}
			</AuthContext.Consumer>
		</AuthContext.Provider>
	);
};

export const keycloak = new Keycloak("/keycloak.json");

(window as any).keycloak = keycloak;
