// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const SubstituteTimestamp = () => {
    //Get Date from Google Timestamp
    var currentTimeInSeconds=Math.floor(Date.now()/1000) //unix timestamp in seconds
    var currentTimeInMilliseconds=Date.now() // unix timestamp in milliseconds
    let substituteTime = {seconds: currentTimeInSeconds, nanos: currentTimeInMilliseconds}
   
    //Lastchange Date and User Information
    return (
        substituteTime 
    )
}