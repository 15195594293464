import { useAuth } from "../../hooks/useAuth";
import { LoginButton } from "./LoginButton";

export interface ProtectedRouteProps {
	children?: any;
}

const PleaseLogIn = () => {
	return (
		<>
			<LoginButton />
			<p>Please Log in</p>
		</>
	);
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
	const { authState } = useAuth();

	// handle users that are not authenticated
	if (!authState.isAuthenticated) {
		return PleaseLogIn();
	}

	return children;
};