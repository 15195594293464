// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import {
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const SelectoptionsAllOrganisationsName = () => {
    const organisations = useAppSelector(selectOrganisationsById)
     

    //initialise options
    const options= [
        {label: '', value: 0},
    ] as any
    options.pop()

    //For Loop over all organisations
    for (var i in organisations) {
        const currValue = organisations[i].id
        const currName = organisations[i].organisationInfo?.shortName

        options.push(
            {label: currName, value: currValue, }
        )
    }        

    return(
        options
    )        
}