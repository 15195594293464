// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box, Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    createPlaceMessage 
} from "../../features/place/placesSlice"

import {
    PlaceFormLayout
} from "../../layouts/forms/PlaceFormLayout"

import {
    PlaceValidationSchema
} from "./PlaceValidationSchema"

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'


// Define interfaces
//Title and Initial values of the form 
interface PlaceCreateFormProps {    
    organisationId?: number
}


/**
 * The Page component renders the Place Create Form page of the application.
 */
export const PlaceCreateForm = (props: PlaceCreateFormProps) => { 
    const dispatch = useAppDispatch()

    const form = useForm({
        initialValues: {
            room: '',
            rack: '',
            description: '',
            organisationId: props.organisationId || 0,
        },

        validate: yupResolver(PlaceValidationSchema),

        transformValues: (values) => ({
            room: values.room,
            rack: values.rack,
            description: values.description,
            organisationId: Number(values.organisationId) || 0,
        }),
    })

    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(createPlaceMessage(values)))}
            >
                <PlaceFormLayout
                    form={form}
                />
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}