// Imports from external sources
import { 
    useTranslation
} from "react-i18next"


/**
 * The ExternalRentFormatter component returns the value of externalRent.
 */
export const ExternalRentFormatter = (externalRent: boolean) => {
    const { t } = useTranslation()

    let externalRentText = ''

    if (externalRent !== undefined) {
        if (externalRent === true) {
            externalRentText = t("Storeroom.yes")
        } else {
            externalRentText = t("Storeroom.no")
        }
    }
           
    return(
        externalRentText
    )
}