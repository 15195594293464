// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import { 
    Grid, 
    TextInput
} from "@mantine/core"

import { 
    UseFormReturnType 
} from "@mantine/form"

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    TagAttributes 
} from "../../features/tag/tagsSlice"


// Define interfaces

/**
 * The Page component renders the admin page of the application.
 */
//Organisation Form
export const TagFormLayout = ({ form }:{ form: UseFormReturnType<TagAttributes>}) => {
    const { t } = useTranslation()  

    
    return (
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("Tags.name")}
                    placeholder = {t("Tags.name") + ''}
                    {...form.getInputProps('tagName')}
                />
            </Grid.Col>
        </Grid>
    )
}