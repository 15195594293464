// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Fragment,
    useEffect,
    useState 
} from 'react'

import { 
    useTranslation
} from "react-i18next"

import {  
    useLocation
} from "react-router-dom"

import {
    Container,
    createStyles, 
    Navbar,
    Space
} from "@mantine/core"


// Imports from vseth-canine-ui

// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    selectSub,
    selectUserRoles
} from "../../features/auth/authSlice"

import { 
    selectUsersByUUID,
    updateUserActiveOrganisationMessage,
} from "../../features/user/usersSlice"

import { 
    useActiveOrganisationId 
} from '../../hooks/useActiveOrganisationId'

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"


// Define interfaces
const useStyles = createStyles((theme, _params, getRef) => {
    return {
        header: {
            paddingBottom: theme.spacing.md,
            marginBottom: `calc(theme.spacing.md * 1.5)`,
            borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
            }`,
        },

        link: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.sm,
            color: theme.colorScheme === 'light' ? theme.colors.dark[9] : theme.colors.gray[2],
            padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
            borderRadius: theme.radius.sm,
            fontWeight: 500,
            border: '2px solid',
            borderColor: theme.colors.vsethMain,
            margin: '0px',
           
            '&:hover': {
                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.gray[0],
                color: theme.colorScheme === 'light' ? theme.black : theme.white,          
            },
        },    

        linkActive: {
            '&, &:hover': {
                backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
                .background,
                color: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).color,
            },
        },
    }
})


/**
 * The MyOrganisationsList component renders the Navbar for your organisationsn.
 */
export const MyOrganisationsList = () => {
    const {classes, cx } = useStyles()
    const [active, setActive] = useState('')

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const organisations = useAppSelector(selectOrganisationsById)    
    const users = useAppSelector(selectUsersByUUID)

    const sub = useAppSelector(selectSub).sub
    const activeOrganisationId = useActiveOrganisationId()  
    const userRoles = useAppSelector(selectUserRoles)
    

    useEffect(() => {
        if (organisations[activeOrganisationId] !== undefined) {
            setActive(organisations[activeOrganisationId].organisationInfo!.shortName)
        }           
    }, [organisations, activeOrganisationId])


    const isOnOrganisationPage = () => {
        const currLocation = location.pathname.split("/")
        const currPath = currLocation[1]

        if (currPath === "organisations") {
            return true
        } else {
            return false
        }
    }


    const isOnReservationPage = () => {
        const currLocation = location.pathname.split("/")
        const currPath = currLocation[1]

        if (currPath === "reservations") {
            return true
        } else {
            return false
        }
    }


    //initialise data
    const myOrganisations = [
        { link: '', label: '', orgId: 0},  
    ]
    myOrganisations.pop()
    
   
    for (var i in userRoles) {
        if (userRoles[i] !== "admin") {
            for (var j in organisations) {
                const currId = organisations[j].id
                const currShortName = organisations[j].organisationInfo!.shortName
                const currEditRole = organisations[j].organisationInfo!.editRole
                const currViewRole = organisations[j].organisationInfo!.viewRole
        
                
                const currEntry = () => {
                    if (isOnOrganisationPage()) { //Organisation Page
                        return (
                            { link: '/organisations/' + currShortName, label: `${currShortName}`, orgId: currId}                           
                        )
                    } else if (isOnReservationPage()) { //Reservation Page
                        return (
                            { link: '/reservations/' + currShortName, label: `${currShortName}`, orgId: currId}    
                        )
                    } else  {  //Cataloque Page
                        return (
                            { link: '', label: `${currShortName}`, orgId: currId} 
                        )                 
                    }
                }                 
                
                    
                if (currEditRole === userRoles[i]) {
                    myOrganisations.push(currEntry())
                } 
                if (currViewRole === userRoles[i] && userRoles.indexOf(currEditRole) === -1 ) {
                    myOrganisations.push(currEntry())
                }
                                   
            }        
        }
    }


    const links = myOrganisations.map((item) => (
        <Fragment>
            <a
                className={cx(classes.link, {[classes.linkActive]: item.label === active})}
                href={item.link}
                key={item.orgId}
                onClick={(event) => {
                    event.preventDefault()
                    setActive(item.label)
                    dispatch(updateUserActiveOrganisationMessage(users[sub], item.orgId)) 
                }}
                >
                <span>{item.label}</span>
            </a>
            <Space
                h="xs" 
            />
        </Fragment>      
    ))


    return (
        <Container>
            <Navbar>
                <Navbar.Section className={classes.header}>
                    <h4>{t("Organisations.myOrganisations")}</h4>
                </Navbar.Section>
                <Navbar.Section grow mt="md">
                    {links}
                </Navbar.Section>
            </Navbar>
        </Container>
    )
}