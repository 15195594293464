// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Button
} from "@mantine/core"

import { 
    useTranslation 
} from "react-i18next"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../../hooks/useAppSelector"

import { 
    selectTagsById
} from "../../../features/tag/tagsSlice"

import {
    selectTagSingleId, 
    setTagSingleId,
} from "../../../features/tag/tagSingleIdSlice"

import {
    useAppDispatch 
} from "../../../hooks/useAppDispatch"


export const NextEntryTagButton = () => {  
    const { t } = useTranslation()    
    const dispatch = useAppDispatch()
    
    const tags = useAppSelector(selectTagsById)
    const editTagId = useAppSelector(selectTagSingleId)


    //initialise options
    const tagIds = [] as number[]

    //For Loop over all organisations
    for (var i in tags) {
        const currValue = tags[i].id
       
        tagIds.push(currValue)
    }        
   
    const lengthArr = tagIds.length
    const currIndex = tagIds.indexOf(editTagId)


    const onChangeForward = () => {
         return(
            dispatch(setTagSingleId(tagIds[currIndex+1]))
        )
    }

    
    const isDisabledForward = () => {
        if (tagIds[lengthArr-1] === editTagId) {
            return(
                true
            )
        } else {
            return(
                false
            )
        }    
    }

    
    return (       
        <Button 
            onClick={() => {onChangeForward()}}
            disabled = {isDisabledForward()}
            fullWidth
            variant="outline"
        >
            {t("Storeroom.nextEntryButton")}
        </Button>            
    )
} 